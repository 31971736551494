import React, { useCallback } from 'react';
import {
	Row, Col, Button,
} from 'antd';
import BaseModal, { BaseModalProps } from '../base';
import { ApiTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

/* This is the pathway that navigates the user to the CopilotAI page allowing user to reconnect to their LinkedIn account */
const path = '/settings/linkedin';

/**
 * Reconnect LinkedIn Modal for users logging onto CopilotAI platform who are not connected to their LinkedIn account
 * @param {function} onCancel called to close the modal, passed in automatically
 */
const ReconnectLinkedInModal = React.memo<BaseModalProps>((props) => {
	const { onCancel } = props;

	const history = useHistory();

	const closeModal = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		onCancel?.(event);
		history.push(path);
	}, [onCancel]);

	return (
		<BaseModal {...props}>
			<Row justify="center">
				<Col>
					<ApiTwoTone style={{ fontSize: '600%' }} />
				</Col>
			</Row>
			<br />
			<Row justify="center" style={{ textAlign: 'center' }}>
				<Col>
					<h3><strong>Please connect your LinkedIn account</strong></h3>
					<p>
						You need to connect your LinkedIn account to CoPilotAI to access all automation features on your dashboard.
					</p>
				</Col>
			</Row>
			<Row justify="center">
				<Col>
					<Button type="primary" onClick={closeModal}>
						Connect
					</Button>
				</Col>
			</Row>
		</BaseModal>
	);
});

export default ReconnectLinkedInModal;
