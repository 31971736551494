import useTracking from '@copilot/common/hooks/tracking';
import { useCallback } from 'react';

const EVENT_NAME = 'send_message_with_reminder';
export type SendMessageWithReminder =
    'Sent from Inbox Cardview' |
    'Sent from Prospect Drawer';

export interface SendMessageWithReminderTrackingParams {
	buttonClicked: SendMessageWithReminder;
	/**
	 * Time span from now in ms
	 */
	interval: number;
}

export const useSendMessageWithReminderTrackingParamsTracking = (componentName: string, params: SendMessageWithReminderTrackingParams | null = null): (buttonClicked: SendMessageWithReminder, interval: number) => void => {
	const trackEvent = useTracking(componentName, EVENT_NAME, params);
	const trackButtonClicked = useCallback((buttonClicked: SendMessageWithReminder, interval: number) => {
		trackEvent({ buttonClicked, interval });
	}, [trackEvent]);

	return trackButtonClicked;
};
