import BaseDataManager, { QueryObject, PaginationObject } from './base';
import {
	ContactResponse,
	PaginatedResponse,
	ContactConnectionsResponse,
	ContactTagResponse,
} from '../responses/interface';
import { ContactModel } from '../responses/models/contact';
import { PaginatedResultModel } from '../responses/models/paginatedResult';
import { ContactConnectionModel } from '../responses/models/contactConnections';
import {
	ContactConnectionDeleteRequestModel,
	FiltersRequestModel,
	CSVRequestModel,
} from '../requests/models';

enum ContactManagerKeyMap {
	name = 'name',
	tags = 'tagname',
	lastUpdated = 'lastThreadActivity',
	connectedMember = 'orgMemberName',
	campaign = 'campaignName'
}

class ContactManager extends BaseDataManager {
	constructor() {
		super();
	}

	public get KeyMap() {
		return ContactManagerKeyMap;
	}

	/**
	 * Get Contacts for a user
	 * @param {string} id Id of the org member
	 * @param {QueryObject} query Query options
	 */
	public getContactsByUser = (id: string, query: QueryObject = new QueryObject()) => {
		const url = this.paginate(
			this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default, id, 'contacts'),
			query
		);
		return this.RequestManager.get<PaginatedResponse<ContactResponse>>(url, {
			params: query.toQueryParam(),
		}).then((response) => new PaginatedResultModel(response.data, ContactModel));
	};

	/**
	 * Get multiConnections
	 * @param id Id of the organization
	 * @param query Query Options
	 * @param connectionFilterRequest FliterRequest

	 */
	public getMultiConnections = (
		id: string,
		query: PaginationObject = new PaginationObject(),
		connectionFilterRequest?: Partial<FiltersRequestModel>,
		isAdmin?: boolean
	) => {
		const url = this.paginate(
			this.combineRoute(
				this.BACKEND_ROUTES.ORGANIZATION.Default,
				id,
				'contacts',
				'connections'
			),
			query
		);
		return this.RequestManager.post<PaginatedResponse<ContactConnectionsResponse>>(
			url,
			connectionFilterRequest,
			{ params: { oid: isAdmin ? id : null } }
		).then((response) => {
			const { data } = response;
			return new PaginatedResultModel(data, ContactConnectionModel);
		});
	};

	/**
	 * Get a multiContact by contactId
	 * @param contactId Id of the contact
	 */
	public getMultiConnection = (orgId: string, contactId: string) => {
		if (!orgId || !contactId) {
			return Promise.reject();
		}
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			orgId,
			'contacts',
			contactId,
			'connections'
		);
		return this.RequestManager.post(url).then((response) => {
			const result = response.data;
			return result;
		});
	};

	/**
	 * Get a contact
	 * @param id Id of the contact
	 */
	getContact(id: string) {
		const url = `${this.BACKEND_ROUTES.CONTACT.Default}/${id}`;
		return this.RequestManager.get<ContactResponse>(url).then((response) => {
			const data = new ContactModel(response.data);
			return data;
		});
	}

	/**
	 * Update contact
	 * @param {Partial<ContactResponse>} contact Contact we want to update
	 */
	updateContact = (contact: Partial<ContactResponse>) => {
		const url = `${this.BACKEND_ROUTES.CONTACT.Default}/${contact.id}`;
		return this.RequestManager.put(url, contact).then((response) => {
			const { data } = response;
			const result = data as ContactResponse;
			return result;
		});
	};

	/**
	 * Update connection tags
	 * @param {string} connectionIds Connections we want to update
	 * @param {string[]} tags The new tags to add to contact
	 * @param {string} csOrgId organization id, defined only for CS Admins
	 * @param {boolean} overwriteTags true if we want to overwrite the existing tags with new tags
	 * @param {Partial<FiltersRequestModel>} [connectionsFilter] filter to apply on the entire connections to apply selected tags on
	 */
	updateConnectionTags = (
		connectionIds: string[],
		tags: string[],
		csOrgId: string | null,
		overwriteTags = false,
		connectionsFilter?: Partial<FiltersRequestModel>
	) => {
		const url = `${this.BACKEND_ROUTES.CONTACT.Default}/tags`;
		return this.RequestManager.post<ContactTagResponse[]>(url, {
			contactConnectionIds:
				typeof connectionIds === 'string' ? [connectionIds] : connectionIds,
			tagNames: tags,
			overwriteTags,
			connectionsFilter,
		}, { params: { oid: csOrgId } }).then((response) => {
			const { data } = response;
			return data;
		});
	};

	/**
	 * Delete contact and/or connections to the contact
	 * @param {ContactConnectionDeleteRequestModel[]} contactConnections Contacts we want to remove
	 * @param {string | null} orgId
	 */
	deleteContacts(contactConnections: ContactConnectionDeleteRequestModel[], orgId: string | null) {
		const url = `${this.BACKEND_ROUTES.CONTACT.Default}/delete`;
		return this.RequestManager.post(url, contactConnections, { params: { o: orgId } }).then((response) => {
			const { data } = response;
			return data;
		});
	}
	/**
	 * Download the connections CSV
	 * @param orgMemberId The id of the org member we want to download the connections for. leave null or blank to get all connections if your an admin
	 * @param columns column keys for columns we want to include in the csv
	 * @param filters filters
	 * @param connectionId connection Ids of connections we want to export into csv
	 */
	public getConnectionsCSV = (
		orgId: string | null,
		columns: string[],
		filters: Partial<FiltersRequestModel>,
		connectionIds?: string[]
	) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CONTACT.Export);
		const request: CSVRequestModel = {
			connectionFilters: filters,
			contactConnectionIds: connectionIds ?? [],
			columns,
		};

		return this.RequestManager.post(url, request, {
			params: { oid: orgId },
		}).then((response) => response.data);
	};

	public downloadConnectionsCSV = (
		orgId: string | null,
		columns: string[],
		filters: Partial<FiltersRequestModel>,
		connectionId?: string[]
	) =>
		this.getConnectionsCSV(orgId, columns, filters, connectionId).then((data) => {
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = url;
			const dateString = new Date().toDateString().replace(/ /gi, '-');
			link.setAttribute('download', `connections-csv-${dateString}.csv`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
}

const instance = new ContactManager();
export default instance;
