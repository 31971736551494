import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import modalManager from '@copilot/common/utils/modalManager';
import { NewUserOnboardManager } from '@copilot/data';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useActiveModal } from '../../../hooks/modal';
import { ModalType } from '../../../store/models/redux/modal';
import { fetchNewUserOnboard, getNewUserOnboard } from './data';

/**
 * show pre-onboard screen when user hasn't completed inital onboard
 * @param campaignId id of initial campaign
 */
export const useShowPreOnboardScreen = (campaignId?: string): void => {
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const [showLinkedInLogin, setShowLinkedInLogin] = useState<boolean>(!activeMember?.isLinkedInLoggedIn);
	const dispatch = useDispatch();
	const onboard = useSelector(getNewUserOnboard).data;
	const loginModal = useActiveModal(ModalType.InitialSyncLinkedIn);
	useEffect(() => {
		if (campaignId) dispatch(fetchNewUserOnboard(NewUserOnboardManager.getNewUserOnboard, {}, campaignId));
	}, [campaignId]);

	useEffect(() => {
		setShowLinkedInLogin(!activeMember?.isLinkedInLoggedIn);
	}, [activeMember?.isLinkedInLoggedIn]);

	useEffect(() => {
		if (!activeMember || !onboard) return;
		if (showLinkedInLogin && !onboard.isFirstLogin && !loginModal) {
			modalManager.openInitialLinkedInModal({ orgMemberId: activeMember.id });
			setShowLinkedInLogin(false);
		}
	}, [activeMember, showLinkedInLogin, onboard, campaignId]);
};
