import BaseDataManager from './base';
import { OrgMemberAttentionItemModel } from '@copilot/common/utils/attentionItem/models';

/**
 * Data Manager for handling Attention Item requests to the backend
 */
class AttentionItemManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Gets the attention items for the given org or for a specific member in that org
	 * @param {string} orgId the id of the organization
	 * @param {string | null} orgMemberId the id of the organization member we want items for
	 * @param {number} page page to get attention items for
	 * @param {number} pageSize size of the page we're getting items for
	 */
	 public getOrgAttentionItems = async (orgId: string, orgMemberId: string | null = null, page = 0, pageSize = 200) => {
		const combinedUrl = this.combineRoute(
			this.BACKEND_ROUTES.ATTENTION_ITEMS.Default,
			'organization',
			orgId
		);
		const url = this.paginate(combinedUrl, page, pageSize);
		const response = await this.RequestManager.get<OrgMemberAttentionItemModel[]>(url, { params: { orgMemberId } });
		return response.data;
	};
}

const instance = new AttentionItemManager();
export default instance;
