import _ from 'lodash';
import { BLANK_STATE_STRING } from '@copilot/common/constant/campaignConst';

/**
 * Produces string 'numeratorVal numeratorText / denominatorVal denomiatorText = percentVal'
 * If values not provided, filled with BLANK_STATE_STRING
 * @param numeratorVal 
 * @param numeratorText 
 * @param denominatorVal 
 * @param denominatorText 
 * @param percentVal 
 */
export const getCalculationString = (
	numeratorText: string,
	denominatorText: string,
	numeratorVal?: number,
	denominatorVal?: number,
	percentVal?: number
) => (
	`${Number.isFinite(numeratorVal) ? numeratorVal : BLANK_STATE_STRING} ${numeratorText} / \
	${Number.isFinite(denominatorVal) ? denominatorVal : BLANK_STATE_STRING} ${denominatorText} = \
	${Number.isFinite(percentVal) ? percentVal : BLANK_STATE_STRING}%`
);

/**
 * Format a percentage to be showing in the charts
 * @param percent The percent we want to format
 * @returns The formatted percentage
 */
export const formatPercentage = (percent: number): number => _.round(percent, 1);