import { call, takeLatest } from 'redux-saga/effects';

/**
 * Create a watcher to invoke a callback function when a store action type is dispatched
 * Note: This callback is excuted before the action reaches reducers.
 * See https://redux-saga.github.io/redux-saga/docs/api/index.html#selectselector-args for more detail
 * @param actionType Store action type
 * @param callback Function to invoke
 * @param args arguments to invoke callback function with
 */
export const createSagaWatcher = <CallbackFn extends Callable<T>, T>(actionType: string, callback: CallbackFn, ...args: ArgumentTypes<CallbackFn>) => {
	function* worker() {
		yield call(callback, ...args);
	}
	function* watcher() {
		yield takeLatest(actionType, worker);
	}
	return watcher;
};
