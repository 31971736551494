import {
	FC, useState, useCallback, ReactNode, useContext,
} from 'react';
import {
	Row, Col, Button, Input, Form,
} from 'antd';
import styled, { ThemeContext } from 'styled-components';
import { RuleObject } from 'rc-field-form/lib/interface';

/**
 * Options that are needed to generate an input box for the ButtonOptionCard
 * @param {string} inputPlaceholder placeholder for the input
 * @param {string} initialValue initial value of the input
 * @param {function} inputValidator validator function to be passed into the form to return errors
 * @param {function} inputStringValidator validator function to check if our input string is valid
 */
interface IInputOptions {
	placeholder: string;
	initialValue: string;
	validator: (_: RuleObject, value: string) => Promise<void>;
	stringValidator: (value: string) => boolean;
}

const StyledRow = styled(Row)`
	background-color: white;
	text-align: center;
	align-content: space-between;
	padding: 24px;
	margin: auto;
	height: 100%;
`;

const Footer = styled(Row)`
	margin-top: 24px;
	font-size: 14px;
	color: ${(props) => props.theme['@text-color-secondary']};
`;

const StyledFormItem = styled(Form.Item)`
	&&& {
		height: 64px;
		margin-bottom: 0;
		text-align: left;

		.${(props) => props.theme['@ant-prefix']}-form-item-explain {
			font-size: 14px;
		}
	}
`;

interface IButtonOptionCardProps {
	title: string;
	description: string;
	inputOptions?: IInputOptions;
	buttonLabel: string;
	onButtonClick: (payload: string) => void;
	aboveInputChildren?: ReactNode;
	belowInputChildren?: ReactNode;
}

/**
 * [Presentational] Card with a button for displaying multiple options
 * @param {string} title title of the option
 * @param {string} description description below the title
 * @param {IInputOptions} inputOptions options required to create an input, if needed
 * @param {string} buttonLabel label for the button
 * @param {function} onButtonClick callback when the button gets clicked
 * @param {ReactNode} aboveInputChildren displayed above the textbox input
 * @param {ReactNode} belowInputChildren displayed below the textbox input
 * @param {ReactNode} children displayed below the main content as a footer
 */
const ButtonOptionCard: FC<IButtonOptionCardProps> = (props) => {
	const {
		title, description, inputOptions, buttonLabel, onButtonClick, aboveInputChildren, belowInputChildren, children,
	} = props;
	const [inputValue, setInputValue] = useState<string>(inputOptions?.initialValue ?? '');
	const themeContext = useContext(ThemeContext);

	const handleButtonClick = useCallback(() => {
		onButtonClick(inputValue);
	}, [onButtonClick, inputValue]);

	return (
		<StyledRow>
			<Col style={{ marginBottom: '24px' }} span={24}>
				<Row justify="center">
					<h3 style={{ fontWeight: 'bold' }}>{title}</h3>
				</Row>
				<Row style={{ fontSize: '14px', color: themeContext['@text-color-secondary'] }} justify="center">
					{description}
				</Row>
				<Row style={{ marginTop: '24px' }} justify="center">
					{aboveInputChildren}
				</Row>
			</Col>
			<Col span={24}>
				{inputOptions && (
					<Row>
						<Form
							style={{ width: '100%' }}
							initialValues={{ buttonOptionCardInput: inputValue }}
						>
							<StyledFormItem
								name="buttonOptionCardInput"
								rules={[{ validator: inputOptions.validator }]}
							>
								<Input
									type="text"
									placeholder={inputOptions.placeholder}
									aria-label={inputOptions.placeholder}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
								/>
							</StyledFormItem>
						</Form>
					</Row>
				)}
				{belowInputChildren}
				<Row>
					<Button
						onClick={handleButtonClick}
						disabled={inputOptions && !inputOptions.stringValidator(inputValue)}
						type="primary"
						block
					>
						{buttonLabel}
					</Button>
				</Row>
				<Footer justify="center">{children}</Footer>
			</Col>
		</StyledRow>
	);
};

export default ButtonOptionCard;
