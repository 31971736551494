import { CampaignLaunchErrors, CampaignType, PaginatedResponse } from '@copilot/data/responses/interface';
import { CampaignStatusEnum } from '@copilot/data/requests/models';
import { CampaignModel } from '@copilot/common/pages/campaigns/data/models';
import { OrganizationType } from '@copilot/common/store/models/const/enum';

type CampaignsLoadAction = {
	type: 'LOAD_CAMPAIGNS';
};

type CampaignsLoadSuccessAction = {
	type: 'CAMPAIGNS_LOADED';
	payload: PaginatedResponse<CampaignModel>;
};

type CampaignsLoadSuccessWithFilterAction<Filters> = {
	type: 'CAMPAIGNS_LOADED_WITH_FILTER';
	payload: PaginatedResponse<CampaignModel>;
	filter: Filters;
	searchTerm: string;
};

type CampaignsLoadErrorAction = {
	type: 'CAMPAIGNS_LOAD_FAILED';
	error: any;
};

type CampaignLoadAction = {
	type: 'CAMPAIGN_LOADED';
	payload: CampaignModel;
};

type CampaignLoadErrorAction = {
	type: 'CAMPAIGN_LOAD_FAILED';
	error: any;
};

/**
 * Load action.  Initiates loading of campaigns.
 */
export const load = (): CampaignsLoadAction => ({ type: 'LOAD_CAMPAIGNS' });

/**
 * Load Success action, dispatched when loading of campaigns was successful.
 * @param payload
 */
export const loadSuccess = (payload: PaginatedResponse<CampaignModel>): CampaignsLoadSuccessAction => ({ type: 'CAMPAIGNS_LOADED', payload });

/**
 * Load error action, dispatched when loading of campaigns failed.
 * @param error
 */
export const loadError = (error: unknown): CampaignsLoadErrorAction => ({ type: 'CAMPAIGNS_LOAD_FAILED', error });

/**
 * Load success action of one campaign, dispatched when loading of one campaign was successful.
 * @param payload
 */
export const loadOneSuccess = (payload: CampaignModel): CampaignLoadAction => ({ type: 'CAMPAIGN_LOADED', payload });

/**
 * Load error action of one campaign, dispatched when loading of one campaign failed.
 * @param error
 */
export const loadOneError = (error: unknown): CampaignLoadErrorAction => ({ type: 'CAMPAIGN_LOAD_FAILED', error });

export const loadSuccessWithFilter = <Filters>(payload: PaginatedResponse<CampaignModel>, filter: Filters, searchTerm: string): CampaignsLoadSuccessWithFilterAction<Filters> => ({
	type: 'CAMPAIGNS_LOADED_WITH_FILTER',
	payload,
	filter,
	searchTerm,
});

/**
 * The action type for fetching campaigns.
 */
export const FetchCampaignsActionType = 'FETCH_CAMPAIGNS';
/**
 * The action to initiate loading of campaigns.
 */
export type FetchCampaignsAction = {
	type: typeof FetchCampaignsActionType;
	organizationId: string;
	pageNumber: number;
	pageSize: number;
	searchTerm?: string;
};

/**
 * Initiates loading of campaigns.
 * @param organizationId
 * @param pageNumber
 * @param pageSize
 */
export const fetchCampaigns = (organizationId: string, pageNumber: number, pageSize: number, searchTerm?: string): FetchCampaignsAction => ({
	type: FetchCampaignsActionType, organizationId, pageNumber, pageSize, searchTerm,
});

/**
 * The action type for toggling the campaign status.
 */
export const ToggleCampaignStatusActionType = 'CAMPAIGN_TOGGLE_STATUS';

/**
 * The action to initiate toggling of campaign status.
 */
export type ToggleCampaignStatusAction = {
	type: typeof ToggleCampaignStatusActionType;
	campaignId: string;
	status: CampaignStatusEnum;
	campaignType: CampaignType;
	orgType: OrganizationType;
	onNotificationButtonClick: () => void;
	onConflictError?: (errors: CampaignLaunchErrors[]) => unknown;
};

/**
 * Initiates toggling of campaign status.
 * @param campaignId The id of the campaign to toggle the status.
 * @param status The new status.
 * @param campaignType The type of the campaign that is toggled.
 * @param orgType The organization type of the user.
 * @param onNotificationButtonClick Callback for notification CTA
 * @param onConflictError Callback for conflict response
 */
export const toggleStatus = (campaignId: string, status: CampaignStatusEnum, campaignType: CampaignType, orgType: OrganizationType, onNotificationButtonClick: () => void, onConflictError?: ToggleCampaignStatusAction['onConflictError']): ToggleCampaignStatusAction => ({
	type: ToggleCampaignStatusActionType, campaignId, status, campaignType, orgType, onNotificationButtonClick, onConflictError,
});

//#region Filters
export const CampaignsUpdateFilterActionType = 'CAMPAIGNS_FILTER_UPDATE';

/**
 * The action to update campaigns filter
 */
export type CampaignsUpdateFilterAction<Filters> = {
	type: typeof CampaignsUpdateFilterActionType;
	filter: Filters;
	orgId: string;
	searchTerm: string;
};

export const updateCampaignsFilters = <Filters>(orgId: string, filter: Filters, searchTerm: string): CampaignsUpdateFilterAction<Filters> => ({
	type: CampaignsUpdateFilterActionType, orgId, filter, searchTerm,
});

//#endregion Filters

//#region Pagination

export const CampaignsUpdatePaginationActionType = 'CAMPAIGNS_PAGE_UPDATE';
/**
 * The action to update campaigns pagination
 */
export type CampaignsUpdatePaginationAction = {
	type: typeof CampaignsUpdatePaginationActionType;
	orgId: string;
	pageSize: number | undefined;
	page: number | undefined;
};

export const updateCampaignsPage = (orgId: string, page: number | undefined, pageSize: number | undefined): CampaignsUpdatePaginationAction => ({
	type: CampaignsUpdatePaginationActionType, orgId, page, pageSize,
});

//#endregion Pagination

/**
 * The type of campaign action.  It's a union of different types of actions to be handled by the reducer - very useful in reducer to write type safe code.
 */
export type CampaignAction<T> =
 CampaignsLoadAction
 | CampaignsLoadSuccessAction
 | CampaignsLoadErrorAction
 | CampaignLoadAction
 | CampaignsUpdateFilterAction<T>
 | CampaignsLoadSuccessWithFilterAction<T>
 | CampaignsUpdatePaginationAction;
