import { Reducer, ReducersMapObject } from 'redux';
import {
	CampaignAction, CampaignsUpdateFilterActionType,
} from '@copilot/common/pages/campaigns/data/actionCreators';
import { CampaignModel } from '@copilot/common/pages/campaigns/data/models';
import { CampaignsFilters } from '../types';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 10;

export type CampaignModelState<Filters> = {
	data: CampaignModel[];
	totalCount: number;
	loading: boolean;
	error: boolean;
	page: number;
	pageSize: number;
	filter: Filters;
	searchTerm?: string;
};

const stateBase: CampaignModelState<CampaignsFilters> = {
	data: [],
	totalCount: -1,
	loading: false,
	error: false,
	page: DEFAULT_PAGE,
	pageSize: DEFAULT_PAGE_SIZE,
	filter: {
		teamMembers: [],
	},
};

const reducer: Reducer<CampaignModelState<CampaignsFilters>, CampaignAction<CampaignsFilters>> | undefined = (state, action) => {
	switch (action.type) {
		case 'LOAD_CAMPAIGNS':
			return {
				...stateBase,
				...{
					loading: true,
				},
			};
		case 'CAMPAIGNS_LOADED':
			return {
				...stateBase,
				...{
					data: action.payload.results,
					totalCount: action.payload.totalCount,
					page: action.payload.pageIndex,
					pageSize: action.payload.pageSize,
				},
			};
		case 'CAMPAIGNS_LOAD_FAILED':
			return {
				...stateBase,
				...{
					error: true,
				},
			};
		case 'CAMPAIGN_LOADED':
			if (state?.data) {
				const index = state.data.findIndex((model) => model.id == action.payload.id);
				if (index > -1) {
					const newData = [
						...state.data.slice(0, index),
						action.payload,
						...state.data.slice(index + 1),
					];
					return {
						...stateBase,
						...{
							data: newData,
						},
					};
				} else {
					// this item doesn't exist.  simply add to the existing list.
					state.data = [
						...state.data,
						action.payload,
					];
				}
			}
			return state ?? stateBase;
		case CampaignsUpdateFilterActionType:
			return {
				...stateBase,
				...{
					filter: action.filter,
					searchTerm: action.searchTerm,
				},
			};

		case 'CAMPAIGNS_LOADED_WITH_FILTER':
			return {
				...stateBase,
				...{
					data: action.payload.results,
					totalCount: action.payload.totalCount,
					page: action.payload.pageIndex,
					pageSize: action.payload.pageSize,
					filter: action.filter,
					searchTerm: action.searchTerm,
				},
			};

		default: return state ?? stateBase;
	}
};

export type State = {
	sagaPagedCampaigns: CampaignModelState<CampaignsFilters>;
};

const reducers: ReducersMapObject<State> = {
	sagaPagedCampaigns: reducer,
};

export default reducers;
