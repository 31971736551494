import React, { MouseEventHandler, useCallback } from 'react';
import CarouselWithButton, { CarouselContents, ICarouselWithButtonProps } from './carouselWithButton';
import BaseModal, { BaseModalProps } from '../base';

export interface ICarouselModalProps extends BaseModalProps {
	carouselItems: CarouselContents[];
	finalButtonText: string;
	skipButtonText: string;
	onSkip?: () => void;
	onCarouselSlideChange?: ICarouselWithButtonProps['afterChange'];
}

/**
 * [Presentational] Renders a modal containing a carousel w/ a next button
 * @param props
 * @param carouselItems - a list of the content to display in each carouselItem
 * @param finalButtonText - the text to display within the button on the last carousel page
 * @returns a modal containing a carousel w/ a next button
 */
const CarouselModal: React.FC<ICarouselModalProps> = (props) => {
	const {
		centered = true, onSkip, onCarouselSlideChange, ...rest
	} = props;

	const handleSkip = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
		onSkip?.();
		props.onCancel?.(event);
	}, [onSkip]);

	const carousel = (
		<CarouselWithButton
			carouselItems={props.carouselItems}
			finalButtonText={props.finalButtonText}
			skipButtonText={props.skipButtonText}
			onFinish={props.onCancel}
			onSkip={handleSkip}
			afterChange={onCarouselSlideChange}
		/>
	);

	return (
		<BaseModal {...rest} closable={false} maskClosable={false} centered={centered}>
			{carousel}
		</BaseModal>
	);
};

export default CarouselModal;
