import { ContactManager } from '@copilot/data';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ContactConnectionModel } from '@copilot/data/responses/models/contactConnections';
import { ContactConnectionActions } from '@copilot/common/store/actions';
import { ContactConnectionsResponse } from '@copilot/data/responses/interface';

export const useContactConnection = (orgId: string, contactId: string) => {
	const storeDispatch = useDispatch();

	useEffect(() => {
		if (contactId != '') {
			ContactManager.getMultiConnection(orgId, contactId).then((result: ContactConnectionsResponse[]) => {
				const contactConnection = new ContactConnectionModel(result[0]);
				storeDispatch(ContactConnectionActions.load(contactConnection));
			});
		}
	}, [orgId, contactId]);
};
