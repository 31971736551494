import * as React from 'react';
import {
	Drawer, Button, Input, Row, Col,
} from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

interface CreateNodeDrawerProps extends DrawerProps {
	createNode: (name: string) => void;
	closeAlert?: boolean;
}

class CreateNodeDrawer extends React.Component<CreateNodeDrawerProps> {
	state = {
		name: '',
	};
	constructor(props: CreateNodeDrawerProps) {
		super(props);
		this.createNode = this.createNode.bind(this);
		this.changeName = this.changeName.bind(this);
	}

	changeName(value: any) {
		this.setState({ name: value.target.value });
	}

	createNode() {
		this.props.createNode(this.state.name);
	}

	render() {
		const { createNode: _createNode, closeAlert: _closeAlert, ...rest } = this.props;
		return (
			<Drawer
				{...rest}
				width="720px"
				open
				rootStyle={{ display: 'flex', height: '100%', flexDirection: 'column' }}
			>
				<Row>
					<Col span={12}>
						<Input placeholder="Step Name" onChange={this.changeName} />
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<Button onClick={this.createNode} type="primary">
							Create
						</Button>
					</Col>
				</Row>
			</Drawer>
		);
	}
}

export default CreateNodeDrawer;
