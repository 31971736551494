import ProgressBar from '@copilot/common/components/loadingIndicator/bar';

interface ILinkedInProgressProps {
	/** The loading progress of the linkedin authentication flow */
	progress?: number;
}

/**
 * Displays the loading status of linkedin login.
 */
const LinkedInProgress: React.FC<ILinkedInProgressProps> = (props) => {
	const { progress = 100 } = props;

	return (
		<div style={{ textAlign: 'center' }}>
			<br />
			<ProgressBar progress={progress} showInfo={false} status="active" strokeColor="#1890ff" />
			<br />
			<br />
			<h2>Verifying Account</h2>
			<div>We're nearly there! Please hold as we verify your account...</div>
			<br />
		</div>
	);
};

export default LinkedInProgress;
