import { DefaultMessageTemplates, DefaultMessageTemplatesResponse } from '../responses/interface';
import BaseDataManager from './base';

export type DefaultMessageTemplatesMap = {
	[strategy: number]: {
		groupId: string;
		messageTemplates: string[];
		version: number;
	}
};

class DefaultTemplatesManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get the default message templates
	 */
	public getDefaultMessageTemplates = (): Promise<DefaultMessageTemplatesMap> => {
		const url = this.BACKEND_ROUTES.DEFAULT_TEMPLATES.Default;
		return this.RequestManager
			.get<DefaultMessageTemplatesResponse>(url)
			.then((response) => defaultMessagesResponseToMap(response.data));
	};
}

/**
 * Converts a DefaultMessageTemplatesResponse to a DefaultMessageTemplatesMap for fast indexing
 * @param response
 * @returns
 */
const defaultMessagesResponseToMap = (response: DefaultMessageTemplatesResponse): DefaultMessageTemplatesMap =>
	response.reduce((accumulator: DefaultMessageTemplatesMap, template: DefaultMessageTemplates) => {
		accumulator[template.strategy] = {
			groupId: template.groupId,
			messageTemplates: template.messageTemplates,
			version: template.version,
		};
		return accumulator;
	}, {});

const instance = new DefaultTemplatesManager();
export default instance;
