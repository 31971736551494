import React, {
	useMemo, useState, useEffect, useCallback, useContext,
} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {
	Col, Row, Table,
} from 'antd';
import styled, { ThemeContext } from 'styled-components';
import { TableProps } from 'antd/lib/table';
import BasicContainer from '../containers/basic';
import ColumnSelector from './columnSelector';
import {
	ColumnTypes,
	ColumnDefinition,
	ActionDropdownColumnDefinition,
	ColumnDefinitionAdvanced,
	TagColumnDefinition,
	TextWithTagsColumnDefinition,
	StatusWithPopoverColumnDefinition,
	LinkActionColumnDefinition,
	IconColumnDefinition,
	StringWithIdColumnDefinition,
} from '../componentModels/columnDefinition';
import DateRangeFilter from '@copilot/common/components/filters/dateRange';
import {
	StringColumn,
	SubStringColumn,
	DateColumn,
	PercentColumn,
	TagsColumn,
	DropdownColumn,
	LinkColumn,
	IconColumn,
	StringWithIdColumn,
} from './columns';
import { EnabledStatus } from './columns/enabledStatus';
import { TextWithTagsColumn } from './columns/textWithTags';
import { LinkActionColumn } from './columns/linkaction';
import { StatusWithPopoverColumn } from './columns/statusWithPopover';
import { CSVColumnDefinition } from '../componentModels/filterTypeDefinition';
import { FiltersRequestModel } from '@copilot/data/requests/models';
import ExportSelector from './tableTypes/exportSelector';
import { TextWithIconColumn } from './columns/TextWithIcon';
import { useCanViewPermission } from '@copilot/common/hooks/permission';
import { PermissionName } from '@copilot/common/hooks/permission/interface';

export interface IModelTableModuleProps<M = any> extends Omit<TableProps<M>, 'title'> {
	columns: (ColumnDefinition | ActionDropdownColumnDefinition)[];
	updateColumns?: (columns: ColumnDefinition[]) => void;
	data: readonly M[];
	exportData?: (columns: CSVColumnDefinition[], connectionId?: string[] | undefined) => unknown;
	exportColumns?: CSVColumnDefinition[];
	isExporting?: boolean;
	title?: React.ReactNode;
	subtitle?: React.ReactNode;
	filterRequest?: Partial<FiltersRequestModel>;
	onTimeRangeChanges?: (start?: Date, end?: Date) => void;
	onRowClick?: (record: M, event: React.MouseEvent<HTMLElement>, rowIndex?: number) => void;
	headerContent?: React.ReactNode;

	/**
	 * Should the tab overflow be hidden for the date filter component
	 */
	hideDateFilterTabOverflow?: boolean;
}

const StyledBasicContainer = styled(BasicContainer)`
	.flag {
		background: #ffd9d9;
	}

	.messageRead {
		background: #f7f7f7;
	}

	.messageUnread {
		font-weight: bold;
	}
`;

//TODO: add documentation after batch action refactor is done as few props will be removed

const ModelTableModule = <M extends {}>(props: IModelTableModuleProps<M>) => {
	const {
		title,
		subtitle,
		exportData,
		exportColumns,
		isExporting = false,
		data,
		dataSource,
		rowKey = 'id',
		pagination,
		filterRequest: _filterRequest = {},
		onRowClick,
		headerContent,
		rowSelection,
		hideDateFilterTabOverflow,
		...tableProps
	} = props;
	const [columns, setColumns] = useState(props.columns || []);
	useEffect(() => {
		setColumns(props.columns);
	}, [props.columns]);
	const themeContext = useContext(ThemeContext);

	const generatedColumns = useMemo(() => {
		if (!columns) return [];
		return columns
			.filter((c) => c.isVisible)
			.map((c) => {
				switch (c.type) {
					case ColumnTypes.String:
						return new StringColumn(c);
					case ColumnTypes.SubString:
						return new SubStringColumn(c as ColumnDefinitionAdvanced);
					case ColumnTypes.Date:
						return new DateColumn(c);
					case ColumnTypes.Percent:
						return new PercentColumn(c);
					case ColumnTypes.Tags:
						return new TagsColumn(c as TagColumnDefinition);
					case ColumnTypes.EnabledStatus:
						return new EnabledStatus(c);
					case ColumnTypes.Dropdown:
						return new DropdownColumn(c as ActionDropdownColumnDefinition);
					case ColumnTypes.Link:
						return new LinkColumn(c);
					case ColumnTypes.Icon:
						return new IconColumn(c as IconColumnDefinition);
					case ColumnTypes.LinkAction:
						return new LinkActionColumn(c as LinkActionColumnDefinition);
					case ColumnTypes.TextWithTags:
						return new TextWithTagsColumn(c as TextWithTagsColumnDefinition);
					case ColumnTypes.StatusWithPopover:
						return new StatusWithPopoverColumn(c as StatusWithPopoverColumnDefinition);
					case ColumnTypes.StringWithId:
						return new StringWithIdColumn(c as StringWithIdColumnDefinition);
					case ColumnTypes.TextWithIcon:
						return new TextWithIconColumn(c as IconColumnDefinition);
					default:
						return new StringColumn(c);
				}
			});
	}, [columns]);

	const handleRow = useCallback(
		(record: M, rowIndex?: number) => {
			if (!onRowClick) return tableProps.onRow?.(record, rowIndex) ?? {};
			return {
				onClick: (event: React.MouseEvent<HTMLElement>) => onRowClick(record, event, rowIndex),
				style: { cursor: 'pointer' },
				...(tableProps.onRow?.(record, rowIndex)),
			};
		},
		[onRowClick]
	);
	const isTeamMembersVisible = useCanViewPermission(PermissionName.TeamMembers);
	const nonAdminColumns = exportColumns?.filter((c) => c.key !== 'OrgMemberName');
	const availableColumns = isTeamMembersVisible ? exportColumns : nonAdminColumns;

	const exportCSVSelector = useMemo(
		() =>
			(isExporting ? (
				<>
					<LoadingOutlined />
					{' '}
					Exporting...
				</>
			) : (
				<ExportSelector
					columns={availableColumns ?? []}
					exportCSV={exportData}
					label="Export CSV"
				/>
			)),
		[isExporting, exportData]
	);

	return (
		<StyledBasicContainer>
			<BasicContainer.Header>
				{props.onTimeRangeChanges && (
					<Row
						justify="space-between"
						align="middle"
						style={{ marginBottom: '1em' }}
					>
						<Col />
						<Col>
							<DateRangeFilter onChange={props.onTimeRangeChanges} hideTabOverflow={ hideDateFilterTabOverflow } />
						</Col>
					</Row>
				)}
				<Row gutter={16} justify="space-between" align="middle">
					<Col>{headerContent}</Col>
					<Col style={{ flexGrow: 1, fontSize: '1.3em' }}>{title}</Col>
					<Col>{exportData && exportCSVSelector}</Col>
					<Col>
						<ColumnSelector
							columns={columns}
							updateColumns={props.updateColumns ?? setColumns}
						/>
					</Col>
				</Row>
				{subtitle && (
					<Row gutter={16} justify="space-between" align="middle">
						<Col
							style={{
								flexGrow: 1,
								fontSize: '1em',
								fontWeight: themeContext['@table-text-weight'] as any,
							}}
						>
							{subtitle}
						</Col>
					</Row>
				)}
			</BasicContainer.Header>
			<BasicContainer.Content style={{ padding: '0px' }}>
				<Table<M>
					{...tableProps}
					rowKey={rowKey}
					columns={generatedColumns}
					dataSource={(data as typeof dataSource) ?? dataSource}
					rowSelection={rowSelection}
					pagination={pagination}
					onRow={handleRow}
				/>
			</BasicContainer.Content>
		</StyledBasicContainer>
	);
};

export default ModelTableModule;
