import { Button, Popover } from 'antd';
import Selector from '../selector';
import { FC, useRef, useState } from 'react';

export interface ColumnSelectorProps {
	columns: any[];
	updateColumns?: (columns: ColumnSelectorProps['columns']) => void;
	title?: string;
}

const ColumnSelector: FC<ColumnSelectorProps> = (props) => {
	const { updateColumns, title = 'Columns', columns } = props;
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const selectorRef = useRef<any>(null);

	const handleUpdateColumns = (updatedColumns?: ColumnSelectorProps['columns']) => {
		if (updatedColumns) updateColumns?.(updatedColumns);
		setIsVisible(false);
	};

	const toggleVisibility = (visible = false) => {
		setIsVisible(visible);
		if (!visible) selectorRef.current.resetFields();
	};

	const selector = (
		<Selector
			ref={selectorRef}
			columns={columns}
			filters={[]}
			updateColumns={handleUpdateColumns}
			cancel={() => setIsVisible(false)}
		/>
	);
	return (
		<Popover
			content={selector}
			trigger="click"
			onVisibleChange={toggleVisibility}
			placement="bottomRight"
			visible={isVisible}
			autoAdjustOverflow={false}
		>
			<Button onClick={() => toggleVisibility(true)}>
				{title}
			</Button>
		</Popover>
	);
};

export default ColumnSelector;
