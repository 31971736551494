import {
	createContext,
	PropsWithChildren,
	useMemo,
	useContext,
	useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PermissionSelectors } from '@copilot/common/store/selectors/permission';
import { PermissionActions } from '@copilot/common/store/actions/permissions';
import PermissionProfiles from './profiles';
import Store from '@copilot/common/store';
import { PermissionFlag, FullPermission } from './flags';
import { PermissionName } from './interface';

export { PermissionFlag, FullPermission } from './flags';

type IPermissions = Record<string, number>;
const PermissionStateContext = createContext<IPermissions>({});
type SetPermission<T> = (name: keyof T, permission: number) => void;
const PermissionDispatchContext = createContext<SetPermission<any>>(() => { });

interface IPermissionsProviderProps<T> {
	defaultPermissions?: T;
}

/**
 * A provider / initializer to give us a simpler way of loading defaults and showing a loading state if permissions haven't loaded yet
 * @param props
 */
export const PermissionsProvider = <T extends IPermissions>(
	props: PropsWithChildren<IPermissionsProviderProps<T>>
) => {
	const { children, defaultPermissions } = props;
	const permissions = useSelector(PermissionSelectors.getPermissions);
	const dispatch = useDispatch();

	// [TODO] Des - Determine whether we need to create this proxy
	const memoizedPermissions = useMemo<T>(() => {
		if (!permissions && defaultPermissions) return defaultPermissions;
		return permissions as T;
	}, [permissions, defaultPermissions]);

	const setPermission = useCallback<SetPermission<T>>((name, permission) => {
		dispatch(PermissionActions.setPermissions({ [name]: permission }));
	}, []);

	// [TODO] Des - Remove context especially if the proxy above is not needed
	return (
		<PermissionStateContext.Provider value={memoizedPermissions}>
			<PermissionDispatchContext.Provider value={setPermission}>
				{children}
			</PermissionDispatchContext.Provider>
		</PermissionStateContext.Provider>
	);
};

/**
 * Grab a profile of permissions given a name
 * @param name The name of the profile
 */
const getProfile = (name: keyof typeof PermissionProfiles): Record<PermissionName, number | undefined> =>
	PermissionProfiles[name];

type LoadProfile<T> = {
	(name: keyof typeof PermissionProfiles): void;
	(permissions: Partial<T>): void;
};

export function loadProfile(name: keyof typeof PermissionProfiles, isOverwrite?: boolean): void;
export function loadProfile<T extends IPermissions>(
	permissions: Partial<T>,
	isOverwrite?: boolean
): void;
export function loadProfile<T extends IPermissions>(
	permission: keyof typeof PermissionProfiles | Partial<T>
) {
	const updates = typeof permission === 'string' ? getProfile(permission) : permission;
	Store.Dispatch(PermissionActions.setPermissions(updates));
}

/**
 * Hook to provide permissions and a way to update them
 */
export function usePermission<T extends IPermissions>(): [T, SetPermission<T>, LoadProfile<T>];
export function usePermission<T extends IPermissions>(
	name?: PermissionName
): [number, SetPermission<T>, LoadProfile<T>];
export function usePermission<T extends IPermissions>(name?: PermissionName) {
	const stateContext = useContext(PermissionStateContext);
	const dispatchContext = useContext<SetPermission<T>>(PermissionDispatchContext);
	if (!dispatchContext) throw new Error('usePermission must be used with a PermissionProvider');
	const result = name ? stateContext[name] : stateContext;
	return [result ?? PermissionFlag.None, dispatchContext, loadProfile];
}

/**
 * Convert a dictionary of string string to a dictionary of string number
 * @param {Record<string, string>} permissions The permissions object from the feature toggle route
 */
export const convertStringPermissionsToFlag = (
	permissions: Record<string, string>
): IPermissions => {
	const result: IPermissions = {};
	for (const key in permissions) {
		result[key] =
			permissions[key].toLowerCase() === 'true' ? FullPermission : PermissionFlag.None;
	}
	return result;
};

/**
 * Verify whether something should be visible based on the flag.
 * @param permission The permission allowed
 */
export const validateIsVisible = (permission: number): boolean => permission > PermissionFlag.None;

/**
 * Verify whether something should be editable based on the flag.
 * @param permission The permission allowed
 */
export const validateIsEditable = (permission: number): boolean => !!(permission & PermissionFlag.Edit);

/**
 * Hook for checking the view permission
 * @param name name of the permission
 */
export const useCanViewPermission = (name: PermissionName): boolean => {
	const [permission] = usePermission(name);
	return validateIsVisible(permission);
};

/**
 * Hook for checking the view permission
 * @param name name of the permission
 */
export const useCanEditPermission = (name: PermissionName): boolean => {
	const [permission] = usePermission(name);
	return validateIsEditable(permission);
};
