import { OrgRoleTitles } from '@copilot/common/config/roles';
import { OnboardTemplateStep } from '@copilot/common/pages/onboard/wizard/messaging/const';
import { Template } from '@copilot/common/pages/settings/message/const';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { generateOrdinalNumArray } from '..';

const IndexToOnboardTemplateStepMap = [
	OnboardTemplateStep.Open,
	OnboardTemplateStep.FollowUps,
];

/**
 * Get message template
 * @param idx message index we are getting a template for
 * @param templates message templates
 */
export const getMessageTemplates = (idx: number, templates?: { [k: string]: { [t: string]: string } }): Template[] | undefined => {
	const selectedTemplateStep = templates?.[IndexToOnboardTemplateStepMap[idx]];
	if (selectedTemplateStep) {
		const messageTemplates = Object.keys(selectedTemplateStep).map((k) => ({
			key: k,
			label: k,
			value: selectedTemplateStep[k],
		}));
		return messageTemplates;
	} else {
		return undefined;
	}
};

export const getMessageTitles = (startMessageIndex: number, endMessageIndex: number, defaultMessages: string[], suffix?: string): string[] => {
	const optionalMessageTitles = generateOrdinalNumArray(startMessageIndex + 1, endMessageIndex + 1, suffix);
	return [...defaultMessages, ...optionalMessageTitles];
};

/**
 * Determine whether the member can edit automated message
 * @param activeMember member to check the permission for
 */
export const canEditAutomatedMessage = (activeMember: IOrganizationMember): boolean => activeMember.isOrgAdmin || activeMember.orgRoles.includes(OrgRoleTitles.Advanced);
