/**
 * Redux State Name
 */
export const CAMPAIGN_SEARCH_LIST_TAB = 'campaign_search_list_tab';

/**
 * Types of the actions related to the Campaign Search List Tab
 */
export enum CampaignSearchListTabActionTypes {
	UpdateOne = 'UPDATE_ONE_CAMPAIGN_SEARCH_LIST_TAB',
	ResetOne = 'RESET_ONE_CAMPAIGN_SEARCH_LIST_TAB',
	ResetAll = 'RESET_ALL_CAMPAIGN_SEARCH_LIST_TAB'
}
