import { Col, Row } from 'antd';
import {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import PillCheckbox from '.';

export interface PillCheckboxGroupItem {
	/**
	 * String to display for the checkbox
	 */
	label: string | null;
	/**
	 * Unique identifier that relates to the checkbox
	 */
	value: string;
	/**
	 * Indicate whether the checkbox is checked
	 */
	isChecked: boolean;
}

export interface PillCheckboxGroupProps {
	/**
	 * The initial state of the checkboxs
	 */
	initialValues: PillCheckboxGroupItem[];
	/**
	 * Call back when the state of the checkbox group changes
	 */
	onChange: (titles: PillCheckboxGroupItem[]) => void;
	/**
	 * Whether or not to display the Select All Option
	 */
	showCheckAll: boolean | undefined;
}

/**
 * [Presentational] Grouping of pill checkbox components
 * @param {PillCheckboxGroupProps} props The properties for checkbox group component
 */
function PillCheckboxGroup(props: PillCheckboxGroupProps) {
	const { initialValues, onChange, showCheckAll = false } = props;
	const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
	const [values, setValues] = useState<PillCheckboxGroupItem[]>([]);
	useEffect(
		() => {
			setValues(initialValues);
			setIsAllChecked(initialValues?.reduce<boolean>((acc, item) => item.isChecked && acc, true));
		},
		[initialValues]
	);
	const handleValueChange = (updatedValues: PillCheckboxGroupItem[]) => {
		setValues(updatedValues);
		onChange(updatedValues);
	};
	const handleCheckAll = useCallback(
		(state: boolean) => {
			setIsAllChecked(state);
			const updatedValues = values.map((v) => ({ ...v, isChecked: state }));
			handleValueChange(updatedValues);
		},
		[handleValueChange, values]
	);
	const handleCheckElement = useCallback(
		(value) => {
			let isAllValueChecked = true;
			const updatedValues = values.map(
				(v) => {
					if (value === v.value) {
						isAllValueChecked = isAllValueChecked && !v.isChecked;
						return { ...v, isChecked: !v.isChecked };
					}
					isAllValueChecked = isAllValueChecked && v.isChecked;
					return { ...v };
				}
			);
			handleValueChange(updatedValues);
			setIsAllChecked(isAllValueChecked);
		},
		[values, handleValueChange]
	);
	const showAllComponent = useMemo(
		() => (
			<Row gutter={[8, 8]} style={{ marginBottom: 5 }}>
				<Col>
					<PillCheckbox
						label="Select All"
						isChecked={isAllChecked}
						onClick={() => handleCheckAll(!isAllChecked)}
					/>
				</Col>
			</Row>
		),
		[handleCheckAll, isAllChecked]
	);
	return (
		<>
			{showCheckAll ? showAllComponent : null}
			<Row gutter={[8, 8]}>
				{values.map(
					(checkbox) => (
						<Col key={checkbox.label}>
							<PillCheckbox
								label={checkbox.label}
								isChecked={checkbox.isChecked}
								onClick={() => handleCheckElement(checkbox.value)}
							/>
						</Col>
					)
				)}
			</Row>
		</>
	);
}
export default PillCheckboxGroup;
