import { ReactElement } from 'react';
import { Button, Form, Space } from 'antd';
import BaseDrawer from '@copilot/common/components/drawer/wrappers/base';
import { AccountCreationForm } from './accountCreationForm';
import { FormInstance } from 'antd/es/form';
import drawerManager from '@copilot/common/utils/drawerManager/index';
import {
	AccountCreationInput,
	isAccountCreationInput,
} from '@copilot/common/hooks/account/accountCreation/accountCreationTypes';
import NotificationManager from '@copilot/common/utils/notificationManager/index';
import { useAccountCreation } from '@copilot/common/hooks/account/accountCreation';
import DrawerHeader from '@copilot/common/components/drawer/wrappers/accountDashboard/drawerHeader';
import { IAccountManager } from '@copilot/data/graphql/_generated';

/* The width of the account creation drawer */
const DRAWER_WIDTH = 495;

/**
 * Footer for the Account Creation drawer
 */
function AccountCreationDrawerFooter(
	accountCreationForm: FormInstance,
	isLoading: boolean
): ReactElement {
	return (
		<footer style={{ textAlign: 'right' }}>
			<Space>
				<Button type="default" onClick={() => drawerManager.closeDrawer()}>
					Cancel
				</Button>
				<Button
					type="primary"
					onClick={() => accountCreationForm.submit()}
					loading={isLoading}
				>
					Create Account
				</Button>
			</Space>
		</footer>
	);
}

/**
 * Props for account creation drawer
 */
export type AccountCreationDrawerProps = {
	/**
	 * List of account managers
	 */
	accountManagers?: Readonly<IAccountManager[]>;
	/**
	 * Callback function for when an account is created
	 */
	onAccountCreated: (accountId: string) => unknown;
};

/**
 * Smart Component for the Account Creation form
 */
function AccountCreationDrawer({
	onAccountCreated,
	accountManagers,
}: AccountCreationDrawerProps): ReactElement {
	const [createAccount, isLoading] = useAccountCreation();
	// Setup the Account Creation form
	const form: FormInstance<AccountCreationInput>[] = Form.useForm();
	const accountCreationForm: FormInstance<AccountCreationInput> = form[0];

	function onFormFinishCallback(values: AccountCreationInput): void {
		// User should be unable to submit an incomplete form
		if (!isAccountCreationInput(values))
			throw new Error('User submitted account creation form without completing the form');

		createAccount(values)
			.then((result) => {
				onAccountCreated(result.accountId);
				drawerManager.closeDrawer();
			})
			.catch((e) =>
				NotificationManager.showErrorNotification({ message: e.message })
			);
	}

	return (
		<BaseDrawer
			width={DRAWER_WIDTH}
			title={DrawerHeader({ title: 'Add Account' })}
			footer={AccountCreationDrawerFooter(accountCreationForm, isLoading)}
			onClose={drawerManager.closeDrawer}
		>
			<AccountCreationForm
				accountManagers={accountManagers}
				form={accountCreationForm}
				onFinish={onFormFinishCallback}
			/>
		</BaseDrawer>
	);
}

export default AccountCreationDrawer;
