import { LONGER_TIME_UNITS } from '@copilot/common/pages/settings/message/const';
import { NodeTimerPeriodEnum } from '@copilot/common/store/models/const/enum';
import { Button, Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import MessageEditorTiming from './timing';

interface Message {
	nodeId?: string;
	period: number;
	text: string;
	time: number;
}
interface InitiateTimingEditorProps {
	header: string;
	description?: string;
	onSave: (message: Message) => void;
	message: Message;
	isEditable: boolean;
}

/**
 * Timing Editor for a message where we can set period and time a message is
 * sent after the previous message is initiated
 *
 * @param {string} header title of the editor
 * @param {string} [description] description for the editor
 * @callback onSave called on save
 * @param {Message} message message to be edited
 * @param {boolean} isEditable whether time can be edited
 */

const InitiateTimingEditor: React.FC<InitiateTimingEditorProps> = (props) => {
	const {
		onSave, message, header, description, isEditable,
	} = props;
	const [period, setPeriod] = useState<NodeTimerPeriodEnum>(message.period);
	const [time, setTime] = useState<number>(message.time);

	useEffect(() => {
		setPeriod(message.period);
		setTime(message.time);
	}, [message.period, message.time]);

	const handleSave = useCallback(() => {
		onSave({
			nodeId: message.nodeId,
			period,
			text: message.text,
			time,
		});
	}, [period, time, message]);

	return (
		<>
			<h3 style={{ fontWeight: 600 }}>{header}</h3>
			<p>{description}</p>
			<Row justify="start">
				<Col>
					<MessageEditorTiming
						period={period}
						time={time}
						onPeriodUpdate={setPeriod}
						onTimeUpdate={setTime}
						timeUnits={LONGER_TIME_UNITS}
					/>
				</Col>
				<Button onClick={handleSave} disabled={!isEditable || !message.text}>Save</Button>
			</Row>
		</>
	);
};

export default InitiateTimingEditor;
