import { IUserInterestsModel, UserInterestType } from '../responses/models/interests';
import BaseDataManager from './base';

class UserInterestsManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get the User Interests dictionary
	 */
	public getUserInterests = async (): Promise<IUserInterestsModel> => {
		const url = this.BACKEND_ROUTES.USER_INTERESTS.Default;
		return (await this.RequestManager.get<IUserInterestsModel>(url)).data;
	};

	/**
	 * Update the user interests for given feature
	 * @param feature the feature for which the interest is to be updated
	 * @param interests user interests data to update with
	 * @returns updated user interest
	 */
	public updateUserInterests = async (
		feature: UserInterestType,
		isInterested: boolean
	): Promise<IUserInterestsModel> => {
		const url = this.combineRoute(this.BACKEND_ROUTES.USER_INTERESTS.Default, feature, String(isInterested));
		return (await this.RequestManager.post<IUserInterestsModel>(url)).data;
	};

	/**
	 * remove the user interests for the given feature (sets it to false)
	 * @param feature the feature for which the interest is to be updated
	 * @returns the updated interest
	 */
	public removeUserInterests = async (feature: UserInterestType): Promise<IUserInterestsModel> => {
		const url = this.combineRoute(this.BACKEND_ROUTES.USER_INTERESTS.Default, feature);
		return (await this.RequestManager.delete(url)).data;
	};
}

const instance = new UserInterestsManager();
export default instance;
