
import Store from '..';
import {
	createSelector, Ref, Selector, SessionBoundModel,
} from 'redux-orm';
import { Organization } from '@copilot/common/store/models/redux';

export namespace OrganizationSelectors {
	export const getActiveOrganizationId = (
		state: ReturnType<typeof Store['ReduxStore']['getState']>
	): string => state.app.organization;

	export const getActiveOrganization = createSelector(
		Store.ORM,
		(state: typeof Store.State) => state.app.organization,
		(session, organizationId: string) => session.Organization.withId(organizationId)
	);

	export const getOrganization = (orgId: string): Selector<any, Ref<SessionBoundModel<Organization>> | undefined> => createSelector(
		Store.ORM,
		(session) => session.Organization.withId(orgId)?.ref
	);

	export const getOrganizations = createSelector(
		Store.ORM,
		(session) => session.Organization.all().toRefArray()
	);
}
