import React, {
	useCallback, useState, useEffect,
} from 'react';
import { useFetch } from '@copilot/common/hooks/common';
import BaseModal, { BaseModalProps } from '../base';
import { AdminManager } from '@copilot/data/managers/admin';
import CalendlyForm, {
	CalendlyUpdateFormValues,
} from '@copilot/common/components/forms/user/calendlyForm';
import notificationManager from '@copilot/common/utils/notificationManager';
import { AdminMemberResponse } from '@copilot/data/responses/interface';

interface CalendlyProps extends BaseModalProps {
	uid: string;
}

const Calendly: React.FC<CalendlyProps> = (props) => {
	const { uid, ...modalProps } = props;
	const [, fetchAdminMember] = useFetch(AdminManager.getAdminMember);
	const [, setAdminUrl] = useFetch(AdminManager.setAdminMemberUrl);
	const [, unsetAdminUrl] = useFetch(AdminManager.unsetAdminMemberUrl);

	const [calendlyUrl, setCalendlyUrl] = useState<string>('');

	useEffect(() => {
		fetchAdminMember(uid).then((response: AdminMemberResponse) => {
			if (response) {
				setCalendlyUrl(response.calendlyUrl);
			}
		});
	}, [uid]);

	const onSubmit = useCallback(
		(values: CalendlyUpdateFormValues) => {
			setAdminUrl(uid, values.url).then(() => {
				notificationManager.showSuccessNotification({
					message: 'Saved',
				});
			});
		},
		[uid]
	);

	const onResetInfo = () => {
		unsetAdminUrl(uid).then(() => {
			setCalendlyUrl('');
			notificationManager.showSuccessNotification({
				message: 'Reset',
			});
		});
	};

	return (
		<BaseModal {...modalProps}>
			<CalendlyForm calendlyUrl={calendlyUrl} onSubmit={onSubmit} onResetInfo={onResetInfo} />
		</BaseModal>
	);
};

export default Calendly;
