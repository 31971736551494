import React from 'react';
import { isReactElement } from '@copilot/common/utils';
import { Row, Col } from 'antd';
import BaseWizardStep from './steps';
import { BaseWizardContext } from './context';

interface IWizardHeaderProps {}

/**
 * Wizard Title Container
 */
const WizardHeader: React.FC<IWizardHeaderProps> = (props) => {
	const { children } = props;
	return (
		<p style={{ textAlign: 'left' }}>
			{children}
		</p>
	);
};

interface IWizardFooterProps {}

/**
 * Wizard Title Container
 */
const WizardFooter: React.FC<IWizardFooterProps> = (props) => {
	const { children } = props;
	return (
		<>
			{ children }
		</>
	);
};

const getHeaderAndContent = (children: React.ReactNode) => {
	let header: React.ReactNode = null;
	const content: React.ReactNode[] = [];
	let footer: React.ReactNode = null;
	React.Children.map(children, (child) => {
		if (isReactElement(child)) {
			switch (child.type) {
				case WizardHeader:
					header = child;
					break;
				case BaseWizardStep:
					content.push(child);
					break;
				case WizardFooter:
					footer = child;
					break;
			}
		}
	});
	return {
		header, content, footer,
	};
};

interface IBasicWizardContainerStaticProps {
	Header: typeof WizardHeader
	Step: typeof BaseWizardStep;
	Footer: typeof WizardFooter;
}

interface IBasicWizardContainerProps {
	farthestNode: number;
}

/**
 * Container for the basic wizard
 * @param farthestNode farthestNode in the wizard
 */
const BasicWizardContainer: React.FC<IBasicWizardContainerProps> & IBasicWizardContainerStaticProps = (props) => {
	const {
		farthestNode, children,
	} = props;
	const {
		header, content, footer,
	} = getHeaderAndContent(children);

	return (
		<BaseWizardContext.Provider value={{ currentNode: farthestNode }}>
			<Row>
				<Col span={24}>{header}</Col>
			</Row>
			<Row>
				<Col span={24}>{content}</Col>
			</Row>
			<Row>
				<Col span={24}>{footer}</Col>
			</Row>
		</BaseWizardContext.Provider>
	);
};
BasicWizardContainer.Header = WizardHeader;
BasicWizardContainer.Step = BaseWizardStep;
BasicWizardContainer.Footer = WizardFooter;

export default BasicWizardContainer;
