import React, { useMemo, useCallback, useEffect } from 'react';
import CampaignMemberTable from '@copilot/common/components/tables/tableTypes/campaignMember';
import DrawerManager from '@copilot/common/utils/drawerManager';
import { Button } from 'antd';
import { CampaignFields } from '@copilot/common/store/models/redux';
import { ModelState } from '@copilot/common/store/models/fetch';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';
import { usePermission, validateIsEditable, validateIsVisible } from '@copilot/common/hooks/permission';
import { PermissionName } from '@copilot/common/hooks/permission/interface';

interface CampaignDashboardTeamMembersProps {
	campaign: CampaignFields;
	activeMemberId: string;
	initialCampaignMemberId?: string;
	onInitialDrawerOpened?: () => void;
	members: ModelState<CampaignMemberModel>;
}

/**
 * [Smart] The Team Members tab of the Campaign Dashboard
 * @param {CampaignFields} campaign the current campaign
 * @param {string} activeMemberId the current user
 * @param {string} initialCampaignMemberId if set, the drawer for this campaign member gets opened on tab load
 * @param {function} onInitialDrawerOpened called after the drawer for the initialCampaignMemberId gets opened
 */
const CampaignDashboardTeamMembers: React.FC<CampaignDashboardTeamMembersProps> = (props) => {
	const {
		campaign, activeMemberId, initialCampaignMemberId, onInitialDrawerOpened, members,
	} = props;
	const [teamMembersPermission] = usePermission(PermissionName.TeamMembers);
	const [ownTeamMembersPermission] = usePermission(PermissionName.OwnTeamMembers);

	useEffect(() => {
		if (initialCampaignMemberId) {
			const campaignMember = members.data.find((m) => m.id === initialCampaignMemberId);
			if (campaignMember) {
				DrawerManager.openCampaignMemberDrawer({ campaign, campaignMember });
				onInitialDrawerOpened?.();
			}
		}
	}, [campaign, members.data, initialCampaignMemberId, onInitialDrawerOpened]);

	const editMembers = useCallback((campaignMembers) => {
		if (campaign?.id && (validateIsEditable(teamMembersPermission | ownTeamMembersPermission))) {
			DrawerManager.openCampaignTeamDrawer({
				id: campaign.id,
				campaignMembers: campaignMembers.data,
				onClose: () => {
					DrawerManager.closeDrawer();
				},
			});
		}
	}, [campaign?.id, teamMembersPermission, ownTeamMembersPermission]);

	const tableTitle = useMemo(() => (
		validateIsEditable(teamMembersPermission) && (
			<Button onClick={() => editMembers(members)}>Add Members</Button>
		)
	), [editMembers, members, teamMembersPermission]);

	const tableData = useMemo(() => (
		validateIsVisible(teamMembersPermission)
			? members?.data
			: validateIsVisible(ownTeamMembersPermission) && members?.data?.filter((m) => m.orgMemberId === activeMemberId)
	), [members?.data, activeMemberId, teamMembersPermission, ownTeamMembersPermission]);

	return (
		<>
			{tableData && (
				<CampaignMemberTable
					title={tableTitle}
					data={tableData}
					allowRowClick
					campaign={campaign}
					campaignType={campaign.type}
				/>
			)}
		</>
	);
};

export default CampaignDashboardTeamMembers;
