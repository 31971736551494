import { Tag } from 'antd';
import React, { useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

interface ITagListProps {
	tagInfo: { id: string; name: string }[];
	numMaxTag: number;
	showAllTags: boolean;
	onClose?: (tagId: string) => void;
	isClosable?: boolean;
}
const TagList: React.FC<ITagListProps> = (props) => {
	const {
		tagInfo, onClose, isClosable,
	} = props;

	return (
		<>
			{tagInfo.map((tag) => (
				<Tag key={tag.id} onClose={() => onClose?.(tag.id)} closable={isClosable}>
					{tag.name}
				</Tag>
			))}
		</>
	);
};

interface IRemainingTagProps {
	total: number;
}
const RemainingTag: React.FC<IRemainingTagProps> = (props) => {
	const { total } = props;
	if (total < 1) return null;
	return (
		<Tag>
			...
			{total}
			{' '}
			more contacts
		</Tag>
	);
};

interface IExpandTagProps {
	onClick: () => void;
	visible: boolean;
}
const ExpandTag: React.FC<IExpandTagProps> = (props) => {
	const { onClick, visible } = props;
	return visible 
		? (
			<Tag onClick={onClick}>
				<PlusOutlined />Expand
			</Tag>
		) : null;
};

interface IExpandableTagListProps {
	tagInfo: { id: string; name: string }[];
	totalCount?: number;
	title?: string;
	numMaxTag?: number;
	isClosable?: boolean;
	onClose?: (tagId: string) => void;
}

/**
 * Show a expandable list of tag elements
 * @param {{id: string; name: string}[]} tags information on tags we want to display
 * @param {number} totalCount total number of tags selected
 * @param {string} title title of the list
 * @param {number} numMaxTag max number of tags to display before expanding
 * @param {boolean} isClosable whether tags are closable or not
 * @param {function} onClose called on tag close
 */
const ExpandableTagList: React.FC<IExpandableTagListProps> = (props) => {
	const {
		tagInfo, title, numMaxTag = 10, onClose, totalCount = tagInfo.length, isClosable = false,
	} = props;

	const [showAllTags, setShowAllTags] = useState<boolean>(false);
	const numHiddenTags = useMemo(() => totalCount - tagInfo.length, [totalCount, tagInfo.length]);
	const isVisibleExpandTag = useMemo(() => (tagInfo.length > numMaxTag) && !showAllTags, [numMaxTag, tagInfo.length, showAllTags]);
	const isExpandable = useMemo(() => (tagInfo.length === totalCount) && (tagInfo.length > numMaxTag), [tagInfo.length, totalCount]);

	return (
		<>
			<h4>{title}</h4>
			<div>
				{' '}
				<TagList
					tagInfo={tagInfo}
					numMaxTag={numMaxTag}
					showAllTags={showAllTags}
					onClose={onClose}
					isClosable={isClosable}
				/>
				{isExpandable ?
					(
						<ExpandTag
							onClick={() => setShowAllTags(true)}
							visible={isVisibleExpandTag}
						/>
					)
					:
						<RemainingTag total={numHiddenTags} />}
			</div>
		</>
	);
};

export default ExpandableTagList;
