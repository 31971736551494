import useTracking from '@copilot/common/hooks/tracking';
import { useCallback, useEffect, useState } from 'react';

export const EVENT_NAME = 'linkedin_login';

export type LinkedInWizardStepTypes =
	'LinkedIn Login Form' |
	'LinkedIn Credentials Submitted' |
	'LinkedIn Pin Form' |
	'LinkedIn Pin Submitted' |
	'LinkedIn Incorrect Pin Submitted' |
	'LinkedIn System Error' |
	'LinkedIn Login Success';

export type LinkedInButtonClickTypes =
    'LinkedIn Reset Password' |
    'LinkedIn Get Support' |
    'LinkedIn Skip Credentials For Now' |
    'LinkedIn Skip Pin Verification For Now';

interface LinkedInWizardTrackingParameter {
	wizardStep: LinkedInWizardStepTypes,
	buttonClicked: LinkedInButtonClickTypes,
}

export const useLinkedInLoginTracking = (componentName: string, params: Partial<LinkedInWizardTrackingParameter> | null): [((wizardStep: LinkedInWizardStepTypes) => void), ((buttonClicked: LinkedInButtonClickTypes) => void)] => {
	const trackEvent = useTracking(componentName, EVENT_NAME, params);
	const [wizardStep, setWizardStep] = useState<LinkedInWizardStepTypes>();

	/**
	 * Since Linkedin Login's wizard step depends on polling, we need to avoid
	 * sending events every time we poll and wizard step has not changed
	 */
	useEffect(() => {
		if (wizardStep) {
			trackEvent({ wizardStep });
		}
	}, [wizardStep, trackEvent]);

	const trackButtonClicked = useCallback((buttonClicked: LinkedInButtonClickTypes) => {
		trackEvent({ buttonClicked });
	}, [trackEvent]
	);

	return [setWizardStep, trackButtonClicked];
};
