import { Config } from '@copilot/common/config';

export interface VideoModalDetailsType {
	title: string;
	description?: string;
	url: string;
}

export const VideoModalDetails: { [key in string]: VideoModalDetailsType } = {
	SearchVideo: {
		title: 'Here is a refresher for your URL',
		description: 'Watch our video on how you can keep the search you made through LinkedIn Sales Navigator and use it for our prospecting',
		url: Config.SearchVideoHash,
	},
	NurtureSearchVideo: {
		title: 'Here is how to target your current connections with a Sales Navigator URL',
		url: Config.NurtureSearchVideoHash,
	},
};
