import { FC } from 'react';
import { PromptMenu } from './promptMenu';

const CUSTOM_OPTION = 'customEdit';

const customPromptEditItem = {
	key: CUSTOM_OPTION,
	label: 'Custom Edit',
};

/**
 * Interface for Edit Flow Dropdown Props
 */
export type EditFlowDropdownPropsType = Readonly<{
	/**
	 * Prompts we want to display
	 */
	prompts: { id: string, label: string, value: string, action: string }[];
	/**
	 * Callback when the user select a preset prompt to generate
	 */
	onSelectPreset: (key: string) => void;
	/** 
	 * Callback to update whether the user is writing a custom prompt to edit
	 */
	setIsWritingCustomPrompt: (isWritingCustomPrompt: boolean) => void;
	/** 
	 * Callback to update whether the user is writing a custom edit
	 */
	setIsEditing: (isEditing: boolean) => void;
}>;

/**
 * [Presentational] Dropdown to represent the edit flow of smart reply
 * @param props
 */
export const EditFlowDropdown: FC<EditFlowDropdownPropsType> = (props) => {
	const { prompts, onSelectPreset, setIsWritingCustomPrompt, setIsEditing } = props;

	return <PromptMenu
		prompts={prompts}
		customPrompt={customPromptEditItem}
		
		selectable={false}
		onClick={({ key }) => {
			if (key === CUSTOM_OPTION) {
				setIsWritingCustomPrompt(true);
				setIsEditing(true);
			} else {
				onSelectPreset(key);
			}
		}}
	/>;
};
