import React, {
	useMemo, useCallback, useState, useEffect,
} from 'react';
import { Col, Row } from 'antd';
import BubbleCheckbox from './bubble';

interface BubbleCheckboxGroupProps {
	options: { key: string; value: string; label: string }[];
	selectedValues?: string[];
	onUpdate?: (values: string[]) => unknown;
	showCheckAll?: boolean;
}

const BubbleCheckboxGroup: React.FC<BubbleCheckboxGroupProps> = (props) => {
	const {
		onUpdate, options = [], selectedValues = [], showCheckAll = false,
	} = props;
	const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>(selectedValues);
	useEffect(() => {
		if (selectedCheckboxes) onUpdate?.(selectedCheckboxes);
	}, [selectedCheckboxes]);
	const handleAllChecked = useCallback(
		() => {
			setSelectedCheckboxes((c) =>
				(c.length === options.length ? [] : options.map((o) => o.value))
			);
		},
		[options]
	);
	const handleCheckboxClick = useCallback(
		(value: string, checked: boolean) => {
			if (checked) setSelectedCheckboxes((c) => [...c, value]);
			else setSelectedCheckboxes((c) => c.filter((v) => v !== value));
		},
		[selectedCheckboxes]
	);
	const checkboxes = useMemo(() => options.map((o) => (
		<BubbleCheckbox
			key={o.key}
			value={o.value}
			onChange={handleCheckboxClick}
			checked={selectedCheckboxes.includes(o.value)}
		/>
	)), [options, selectedCheckboxes]);
	return (
		<Row>
			<Col span={24}>
				{showCheckAll && options.length > 0 && (
					<Row>
						<Col>
							<BubbleCheckbox
								value="Select All"
								onChange={handleAllChecked}
								checked={selectedCheckboxes.length === options.length}
							>
								{selectedCheckboxes.length === options.length
									? 'Unselect All'
									: 'Select All'}
							</BubbleCheckbox>
						</Col>
					</Row>
				)}
				<Row>
					<Col>{checkboxes}</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default BubbleCheckboxGroup;
