import React from 'react';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

interface ActiveToggleProps extends SwitchProps {}

const ActiveToggle: React.ForwardRefRenderFunction<typeof Switch, ActiveToggleProps> = (props) => {
	const { children: _, checkedChildren, unCheckedChildren, style, ...rest } = props;
	return (
		<Switch
			checkedChildren={checkedChildren ?? 'Active'}
			unCheckedChildren={unCheckedChildren ?? 'Disabled'}
			style={ style ?? { width: '115px' }}
			{...rest}
		/>
	);
};

export default React.forwardRef(ActiveToggle);
