import * as React from 'react';
import {
	Col, InputNumber, Row, Select,
} from 'antd';
import { NodeConditionTimeTypeEnum } from '@copilot/common/store/models/const/enum';

interface TimeTriggerProps {
	selectPeriod: (x: any) => void;
	selectAmount: (x: any) => void;
	selectUnit: (x: any) => void;
	currentAmount: number;
	currentUnit: string;
	currentSubType: NodeConditionTimeTypeEnum;
}

class TimeTrigger extends React.Component<TimeTriggerProps> {
	constructor(props: TimeTriggerProps) {
		super(props);
		this.selectAmount = this.selectAmount.bind(this);
		this.selectUnit = this.selectUnit.bind(this);
		this.selectPeriod = this.selectPeriod.bind(this);
	}

	selectPeriod(e: any) {
		this.props.selectPeriod(e);
	}

	selectAmount(e: any) {
		this.props.selectAmount(e);
	}

	selectUnit(e: any) {
		this.props.selectUnit(e);
	}
	render() {
		return (
			<>
				<h3>Time options</h3>
				<Row>
					<Col style={{ width: 500 }}>
						<Select
							value={this.props.currentSubType}
							onSelect={this.selectPeriod}
							style={{ margin: '5px' }}
						>
							<Select.Option value={NodeConditionTimeTypeEnum.After}>
								After
							</Select.Option>
							<Select.Option value={NodeConditionTimeTypeEnum.Before}>
								Before
							</Select.Option>
						</Select>
						<InputNumber
							min={1}
							max={1000}
							value={this.props.currentAmount}
							onChange={this.selectAmount}
							style={{ margin: '5px' }}
						/>
						<Select
							value={this.props.currentUnit}
							onSelect={this.selectUnit}
							style={{ margin: '5px' }}
						>
							<Select.Option value="Minutes">Minutes</Select.Option>
							<Select.Option value="Hours">Hours</Select.Option>
							<Select.Option value="Days">Days</Select.Option>
							<Select.Option value="Weeks">Weeks</Select.Option>
							<Select.Option value="Months">Months</Select.Option>
						</Select>
					</Col>
				</Row>
			</>
		);
	}
}

export default TimeTrigger;
