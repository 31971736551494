import BaseDrawer from '@copilot/common/components/drawer/wrappers/base';
import SearchInput from '@copilot/common/components/search/input';
import drawerManager from '@copilot/common/utils/drawerManager';
import { ICoupon } from '@copilot/data/responses/models/billing';
import { sorterByName } from '@copilot/common/utils/common/sort';
import { Table, Typography } from 'antd';
import { useState } from 'react';
import CouponDrawerItem from './couponDrawerItem';

const { Title } = Typography;

/**
 * Properties to display coupon drawer.
 */
interface CouponDrawerProps {
	/**
	 * List of coupons to display in coupon drawer.
	 */
	coupons: ICoupon[];
	/**
	 * Current selected coupon id.
	 */
	selectedCouponId?: string;
	/**
	 * Callback for coupon id selection from coupon drawer.
	 */
	onCouponSelect: (couponId: string) => void;
}

/**
 * [Presentational] Coupon drawer to select a coupon from a list of coupons.
 */
const CouponDrawer: React.FC<CouponDrawerProps> = ({
	coupons, selectedCouponId, onCouponSelect,
}) => {
	const [searchTerm, setSearchTerm] = useState<string>('');

	const displayedCoupons = coupons
		.filter((coupon) => {
			const trimmedSearchTerm = searchTerm.trim().toLowerCase();
			const isPercentSearch = trimmedSearchTerm.endsWith('%');
			return coupon.name.toLowerCase().includes(trimmedSearchTerm) || //search by name
				(!isPercentSearch && coupon.amountOff?.toString().includes(trimmedSearchTerm.replace('$', ''))) || //search by price
				(isPercentSearch && trimmedSearchTerm === `${coupon.percentOff?.toString()}%`); //search by percent
		})
		.sort(sorterByName);

	const isCurrentCoupon = (coupon: ICoupon) => coupon.id === selectedCouponId;

	/**
	 * Emits coupon id and closes the coupon drawer.
	 */
	const onCouponSelected = (couponId: string) => {
		onCouponSelect(couponId);
		drawerManager.closeDrawer();
	};

	const columns = [
		{
			title: 'coupon',
			key: 'coupon',
			dataIndex: 'coupon',
			render: (text: string, record: ICoupon) => (
				<CouponDrawerItem
					key={record.id}
					coupon={record}
					showCurrentCouponTag={isCurrentCoupon(record)}
				/>
			),
		},
	];

	return (
		<BaseDrawer
			title={<Title level={2} style={{ marginBottom: 0, fontSize: '24px' }}>Select a Coupon</Title>}
			width="50%"
			placement="right"
			onClose={drawerManager.closeDrawer}
		>
			<SearchInput
				onSearch={setSearchTerm}
				placeholder="Search coupon..."
			/>
			<Table
				columns={columns}
				dataSource={displayedCoupons}
				pagination={false}
				components={{
					// Hide header of table
					header: {
						row: () => null,
					},
				}}
				onRow={(record: ICoupon) => ({
					onClick: () => onCouponSelected(record.id),
				})}
				style={{ cursor: 'pointer' }}
				locale={{
					emptyText: 'No coupons have been found',
				}}
			/>
		</BaseDrawer>
	);
};

export default CouponDrawer;
