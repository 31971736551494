import useTracking from '@copilot/common/hooks/tracking';

type SearchListTabTrackingType =
	'Redirect to Search List Tab' |
	'Search List Tab Search - All Team Users' |
	'Search List Tab Search - Advanced Team User';

interface SearchListTabTrackingParams {
	buttonClicked: SearchListTabTrackingType
}

export const useSearchListTracking = (componentName: string, params: SearchListTabTrackingParams | null): (newParams: SearchListTabTrackingParams) => void =>
	useTracking(componentName, 'search_list_tab', params);
