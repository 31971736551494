import React from 'react';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import Link from 'antd/lib/typography/Link';
import { ILocation } from '@copilot/common/components/linkedin/searchCriteria/location';
import CampaignOnboardWizardCopilotSearch from '@copilot/common/pages/campaignDashboard/onboard/wizard/search/copilotSearch';
import styled from 'styled-components';
import { Config } from '@copilot/common/config';
import NewUserOnboardTitle from './newUserTitle';
import withExtraOccupations from '@copilot/common/pages/campaignDashboard/onboard/wizard/search/withExtraOccupations';
import BubbleCheckboxGroup from '@copilot/common/components/forms/common/generics/checkboxes/bubbleGroup';

const StyledCol = styled(Col)`
	h1 {
		font-size: 1.5em;
	}
`;

const NewUserOnboardWizardSearch = withExtraOccupations(CampaignOnboardWizardCopilotSearch);

interface INewUserSearchCriteriaProps {
	location: ILocation;
	updateLocation: (location: ILocation) => void;
	occupation: string[]
	updateOccupation: (values: string[]) => void;
	extraOccupations: string[]
	setExtraOccupations: (values: string[]) => void;
	experience: string[];
	recentJobChange: boolean;
	headCount: string[];
	exclusions: string[];
	experienceOptions: React.ComponentProps<typeof BubbleCheckboxGroup>['options'];
	headCountOptions: React.ComponentProps<typeof BubbleCheckboxGroup>['options'];
	updateExperience: (values: string[]) => void;
	updateRecentJob: (values:boolean) => void;
	updateHeadCount: (values:string[]) => void;
	updateExclusions: (values:string[]) => void;
}

/**
 * [Presentational] Returns component holding a small header description and the CampaignOnboardWizardCopilotSearch component
 * @param props
 * @param location - current list of selected locations
 * @param updateLocation - function run when an update is made to selected locations
 * @param occupation - current list of selected occupations
 * @param updateOccupation - function run when an update is made to selected occupations
 * @param extraOccupations - current list of extra occupations to target
 * @param setExtraOccupations - function run when uses adds another occupation
 * @param experience - selected years of work experience
 * @param recentJobChange - true if selected criteria to target those with recent job change
 * @param headCount - selected company headcount
 * @param exclusions - selected exclude keywords
 * @param updateExperience - callback on updating experience
 * @param updateRecentJob - callback on updating recent job change
 * @param updateHeadCount - callback on updating headcount
 * @param updateExclusions - callback onupdating a list of excludions
 * @param experienceOptions - options to display for experience
 * @param headCountOptions - options to display for headCount
 * @returns StyledCol holding a header & CampaignOnboardWizardCopilotSearch
 */
const NewUserSearchCriteria: React.FC<INewUserSearchCriteriaProps> = (props) => {
	const {
		location, updateLocation, occupation, updateOccupation, extraOccupations, setExtraOccupations,
		experience, recentJobChange, headCount, exclusions, updateExperience, updateRecentJob, updateHeadCount, updateExclusions,
		experienceOptions, headCountOptions,
	} = props;

	return (
		<Row justify="center">
			<StyledCol xxl={20}>
				<NewUserOnboardTitle title="Let's get precise about your target prospects">
					<Text>
						The best campaigns target those who see the most value in your product.
					</Text>
					<Text>
						<Link target="_blank" href={Config.targetingPeopleURL}>Learn more</Link>
						{' '}
						about selecting your audience.
					</Text>
				</NewUserOnboardTitle>
				<NewUserOnboardWizardSearch
					location={location}
					updateLocation={updateLocation}
					occupation={occupation}
					updateOccupation={updateOccupation}
					extraOccupations={extraOccupations}
					updateExtraOccupations={setExtraOccupations}
					recentJobChange={recentJobChange}
					experience={experience}
					headCount={headCount}
					exclusions={exclusions}
					headCountOptions={headCountOptions}
					experienceOptions={experienceOptions}
					handleExperienceChange={updateExperience}
					handleExclusionsChange={updateExclusions}
					handleHeadCountChange={updateHeadCount}
					handleRecentJobChange={updateRecentJob}
				/>
			</StyledCol>
		</Row>
	);
};

export default NewUserSearchCriteria;
