import { createReduxSingleEntity } from '@copilot/common/store/common/singleEntity';
import { createFetchReducer } from '@copilot/common/store/reducers/fetch';
import { IInvoice } from '@copilot/data/responses/models/billing';
import {
	BILLING, BILLING_ORGANIZATION_MEMBER, PRODUCT, COUPON, INVOICE,
} from './constant';

export const billingReducer = createFetchReducer(BILLING);
export const orgMemberReducer = createFetchReducer(BILLING_ORGANIZATION_MEMBER);
export const productReducer = createFetchReducer(PRODUCT);
export const couponReducer = createFetchReducer(COUPON);

export const {
	reducer: invoicePreviewReducer,
	selector: getInvoicePreview,
	watcher: watchFetchInvoicePreview,
	action: fetchInvoicePreview,
} = createReduxSingleEntity<IInvoice>(INVOICE);
