import {
	createBaseModelState, ModelSingleState, ModelState, ModelStateMap,
} from '../models/fetch';

export const getStateLocationByName = <T>(state: Record<string, ModelStateMap<T>>, name: string): ModelStateMap<T> => state?.[name.toLowerCase()];

const getState = <T>(state: Record<string, ModelStateMap<T>>, name: string, qualifier = 'default'): ModelState<T> => getStateLocationByName(state, name)?.[qualifier]?.state ?? createBaseModelState<T>();
/**
 * Create Selector for fetch
 * @param name State name
 */
export const createFetchSelector = <T,>(name: string) => (state: Record<string, ModelStateMap<T>>) => getState(state, name);

/**
 * Creates a selector that gets one element identified by its id.
 * @param name
 */
export const createFetchOneSelector = <T extends { id: string }>(name: string) => (id: string) => (state: Record<string, ModelStateMap<T>>) => getState(state, name)?.data?.find((x) => x.id === id);

/**
 * Creates a selector that gets a list of items that are qualified by <code>qualifier</code>.
 * @param name
 */
export const createQualifiedFetchSelector = <T,>(name: string) => (qualifier: string) => (state: Record<string, ModelStateMap<T>>) => getState(state, name, qualifier);

/**
 * Creates a selector that gets one element identified by its id qualified by <code>qualifier</code>.
 * @param name
 */
export const createQualifiedFetchOneSelector = <T extends { id: string }>
(name: string) =>
	(qualifier: string) =>
		(state: Record<string, ModelStateMap<T>>, id: string) => getState(state, name, qualifier)?.data?.find((x) => x.id === id);

/**
 * Creates a selector that gets one element identified by its id with state
 * @param name
 */
export const createQualifiedFetchOneWithLoadingSelector = <T extends { id: string }>
(name: string) =>
	(id: string) =>
		(state: Record<string, ModelStateMap<T>>): ModelSingleState<T> => {
			const selectorState = getState(state, name);
			return {
				data: selectorState.data?.find((x) => x.id === id),
				loading: selectorState.loading,
				error: selectorState.error,
			};
		};
