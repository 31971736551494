import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import {
	ColumnDefinition,
	ColumnActionFormat,
	ColumnIconType,
} from '../../componentModels/columnDefinition';
import { LinkedinFilled } from '@ant-design/icons';
import { UtilityFunctions } from '@copilot/common/utils/common';

const convertToValue = (values: string[]) => values.join(' ');

export class LinkColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public sorter: ColumnProps<M>['sorter'];

	constructor(c: ColumnDefinition) {
		super(c);
		this.render = (text: string, record: M) => {
			const { properties = [], label, action } = c;

			const link = convertToValue(this.getValues(properties, record));

			const getIconFromType = (iconType: ColumnIconType) => {
				switch (iconType) {
					case ColumnIconType.LinkedinFilled:
						return <LinkedinFilled />;
					default:
						return UtilityFunctions.assertUnreachable(iconType);
				}
			};

			const handleClick = (e: React.MouseEvent<HTMLElement>) => {
				e.stopPropagation();
			};

			return (
				<span onClick={handleClick}>
					<a href={link} target="_blank">
						{action?.type &&
							action.metaData &&
							action.metaData.values &&
							action.metaData.type == ColumnActionFormat.ImageLink && (
								<img src={`${action.metaData.values}`} />
						)}
						{action?.type &&
							action.metaData &&
							action.metaData.values &&
							action.metaData.type == ColumnActionFormat.IconLink &&
							getIconFromType(action.metaData.values.type as ColumnIconType)}

						{(!action || !action.type || !action.metaData || !action.metaData.values) && label}
					</a>
				</span>
			);
		};
	}
}
