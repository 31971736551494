import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import { ColumnDefinition } from '../../componentModels/columnDefinition';

export class DateColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public sorter: ColumnProps<M>['sorter'];

	constructor(c: ColumnDefinition) {
		super(c);

		this.render = (text: string, record: M) => {
			const { properties = [] } = c;
			const values = this.getValues<Date | string>(properties, record);
			const dates = values
				.map((v) =>
					(v
						? typeof v === 'string'
							? new Date(v).toLocaleDateString()
							: v.toLocaleDateString()
						: '')
				)
				.join(' ');
			return <span>{dates}</span>;
		};
		this.sorter = c.sort;
	}
}
