import * as React from 'react';
import {
	Button, Checkbox, Col, Row, Form, Space,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styled from 'styled-components';
import { FiltersRequestModel } from '@copilot/data/requests/models';
import SearchInput, { SearchInputPlaceholder } from '../search/input';
import Title from '@copilot/common/typography/title';

const StyledDiv = styled.div`
	max-height: 300px;
	overflow: auto;
	::-webkit-scrollbar {
		width: 10px;
		background-color: transparent;
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.3);
	}
`;

interface SelectorColumn {
	key: string;
	label: string;
	isVisible?: boolean;
	hideable?: boolean;
	displayName?: React.ReactNode;
}

export interface SelectorProps<TColumn extends SelectorColumn = SelectorColumn> {
	columns?: TColumn[];
	filters?: TColumn[];
	stats?: TColumn[];
	exportFilters?: FiltersRequestModel;
	showSelectAll?: boolean;
	updateColumns?: (columns: SelectorProps<TColumn>['columns']) => void;
	updateStats?: (stats: SelectorProps<TColumn>['stats']) => void;
	updateData?: (filter: SelectorProps<TColumn>['filters']) => void;
	updateExport?: (columns: TColumn[]) => void;
	cancel?: () => void;
	customTitle?: string;
}

interface SelectorState {
	isAllChecked: boolean;
	search: string;
}

class Selector<TColumn extends SelectorColumn> extends React.Component<SelectorProps<TColumn>, SelectorState> {
	state = {
		isAllChecked: false,
		search: '',
	};
	formRef: React.RefObject<FormInstance> = React.createRef();
	constructor(props: SelectorProps<TColumn>) {
		super(props);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleFinish = this.handleFinish.bind(this);
		this.resetFields = this.resetFields.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.setSearch = this.setSearch.bind(this);
	}

	checkIfAllOptionSelected = () => {
		const option =
			(this.props.columns?.find((c) => !this.formRef?.current?.getFieldValue(c.key))) ??
			(this.props.filters?.find((c) => !this.formRef?.current?.getFieldValue(c.key))) ??
			(this.props.stats?.find((c) => !this.formRef?.current?.getFieldValue(c.key)));
		if (!option) this.setState({ isAllChecked: true });
	};

	optionSelected = (event: CheckboxChangeEvent) => {
		if (!event.target.checked) this.setState({ isAllChecked: false });
		const option =
			(this.props.columns?.find((c) => c.label === event.target.value)) ??
			(this.props.filters?.find((c) => c.label === event.target.value)) ??
			(this.props.stats?.find((c) => c.label === event.target.value));
		if (option)	this.formRef?.current?.setFieldsValue({ [option.key]: event.target.checked });
		this.checkIfAllOptionSelected();
	};

	generateOptions = (options: SelectorProps['columns'] | SelectorProps['filters']) => {
		const optionsList = [];
		if (options) {
			for (let i = 0; i < options.length; i++) {
				const c = options[i];
				if (c.hideable) {
					optionsList.push(
						<Form.Item
							name={c.key}
							key={c.key}
							style={{
								marginBottom: 'unset',
								display:
									typeof c.label === 'string' &&
									c.label.toLowerCase().includes(this.state.search)
										? 'inline'
										: 'none',
							}}
							valuePropName="checked"
							initialValue={c.isVisible}
						>
							<Checkbox onChange={this.optionSelected} value={c.label}>
								{c.displayName ?? c.label}
							</Checkbox>
						</Form.Item>
					);
				}
			}
		}
		return optionsList;
	};

	handleCancel() {
		if (this.props.cancel) this.props.cancel();
	}

	handleFinish(values: any) {
		if (this.props.updateColumns && this.props.columns) {
			const newColumns = this.props.columns.map((c) => {
				const newColumn = { ...c };
				newColumn.isVisible = values[c.key];
				//Hard setting the visibility of selectors that cant be hidden
				if (!newColumn.hideable) {
					newColumn.isVisible = true;
				}
				return newColumn;
			});
			this.props.updateColumns?.(newColumns);
		}
		if (this.props.updateData && this.props.filters) {
			const newFilters = this.props.filters.map((c) => {
				const newFilter = { ...c };
				newFilter.isVisible = values[c.key];
				return newFilter;
			});
			this.props.updateData?.(newFilters);
		}
		if (this.props.updateStats && this.props.stats) {
			const newStats = this.props.stats.map((c) => {
				const newStat = { ...c };
				newStat.isVisible = values[c.key];
				return newStat;
			});
			this.props.updateStats?.(newStats);
		}

		if (this.props.updateExport && this.props.columns && this.props.filters) {
			const newExports = this.props.columns.map((c) => {
				const newExport = { ...c };
				newExport.isVisible = values[c.key];
				return newExport;
			});
			this.props.updateExport?.(newExports);
		}
	}

	setSearch(phase: string) {
		this.setState({ search: phase.toLowerCase() });
	}

	resetFields() {
		this.formRef?.current?.resetFields();
	}

	selectAll = (event: CheckboxChangeEvent) => {
		this.setState({ isAllChecked: event.target.checked });
		this.props.columns?.forEach((option) => {
			this.formRef?.current?.setFieldsValue({ [option.key]: event.target.checked });
		});
		this.props.filters?.forEach((option) => {
			this.formRef?.current?.setFieldsValue({ [option.key]: event.target.checked });
		});
		this.props.stats?.forEach((option) => {
			this.formRef?.current?.setFieldsValue({ [option.key]: event.target.checked });
		});
	};

	componentDidMount() {
		this.checkIfAllOptionSelected();
	}

	render() {
		return (
			<Space direction={'vertical'}>
				{this.props.updateColumns && <Title level={5}>{this.props.customTitle ?? 'Columns'}</Title>}
				{this.props.updateData && <Title level={5}>{this.props.customTitle ?? 'Filters'}</Title>}
				{this.props.updateStats && <Title level={5}>{this.props.customTitle ?? 'Stats'}</Title>}
				{this.props.showSelectAll && (
					<Checkbox
						checked={this.state.isAllChecked}
						onChange={this.selectAll}
					>
						Select All
					</Checkbox>
				)}
				<SearchInput
					placeholder={SearchInputPlaceholder.SearchAndEnter}
					onSearch={this.setSearch}
				/>
				<Form
					onFinish={this.handleFinish}
					ref={this.formRef}
				>
					<StyledDiv>
						{this.props.columns && this.generateOptions(this.props.columns)}
						{this.props.filters && this.generateOptions(this.props.filters)}
						{this.props.stats && this.generateOptions(this.props.stats)}
					</StyledDiv>
					<Row justify="space-between" style={{ marginTop: '1em' }}>
						<Col>
							<Button type="primary" htmlType="submit">
								Done
							</Button>
						</Col>
						<Col>
							<Button type="default" onClick={this.handleCancel}>
								Cancel
							</Button>
						</Col>
					</Row>
				</Form>
			</Space>
		);
	}
}

export default Selector;
