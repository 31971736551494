import { IInstanceData } from '@copilot/data/responses/models/instances';
import { OrmState } from 'redux-orm';
import Store from '..';

export namespace InstanceDataSelectors {
	export const getInstances = (state: { entities: OrmState<any> }): readonly IInstanceData[] =>
		Store.ORM.session(state.entities)
			.InstanceData.all()
			.toRefArray();
}
