import _ from 'lodash';

/**
 * Adds a script with the given name to the document
 * checks if the script has already been added
 * and checks the current userAgent to see if it's on mobile
 * @param scriptName the name of the script
 * @param elem_id the id of the script element to be added
 * @param callback function to run after the script loads.  Default is null
 */
export const addScriptToDocument = (scriptName : string, elem_id: string, callback: ((this: GlobalEventHandlers, ev: Event) => any) | null = null) => {
	if (
		_.isNil(window?.document?.getElementById(elem_id)) &&
    !/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
	) {
		(function () {
			const script = document.createElement('script');
			script.async = true;
			script.id = elem_id;
			script.src = scriptName;
			if (!_.isNil(callback)) script.onload = callback;
			document.body.appendChild(script);
		}());
	}
};