import React, {
	useMemo, useCallback, useState,
} from 'react';
import { FilterTemplateResponse } from '@copilot/data/responses/interface';
import { DeleteOutlined } from '@ant-design/icons';
import {
	Button, Popconfirm, Row, Menu, Dropdown, Popover,
} from 'antd';
import { TemplateManager } from '@copilot/data';
import notificationManager from '@copilot/common/utils/notificationManager';
import { FilterTemplateActions } from '@copilot/common/store/actions/filterTemplate';
import { useDispatch } from 'react-redux';
import { ConnectionsPageButtonClicks } from '@copilot/common/pages/connections/tracking';

interface FilterTemplateSelectorProps {
	filterTemplates: readonly FilterTemplateResponse[];
	applyTemplate: React.Dispatch<React.SetStateAction<string>>;
	onTrackingUpdate: (buttonName: ConnectionsPageButtonClicks) => void;
}

/**
 * Selector for filter template to apply filters from
 *
 * @param {readonly FilterTemplateResponse[]} filterTemplates list of filter templates
 * @param {React.Dispatch<React.SetStateAction<string>>} applyTemplate called on click. Passes in filter template id we are intereseted in applying
 *
 */

const FilterTemplateSelector: React.FC<FilterTemplateSelectorProps> = (props) => {
	const {
		filterTemplates = [], applyTemplate, onTrackingUpdate,
	} = props;
	const storeDispatch = useDispatch();
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const handleDelete = useCallback((templateId: string) => {
		TemplateManager.deleteTemplateFilter(templateId)
			.then(() => {
				storeDispatch(FilterTemplateActions.deleteTemplate({ id: templateId }));
				notificationManager.showSuccessNotification({
					message: 'Template deleted',
					description: 'Filter Template has been deleted',
				});
				setIsVisible(false);
			})
			.catch(() => {
				notificationManager.showErrorNotification({
					message: 'Failed to delete template',
					description: 'Please try again',
				});
			});
	}, []);

	const handleOnClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		const selectedTemplateId = event.currentTarget.value;
		applyTemplate(selectedTemplateId);
		setIsVisible(false);
	}, [applyTemplate]);

	const handleSaveTemplateClick = () => {
		onTrackingUpdate('Saved Templates');
	};

	const handleVisibleChange = (visible: boolean) => {
		setIsVisible(visible);
	};

	const templateOptions = useMemo(() => (
		<Menu>
			{filterTemplates.map((f) => (
				<Menu.Item key={f.id}>
					<Row style={{ alignItems: 'center' }}>
						<Button value={f.id} type="link" size="small" style={{ marginBottom: '0px', marginRight: '20px', color: 'dimgray' }} onClick={handleOnClick}>{f.name}</Button>
						<Popconfirm
							placement="bottomRight"
							title="Are you sure to delete the saved filter?"
							okText="Yes"
							onConfirm={() => handleDelete(f.id)}
							getPopupContainer={(node) => node.parentNode as HTMLElement}
							overlayStyle={{ width: 300 }}
						>
							<DeleteOutlined
								style={{
									color: 'blue', position: 'absolute', right: '0px', marginRight: '10px',
								}}
							/>
						</Popconfirm>
					</Row>
				</Menu.Item>
			))}
		</Menu>
	), [filterTemplates, handleOnClick, handleDelete]);

	const saveButton = useMemo(() => (
		<Button
			style={{ marginLeft: '7px' }}
			onClick={handleSaveTemplateClick}
		>
			Saved Templates
			{' '}
		</Button>
	), []);

	const templateDropdown = useMemo(() => (
		<Dropdown trigger={['click']} overlay={templateOptions} open={isVisible} onVisibleChange={handleVisibleChange} placement="bottomRight">
			{saveButton}
		</Dropdown>
	), [templateOptions, isVisible, saveButton]);

	const emptyTemplateInfo = (
		<Popover content="There are currently no search templates" placement="bottomRight" trigger="click">
			{saveButton}
		</Popover>
	);

	const displaySelector = useMemo(() => {
		if (filterTemplates.length === 0) {
			return emptyTemplateInfo;
		} else return templateDropdown;
	}, [filterTemplates, templateDropdown, emptyTemplateInfo]);

	return (
		<>
			{displaySelector}
		</>
	);
};

export default FilterTemplateSelector;
