import {
	Button, Col, Row, Typography,
} from 'antd';
import BaseModal, { BaseModalProps } from '../base';

const { Title, Text } = Typography;

export interface ChangesNotSavedProps extends BaseModalProps {
	onConfirm: () => void;
	titleText?: string;
	bodyText?: string;
}

const ChangesNotSaved: React.FC<ChangesNotSavedProps> = (props) => {
	const {
		onConfirm,
		titleText = 'Your changes are not saved',
		bodyText = 'Any updates you made to this account will be lost if you leave this page.',
		onCancel,
		...modalProps
	} = props;

	const onConfirmCallback = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		onConfirm();
		onCancel?.(event);
	};

	return (
		<BaseModal bodyStyle={{ padding: '40px' }} closable={false} {...modalProps}>
			<Row style={{ marginBottom: '24px', justifyContent: 'center' }}>
				<Title level={5}>{titleText}</Title>
			</Row>
			<Row style={{ marginBottom: '24px', justifyContent: 'center' }}>
				<Text>
					{bodyText}
				</Text>
			</Row>
			<Row justify="center" gutter={8}>
				<Col>
					<Button onClick={onCancel}>Cancel</Button>
				</Col>
				<Col>
					<Button type="primary" onClick={onConfirmCallback}>
						Confirm
					</Button>
				</Col>
			</Row>
		</BaseModal>
	);
};

export default ChangesNotSaved;
