import { FC, useContext, useCallback } from 'react';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components';
import { WizardContext } from '@copilot/common/components/wizard/context';

export interface IWizardStepButtonProperties {
	backLabel: string;
	nextLabel: string;
	isBackDisabled: boolean;
	isNextDisabled: boolean;
	isNextLoading: boolean;
	onBackClick: () => void;
	onNextClick: () => void;
}

export interface IWithButtonPropertiesProps extends IWizardStepButtonProperties {
	backButtonTargetStep: number;
	nextButtonTargetStep: number;
	isAllowedToContinue: () => boolean;
}

/**
 * HOC to add wizard step button properties to the given component
 * @param {FC} Component
 * @param {() => boolean} isAllowedToContinue (optional) Should the user be allowed to continue to the next step?
 */
export const withWizardStepButtonProperties = <T extends Partial<IWithButtonPropertiesProps>>(Component: FC<Omit<T, 'backButtonTargetStep' | 'nextButtonTargetStep'> & IWizardStepButtonProperties>) => (props: T) => {
	const {
		backLabel, nextLabel, isBackDisabled, isNextDisabled, isNextLoading, backButtonTargetStep, nextButtonTargetStep, onBackClick, onNextClick, isAllowedToContinue,
	} = props;
	const { currentNode, onNodeChange } = useContext(WizardContext);

	const handleBackClick = useCallback(() => {
		if (onBackClick) {
			onBackClick();
			if (backButtonTargetStep !== undefined) onNodeChange(backButtonTargetStep);
		} else {
			onNodeChange(backButtonTargetStep ?? currentNode - 1);
		}
	}, [onBackClick, backButtonTargetStep, currentNode, onNodeChange]);

	const handleNextClick = useCallback(() => {
		if (isAllowedToContinue !== undefined && !isAllowedToContinue())
			return;
		if (onNextClick) {
			onNextClick();
			if (nextButtonTargetStep !== undefined) onNodeChange(nextButtonTargetStep);
		} else {
			onNodeChange(nextButtonTargetStep ?? currentNode + 1);
		}
	}, [onNextClick, nextButtonTargetStep, isAllowedToContinue, currentNode, onNodeChange]);

	return (
		<Component
			{...props}
			backLabel={backLabel ?? 'Back'}
			nextLabel={nextLabel ?? 'Next'}
			isBackDisabled={!!isBackDisabled}
			isNextDisabled={!!isNextDisabled}
			isNextLoading={!!isNextLoading}
			onBackClick={handleBackClick}
			onNextClick={handleNextClick}
		/>
	);
};

const StyledButton = styled(Button)`
    min-width: 100px;
`;

/**
 * Pair of back/next buttons to display in the wizard steps
 * @param {string} backLabel label for the back button
 * @param {string} nextLabel label for the next button
 * @param {boolean} isBackDisabled whether the back button is disabled
 * @param {boolean} isNextDisabled whether the next button is disabled
 * @param {boolean} isNextLoading whether the next button is in loading state
 * @param {function} onBackClick called when the back button is clicked
 * @param {function} onNextClick called when the next button is clicked
 * @param {function} handleGoNext called when the next button is clicked to verify that the user is allowed to move on and can display err messages
 */
export const WizardStepNavButtons: FC<IWizardStepButtonProperties> = (props) => {
	const {
		backLabel, nextLabel, isBackDisabled, isNextDisabled, isNextLoading, onBackClick, onNextClick,
	} = props;

	return (
		<>
			<Col style={{ marginRight: '16px' }}>
				<StyledButton
					type="default"
					disabled={isBackDisabled}
					onClick={onBackClick}
				>
					{backLabel}
				</StyledButton>
			</Col>
			<Col>
				<StyledButton
					type="primary"
					disabled={isNextDisabled}
					onClick={onNextClick}
					loading={isNextLoading}
				>
					{nextLabel}
				</StyledButton>
			</Col>
		</>
	);
};

/**
 * Basic wizard footer with back and next buttons
 * @param {IWizardStepButtonProperties} props
 */
const WizardStepNavButtonFooter: FC<IWizardStepButtonProperties> = (props) => (
	<Row justify="space-between">
		<WizardStepNavButtons {...props} />
	</Row>
);
export default withWizardStepButtonProperties(WizardStepNavButtonFooter);
