import { createLoadSingleActions } from '@copilot/common/store/actionCreators/singleEntity';
import { createFetchSingleReducer } from '@copilot/common/store/reducers/singleEntity';
import { createFetchSingleSelector } from '@copilot/common/store/selectors/singleEntity';
import { createSingleEntitySaga } from '@copilot/common/store/saga';

export const createReduxSingleEntity = <T>(name: string) => {
	const reducer = createFetchSingleReducer(name);
	const selector = createFetchSingleSelector<T>(name);
	const { fetchWatcher: watcher, fetchSingleAction: action } = createSingleEntitySaga(name, createLoadSingleActions<T>(name));

	return {
		reducer, selector, watcher, action,
	};
};
