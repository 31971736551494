import { partyIcon } from '@copilot/common/constant/icons';
import { Icon } from '@iconify/react';
import { Button, ButtonProps } from 'antd';

const iconStyle = {
	verticalAlign: 'text-top',
	marginRight: '6px',
} as const;
/**
 * Presentational component for our meeting booked button
 * @returns 
 */
export const MeetingButton = ({
	isBooked,
	handleMeetingClick,
	...rest
}: { isBooked: boolean, handleMeetingClick: (isBooked: boolean) => void } & ButtonProps) => {
	const text = isBooked 
		? <span><Icon icon={partyIcon} style={iconStyle} /> Meeting!</span>
		: <span>Mark as meeting booked</span>;
	const buttonType = isBooked ? 'default' : 'primary';
	return <Button type={buttonType} onClick={() => handleMeetingClick(!isBooked)} {...rest}>{text}</Button>;
};