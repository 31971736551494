import React from 'react';
import {
	AutoComplete, Button, Col, Row,
} from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { SelectValue } from 'antd/lib/select';
import { ITag } from '@copilot/common/store/models/redux';
import TagOption from './option';

interface TagFilterProps extends AutoCompleteProps {
	tags: ITag[];
	confirm?: () => void;
	resetFields?: () => void;
	setTag?: (selectedKeys: string[]) => void;
}

const preventDefault = (e: React.MouseEvent<HTMLSpanElement>) => e.preventDefault();
// TODO: specify type for option
const filterOption = (inputValue: string, option: any) => {
	const tagValue = option.value;
	if (tagValue === undefined) return true;
	if (typeof tagValue === 'string')
		return tagValue.toUpperCase().includes(inputValue.toUpperCase());
	else return ((tagValue as unknown) as string) == inputValue;
};

const TagFilter: React.FC<TagFilterProps> = (props) => {
	const {
		tags, confirm, setTag, resetFields, ...rest
	} = props;
	const [selectedTags, setSelectedTags] = React.useState<{ [k: string]: boolean }>({});
	const handleDone = React.useCallback(() => {
		confirm?.();
	}, [confirm]);
	const handleReset = React.useCallback(() => {
		setSelectedTags({});
		resetFields?.();
	}, [resetFields]);
	const handleSelect = React.useCallback(
		(value: SelectValue) => {
			const tag = value as string;
			setSelectedTags((state) => {
				let newTags;
				if (state[tag]) {
					const { [tag]: _, ...others } = state;
					newTags = others;
				} else {
					newTags = { ...state, [tag]: true };
				}
				setTag?.(Object.keys(newTags));
				return newTags;
			});
		},
		[setTag]
	);
	const dataSource = React.useMemo(() => {
		const tagOptions = Array.isArray(tags)
			? tags.map((t) => {
				const value = t.name || '';
				return (
					<AutoComplete.Option key={t.id} value={value}>
						<span onClick={preventDefault}>
							<TagOption tag={t} selected={selectedTags[value] === true} />
						</span>
					</AutoComplete.Option>
				);
			  })
			: [];
		return [
			// <AutoComplete.OptGroup key="campaign-id" label="All Tags">
			...tagOptions,
			// </AutoComplete.OptGroup>,
			<AutoComplete.Option
				style={{ bottom: '0px', height: '42px', position: 'fixed' }}
				key="footer"
				value="footer"
				disabled
			>
				<Row gutter={16}>
					<Col>
						<Button type="primary" onClick={handleDone}>
							Done
						</Button>
					</Col>
					<Col>
						<Button type="default" onClick={handleReset}>
							Reset
						</Button>
					</Col>
				</Row>
			</AutoComplete.Option>,
		];
	}, [tags, selectedTags]);

	return (
		<Row style={{ padding: '10px' }}>
			<Col>
				<AutoComplete
					{...rest}
					// TODO: [AntdUpgrade] handle dropdownMenuStyle (not available in v4) - add as CSS
					// dropdownMenuStyle={{
					// 	maxHeight: '250px',
					// 	marginBottom: '42px',
					// }}
					backfill
					defaultActiveFirstOption={false}
					filterOption={filterOption}
					onSelect={handleSelect}
					// TODO: [AntdUpgrade] handle optionLabelProp (not available in v4) - set Option value directly
					// optionLabelProp="label"
					placeholder="Search for a tag..."
					getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
				>
					{dataSource}
				</AutoComplete>
			</Col>
		</Row>
	);
};

export default TagFilter;
