import { isReactElement } from '@copilot/common/utils';
import { Card, Skeleton } from 'antd';
import React from 'react';

interface ILoadingPaneProps {
	LoadingSection: typeof LoadingSection;
}

const LoadingSection: React.FC<{}> = (props) => <>{props.children}</>;

/**
 * A simple wrapper to show a loading pane while loading
 *
 * @param props.isLoading is the current pane loading
 * @returns LoadedSection when loaded and LoadingSection when loading
 */
export const LoadingPane: React.FC<{ isLoading: boolean }> & ILoadingPaneProps = (props) => {
	const { isLoading, children } = props;
	let Loading = null;
	const Loaded = React.Children.map(children, (child) => {
		if (isReactElement(child)) {
			if (child.type == LoadingSection) {
				Loading = child;
				return undefined;
			}
		}
		return child;
	});
	return <>{!isLoading ? Loaded : Loading ?? <Card><Skeleton active /></Card>}</>;
};

LoadingPane.LoadingSection = LoadingSection;
