import React, { useMemo, useState, useEffect } from 'react';
import BaseDrawer from '../base';
import BasicContainer from '@copilot/common/components/containers/basic';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import BubbleCheckbox from '@copilot/common/components/forms/common/generics/checkboxes/bubble';
import { useSearchCriteria } from '@copilot/common/hooks/searchCriteria';

interface SearchCriteriaDrawerProps extends React.ComponentProps<typeof BaseDrawer> {
	campaignId: string;
	campaignMemberId: string;
	campaignName: string;
}

const SearchCriteriaDrawer: React.FC<SearchCriteriaDrawerProps> = (props) => {
	const {
		campaignId = '', campaignMemberId = '', campaignName = '', ...baseDrawerProps
	} = props;
	const [experience, setExperience] = useState<string[]>([]);
	const [titles, setTitles] = useState<string[]>([]);
	const [radius, setRadius] = useState<string>('');
	const [headCount, setHeadCount] = useState<string[]>([]);
	const [locations, setLocations] = useState<string[]>([]);
	const [zip, setZip] = useState<string[]>([]);
	const [recentJobChange, setRecentJobChange] = useState<string>('');
	const [exclusions, setExclusions] = useState<string[]>([]);
	const generateCheckboxes = (arr: string[]) => {
		if (!Array.isArray(arr)) return [];
		return arr.map((a) => <BubbleCheckbox key={a} checked value={a} />);
	};
	const criterias = useSearchCriteria(campaignId, campaignMemberId);

	useEffect(() => {
		if (criterias && criterias.length > 0) {
			const criteria = criterias[0];
			setExperience(criteria.experience);
			setTitles(criteria.titles);
			setRecentJobChange(criteria.recentJobChange ? 'Yes' : 'No');
			setRadius(criteria.radius);
			setHeadCount(criteria.headCount);
			setLocations(criteria.locations);
			setZip(criteria.zip);
			setExclusions(criteria.exclusions);
		}
	}, [criterias]);

	const locationBubbles = useMemo(() => generateCheckboxes(locations), [locations]);
	const zipBubbles = useMemo(() => generateCheckboxes(zip), [zip]);
	const radiusBubbles = useMemo(() => generateCheckboxes([radius]), [radius]);
	const occupationBubbles = useMemo(() => generateCheckboxes(titles), [titles]);
	const experienceBubbles = useMemo(() => generateCheckboxes(experience), [experience]);
	const exclusionsBubbles = useMemo(() => generateCheckboxes(exclusions), [exclusions]);
	const headCountBubbles = useMemo(() => generateCheckboxes(headCount), [headCount]);
	const recentJobChangeBubbles = useMemo(
		() => generateCheckboxes([recentJobChange]), [recentJobChange]);

	return (
		<BaseDrawer closable {...baseDrawerProps}>
			<BasicContainer bordered={false}>
				<BasicContainer.Header>
					<h2>
						Search Criteria for
						{' '}
						{campaignName}
					</h2>
				</BasicContainer.Header>
				<BasicContainer.Content>
					{locationBubbles && locationBubbles.length > 0 && (
						<BlockRow>
							<Col>
								<h2>States and cities you are Targeting </h2>
							</Col>
							<Col>{locationBubbles}</Col>
						</BlockRow>
					)}
					{zipBubbles && zipBubbles.length > 0 && (
						<>
							<BlockRow>
								<Col>
									<h2>Zip Codes you are Targeting </h2>
								</Col>
								<Col>{zipBubbles}</Col>
							</BlockRow>
							{radiusBubbles && radiusBubbles.length > 0 && (
								<BlockRow>
									<Col>
										<h2>Radius</h2>
									</Col>
									<Col>{radiusBubbles}</Col>
								</BlockRow>
							)}
						</>
					)}
					<BlockRow>
						<Col>
							<h2>Who You're Targeting </h2>
						</Col>
						<Col>{occupationBubbles}</Col>
					</BlockRow>
					<BlockRow>
						<Col>
							<h2>Years of Total Work Experience </h2>
						</Col>
						<Col>{experienceBubbles}</Col>
					</BlockRow>
					<BlockRow>
						<Col>
							<h2>Recent Job Change </h2>
						</Col>
						<Col>{recentJobChangeBubbles}</Col>
					</BlockRow>
					<BlockRow>
						<Col>
							<h2>Company Headcount </h2>
						</Col>
						<Col>{headCountBubbles}</Col>
					</BlockRow>
					<BlockRow>
						<Col>
							<h2>Exclusions </h2>
						</Col>
						<Col>{exclusionsBubbles}</Col>
					</BlockRow>
				</BasicContainer.Content>
			</BasicContainer>
		</BaseDrawer>
	);
};

const BlockRow = styled(Row)`
	display: block;
`;

export default SearchCriteriaDrawer;
