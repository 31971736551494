import React from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import styled, { DefaultTheme } from 'styled-components';

interface BasicContainerHeaderProps {
	children?: React.ReactNode;
}

class BasicContainerHeader extends React.PureComponent<BasicContainerHeaderProps> {
	render() {
		return <>{this.props.children}</>;
	}
}

// TODO Revamp to not have to create to check for element match.
//      DisplayName and Name both doesnt work after uglifying
const BasicContainerHeaderType = React.createElement(BasicContainerHeader).type;
interface BasicContainerContentProps extends React.HTMLAttributes<HTMLElement> {
	children?: React.ReactNode;
}

class BasicContainerContent extends React.PureComponent<BasicContainerContentProps> {
	render() {
		const { className, ...rest } = this.props;
		return (
			//<ErrorBoundary>
			<div {...rest} className={`content ${className}`}>
				{this.props.children}
			</div>
			//</ErrorBoundary>
		);
	}
}
const BasicContainerContentType = React.createElement(BasicContainerContent).type;

interface BasicContainerFooterProps {
	children?: React.ReactNode;
}

class BasicContainerFooter extends React.PureComponent<BasicContainerFooterProps> {
	render() {
		return <>{this.props.children}</>;
	}
}
const BasicContainerFooterType = React.createElement(BasicContainerFooter).type;

export interface BasicContainerProps extends CardProps {}

class BasicContainer extends React.Component<BasicContainerProps> {
	public static Header: typeof BasicContainerHeader = BasicContainerHeader;
	public static Content: typeof BasicContainerContent = BasicContainerContent;
	public static Footer: typeof BasicContainerFooter = BasicContainerFooter;

	constructor(props: BasicContainerProps) {
		super(props);
	}

	getHeaderAndContent(children: React.ReactNode) {
		let header = null;
		let content = null;
		let footer = null;
		React.Children.forEach(children, (child: React.ReactNode) => {
			if (!child || typeof child !== 'object' || !('type' in child)) content = child;
			else {
				const type =
					child.type && (child.type as any).styledComponentId
						? (child.type as any).target
						: child.type;
				switch (type) {
					case BasicContainerHeaderType:
						header = child;
						break;
					case BasicContainerContentType:
						content = child;
						break;
					case BasicContainerFooterType:
						footer = child;
						break;
					default:
						content = child;
						break;
				}
			}
		});
		return { header, content, footer };
	}

	render() {
		const { header, content, footer } = this.getHeaderAndContent(this.props.children);
		const { title, ...rest } = this.props;
		return (
			<BasicCard
				{...rest} 
				title={header ?? title}
			>
				{content}
				{footer && <div className="footer">{footer}</div>}
			</BasicCard>
		);
	}
}

const BasicCard = styled(Card)`
	${(props: { theme: DefaultTheme & Record<string, string> }) => {
		const prefix = props.theme['@ant-prefix'];
		const cardPaddingBase = props.theme['@card-padding-base'];
		return `
			.${prefix}-card {
				box-shadow: none;
			}
			
			& > .${prefix}-tabs-bar {
				background-color: white;
			}

			& > .${prefix}-card-head {
				border-bottom: 0;
				
				.${prefix}-card-head-wrapper {
					padding: ${props.theme['@card-head-padding']} 0px;
				}
			}
			
			
		
			& > .${prefix}-card-body {
				padding: 0px;
				
				& > .content {
					padding: ${cardPaddingBase} 30px;

					& > .${prefix}-table-wrapper {
						th, td {
							&:first-child, &:last-child {
								padding-left: ${cardPaddingBase};
								padding-right: ${cardPaddingBase};
							}
						}

						ul.${prefix}-table-pagination {
							margin-right: 10px;
						}
					}
				}

				& > .footer {
					border-top: ${props.theme['@border-width-base']} \
					${props.theme['@border-style-base']} ${props.theme['@border-color-split']};
					padding: ${props.theme['@card-head-padding']} ${cardPaddingBase}
				}
			}
		`;
	}}
`;

export default BasicContainer;
