import { NodeTimerPeriodEnum } from '@copilot/common/store/models/const/enum';
import { DEFAULT_DAYS } from '@copilot/common/components/editors/connection/timing';
import { MultipleInvite } from '@copilot/data/responses/interface';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';
import { ModelState } from '@copilot/common/store/models/fetch';
import { DropdownOption } from '@copilot/common/pages/campaignDashboard/teamMemberDropDown/types';
import { SearchStatus } from '@copilot/common/components/editors/searchUrl/update/consts';

/**
 * Base props for the search list component
 * @param {string} campaignId id of the campaign
 * @param {boolean} isTeamUser whether the user is a team user
 * @param {string} initialSelectedMemberId id of the selected member
 */
export interface ICampaignDashboardSearchListBaseProps {
	campaignId: string;
	isTeamUser: boolean;
	initialSelectedMemberId?: string;
	isMissingInMailSequence: boolean;
	onSearchListStatusChange?: (newState: SearchStatus) => void;
	onSetupInMailSequence?: () => void;
}

/**
 * Props dealing with the selected campaign member
 * @param {ICampaignMember} campaignMember campaign member to show details for
 * @param {function} onCampaignMemberSelect called when a campaign member option is selected
 */
export interface ICampaignMemberSelectProps {
	campaignMember?: CampaignMemberModel;
	onCampaignMemberSelect: (id: string | undefined) => void;
}

/**
 * Props for displaying campaign member options
 * @param {string[]} campaignMemberOptions options for the campaign member dropdown
 */
export interface ICampaignMemberOptionsProps {
	campaignMemberOptions?: DropdownOption[];
}

/**
 * Props for the Campaign Dashboard Search List component
 */
export type ICampaignDashboardSearchListProps = ICampaignDashboardSearchListBaseProps &
	ICampaignMemberSelectProps &
	ICampaignMemberOptionsProps;

/**
 * Props that are used by the HOCs wrapping wround Search List to help obtain values they need
 * @param {ModelState<CampaignMemberModel>} campaignMembers the team members in the campaign
 * @param {boolean} isCampaignMemberOptionsVisible whether the campaign member dropdown is visible
 */
export interface ICampaignMemberOptionsHelperProps {
	campaignMembers: ModelState<CampaignMemberModel>;
	isCampaignMemberOptionsVisible: boolean;
}

/**
 * Constants for the Instruction Steps for when a campaign member isn't selected for a search list
 */
export const MANAGE_SEARCH_URL_STEPS_TITLE =
	'Manage the search URLs for team members in this campaign';
export const ManageSearchUrlInstructionSteps = [
	{ title: '', description: "Choose which team member's search URL you want to view or update." },
	{
		title: '',
		description:
			'View the search URL. See when it was last updated and how many prospects remain.',
	},
	{
		title: '',
		description: 'Enter the search URL. Add more propsects to the list, or start fresh!',
	},
];

/**
 * Initial Multiple Invite state
 */
export const INITIAL_MULTIPLE_INVITE: MultipleInvite = {
	multipleInvite: false,
	time: DEFAULT_DAYS,
	period: NodeTimerPeriodEnum.Days,
};

/**
 * Maximum number of open inmail allowed per campaign
 */
export const MAX_DAILY_OPEN_INMAIL_PER_CAMPAIGN = 20;

export const CampaignType = {
	prospecting: 'PROSPECTING',
} as const;

/**
 * Keys for prospecting campaign search list tabs
 */
export const PROSPECT_TAB_KEY = 'prospect';
export const OPEN_PROFILE_TAB_KEY = 'openProfile';
export type SearchListTableTabKeys = typeof PROSPECT_TAB_KEY | typeof OPEN_PROFILE_TAB_KEY;