import {
	ColumnDefinition,
	LinkActionColumnDefinition,
	ColumnTypes,
	ColumnActionTypes,
	ColumnActionFormat,
	ColumnIconType,
} from '@copilot/common/components/componentModels/columnDefinition';
import { CustomFieldActions } from '@copilot/common/components/componentModels/columnTypeDefinition';

export enum ProspectTableColumns {
	ContactUrl = 'contactUrl',
	Name = 'name',
	TeamMember = 'orgMemberName',
	CurrentStage = 'connectionStatus',
	Position = 'position',
	Company = 'company',
	Location = 'location',
	DateAdded = 'dateCreated',
	Actions = 'actions'
}

export const COLUMN_MAP = {
	[ProspectTableColumns.ContactUrl]: new ColumnDefinition({
		key: ProspectTableColumns.ContactUrl,
		properties: [ProspectTableColumns.ContactUrl],
		label: '',
		type: ColumnTypes.Link,
		isVisible: true,
		width: 1,
		action: {
			type: ColumnActionTypes.Custom,
			metaData: {
				type: ColumnActionFormat.IconLink,
				values: {
					type: ColumnIconType.LinkedinFilled,
				},
			},
		},
	}),
	[ProspectTableColumns.Name]: new ColumnDefinition({
		key: ProspectTableColumns.Name,
		properties: [ProspectTableColumns.Name],
		label: 'Name',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	[ProspectTableColumns.TeamMember]: new ColumnDefinition({
		key: ProspectTableColumns.TeamMember,
		properties: [ProspectTableColumns.TeamMember],
		label: 'Team Member',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	[ProspectTableColumns.CurrentStage]: new ColumnDefinition({
		key: ProspectTableColumns.CurrentStage,
		properties: [ProspectTableColumns.CurrentStage],
		label: 'Current stage',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	[ProspectTableColumns.Position]: new ColumnDefinition({
		key: ProspectTableColumns.Position,
		properties: [ProspectTableColumns.Position],
		label: 'Position',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	[ProspectTableColumns.Company]: new ColumnDefinition({
		key: ProspectTableColumns.Company,
		properties: [ProspectTableColumns.Company],
		label: 'Company',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	[ProspectTableColumns.Location]: new ColumnDefinition({
		key: ProspectTableColumns.Location,
		properties: [ProspectTableColumns.Location],
		label: 'Location',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	[ProspectTableColumns.DateAdded]: new ColumnDefinition({
		key: ProspectTableColumns.DateAdded,
		properties: [ProspectTableColumns.DateAdded],
		label: 'Date added',
		type: ColumnTypes.Date,
		isVisible: true,
	}),
	[ProspectTableColumns.Actions]: new LinkActionColumnDefinition({
		key: ProspectTableColumns.Actions,
		properties: ['id', 'campaignMemberId', 'isRemoveHidden'],
		label: 'Actions',
		type: ColumnTypes.LinkAction,
		isVisible: true,
		action: {
			type: ColumnActionTypes.Custom,
			metaData: {
				type: CustomFieldActions.Remove,
				fieldValues: { id: 'id' },
			},
		},
	}),
};

export const ProspectColumns = Object.values(COLUMN_MAP);

// Order needs to match with properties of COLUMN_MAP[ProspectTableColumns.Actions]
export enum ProspectRecord {
	connectionId,
	campaignMemberId,
	isRemoveHidden
}
