import { getFormattedDiscountTag } from '@copilot/cs/src/components/billing/utils/discountFormat';
import { ICoupon } from '@copilot/data/responses/models/billing';
import {
	Col,
	Row,
	Space, Tag, Typography,
} from 'antd';

const { Title, Text } = Typography;

/**
 * Properties to display a coupon drawer item
 */
interface CouponDrawerItemProps {
	/**
	 * Coupon to display in coupon drawer item.
	 */
	coupon: ICoupon;
	/**
	 * True if "Current Coupon" tag should be shown.
	 */
	showCurrentCouponTag: boolean;
}

/**
 * [Presentational] Single coupon drawer item to be displayed in the coupon drawer.
 */
const CouponDrawerItem: React.FC<CouponDrawerItemProps> = ({
	coupon, showCurrentCouponTag,
}) => (
	<>
		<Row>
			<Col>
				<Space style={{ alignItems: 'baseline' }}>
					<Title style={{ fontSize: '16px' }} level={3}>{coupon.name}</Title>

					{
						showCurrentCouponTag &&
							<Tag color="#008800">Current Coupon</Tag>
					}
				</Space>
			</Col>
		</Row>
		<Row>
			<Col>
				<Space>
					<Text type="secondary">{`${coupon.formattedDiscount} Off`}</Text>
					<Tag>{getFormattedDiscountTag(coupon.duration, coupon.durationInMonths)}</Tag>
				</Space>
			</Col>
		</Row>
	</>
);

export default CouponDrawerItem;
