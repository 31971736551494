import { Collapse, Space, Typography } from 'antd';
import { ComponentProps } from 'react';
import { useTheme } from 'styled-components';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { pluralString } from '@copilot/common/utils/stringFormat';
import AttentionRequiredItemList from '@copilot/common/components/lists/attentionRequired';

type AttentionRequiredPropsType = {
	/**
	 * The list of items require attention
	 */
	items: ComponentProps<typeof AttentionRequiredItemList>['items'];
};

const HeaderIconBackgroundSize = '24px';


const AttentionRequiredHeader = ({ count }: { count: number }) => {
	const theme = useTheme();
	const copy = `Resolve ${count} ${pluralString('issue', count)} to help your campaign run smoothly`;
	return (
		<Space>
			<div style={{
				background: theme['@Error/20'],
				width: HeaderIconBackgroundSize,
				lineHeight: HeaderIconBackgroundSize,
				textAlign: 'center',
				borderRadius: '4px',
			}}>
				<ExclamationCircleTwoTone twoToneColor={theme['@Error/70']} />
			</div>
			<Typography.Text>{copy}</Typography.Text>
		</Space>
	);
};

/**
 * [Persentational] Show a collapsable list of attention required items
 * @param props 
 * @returns 
 */
const AttentionRequired = (props: AttentionRequiredPropsType) => {
	const { items } = props;
	const theme = useTheme();
	return (
		<Collapse ghost style={{ userSelect: 'none' }}>
			<Collapse.Panel header={<AttentionRequiredHeader count={items.length} />} key="items">
				<div style={{ paddingLeft: theme['@spacer-num-md'], paddingRight: theme['@spacer-num-md'] }}>
					<AttentionRequiredItemList items={items} />
				</div>
			</Collapse.Panel>
		</Collapse>
	);
};

export default AttentionRequired;
