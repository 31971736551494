import { Config } from '../config';

const oldComplianceMessage =
	'For regulated industries, please ensure compliance approval is obtained in advance of updating your messaging.';
const newComplianceMessage =
	'Work in a regulated industry (like financial services)? Submit message changes to your compliance team for review and approval.';
export const complianceMessage =
	Config.agencyName === 'Cleverly' ? oldComplianceMessage : newComplianceMessage;
export const linkedInDisconnectedMsg =
	'You’re currently unable to send and receive messages because this LinkedIn account is disconnected from our app. Please reconnect to continue messaging.';
export const linkedInRestrictedMsg = 'This prospect has restricted incoming communication.';
/**
 * Message to display when sending messages through message editor is disabled
 */
export const manualMessageDisabledMsg = 'This feature is currently under maintenance. Please try again later.';