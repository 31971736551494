import BaseDataManager from '@copilot/data/managers/base';

class AIFeatureManager extends BaseDataManager {
	constructor() {
		super();
		this.acceptTermsOfUse = this.acceptTermsOfUse.bind(this);
		this.hasAcceptTermsOfUse = this.hasAcceptTermsOfUse.bind(this);
	}

	/**
	 * Accept ai feature's terms of use
	 */
	public async acceptTermsOfUse() {
		const url = this.combineRoute(this.BACKEND_ROUTES.AI_FEATURE.Default, 'acceptTerms');
		await this.RequestManager.post(url);
	}

	/**
	 * Get if the current user has accepted ai feature's terms of use
	 */
	public async hasAcceptTermsOfUse() {
		const url = this.combineRoute(this.BACKEND_ROUTES.AI_FEATURE.Default, 'acceptTerms');
		const response = await this.RequestManager.get<boolean>(url);
		return response.data;
	}
}

const instance = new AIFeatureManager();
export default instance;