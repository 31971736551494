import { BaseModel } from './base';
import {
	attr, many, fk, QuerySet,
} from 'redux-orm';
import { Campaign } from './campaign';

export interface IMessageTemplateFields {
	id: string;
	organizationId: string;
	orgMemberId: string;
	campaignIds: string[];
	name: string;
	message: string;
}

interface InstanceFields {
	campaigns: QuerySet<Campaign>;
}

export type IMessageTemplate = IMessageTemplateFields & InstanceFields;

export class MessageTemplate extends BaseModel<typeof MessageTemplate, IMessageTemplate> {
	static modelName = 'MessageTemplate';
	static fields = {
		id: attr(),
		organizationId: fk({ to: 'Organization', as: 'organization' }),
		orgMemberId: fk({ to: 'OrganizationMember', as: 'OrganizationMember' }),
		campaigns: many('Campaign'),
		name: attr(),
		message: attr(),
	};
}
