import { OnboardMessage } from '@copilot/data/requests/models';
import {
	Col, Divider, Row, Typography,
} from 'antd';
import { FC, ReactNode } from 'react';
import NewUserMessageEditor from './messageEditor';

const { Title } = Typography;

interface INewUserMessageBasicEditPageProps {
	title: string;
	description: ReactNode;
	message: OnboardMessage;
	onMessageUpdate: (message: OnboardMessage) => void;
	timeDescription?: string;
}

const NewUserMessageBasicEditPage: FC<INewUserMessageBasicEditPageProps> = (props) => {
	const {
		title,
		description,
		message,
		onMessageUpdate,
		timeDescription,
	} = props;

	return (
		<>
			<Row>
				<Col>
					<Title level={5}>{title}</Title>
					{description}
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={24}>
					<NewUserMessageEditor
						message={message}
						placeholder="Write a message to try and engage your prospects to respond to you"
						onMessageUpdate={onMessageUpdate}
						timeDescription={timeDescription}
					/>
				</Col>
			</Row>
		</>
	);
};

export default NewUserMessageBasicEditPage;
