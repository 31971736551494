import React, { useMemo, useCallback } from 'react';
import ModelTableModule, { IModelTableModuleProps } from '../..';
import {
	CampaignMemberColumnMap,
	CampaignMemberColumns,
	CampaignMemberProspectingColumnsArray,
	CampaignMemberNurtureColumnsArray,
} from './columns';
import DrawerManager from '@copilot/common/utils/drawerManager';
import { StatsManager } from '@copilot/data';
import { CampaignType, Stats } from '@copilot/data/responses/interface';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';
import { CampaignFields } from '@copilot/common/store/models/redux';
import { getStartOfDay } from '@copilot/common/pages/campaignDashboard/summaryV2/dataFilterUtils';

interface CampaignMemberTableProps
	extends Omit<IModelTableModuleProps<CampaignMemberModel>, 'columns'> {
	campaign: CampaignFields;
	campaignType: CampaignType;
	columns?: CampaignMemberColumns[];
	allowRowClick?: boolean;
}

type CampaignMemberStats = {
	invites: number;
	messages: number;
	connections: number;
	movedConnections: number;
	replies: number;
	connectionRate: number;
	replyRate: number;
	nurtureReplyRate: number;
};

type CampaignMemberModelWithStats = CampaignMemberModel & CampaignMemberStats;

const CampaignMemberTable: React.FC<CampaignMemberTableProps> = (props) => {
	const {
		columns, allowRowClick = true, campaign, campaignType, ...modelTableModuleProps
	} = props;
	const [startDate, setStartDate] = React.useState<Date | undefined>(() => new Date(getStartOfDay(7)));
	const [endDate, setEndDate] = React.useState<Date | undefined>(new Date());
	const [memberStats, setMemberStats] = React.useState<{ [memberId: string]: Stats[] }>({});
	const [memberInfos, setMemberInfos] = React.useState<CampaignMemberModelWithStats[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	const handleRowClick = useCallback(
		(record: CampaignMemberModel) => {
			if (allowRowClick && record) {
				DrawerManager.openCampaignMemberDrawer({
					campaign,
					campaignMember: record,
				});
			}
		},
		[allowRowClick]
	);

	const getRowClassName = useCallback(() => (allowRowClick ? 'clickable' : ''), [allowRowClick]);

	const onRow = useCallback(
		(record: CampaignMemberModel) => ({
			onClick: () => handleRowClick(record),
		}),
		[handleRowClick]
	);

	const getColumnsByCampaignType = useMemo(() => {
		switch (campaignType) {
			case CampaignType.Prospecting:
				return CampaignMemberProspectingColumnsArray;
			case CampaignType.Nurture:
				return CampaignMemberNurtureColumnsArray;
			default:
				return UtilityFunctions.assertUnreachable(campaignType);
		}
	}, [campaignType]);

	const generatedColumns = useMemo(() => {
		const displayedColumns = columns
			? columns.map((c) => CampaignMemberColumnMap[c])
			: getColumnsByCampaignType;
		return displayedColumns;
	}, [columns]);

	React.useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const stats = await StatsManager.getStatsForCampaignMembers(
					campaign.id,
					startDate,
					endDate
				);
				setMemberStats(stats);
			} finally {
				setLoading(false);
			}
		})();
	}, [startDate, endDate, campaign.id]);

	const calculateMemberStats = React.useCallback(
		(campaignMemberId: string): CampaignMemberStats => {
			const stats = memberStats[campaignMemberId];
			let invites = 0;
			let messages = 0;
			let connections = 0;
			let replies = 0;
			let movedConnections = 0;
			for (let i = 0; i < stats?.length; i++) {
				invites += stats[i].actionStats.LinkedInInvite ?? 0;
				messages += stats[i].actionStats.SendMessage ?? 0;
				connections += stats[i].actionStats.LinkedInConnected ?? 0;
				replies += (campaignType === CampaignType.Prospecting) ? stats[i].actionStats.UniqueReplies ?? 0 : 0;
				replies += (campaignType === CampaignType.Nurture) ? stats[i].actionStats.NurtureUniqueReplies ?? 0 : 0;
				movedConnections += stats[i].actionStats.MovedConnection ?? 0;
			}
			return {
				invites,
				messages,
				connections,
				movedConnections,
				replies,
				connectionRate: invites ? connections / invites : 0,
				replyRate: connections ? replies / connections : 0,
				nurtureReplyRate: movedConnections ? replies / movedConnections : 0,
			};
		},
		[memberStats]
	);
	React.useEffect(() => {
		setMemberInfos(
			props.data.map((member: CampaignMemberModel): CampaignMemberModelWithStats => {
				const stats = calculateMemberStats(member.id);
				return {
					...member,
					...stats,
				};
			})
		);
	}, [calculateMemberStats, props.data]);

	return (
		<>
			<ModelTableModule<CampaignMemberModel>
				{...modelTableModuleProps}
				data={memberInfos}
				columns={generatedColumns}
				rowKey="id"
				onRow={allowRowClick ? onRow : undefined}
				rowClassName={getRowClassName}
				style={{ border: '0' }}
				onTimeRangeChanges={(start?: Date, end?: Date) => {
					setStartDate(start);
					setEndDate(end);
				}}
				loading={loading}
				hideDateFilterTabOverflow
			/>
		</>
	);
};

export default CampaignMemberTable;
