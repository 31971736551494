import { MessageTypeEnum } from '../../onboard/wizard/messaging/const';
import { Typography } from 'antd';
import { Config } from '@copilot/common/config';
import { ReactNode } from 'react';
import { CarouselContents } from '@copilot/common/components/modals/wrappers/carousel/carouselWithButton';
import GetStarted from '@copilot/common/assets/images/onboarding/welcomeToCopilot.png';
import ConnectLinkedIn from '@copilot/common/assets/images/onboarding/linkedinConnect.png';
import BuildFirstCampaign from '@copilot/common/assets/images/onboarding/campaign.png';
import ReviewDraft from '@copilot/common/assets/images/onboarding/weReviewIt.png';

const { Link, Text } = Typography;

export enum NewUserOnboardSteps {
	START = 0,
	SEARCH = 1,
	MESSAGING = 2,
	TIMEZONE = 3,
	COMPLETE = 4
}

export enum NewUserOnboardStatus {
	InProgress,
	Submitted,
	Approved
}

export const NewUserOnboardStepTitles = {
	[NewUserOnboardSteps.START]: 'Target Audience',
	[NewUserOnboardSteps.SEARCH]: 'Search Criteria',
	[NewUserOnboardSteps.MESSAGING]: 'Messaging',
	[NewUserOnboardSteps.TIMEZONE]: 'Sending Hours',
	[NewUserOnboardSteps.COMPLETE]: 'Complete',
};

//#region Welcome Screen
export const WelcomeCarouselContents: CarouselContents[] = [
	{
		topText: 'Get started in minutes',
		titleText: 'Welcome to Copilot AI!',
		bodyText: 'Grow your pipeline and amplify your outreach alongside 1,000+ companies worldwide.',
		image: GetStarted,
		isSkipVisible: false,
	},
	{
		topText: 'Step 1',
		titleText: 'Connect a LinkedIn account',
		bodyText: 'Make personalized connections to build a valuable, targeted professional network.',
		image: ConnectLinkedIn,
		isSkipVisible: true,
	},
	{
		topText: 'Step 2',
		titleText: 'Build your first campaign',
		bodyText: 'Create your first collection of messages to engage with potential leads.',
		image: BuildFirstCampaign,
		isSkipVisible: true,
	},
	{
		topText: 'Step 3',
		titleText: 'We\'ll review it with you on a call',
		bodyText: 'Your Account Strategist will review your draft and ensure your campaign is set up for success!',
		image: ReviewDraft,
		isSkipVisible: false,
	},
];

//#endregion Welcome Screen

//#region Messaging Constants

export const MAX_MESSAGES_NUMBER = 10;
export const DEFAULT_MESSAGE_TITLES = ['Connection Request Message'];
export const DEFAULT_SECOND_MESSAGE_TIMING = 60;
export const TIME_DESCRIPTION = {
	SecondMessage: 'We recommend you wait at least 2-3 hours',
	ThirdMessage: 'We recommend you wait at least 3-5 days',
	FollowUps: 'We recommend you wait at least 2-4 weeks',
};
export const STRATEGY_OPTIONS = [MessageTypeEnum.Casual, MessageTypeEnum.Moderate, MessageTypeEnum.Direct];

export const DEFAULT_MESSAGE_DESCRIPTIONS = ['This message will be sent to all prospects in a connection request.',
	'If a prospect accepts your invitation to connect, you can begin to start a conversation with them with the 2nd message.',
	'Be proactive with prospects who don\'t respond or disengage.'];

export const MESSAGE_EDIT_DESCRIPTION: ReactNode[] = [
	<Text>It's the first message your prospects will receive. Introduce yourself as you would in a face-to-face networking event. Be personable and keep it short.</Text>,
	<Text>Follow-up with your new connections! Thank them for connecting, and then let them know what you would like to pursue in your outreach.</Text>,
	<Text>
		Write a follow-up message if a prospect doesn't respond to your second message. View
		{' '}
		<Link href={Config.followupMessagingURL} target="_blank">examples</Link>
		{' '}
		of follow-up messages.
	</Text>,

];
//#endregion Messaging Constants
