import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import {
	Button, Col, Divider, Row, Select, Space, theme as antdTheme, Typography,
} from 'antd';
import {
	FC, useCallback, useMemo, useState,
} from 'react';
import { getEntitlementDescription } from './const';
import { arrayToMap } from '@copilot/common/utils';

const { Text } = Typography;
const { useToken } = antdTheme;

interface IMemberEntitlementProps {
	/**
	 * entitlement of member
	 */
	entitlement: OrgRoleTitles;
	/**
	 * Callback on save
	 */
	onSave: (value: OrgRoleTitles) => void;
	/**
	 * Types of entitlement
	 */
	entitlementTypes: { name: string, id: string }[];
	/**
	 * whether it is editable
	 */
	 isEditable: boolean;
}

/**
 * [Presentational] Selector for orgMember entitlement info
 */
const OrgMemberEntitlement: FC<IMemberEntitlementProps> = (props) => {
	const {
		entitlement, onSave, entitlementTypes, isEditable,
	} = props;

	const [isEditing, setIsEditing] = useState<boolean>(false);

	const [selectedEntitlement, setSelectedEntitlement] = useState<OrgRoleTitles>(entitlement);

	const entitlementNames = useMemo(() => arrayToMap(entitlementTypes, (item) => item.id), [entitlementTypes]);
	const entitlementOptions = useMemo(() => entitlementTypes.map(
		(type) => (
			<Select.Option value={type.id} key={type.id}>
				{type.name}
			</Select.Option>
		)), [entitlementTypes]);

	const handleCancel = useCallback(() => {
		setSelectedEntitlement(entitlement);
		setIsEditing(false);
	}, [entitlement]);

	const handleSave = useCallback(() => {
		onSave(selectedEntitlement);
		setIsEditing(false);
	}, [selectedEntitlement]);
	
	const { token } = useToken();
	
	return (
		<Space direction={'vertical'} style={{ width: '100%' }}>
			<Row align={'middle'} gutter={token.size}>
				<Col>
					<Text style={{ fontSize: token.fontSizeHeading3 }} strong>
						Permissions
					</Text>
				</Col>
				<Col>
					{isEditable && !isEditing && <Button size="small" style={{ marginLeft: '4px' }} onClick={() => setIsEditing(true)}>Edit</Button>}
				</Col>
			</Row>
			<Divider style={{ margin: 0 }} />
			<Row align={'middle'} gutter={token.size}>
				<Col>
					<Text>Select Permission:</Text>
				</Col>
				<Col>
					{isEditing ? (
						<Select
							value={selectedEntitlement}
							onChange={setSelectedEntitlement}
							disabled={!isEditable}
							dropdownMatchSelectWidth={false}
						>
							{entitlementOptions}
						</Select>
					) : <Text>{entitlementNames.get(selectedEntitlement)?.name}</Text>}
				</Col>
			</Row>
			<Row>
				<Col>
					{getEntitlementDescription(selectedEntitlement)}
				</Col>
			</Row>
			{isEditing && (
				<Row>
					<Col span={22}>
						<Row justify="end" gutter={16} style={{ marginRight: 0, marginTop: '10px' }}>
							<Col>
								<Button size="small" onClick={handleCancel}>Cancel</Button>
							</Col>
							<Col>
								<Button size="small" type="primary" onClick={handleSave}>Save</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</Space>
	);
};

export default OrgMemberEntitlement;
