import Store from '@copilot/common/store';
import { CampaignManager } from '@copilot/data';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import notificationManager from '@copilot/common/utils/notificationManager';

export const CampaignSettingsLabels = {
	ColorLabel: 'Campaign Color',
	DeleteLabel: 'Delete Campaign',
	DeleteConfirm: 'Are you sure you want to delete this campaign?',
};

/**
 * Updates the given campaign's colour then shows a notification
 *
 * @param campaignId campaign we are updating the colour for
 * @param colorHex hex value of the colour to change to
 */
export const updateCampaignColor = (campaignId: string, colorHex: string) => {
	CampaignManager.updateCampaign(campaignId, { primaryColour: colorHex })
		.then(() => {
			Store.Dispatch(CampaignActions.updateCampaign({
				id: campaignId,
				primaryColour: colorHex,
			}));
			notificationManager.showSuccessNotification({
				message: 'Campaign Color Updated',
				description: 'Campaign Color has been updated.',
			});
		})
		.catch(() => {
			notificationManager.showErrorNotification({
				message: 'Failed',
				description: 'Please refresh the page and try again',
			});
		});
};

/**
 * Deletes the given campaign
 *
 * @param campaignId campaign we are updating the colour for
 * @param colorHex hex value of the colour to change to
 */
export const deleteCampaign = (campaignId: string, deleteCampaignCallback?: () => void) => {
	CampaignManager.deleteCampaign(campaignId)
		.then(() => {
			Store.Dispatch(CampaignActions.deleteCampaign({ id: campaignId }));
			notificationManager.showSuccessNotification({
				message: 'Campaign Deleted',
				description: 'Campaign has been deleted.',
			});
			if (deleteCampaignCallback) deleteCampaignCallback();
		})
		.catch(() => {
			notificationManager.showErrorNotification({
				message: 'Failed',
				description: 'Please refresh the page and try again',
			});
		});
};
