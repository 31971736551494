import Store from '..';
import {
	createSelector, Ref, SessionBoundModel, Selector,
} from 'redux-orm';
import { CampaignMember } from '@copilot/common/store/models/redux';

export namespace CampaignMemberSelectors {

	export const getByCampaign = (campaignId: string): Selector<any, readonly Ref<SessionBoundModel<CampaignMember>>[]> => createSelector(
		Store.ORM,
		(session) => session.Campaign.withId(campaignId)?.members.toRefArray() ?? []
	);

	export const getByCampaignAndOrgMember = (orgMemberId: string, campaignId : string): Selector<any, Ref<SessionBoundModel<CampaignMember>> | undefined> => createSelector(
		Store.ORM,
		(session) => session.Campaign.withId(campaignId)?.members.filter({ orgMemberId }).first()?.ref
	);
}
