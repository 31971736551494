import moment from 'moment';

/**
 * The default date format that should be used in the app when showing date with year.
 * Note: This format strips off the time portion of the date.
 */
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT_WITH_TIME_GRANULARITY = 'YYYY-MM-DD HH:mm:ss';

/**
 * Date format that should be used to show the date as a full string
 * Ex. April 20, 2021
 */
export const LONG_DATE_FORMAT = 'LL';

/**
 * Date format that should be used to show a short date (without the year)
 * Ex. April 20
 */
export const SHORT_DATE_FORMAT = 'MMM D';

/**
 * Date format used to show the full date and time
 * Ex. 05/23/2022 05:30:00
 */
export const FULL_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';

/**
 * Date format that should be used to show the full date with time
 * Ex. May 6, 2021 16:05
 */
export const LONG_DATE_TIME_FORMAT = `${LONG_DATE_FORMAT} HH[:]mm`;
/**
 * Returns a formatted date string for display to the user
 * @param input the date
 */
export const getFormattedDate = (
	input: string | number,
	format: string = DEFAULT_DATE_FORMAT
): string => moment(input).format(format);