import { NodeTimerPeriodEnum } from '@copilot/common/store/models/const/enum';
import { convertToOrdinalAbbr } from '@copilot/common/utils';

export enum MessageTypeEnum {
	Casual = 0,
	Moderate = 1,
	Direct = 2,
	Sales = 3,
	Recruitment = 4,
	Networking = 5,
	None = 6
}

export enum MessageOrderEnum {
	OpenMessage = 0,
	MessageIntent = 1,
	MessageFollowUpOne = 2,
	MessageFollowUpTwo = 3,
	MessageFollowUpThree = 4
}

export const MessageName = ['Casual', 'Moderate', 'Direct', 'Sales', 'Recruitment', 'Networking'];
export const MessageSentence = [
	'For those of you who want to keep things bright, conversational, and fairly simple. This tone is designed to be quick to read, friendly and polite - without too much information or desire to qualify people quickly upfront. This tone works better in a more relaxed setting or when you’re just trying to expand your network.',
	"If you'd prefer your messaging to be a bit more informative and ensure your prospect knows who you are and why you're connecting with them, you may choose a more moderate tone. This tone is more formal than our casual messages and suits someone who is trying to get their point across quickly, but still politely.",
	"Our direct message templates focus on collaboration and specifically outline how you want to help, or work with your prospect. They're still social in nature, but the information within the message makes it obvious that there's a reason you're reaching out.",
];
// Updated Message Sentence for testing. MessageSentence and MessageSentenceNewUser will be merged into one after the testing.
export const MessageSentenceNewUser = [
	'Conversational and quick to read — great for expanding your network quickly. Our most popular messaging tone.',
	'Thoughtful and collaborative — letting your prospects know who you are and why you want to connect.',
	'Focused and informative — specifically outlining your objectives. Can lead to higher quality leads, but may risk lower engagement.',
];

export const OpenMessagesExamples = {
	Casual: {
		'Casual 1':
			'I see we have mutual contacts, {firstname}. Hope to connect to share expertise with each other.',
		'Casual 2':
			'Hi {firstname}! As I sit here coming out of isolation with a bad case of cabin fever, I saw that you popped up as a "recommended connection," so I took the bait! I look forward to connecting!',
		'Casual 3':
			'You showed up in LinkedIn suggested a few times {firstname}, thought I’d connect.',
		'Casual 4':
			'It looks like we know some of the same people on LinkedIn. I thought it might be mutually beneficial to add each other to our professional networks, {firstname}.',
		'Casual 5':
			'Hey {firstname}, I’d like to follow and connect with more professionals like you. Please accept my invite!',
		'Casual 6':
			'I’m looking to get more active on LinkedIn again, would love to connect with professionals like yourself {firstname}.',
	},
	Moderate: {
		'Moderate 1':
			'Hi {firstname} - I’m looking to connect with more people in the <TARGET INDUSTRY> space and your name popped up.',
		'Moderate 2':
			'Hey {firstname}, I’d like to follow and connect with more professionals in <TARGET INDUSTRY> like you. Please accept my invite! ',
		'Moderate 3':
			'Hi {firstname}, I’d like to stay connected with <TARGET INDUSTRY/NICHE> and <TARGET INDUSTRY/NICHE> professionals like yourself. Would love to follow your content. Stay in touch.',
		'Moderate 4':
			'{firstname}, LinkedIn suggested we connect, and I took a look at your profile. We share some network and I think it would be good to have you as a connection.',
		'Moderate 5':
			'Hey {firstname}, LinkedIn recommended that we connect. I checked out your profile and think there could be a great opportunity to collaborate.',
		'Moderate 6':
			'{firstname}, your profile caught my eye, and it looks like we know some of the same people. I focus on <MY SPECIALTY>, and I believe we could both benefit from one another’s network.',
	},
	Direct: {
		'Direct 1':
			'I’ve seen you on my recommended list for some time now, {firstname}. It’s probably because I work with a lot of <TARGET NICHE>. Would love to add you to my network to explore working together.',
		'Direct 2':
			'I’m a <MY TITLE> at <COMPANY>. I’m always looking to expand my professional network, and when I came across your profile I was intrigued. Perhaps we can explore ways to collaborate, {firstname}. ',
		'Direct 3':
			'I made a commitment to add a few new meaningful connections every day, {firstname}. Came across your profile and thought it’d be great to connect so we can exchange some insights.',
		'Direct 4':
			'Hi {firstname}, I’m looking to connect with more <TARGET INDUSTRY/NICHE> professionals like yourself. Would love to talk shop and business strategy. Stay in touch.',
	},
};

export const MessageIntentExamples = {
	Casual: {
		'Casual 1':
			'Hey {firstname}, thanks for connecting. I’m regularly looking to collaborate with my network. Might be good to connect by phone or video conference some time. Do you have some time next week?',
		'Casual 2':
			'Thanks for adding me, {firstname}. I’m curious to hear more about you and your focus. Do you have time for a quick phone call next week?',
		'Casual 3':
			'Thank you for connecting, {firstname}. I’d like to get to know you better as a contact. Let’s chat next week? ',
		'Casual 4':
			'{firstname}, good to connect! Not sure where things are at with you right now but happy to set up a brief chat and learn more. Let me know when works?',
	},
	Moderate: {
		'Moderate 1':
			'{firstname}, great to be connected. I’m a <MY TITLE> at <COMPANY>. I’d welcome a quick call to get introduced and explore working together. Is there a good time to connect in the next few days?',
		'Moderate 2':
			'Glad to connect with you, {firstname}. Looking forward to learning more about what you do, and any new strategies you’ve been trying out. Are you working on anything interesting at the moment?',
		'Moderate 3':
			'Happy we’re connected {firstname}. To keep the conversation going here, I work with many successful <TARGET NICHE> like you. I’d love the opportunity to talk at some point soon to formalize this introduction, get to know your story, and explore whether there’s anything I can do to help you from <MY SPECIALTY> standpoint. Could we set up a brief phone call?',
		'Moderate 4':
			'Thanks for connecting, {firstname}. I always like to send a personalized Loom introduction to my new LinkedIn connections. Is this something you’d be open to receiving?',
	},
	Direct: {
		'Direct 1':
			'Happy we’re connected {firstname}. To keep the conversation going here, I work with many successful <TARGET NICHE> like you. I’d love to get to know your story, and explore whether there’s anything I can do to help you from <MY SPECIALTY> standpoint. Would next week work for a brief phone call?',
		'Direct 2':
			'Impressive profile {firstname}. I’ll jump right in and say I always enjoy working with professionals like you, and I think I could provide you some value when it comes to <MY SPECIALTY>. Thoughts on a quick introductory phone call this week or next to learn more about each other?',
		'Direct 3':
			'Thanks for connecting {firstname}. I partner with a lot of <NICHE> like yourself. I work with <TARGET INDUSTRY> to do <MY SPECIALTY>. Would love to discuss ways we can collaborate. Are you open to a call some time this week?',
	},
};

export const AllowedFollowUpMessages = [
	MessageOrderEnum.MessageFollowUpOne,
	MessageOrderEnum.MessageFollowUpTwo,
	MessageOrderEnum.MessageFollowUpThree,
];

export enum OnboardTemplateStep {
	Open = 'Open',
	FollowUps = 'FollowUps'
}

export const FollowUps = [
	{
		label: convertToOrdinalAbbr(3),
		time: 1,
		period: NodeTimerPeriodEnum.Days,
	},
	{
		label: convertToOrdinalAbbr(4),
		time: 1,
		period: NodeTimerPeriodEnum.Weeks,
	},
	{
		label: convertToOrdinalAbbr(5),
		time: 1,
		period: NodeTimerPeriodEnum.Weeks,
	},
];

