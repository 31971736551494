import { LinkedInSearchType, NewUserOnboardResponse } from '@copilot/data/responses/interface';

import { emptyMessages, NewUserOnboardModel } from '@copilot/data/responses/models/newUserOnboard';
import { MessageTypeEnum } from '@copilot/common/pages/onboard/wizard/messaging/const';

import BaseDataManager from './base';
import { ScheduleHierarchyType, SYSTEM_DEFAULT_SCHEDULE } from '@copilot/common/pages/settings/schedule/constant';
import { NewUserOnboardStatus, NewUserOnboardSteps } from '@copilot/common/pages/campaignDashboard/newUserOnboard/const';
import { ScheduleUtility } from '@copilot/common/utils/schedule';

const DEFAULT_EXAMPLE_KEY = 'None';

/** Extension of BaseDataManager abstracting logic for sending onboard related GET/POST requests
 *  @extends BaseDataManager
 */
class NewUserOnboardManager extends BaseDataManager {
	private convertToModel = (r: NewUserOnboardResponse): NewUserOnboardModel => ({
		id: r.campaignId,
		searchCriteria: r.searchCriteria,
		searchType: r.searchType ?? LinkedInSearchType.Unknown,
		searchUrl: r.searchUrl,
		step: r.step ?? NewUserOnboardSteps.START,
		status: r.status ?? NewUserOnboardStatus.InProgress,
		messages: r.messages ?? emptyMessages,
		messagingStrategy: r.messagingStrategy ?? MessageTypeEnum.None,
		messagingStrategyVersion: r.version,
		messagingStrategyGroupId: r.groupId,
		serviceSchedule: {
			id: r.serviceSchedule?.id ?? r.campaignId,
			ownerId: r.serviceSchedule?.ownerId ?? r.campaignId,
			hierarchyType: r.serviceSchedule?.hierarchyType ?? ScheduleHierarchyType.Campaign,
			timezoneCode: r.serviceSchedule?.timezoneCode ?? ScheduleUtility.getLocaleTimezone(),
			weeklySchedule: r.serviceSchedule?.weeklySchedule ?? SYSTEM_DEFAULT_SCHEDULE.weeklySchedule,
			synchronization: r.serviceSchedule?.synchronization ?? SYSTEM_DEFAULT_SCHEDULE.synchronization,
		},
		firstMessageExampleKey: r.firstMessageExampleKey ?? DEFAULT_EXAMPLE_KEY,
		secondMessageExampleKey: r.secondMessageExampleKey ?? DEFAULT_EXAMPLE_KEY,
		isFirstLogin: r.isFirstLogin ?? true,
	});

	/**
     * Get the new user onboard object
     * @param {string} campaignId id of the campaign used for the new user onboard object
     */
	getNewUserOnboard = async (campaignId: string): Promise<NewUserOnboardModel> => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.LINKED_IN.Onboard,
			`campaign/${campaignId}`
		);
		const r = await this.RequestManager.get<NewUserOnboardResponse>(url);
		return this.convertToModel(r.data);
	};

	/**
     * Updates the campaign for the given id
     * @param {string} camapignId id of the campaign we are updating
     * @param {NewUserOnboardModel} onboard data we want to update the campaign object with
	 * @param {boolean} isSubmit whether we want to submit the onboarding as part of the update
	 * @param {string | null} orgMemberId org member id (needed for impersonation - only for cs admin)
     */
	updateNewUserOnboard = async (
		campaignId: string,
		onboard: NewUserOnboardModel,
		isSubmit = false,
		orgMemberId: string | null = null
	): Promise<NewUserOnboardModel> => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.LINKED_IN.Onboard,
			`campaign/${campaignId}`
		);
		const r = await this.RequestManager.post<NewUserOnboardResponse>(url, onboard, { params: { isSubmit, orgMemberId } });
		return this.convertToModel(r.data);
	};

	// this gets the selfServe status for the front-end
	getSelfServe = async (): Promise<boolean> => {
		const url = this.BACKEND_ROUTES.SELF_SERVE.Default;
		const r = await this.RequestManager.get<boolean>(url);
		return r.data;
	};
}

const instance = new NewUserOnboardManager();
export default instance;
