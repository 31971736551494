import { useCallback, useEffect } from 'react';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import * as _ from 'lodash';

/**
 * Tracking Parameter type to send data to Google Tag Manager and Azure App Insights
 */
type TrackingParameter = {
 	event: string; // has to be 'event' for Google Tag Manager to pick up
 	[name: string]: string | undefined;
};

/**
 * Clears (sets to undefined) the keys of each TrackingParamter in eventParams
 * @param history List of TrackingParamters whose keys to clear
 * @returns a TrackingParamter with all keys set to undefined
 */
const clearCurrentProperties = (eventParams: TrackingParameter): Partial<TrackingParameter> => _.mapValues(eventParams, () => undefined);

/**
 * sends the event data to Google Tag Manager
 * @param eventParams a dictionary of event parameters with the event to send to the Tag Manager
 */
const sendGoogleEventTrackingInfo = (eventParams: TrackingParameter): void => {
	const dataLayer: TrackingParameter[] = window.dataLayer ?? [];
	window.clearedProperties = window.clearedProperties ?? {};

	// add the cleared properties of current event into a global object of cleared properties for all events
	const clearedCurrentProperties = clearCurrentProperties(eventParams);
	window.clearedProperties = {
		...window.clearedProperties,
		...clearedCurrentProperties,
	};

	// use the cleared properties to overwrite any properties set before this event
	const newParams: TrackingParameter = {
		...window.clearedProperties,
		...eventParams,
	};

	// finally, push new event params for Google Tag Manager to pick up
	dataLayer.push(newParams);
};

/**
 * Generic tracking hook used with Google Analytics and Azure App Insights
 * App Insights uses componentName for component engaged time
 * Google Analytics and App Insights both use params for event tracking
 * @param componentName string used to display on Azure App Insights
 * @param paramsOnLoad TrackingParameter data to send to Tag Manager and App Insights when the component mounts
 */
const useTracking = <T>(componentName: string, eventName: string, paramsOnLoad: T | null): (newParams: T) => void => {
	const appInsights = useAppInsightsContext();
	const trackComponent = useTrackMetric(appInsights, componentName);

	// Send Tracking Paramters to Google Tag Manager and Azure AppInsights
	const trackParameters = useCallback((params: T) => {
		sendGoogleEventTrackingInfo({
			event: eventName,
			...params,
		});
		appInsights.trackEvent({
			name: eventName,
			...params,
		});
	}, []
	);

	useEffect(() => {
		trackComponent();
		if (paramsOnLoad) {
			trackParameters(paramsOnLoad);
		}
	}, []);

	const trackEvent = useCallback((trackingParam: T) => {
		trackParameters(trackingParam);
	}, [eventName]);

	return trackEvent;
};

export default useTracking;
