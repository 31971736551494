import React, {
	useCallback, useEffect, useMemo,
} from 'react';
import { useFetch } from '@copilot/common/hooks/common';
import { CampaignManager, LinkedInManager } from '@copilot/data';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import MessageSettings from '../../settings/message';
import BasicContainer from '@copilot/common/components/containers/basic';
import { Col, Row } from 'antd';
import { CampaignType } from '@copilot/data/responses/interface';
import { CampaignFields } from '@copilot/common/store/models/redux';
import { OnboardMessage } from '@copilot/data/requests/models';
import notificationManager from '@copilot/common/utils/notificationManager';
import { MessageType } from '../../settings/message/const';
import { generateOrdinalStringNumArray } from '@copilot/common/utils';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useSelector } from 'react-redux';
import { useAdvancedTeamUserTracking } from '@copilot/common/components/editors/orgMemberInfo/tracking';
import { isTeamAdvancedUser } from '@copilot/common/utils/organizationMember';
import { MessageUpdater } from '../helpers';
import Title from 'antd/lib/typography/Title';

const MAX_MESSAGE_NUMBER = 11;
const INITIATE_MESSAGE_LABEL = 'Connection request';

const prospectingOrder = [INITIATE_MESSAGE_LABEL, ...generateOrdinalStringNumArray(2, MAX_MESSAGE_NUMBER + 1)];
const nurtureOrder = [...generateOrdinalStringNumArray(0, MAX_MESSAGE_NUMBER + 1)];
// message order varies by campaign type as nurture does not have editable initiate invite node
const getMessageOrder = (type: CampaignType) => (type === CampaignType.Nurture ? nurtureOrder : prospectingOrder);

const NEW_MESSAGE: OnboardMessage = {
	period: -1,
	text: '',
	time: -1,
};

interface CampaignDashboardSequenceProps {
	campaign: CampaignFields;
	isCampaignEnable: boolean;
	/**
	 * Callback called when saving messages
	 */
	onSaveCallback: () => unknown;
	/**
	 * Update messages state
	 */
	updateMessages: MessageUpdater;
	/**
	 * Messages to display
	 */
	campaignMessages: OnboardMessage[];
}

const CampaignDashboardSequence: React.FC<CampaignDashboardSequenceProps> = (props) => {
	const {
		campaign, isCampaignEnable, campaignMessages, onSaveCallback, updateMessages,
	} = props;
	const [, fetchNodes] = useFetch(
		CampaignManager.getSequenceNodes,
		CampaignActions.updateCampaign,
		(r) => ({ id: campaign.id, sequenceNodes: r })
	);

	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const updateAdvancedTeamUserTracking = useAdvancedTeamUserTracking('Campaign Dashboard Sequence');

	useEffect(() => {
		fetchNodes(campaign.id);
	}, [campaign.id]);

	const onMessageDelete = (message: OnboardMessage) => {
		if (!message.nodeId) {
			updateMessages((messages) => messages.slice(0, messages.length - 1));
		} else
			CampaignManager.deleteNode(campaign.id, message.nodeId)
				.then(() => {
					notificationManager.showSuccessNotification({ message: 'Message deleted' });
					updateMessages((messages) => messages.filter((m) => m.nodeId != message.nodeId));
				})
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'Delete Failed',
						description: 'Please refresh the page and try again',
					});
				});
	};

	const addMessage = useCallback(() => {
		updateMessages([...campaignMessages, NEW_MESSAGE]);
	}, [campaignMessages]);

	const onMessageSave = (idx: number, notification?: string) => (message: typeof campaignMessages[0]) => {
		//filter messages with no text or nodeId out to prevent saving unwanted message changes
		//when multiple editors are open
		const newMessages: OnboardMessage[] = [
			...campaignMessages.slice(0, idx),
			message,
			...campaignMessages.slice(idx + 1),
		].filter((m) => m.text || m.nodeId);

		LinkedInManager.saveCampaignMessages(campaign.id, newMessages).then(() => {
			if (activeMember && isTeamAdvancedUser(activeMember)) {
				updateAdvancedTeamUserTracking({ buttonClicked: 'Advanced Team User - Messaging Updated' });
			}
			notificationManager.showSuccessNotification({
				message: notification ?? 'Message Saved',
			});
			onSaveCallback();
		}).catch(() => {
			notificationManager.showErrorNotification({
				message: 'Message Failed to Save',
				description: 'Please refresh the page and try again',
			});
		});
	};

	const getIsMessageHidden = useCallback((idx: number) => {
		if (campaign.type === CampaignType.Nurture && idx === 0) {
			return true;
		} else return false;
	}, [campaign.type]);

	const initialMessageIndex = useMemo(() => {
		if (campaign.type === CampaignType.Nurture) return 1;
		else return 0;
	}, [campaign.type]);

	const getIsMessageDeletable = useCallback((idx: number) => {
		if (idx === initialMessageIndex) {
			return false;
		} else if (idx === campaignMessages.length - 1) {
			return true;
		} else return false;
	}, [campaign.type, campaignMessages]);

	return (
		<BasicContainer style={{ width: '80%', maxWidth: '900px', marginLeft: 'auto', marginRight: 'auto', padding: '20px 15px' }}>
			<BasicContainer.Content>
				<Row>
					<Col span={24} >
						<Title style={{ fontSize: 18 }}>Automated messages are what your prospects see when you reach out to them on LinkedIn. Follow up messages are used to gently nudge a prospect to reply.</Title>
					</Col>
				</Row>
				<MessageSettings
					messages={campaignMessages}
					isEditable={!isCampaignEnable}
					messageOrder={getMessageOrder(campaign.type)}
					onSave={onMessageSave}
					getIsMessageHidden={getIsMessageHidden}
					getIsMessageDeletable={getIsMessageDeletable}
					onDelete={onMessageDelete}
					hasInitiateTimer={campaign.type === CampaignType.Nurture}
					messageType={campaign.type === CampaignType.Nurture ? MessageType.Nurture : MessageType.FollowUp}
					addMessage={addMessage}
					initialMessageIndex={initialMessageIndex}
					campaignId={campaign.id}
				/>
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default CampaignDashboardSequence;
