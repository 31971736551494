import * as React from 'react';
import { SwapOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { AdminManager } from '@copilot/data/managers/admin';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationActions } from '@copilot/common/store/actions/organization';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { useSelector } from 'react-redux';
import { useOrganizationChanger } from '@copilot/common/hooks/admin';
import { JSX } from 'react';
import styles from './header.module.less';

type OrganizationSwitcherProps = Readonly<{
	isDark?: boolean;
}>;

/**
 * Button allowing a user to choose which of the organizations they would like to see the prospecting tool for.
 */
export function OrganizationSwitcher({ isDark }: OrganizationSwitcherProps): JSX.Element {
	
	const organizationsGetter = React.useCallback<typeof AdminManager.getOrganizations>(
		() => AdminManager.getOrganizations(),
		[]
	);

	const [, fetchOrganizations] = useFetch(
		organizationsGetter,
		OrganizationActions.loadOrganization,
		(r) => r.results
	);
	const organizationsLoaded = useSelector(OrganizationSelectors.getOrganizations);

	React.useEffect(() => {
		fetchOrganizations();
	}, []);

	const changeOrganization = useOrganizationChanger();

	const items = organizationsLoaded.map((org) => ({
		key: org.id,
		label: org.name,
		onClick: () => changeOrganization(org.id),
	}));

	return (
		<Dropdown
			trigger={['click']}
			menu={{ items }}
			overlayStyle={{ userSelect: 'none', width: 260 }}
		>
			<div className={isDark ? styles.organizationSwitcherDark : styles.organizationSwitcherLight} tabIndex={3}>
				<SwapOutlined
					className={styles.headerIcon}
					title="Change Organization"
				/>
			</div>
		</Dropdown>
	);
}
