import { RoleTitles } from '@copilot/common/config/roles';
import { IOrganizationTeamMember } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/models';
import { OrganizationType, OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { LinkedinOrganizationMemberResponse, OrganizationMemberResponse } from '@copilot/data/responses/interface';

/**
 * TODO: Return the joined orgmembers and their linkedin information in the endpoint instead of joining like this
 * Adds neccessay additional fields to orgMembers based on their linkedIn information
 * @param orgMembers orgMembers to add fields to
 * @param orgMembersLinkedIn information used to populate fields of orgMembers
 * @returns updated orgMembers
 */
export const joinOrgMembersAndTheirLinkedIn = (orgMembers: OrganizationMemberResponse | OrganizationMemberResponse[], orgMembersLinkedIn: LinkedinOrganizationMemberResponse | LinkedinOrganizationMemberResponse[]): IOrganizationTeamMember[] => {
	const orgMembersArr = Array.isArray(orgMembers) ? orgMembers : [orgMembers];
	const orgMembersLinkedInArr = Array.isArray(orgMembersLinkedIn) ? orgMembersLinkedIn : [orgMembersLinkedIn];

	const teamMembers = orgMembersArr.map((member) => ({
		...member,
		...getMemberLinkedInInfo(member, orgMembersLinkedInArr),
		...calculateHelperFieldsForMember(member),
	}));
	return teamMembers;
};

/**
 * Return additional calculated helper fields for member
 * @param member member to calculate extra fields from
 */
const calculateHelperFieldsForMember = (member: OrganizationMemberResponse) => {
	const isOrgAdmin = member.orgRoles.includes(OrgRoleTitles.Admin) || member.orgRoles.includes(OrgRoleTitles.Owner);
	const isSysAdmin = member.userRoles.includes(RoleTitles.Admin) || member.userRoles.includes(RoleTitles.CustomerSuccess) || member.userRoles.includes(RoleTitles.System);
	const isOrgAdvanced = member.orgRoles.includes(OrgRoleTitles.Advanced);
	const name = getOrganizationMemberName(member);
	return {
		isOrgAdmin,
		isOrgAdvanced,
		isSysAdmin,
		name,
	};
};

/**
 * Return LinkedIn information of the member
 * @param member member to get LinkedIn information for
 * @param orgMembersLinkedInArr array to search for member's LinkedIn information
 */
const getMemberLinkedInInfo = (member: OrganizationMemberResponse, orgMembersLinkedInArr: LinkedinOrganizationMemberResponse[]) => {
	const linkedInIndex = orgMembersLinkedInArr.findIndex((memberLinkedIn) => memberLinkedIn.orgMemberId === member.id);
	let isLinkedInLoggedIn: boolean;
	if (linkedInIndex > -1) {
		const memberLinkedInInfo = orgMembersLinkedInArr[linkedInIndex];
		isLinkedInLoggedIn = memberLinkedInInfo.isLoggedIn;
	} else {
		isLinkedInLoggedIn = false;
	}
	return {
		isLinkedInLoggedIn,
	};
};

/**
 * Returns the full name or first name if no last name of the organization member
 * @param organizationMember
 * @returns organization member full name
 */
export const getOrganizationMemberName = (organizationMember: OrganizationMemberResponse) => {
	const first: string = organizationMember.firstName || '';
	const last: string = organizationMember.lastName || '';
	const name = `${first}${last ? ' ' : ''}${last}`;
	return name;
};

/**
 * Returns true if the given organization member is a teams advanced user
 * @param {IOrganizationMember} organizationMember
 */
export const isTeamAdvancedUser = (organizationMember: IOrganizationMember) => (
	organizationMember.orgType === OrganizationType.Enterprise && organizationMember.orgRoles.includes(OrgRoleTitles.Advanced)
);

/**
 * Returns true if the given organization member title is a either: an Admin, or Customer Success, or System
 * @param {IOrganizationMember} organizationMember
 */
export const isSystemAdmin = (organizationMember: IOrganizationMember) => {
	const retVal = organizationMember.userRoles.find((Role: string) => (Role === RoleTitles.Admin || Role === RoleTitles.CustomerSuccess || Role === RoleTitles.System));
	return (retVal != undefined);
};
