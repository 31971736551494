import { CampaignStatsType, getCampaignStatsThresholds } from '@copilot/common/utils/campaign';
import { PercentageRingAndDescription, PercentageRingAndDescriptionProps } from './percentageRingAndDescription';
import { useTheme } from 'styled-components';

interface MetricConditionalPercentageRingAndDescriptionProps extends Omit<PercentageRingAndDescriptionProps, 'ringColour' | 'textColour'> {
	/**
	 * The metric type to be displayed
	 */
	metricType: CampaignStatsType;
}

type MetricValueProperties = {
	ringColour: string;
	percentageTextColour: string;
};

/**
 * type for the three categories of supporting text for a metric
 */
type ThreeCategoryMetricTexts = {
	alert: string;
	warning: string;
	success: string;
};

/**
 * type for specifying metrics' supporting texts
 */
type MetricSupportingTexts = {
	[CampaignStatsType.ConnectionRate]: ThreeCategoryMetricTexts;
	[CampaignStatsType.InterestedRate]: ThreeCategoryMetricTexts;
	[CampaignStatsType.ReplyRate]: ThreeCategoryMetricTexts;
};

const metricSupportingTexts: MetricSupportingTexts = {
	[CampaignStatsType.ConnectionRate]: {
		alert: "There's room to grow and maximize your outreach!",
		warning: 'Making good progress - keep connecting and engaging!',
		success: "You're building a solid network!",
	},
	[CampaignStatsType.ReplyRate]: {
		success: "Amazing stats. You're a natural!",
		warning: "Looking good, but there's room to grow!",
		alert: "Let's get some conversations started!",
	},
	[CampaignStatsType.InterestedRate]: {
		success: 'Keep up the great work!',
		warning: "You're on the right track, let's aim higher!",
		alert: "It's not necessarily a dead end!",
	},
};

/**
 * Return the supporting text for the given metric type and value
 * @param metricValue 
 * @param metricType 
 * @returns 
 */
function getMetricSupportingText(metricValue: number, metricType: CampaignStatsType): string {
	const { alertRate, successRate } = getCampaignStatsThresholds(metricType);
	if (metricValue < alertRate)
		return metricSupportingTexts[metricType].alert;
	else if (metricValue < successRate)
		return metricSupportingTexts[metricType].warning;
	else
		return metricSupportingTexts[metricType].success;
}

/**
 * Component displaying a percentage ring and description with colours and supporting text dependent on whether rate is considered low
 */
export const MetricConditionalPercentageRingAndDescription: React.FC<MetricConditionalPercentageRingAndDescriptionProps> = (props) => {
	const {
		metricType,
		metricValue,
	} = props;

	const theme = useTheme();

	const alertMetricValueCommonProperties: MetricValueProperties = {
		ringColour: theme['@Error/20'],
		percentageTextColour: theme['@Error/70'],
	};

	const warningMetricValueCommonProperties: MetricValueProperties = {
		ringColour: theme['@Warning/20'],
		percentageTextColour: theme['@Warning/70'],
	};

	const successMetricValueCommonProperties: MetricValueProperties = {
		ringColour: theme['@Success/20'],
		percentageTextColour: theme['@Success/70'],
	};

	function getMetricColor(valuePropName: keyof MetricValueProperties): string {
		const { alertRate, successRate } = getCampaignStatsThresholds(metricType);
		if (metricValue < alertRate)
			return alertMetricValueCommonProperties[valuePropName];
		else if (metricValue < successRate)
			return warningMetricValueCommonProperties[valuePropName];
		else
			return successMetricValueCommonProperties[valuePropName];
	}

	const ringColour = getMetricColor('ringColour');
	const percentageTextColour = getMetricColor('percentageTextColour');
	const supportingText = getMetricSupportingText(metricValue, metricType);

	return (
		<PercentageRingAndDescription
			{...props}
			ringColour={ringColour}
			textColour={percentageTextColour}
			supportingText={supportingText}
		/>
	);
};