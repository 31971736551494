export enum PermissionName {
	/**
	 * access to the team members tab and all members, as well as team member columns and filters throughout the app
	 */
	TeamMembers = 'Campaign.TeamMembers',
	/**
	 * access to the team members tab for the current user only
	 */
	OwnTeamMembers = 'Campaign.OwnTeamMembers',
	/**
	 * access to the search list tab and searches for all campaign members
	 */
	SearchList = 'Campaign.SearchList',
	/**
	 * access to the search list tab and searches for the current user only
	 */
	OwnSearchList = 'Campaign.OwnSearchList',
	/**
	 * access to the automated messaging tab
	 */
	Messaging = 'Campaign.Messaging',
	/**
	 * access to the sequence view of the automated messaging tab
	 */
	MessagingSequence = 'Campaign.MessagingSequence',
	/**
	 * access to the timezone and sending hours tab
	 */
	SendingHours = 'Campaign.SendingHours',
	/**
	 * access to the settings tab as well as campaign operations such as creation, deletion, and renaming
	 */
	Settings = 'Campaign.Settings'
}

export interface IPermissions extends Record<PermissionName, number> {}
