import React from 'react';
import { Col, Row } from 'antd';
import MessageEditorWithTemplates from '@copilot/common/components/editors/message/withTemplates';

export interface BasicWizardMessageEditorProps
	extends React.ComponentProps<typeof MessageEditorWithTemplates> {
	onUpdate: (message: NonNullable<BasicWizardMessageEditorProps['message']>) => unknown;
	header: React.ReactNode;
}

const BasicWizardMessageEditor: React.FC<BasicWizardMessageEditorProps> = (props) => {
	const { header, ...rest } = props;
	return (
		<Row>
			<Col span={24}>
				{header}
				<br />
				<Row>
					<Col span={24}>
						<MessageEditorWithTemplates
							{...rest}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default BasicWizardMessageEditor;
