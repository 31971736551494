import React, { useCallback } from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';
import { FormInstance } from 'antd/lib/form';
import { Validators } from '../../validators';
import { ValidatorRule } from 'rc-field-form/lib/interface';

interface ISecurePasswordFieldProps {
	form: FormInstance;
	isWithConfirm?: boolean;
}

const SecurePasswordField: React.FC<ISecurePasswordFieldProps> = (props) => {
	const { form, isWithConfirm = false } = props;

	const compareConfirmationPassword: ValidatorRule['validator'] = useCallback((rule, value, callback) => {
		if (value !== form.getFieldValue('newPassword'))
			callback('Your passwords must match.');
		else callback();
	}, []);

	return (
		<>
			<StyledFormItem
				name="newPassword"
				label="New Password"
				style={{ fontWeight: 'bold' }}
				rules={[{ required: true, validator: Validators.validateSecurePassword }]}
			>
				<Input type="password" />
			</StyledFormItem>
			{isWithConfirm && (
			<StyledFormItem
				name="confirmPassword"
				label="Confirm Password"
				style={{ fontWeight: 'bold' }}
				rules={[{ required: true, validator: compareConfirmationPassword }]}
			>
				<Input type="password" />
			</StyledFormItem>
			)}
		</>
	);
};

const StyledFormItem = styled(Form.Item)`
	font-weight: bold;	
	display: block;
`;

export default SecurePasswordField;
