import {
	DefaultContactConnectionsTableColumns,
	TeamsContactConnectionsTableColumns,
} from '@copilot/common/pages/connections/connectionTableColumns';
import { useSelector } from 'react-redux';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { OrganizationType } from '@copilot/common/store/models/const/enum';
import {
	ExportCSVColumns,
} from '@copilot/common/components/componentModels/filterTypeDefinition';

/**
 * Encapsulate logics to determine connection's table columns
 */
export function useContactConnectionsTableColumns() {
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const isTeamUser = activeOrganization?.orgType === OrganizationType.Enterprise;

	if (!isTeamUser) return DefaultContactConnectionsTableColumns;
	return TeamsContactConnectionsTableColumns;
}

/**
 * Encapsulate logic to determine columns that can be exported
 */
export function useExportCsvColumns() {
	return ExportCSVColumns;
}
