import React from 'react';
import { IInfoSectionProps } from '@copilot/common/components/infoSection';
import { Config } from '@copilot/common/config';

interface IWizardStepHeaderText {
	title: string;
	description: React.ReactNode;
}

export enum WizardStepNode {
	NURTURE_TITLE = 0,
	NURTURE_LIST = 1,
	NURTURE_MESSAGING = 2,
	NURTURE_REVIEW = 3,
	NURTURE_COMPLETE = 5 // completed state of the wizard, not an actual wizard step
}

export const NurtureStepTitles = {
	[WizardStepNode.NURTURE_TITLE]: 'Get Started',
	[WizardStepNode.NURTURE_LIST]: 'Select Nurture List',
	[WizardStepNode.NURTURE_MESSAGING]: 'Confirm Messaging',
	[WizardStepNode.NURTURE_REVIEW]: 'Review and Finish',
};

export const WizardStepHeaderText: { [key: number]: IWizardStepHeaderText } = {
	[WizardStepNode.NURTURE_TITLE]: {
		title: 'Welcome to Nurture Campaigns!',
		description: 'Nurture Campaigns help you increase your chance of maintaining relationships with your CoPilot AI prospects who might not be ready to make a purchase or commitment now, but could be ready in the future.',
	},
	[WizardStepNode.NURTURE_LIST]: {
		title: 'Create a Nurture List',
		description: (
			<p>
				To get you started, our AI has curated three different options of handy nurture lists you can use. Or, you can choose to start from an empty list and add prospects over time.
				{' '}
				<i>Don't worry you can always edit the list after the campaign is running.</i>
			</p>
		),
	},
	[WizardStepNode.NURTURE_MESSAGING]: {
		title: 'Create Your Nurture Messages',
		description: 'To get you started, choose a strategy below and browse the examples available. It is important to add your own personal components to your message selection as it leads to much better conversations.',
	},
	[WizardStepNode.NURTURE_REVIEW]: {
		title: 'Review and Finish',
		description: 'Your Nurture campaign is now set up! After selecting Finish you will need to choose how many messages will go out per day and then enable your campaign.',
	},
};

export const WizardInfoSectionText: { [key: number]: IInfoSectionProps } = {
	[WizardStepNode.NURTURE_LIST]: {
		title: 'What are Nurture Campaigns',
		description: 'Learn more about why to nurture your leads and some support information to get started.',
		url: Config.nurtureOverviewURL,
	},
	[WizardStepNode.NURTURE_MESSAGING]: {
		title: 'Showing Value in Nurture Messaging',
		description: 'Learn more about the strategies CoPilot AI recommends and how they can be used to increase your response rate!',
		url: Config.nurtureMessagingURL,
	},
};
