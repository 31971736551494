import React, { useCallback } from 'react';
import { Steps } from 'antd';
import { IWizardNavigationNode } from '../interface';
import styled from 'styled-components';

const StyledSteps = styled(Steps)`
	.${(props) => props.theme['@ant-prefix']}-steps-item-disabled,
	.${(props) => props.theme['@ant-prefix']}-steps-item-active {
		cursor: default;
	}
`;

const { Step } = Steps;

interface INavigationProps {
	nodes: IWizardNavigationNode[];
	currentNode?: number;
	onNodeClick?: (node: number) => void;
	direction?: React.ComponentProps<typeof Steps>['direction'];
	type?: React.ComponentProps<typeof Steps>['type'];
}

/**
 * Returns a navigation panel showing current page, as well as info saved for each page.
 * @param nodes steps for the form
 * @param currentNode current node index displayed in body of wizard
 * @param onNodeClick callback on click of the navigation panel node
 * @param direction the direction of the of the navigation steps. defaults to vertical
 * @param type the type of steps presented
 */
const Navigation: React.FC<INavigationProps> = (props) => {
	const {
		nodes, currentNode, onNodeClick, direction = 'vertical', type,
	} = props;

	if (nodes.length == 0) return <></>;

	const clickableNodes = nodes.map((node: IWizardNavigationNode) => (
		<Step
			key={node.order}
			title={node.description}
			subTitle={node.element}
			disabled={node.disabled}
			status={node.status}
		/>
	));

	const onChange = useCallback((curr: number) => onNodeClick?.(nodes[curr].order), [onNodeClick, nodes]);

	return (
		<StyledSteps
			type={type}
			size="small"
			direction={direction}
			current={currentNode}
			onChange={onChange}
		>
			{clickableNodes}
		</StyledSteps>
	);
};

export default React.memo(Navigation);
