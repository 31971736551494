import AjaxManager from './ajax';
import { AuthorizationManager, LegacyAuthorizationManager } from './authorization';
import { B2CAuthorizationManager, B2COptions } from './b2cAuthorizationManager';

export enum RequestTypes {
	Ajax = 'AJAX',
	Sockets = 'SOCKETS'
}

export interface RequestManagerOptions {
	type?: RequestTypes;
	useB2c: boolean;
	b2cOptions?: B2COptions;
}

type AdapterTypes = AjaxManager;

class RequestManager {
	private ajaxManager?: AjaxManager;
	private adapter!: AdapterTypes;
	private authorizationManager: AuthorizationManager;

	private useB2c: boolean;

	constructor(options: RequestManagerOptions) {
		const {
			useB2c = false,
			b2cOptions,
		} = options;
		this.useB2c = useB2c;
		this.authorizationManager = useB2c && b2cOptions
			? new B2CAuthorizationManager(b2cOptions)
			: new LegacyAuthorizationManager();
		this.setType(options.type ?? RequestTypes.Ajax);
	}

	public get: AdapterTypes['get'] = (...args) =>
		this.adapter.get.apply<
			any,
			Parameters<AdapterTypes['get']>,
			GenericReturnType<any, AdapterTypes['get']>
		>(undefined, args);
	public post: AdapterTypes['post'] = (...args) =>
		this.adapter.post.apply<
			any,
			Parameters<AdapterTypes['post']>,
			GenericReturnType<any, AdapterTypes['post']>
		>(undefined, args);
	public put: AdapterTypes['put'] = (...args) =>
		this.adapter.put.apply<
			any,
			Parameters<AdapterTypes['put']>,
			GenericReturnType<any, AdapterTypes['put']>
		>(undefined, args);
	public delete: AdapterTypes['delete'] = (...args) => 
		this.adapter.delete.apply<
			any,
			Parameters<AdapterTypes['delete']>,
			GenericReturnType<any, AdapterTypes['delete']>
		>(undefined, args);
	public patch: AdapterTypes['patch'] = (...args) => 
		this.adapter.patch.apply<
			any,
			Parameters<AdapterTypes['patch']>,
			GenericReturnType<any, AdapterTypes['patch']>	
		>(undefined, args);

	/**
	 * Set the type for this manager
	 * @param {RequestTypes} type The type of request manager we want to use
	 */
	public setType(type: RequestTypes): void {
		switch (type) {
			case RequestTypes.Sockets:
				break;
			case RequestTypes.Ajax:
			default:
				if (!this.ajaxManager)
					this.ajaxManager = new AjaxManager({
						authorizationManager: this.authorizationManager,
					});
				this.adapter = this.ajaxManager;
				break;
		}
	}

	/**
	 * Apply a middleware to trigger on response
	 * @param responseHandler The handler for a successful response
	 * @param errorHandler The handler for error
	 */
	public applyResponseMiddleware: AdapterTypes['applyResponseMiddleware'] = (
		responseHandler,
		errorHandler
	) => {
		if (this.adapter.applyResponseMiddleware)
			this.adapter.applyResponseMiddleware(responseHandler, errorHandler);
	};

	/**
	 * Get the b2c client application instance if available
	 * @returns The PublicClientApplication Instance
	 */
	public getB2CInstance = () => {
		return this.useB2c ? (this.authorizationManager as B2CAuthorizationManager).pca : null;
	};

	/**
	 * Get the authenticated access token
	 * @returns An access token
	 */
	public getToken = async (url: string) => {
		return this.authorizationManager.getAuthenticatedToken(url);
	};
}

export default RequestManager;
