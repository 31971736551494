import * as React from 'react';
import { DrawerProps } from 'antd/lib/drawer';
import MessageEditForm from './messageEditForm';
import BaseDrawer from '../../base';

export interface MessagesEditDrawerProps extends DrawerProps {
	nodeId: string;
	message: string[];
	saveMessage: (message: string[]) => void;
	closeAlert: boolean;
}

const MessagesEditDrawer: React.FC<MessagesEditDrawerProps> = (props) => {
	const {
		saveMessage, closeAlert: _, nodeId, message, ...rest
	} = props;

	return (
		<BaseDrawer {...rest}>
			<MessageEditForm
				nodeId={nodeId}
				message={message}
				saveMessage={saveMessage}
			/>
		</BaseDrawer>
	);
};

export default MessagesEditDrawer;
