import * as React from 'react';
import {
	Col, Radio, Row, Select,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
	NodeConditionResponseTypeEnum,
	AIThresholdEnum,
	AIThresholdValueEnum,
} from '@copilot/common/store/models/const/enum';
import DynamicAdditions from '@copilot/common/components/forms/common/inputs/dynamicAdditions';

const { Option } = Select;
interface ResponseTriggerProps {
	form: FormInstance | null;
	changeCurrentSubType: (x: any) => void;
	changeCurrentResponseExample: (x: string[]) => void;
	changeAIThreshold: (x: any) => void;
	currentSubType: NodeConditionResponseTypeEnum;
	currentConditionResponseTexts: string[];
	AIThreshold: string[];
}

class ResponseTrigger extends React.Component<ResponseTriggerProps> {
	constructor(props: ResponseTriggerProps) {
		super(props);
		this.changeCurrentSubType = this.changeCurrentSubType.bind(this);
		this.changeCurrentResponseExample = this.changeCurrentResponseExample.bind(this);
		this.changeTolerance = this.changeTolerance.bind(this);
	}

	componentDidMount() {
		this.changeTolerance(this.props.AIThreshold[0]);
	}

	changeCurrentSubType(e: any) {
		this.props.changeCurrentSubType(e.target.value);
	}

	changeCurrentResponseExample(e: any) {
		this.props.changeCurrentResponseExample(e as string[]);
	}

	changeTolerance(value: any) {
		this.props.changeAIThreshold(value.toString());
	}

	render() {
		const savedThreshold = this.props.AIThreshold;
		let valueString = AIThresholdEnum.Med;

		switch (savedThreshold[0]) {
			case AIThresholdValueEnum.Low:
				valueString = AIThresholdEnum.Low;
				break;
			case AIThresholdValueEnum.High:
				valueString = AIThresholdEnum.High;
				break;
			default:
				valueString = AIThresholdEnum.Med;
		}

		return (
			<Row style={{ height: '100%' }}>
				<Col
					style={{
						display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%',
					}}
				>
					<Row>
						<Col>
							<h3>Response Options</h3>
						</Col>
					</Row>
					<Row>
						<Col style={{ paddingBottom: '16px' }}>
							<Radio.Group
								buttonStyle="solid"
								onChange={this.changeCurrentSubType}
								value={this.props.currentSubType}
							>
								<Radio.Button value={NodeConditionResponseTypeEnum.NoResponse}>
									No Response
								</Radio.Button>
								<Radio.Button
									value={NodeConditionResponseTypeEnum.EvaluateResponse}
								>
									Evaluate Response
								</Radio.Button>
								<Radio.Button value={NodeConditionResponseTypeEnum.AnyResponse}>
									Any Response
								</Radio.Button>
							</Radio.Group>
						</Col>
					</Row>
					{this.props.currentSubType ==
						NodeConditionResponseTypeEnum.EvaluateResponse && (
						<>
							<Row>
								<Col>
									<h3>Set confidence level for AI messages</h3>
								</Col>
								<Col>
									<Select
										showSearch
										style={{ width: 200 }}
										placeholder="Select a tolerance"
										optionFilterProp="children"
										onChange={this.changeTolerance}
										value={valueString}
									>
										<Option value={AIThresholdValueEnum.Low}>
											{AIThresholdEnum.Low}
										</Option>
										<Option value={AIThresholdValueEnum.Med}>
											{AIThresholdEnum.Med}
										</Option>
										<Option value={AIThresholdValueEnum.High}>
											{AIThresholdEnum.High}
										</Option>
									</Select>
								</Col>
							</Row>
							<Row style={{ flexGrow: 1, overflow: 'hidden auto' }}>
								<Col style={{ width: '100%' }}>
									<br />
									<h3>Examples of replies that would match this category</h3>
									<DynamicAdditions
										defaultLabel="Example Replies"
										form={this.props.form}
										initialValues={this.props.currentConditionResponseTexts}
									/>
								</Col>
							</Row>
						</>
					)}
				</Col>
			</Row>
		);
	}
}

export default ResponseTrigger;
