import { DaySchedule } from '@copilot/data/responses/interface';
import { MIN_DAILY_SCHEDULE_HOURS } from '@copilot/common/components/selector/timeRangeSelector';
import ianaTimezones from '@copilot/common/components/selector/timezone/ianaTimezones.json';
import timezones from '@copilot/common/components/selector/timezone/timezones.json';

export namespace ScheduleUtility{
	interface ScheduleError {
		insufficientRuntime: boolean;
		excessiveRunDay: boolean;
	}

	export const getScheduleError = (weeklySchedule?: DaySchedule[]): ScheduleError => {
		let enableDayCount = 0;
		return weeklySchedule?.reduce<{ insufficientRuntime: boolean; excessiveRunDay: boolean }>(
			(acc, schedule) => {
				if (
					!acc.insufficientRuntime &&
				schedule.end - schedule.start < MIN_DAILY_SCHEDULE_HOURS * 60 && schedule.enable) {
					acc.insufficientRuntime = true;
				}
				if (schedule.enable) {
					enableDayCount += 1;
					if (enableDayCount > 5) {
						acc.excessiveRunDay = true;
					}
				}
				return acc;
			}, {
				insufficientRuntime: false,
				excessiveRunDay: false,
			}) ?? {
			insufficientRuntime: true,
			excessiveRunDay: true,
		};
	};

	export const guessLocaleTimezone = () => {
		const timezoneOffset = new Date().getTimezoneOffset();
		const utcOffset = timezoneOffset / -60;
		const utcTimezone = `UTC${utcOffset < 0 ? '-' : '+'}${
			utcOffset < 10 && utcOffset > -10 ? `0${Math.floor(utcOffset)}` : utcOffset.toFixed(0)
		}:${utcOffset % 1 ? '30' : '00'}`;
		return timezones[utcTimezone as keyof typeof timezones]
			? Object.values(timezones[utcTimezone as keyof typeof timezones])[0]
			: null;
	};

	export const getLocaleTimezone = () => {
		const localeTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return (
			ianaTimezones[localeTimezone as keyof typeof ianaTimezones] ??
			guessLocaleTimezone() ??
			'Eastern Standard Time'
		);
	};
}
