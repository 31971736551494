import { attr, ModelType } from 'redux-orm';
import { BaseModel } from './base';
import { NotificationActionType } from '../const/enum';

export interface INotificationAction {
	type: NotificationActionType;
	text: string;
	meta: any;
}

export interface INotification {
	id: string;
	title: string;
	body: string;
	actions: INotificationAction[];
}

type UpdateNotifictionCountPayload = {
	id: string;
	update: string;
};

type NotificationAction = {
	type: 'UPDATE_NOTIFICATION_COUNT';
	payload: UpdateNotifictionCountPayload;
};

export class Notification extends BaseModel<typeof Notification, INotification> {
	static modelName = 'Notification';
	static fields = {
		id: attr(),
		title: attr(),
		body: attr(),
		actions: attr(),
	};

	static reducer(action: NotificationAction, modelType: ModelType<Notification>): void {
		switch (action.type) {
			case 'UPDATE_NOTIFICATION_COUNT': {
				const notificationToUpdate = modelType.withId(action.payload.id);
				if (notificationToUpdate != undefined) {
					const updatedCount = parseInt(notificationToUpdate.body) + parseInt(action.payload.update);
					notificationToUpdate.update({
						body: updatedCount.toString(),
					});
				}
				break;
			}
			default:
				break;
		}
	}
}
