import { useEffect, useMemo, useCallback, useState } from 'react';
import { List } from 'antd';
import ReminderItem from './item';
import { Contact, ScheduledMessage } from '@copilot/common/store/models/redux';
import { AIFeatureManager, ActivityManager, OutboxManager } from '@copilot/data';
import { OrmState, SessionBoundModel } from 'redux-orm';
import Store from '@copilot/common/store';
import { ContactActions } from '@copilot/common/store/actions/contact';
import { ScheduledMessageActions } from '@copilot/common/store/actions/scheduledMessage';
import { SchedulingStatus, ThreadStatus } from '@copilot/common/store/models/const/enum';
import { useSelector } from 'react-redux';
import { ScheduledMessageSelectors } from '@copilot/common/store/selectors/scheduledMessage';
import { useFetch, useRedirectToTemplate } from '@copilot/common/hooks/common';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import notificationManager from '@copilot/common/utils/notificationManager';
import { formatNameFromObject, throwError } from '@copilot/common/utils';
import { ProspectDrawerTrackingParams } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import MessageForm from '@copilot/common/components/forms/messageForm';
import { CampaignDashboardTabKeys } from '@copilot/common/utils/campaign/dashboardTabs';
import { Moment } from 'moment';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features, TermsOfUse } from '@copilot/data/responses/interface';
import { useQuickResponseModal } from '@copilot/common/hooks/quickResponse';
import { useSmartReply } from '@copilot/common/hooks/smartReply';
import Layout from '@copilot/common/components/layout';
import { spacingMD } from '@copilot/common/constant/commonStyles';
import { MessageFormMessageTemplate } from '@copilot/common/components/forms/messageForm/types';
import { useTermsOfUse } from '@copilot/common/hooks/termsOfUse';
import { Config } from '@copilot/common/config';
import { checkHasAccess } from '@copilot/common/utils/access';
import { manualMessageDisabledMsg } from '@copilot/common/constant/strings';

export type ScheduledMessagesProps = {
	contact: SessionBoundModel<Contact>;
	orgMemberId: string;
	messageTemplates: MessageFormMessageTemplate[];
	campaignId?: string;
	onTracking?: (params: ProspectDrawerTrackingParams) => void;
};

export default function ScheduledMessages({
	contact,
	orgMemberId,
	messageTemplates,
	onTracking,
	campaignId,
}: ScheduledMessagesProps) {
	const scheduledMessages = useSelector((state: { entities: OrmState<any> }) =>
		ScheduledMessageSelectors.getScheduledMessagesByContactId(state, contact.id)
	);

	const { hasAccepted: hasAcceptedAITermsOfUse } = useTermsOfUse(
		TermsOfUse.AIFeature,
		AIFeatureManager.acceptTermsOfUse
	);
	const isManualSendMessageFeatureEnabled = useFeatureToggle(
		Features.ManualSendMessageFeature
	);

	const videoInAppValidationEnabled =
		!Config.isAgency && useFeatureToggle(Features.VideoValidationFeature);
	const activeUser = useSelector(OrganizationMemberSelectors.getActiveMember);

	const [smartReplyResult, { writeSmartReply, regenerateSmartReply, editSmartReply }] =
		useSmartReply(
			activeUser?.organizationId ?? throwError('Unable to get current org id'),
			orgMemberId,
			contact.id
		);

	const isQuickReplyModalFeatureEnabled = useFeatureToggle(
		Features.SmartReplySaveResponseFeature
	);
	const [createQuickReplyModal] = useQuickResponseModal();

	const [, fetchScheduledMessages] = useFetch(
		OutboxManager.getScheduledMessagesForContact,
		ScheduledMessageActions.loadScheduledMessages,
		(r) => r.results
	);
	const redirectToTemplate = useRedirectToTemplate(CampaignDashboardTabKeys.Template);

	const [isScheduling, setIsScheduling] = useState<boolean>(false);

	useEffect(() => {
		if (orgMemberId && contact.id) fetchScheduledMessages(orgMemberId, contact.id);
	}, [orgMemberId, contact.id]);

	const deleteReminder = async (reminder: ScheduledMessage) => {
		if (reminder) {
			try {
				const response: boolean =
					await ActivityManager.deleteScheduledMsgFromLinkedInProfile(
						contact.ref.id,
						reminder.id
					);
				if (response) {
					Store.Dispatch(
						ScheduledMessageActions.deleteScheduledMessage({
							id: reminder.id,
						} as Partial<ScheduledMessage>)
					);
				}
			} catch (err) {
				notificationManager.showErrorNotification({
					message: 'Message Error',
					description: 'Unable to delete message',
				});
			}
		}
	};

	const generateList = useMemo(() => {
		const filteredScheduledMessages = scheduledMessages.filter(
			(msg: ScheduledMessage) => msg.orgMemberId === orgMemberId
		);
		return (
			<List
				dataSource={filteredScheduledMessages}
				itemLayout="vertical"
				className="reminder-list"
				renderItem={(item: ScheduledMessage, idx: number) => (
					<ReminderItem
						key={`${idx}`}
						scheduledMessage={item}
						className=""
						onDeleteScheduledMessage={deleteReminder}
						isDeletable={checkHasAccess(
							item.orgMemberId,
							item.ownerOrgMemberId,
							!!activeUser?.isOrgAdmin
						)}
					/>
				)}
			/>
		);
	}, [orgMemberId, scheduledMessages, activeUser?.isOrgAdmin]);

	/**
	 * Submits a scheduled message to the backend
	 * @param msg the message we want to send
	 * @param selectedDate the date we want to send the message
	 * @param templateId the id of the template we're sending if there is one
	 */
	const submitReminder = useCallback(
		async (msg: string, selectedDate: Moment, templateId?: string) => {
			try {
				setIsScheduling(true);
				if (msg.trim() != '') {
					const sendDate = selectedDate.toISOString();
					const response = await ActivityManager.submitScheduledMsgToLinkedInProfile(
						contact.id,
						orgMemberId,
						msg,
						sendDate,
						templateId,
						campaignId
					);
					onTracking?.({ buttonClicked: 'Schedule Message' });
					const { organization: _, reminders, ...rest } = contact.ref;
					Store.Dispatch(
						ContactActions.updateContact({
							...rest,
							reminders: [...reminders, response as any],
						})
					);
					const scheduledMessage = {
						name: formatNameFromObject(contact),
						status: ThreadStatus.Pending,
						threadId: '',
						message: response.message,
						timestamp: `${new Date(response.triggerDateTime)}`,
						dateCreated: `${new Date()}`,
						error: SchedulingStatus.OnSchedule,
						contactId: contact.id,
						id: response.id,
						orgMemberId,
						ownerOrgMemberId: orgMemberId,
					} as ScheduledMessage;

					Store.Dispatch(
						ScheduledMessageActions.loadScheduledMessages([scheduledMessage])
					);
				}
			} catch (e) {
				console.error(e);
				throw e;
			} finally {
				setIsScheduling(false);
			}
		},
		[contact, orgMemberId, campaignId, onTracking]
	);

	return (
		<Layout>
			<Layout.Content>
				<div style={{ padding: spacingMD }}>{generateList}</div>
			</Layout.Content>
			<Layout.Footer>
				<div style={{ padding: spacingMD }}>
					<MessageForm
						messageTemplates={messageTemplates}
						isScheduled
						isDisabled={!isManualSendMessageFeatureEnabled}
						isUsingTemplateDropdown
						onSetupTemplate={
							isQuickReplyModalFeatureEnabled
								? (formMessage: string) => {
										createQuickReplyModal(
											[contact.campaignIds[0]],
											formMessage
										);
								  }
								: () => redirectToTemplate(contact.campaignIds[0] ?? '')
						}
						hasAcceptedAITermsOfUse={hasAcceptedAITermsOfUse}
						videoInAppValidationEnabled={videoInAppValidationEnabled}
						onScheduledSubmit={submitReminder}
						chatGPTWriteRetryCallback={regenerateSmartReply}
						chatGPTWriteCallback={writeSmartReply}
						chatGPTEditCallback={editSmartReply}
						chatGPTQueryResult={smartReplyResult}
						isSubmitting={isScheduling}
						placeholder={!isManualSendMessageFeatureEnabled ? manualMessageDisabledMsg : undefined}
					/>
				</div>
			</Layout.Footer>
		</Layout>
	);
}
