import { FC, useCallback, useMemo } from 'react';
import { Row, Col } from 'antd';
import { NewUserOnboardSteps } from '@copilot/common/pages/campaignDashboard/newUserOnboard/const';
import { isSearchUrlValid } from '@copilot/common/utils';
import { SearchUrlValidatorAllowEmpty } from '@copilot/common/components/forms/common/validators';
import ButtonOptionCard from '@copilot/common/components/card/buttonOptionCard';
import styled from 'styled-components';
import NewUserOnboardTitle from './newUserTitle';
import { LinkedInSearchType } from '@copilot/data/responses/interface';
import { CheckOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { Config } from '@copilot/common/config';

const CardWrapperCol = styled(Col)`
	min-width: 320px;
	max-width: 700px;
	min-height: 350px;
`;

const BaseSalesNavUrlDetails = {
	placeholder: 'linkedin/sales/search/people...',
	initialValue: '',
	validator: SearchUrlValidatorAllowEmpty,
	stringValidator: isSearchUrlValid,
};

interface INewUserGetStartedProps {
	salesNavUrl: string;
	setCurrentStep?: (step: number, modifications?: { [k: string]: any }) => void;
	onSearchCriteriaSelected?: () => void;
	onSalesNavUrlSelected?: () => void;
	showCriteriaPanel: boolean;
}

/**
 * The messages displayed in the checklist in the URL based search card
 */
const SearchRequirements: string[] = [
	'2nd-degree connection only',
	'Minimum of 400 search results (recommended) ',
];

interface IChecklistItemProps {
	text: string;
}

/**
 * [Presentational] A checklist item
 * @param {string} text the message to be displayed next to the check
 */
const ChecklistItem: FC<IChecklistItemProps> = (props) => {
	const { text } = props;
	return (
		<Row style={{ marginTop: '0.5rem' }}>
			<Col style={{ display: 'flex' }}>
				<CheckOutlined style={{ display: 'flex', alignItems: 'center' }} />
				<Text style={{ fontSize: '14px', marginLeft: '0.75rem' }}>{text}</Text>
			</Col>
		</Row>
	);
};

interface ISearchRequirementsChecklistProps {
	requirements: string[]
}

/**
 * [Presentational] A checklist of requirements for the user's search
 * @param {Array<string>} requirements the requirements to be displayed
 */
const SearchRequirementsChecklist: FC<ISearchRequirementsChecklistProps> = (props) => {
	const { requirements } = props;
	return (
		<Col span={24} style={{ textAlign: 'left', marginBottom: '2rem' }}>
			<Text strong>Requirements for your search</Text>
			{requirements.map((requirement) => <ChecklistItem key={requirement} text={requirement} />)}
		</Col>
	);
};

/**
 * [Presentational] Get Started step of the in-app New User Onboarding
 * @param {string} salesNavUrl value of the sales nav url
 * @param {function} setCurrentStep called to update the current step, populated by Wizard.Step.MainContent
 * @param {function} onSearchCriteriaSelected called when the Search Criteria panel is selected
 * @param {function} onSalesNavUrlSelected called when the Sales Nav Url panel is selected
 * @param {boolean} showCriteriaPanel should the Search Criteria panel be displayed?
 */
const NewUserGetStarted: FC<INewUserGetStartedProps> = (props) => {
	const {
		salesNavUrl, setCurrentStep, onSalesNavUrlSelected, onSearchCriteriaSelected, showCriteriaPanel,
	} = props;

	const salesNavUrlDetails = useMemo(() => ({
		...BaseSalesNavUrlDetails,
		initialValue: salesNavUrl,
	}), [salesNavUrl]);

	// NOTE: handleSearchCriteriaSelected and handleSalesNavUrlSelected should technically be implemented in the parent smart component,
	// but it is being called here because it needs to use setCurrentStep, which gets passed in by Wizard.Step.MainContent.
	// The modifications must be made here as part of the save to avoid these updates getting overwritten by the response from the BE.
	const handleSearchCriteriaSelected = useCallback(() => {
		onSearchCriteriaSelected?.();
		setCurrentStep?.(NewUserOnboardSteps.SEARCH, { searchType: LinkedInSearchType.Criteria });
	}, [setCurrentStep]);

	const handleSalesNavUrlSelected = useCallback((url: string) => {
		onSalesNavUrlSelected?.();
		setCurrentStep?.(NewUserOnboardSteps.MESSAGING, { searchType: LinkedInSearchType.Url, searchUrl: url });
	}, [setCurrentStep]);

	const learnHowLink = (
		<a href={Config.onboardSalesNavSearchListHelpUrl} target="_blank">
			Learn how with a quick step-by-step guide
		</a>
	);

	const urlButtonOptionCard = (
		<CardWrapperCol flex="1">
			<ButtonOptionCard
				title="Upload a search from LinkedIn Sales Navigator"
				description="Copy the URL of your search from LinkedIn Sales Navigator and upload it into CoPilot AI."
				inputOptions={salesNavUrlDetails}
				buttonLabel="Upload"
				onButtonClick={handleSalesNavUrlSelected}
				aboveInputChildren={learnHowLink}
				belowInputChildren={<SearchRequirementsChecklist requirements={SearchRequirements} />}
			/>
		</CardWrapperCol>
	);

	return (
		<Row justify="center">
			{showCriteriaPanel ? (
				<Col xxl={20} style={{ textAlign: 'center', paddingBottom: '24px' }}>
					<NewUserOnboardTitle title="Choose an option to get started" />
					<Row justify="center" gutter={[16, 16]}>
						<CardWrapperCol flex="1">
							<ButtonOptionCard
								title="New to LinkedIn prospecting?"
								description="Fill out a simple form to tell us about the prospects you would like to target."
								buttonLabel="Create your prospect criteria"
								onButtonClick={handleSearchCriteriaSelected}
							>
								<Col>Recommended</Col>
							</ButtonOptionCard>
						</CardWrapperCol>
						{urlButtonOptionCard}
					</Row>
				</Col>
			) : urlButtonOptionCard}
		</Row>
	);
};

export default NewUserGetStarted;
