export type PropertyKey = number | string;
type SortOrder = 'descend' | 'ascend';
export type Pagination = { current: number; pageSize: number };
export type Filters = Record<string, PropertyKey[] | null>;
export type Sort = { order: SortOrder; columnKey: PropertyKey };
type Sorter = Sort | Sort[];

export type Query = Readonly<Partial<{
	pagination: Pagination;
	filters: Filters;
	sorter: Sorter;
}>>;

/**
 * Get the sortBy string from a Sort
 * @param sorter
 */
const convertSortToSearchParams = (sort: Sort) => {
	const isAscend = sort.order == 'ascend';
	return `${isAscend ? '' : '-'}${sort.columnKey}`;
};

/**
 * Get the sortBy params from a sorter
 * @param sorter
 */
export const convertSorterToSearchParams = (sorter: Sorter) => {
	if (Array.isArray(sorter)) return convertSortToSearchParams(sorter[0]);
	return convertSortToSearchParams(sorter);
};

/**
 * Parse Filters to be search params
 * @param filters
 */
export const convertFiltersToSearchParams = (filters: Filters) => {
	const params: Record<string, string> = {};
	for (const key in filters) {
		const value = filters[key];
		if (!value) continue;
		params[key] = value.join(',');
	}
	return params;
};
