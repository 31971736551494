import Store from '..';
import { createSelector, OrmState } from 'redux-orm';

export namespace FilterTemplateSelectors {
	export const getFilterTemplate = (id: string) => (
		state: { entities: OrmState<any> }
	) => Store.ORM.session(state.entities).FilterTemplate.withId(id);

	export const getFilterTemplates = createSelector(Store.ORM, (session) =>
		session.FilterTemplate.all().toModelArray()
	);
}
