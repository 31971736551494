import BaseDataManager from './base';
import { NurtureOnboardResponse } from '../responses/interface';
import { NurtureOnboardModel } from '@copilot/data/responses/models/nurtureOnboard';
import { NurtureListOptionType, NodeTimerPeriodEnum, NurtureMessageTypeEnum } from '@copilot/common/store/models/const/enum';
import { OnboardTemplateStep } from '@copilot/common/pages/onboard/wizard/messaging/const';
import { NurtureMessagesExamples } from '@copilot/common/components/wizard/steps/nurture/const';

const DefaultNurtureListTags = ['Interested First Reply'];

const DEFAULT_FIRST_MESSAGE_EXAMPLE = 'Check In 1';
const DEFAULT_SECOND_MESSAGE_EXAMPLE = 'Check In 1';

const InitiateMessages = [{
	period: NodeTimerPeriodEnum.Months,
	text: NurtureMessagesExamples.CheckIn[OnboardTemplateStep.Open][DEFAULT_FIRST_MESSAGE_EXAMPLE],
	time: 1,
}, {
	period: NodeTimerPeriodEnum.Weeks,
	text: NurtureMessagesExamples.CheckIn[OnboardTemplateStep.FollowUps][DEFAULT_SECOND_MESSAGE_EXAMPLE],
	time: 1,
}];

const convertToModel = (r: NurtureOnboardResponse): NurtureOnboardModel => ({
	...r,
	tags: r.tags?.length ? r.tags : DefaultNurtureListTags,
	messages: r.messages ?? InitiateMessages,
	messagingStrategy: r.messagingStrategy || NurtureMessageTypeEnum.CheckIn,
	firstMessageExampleKey: r.firstMessageExampleKey ?? DEFAULT_FIRST_MESSAGE_EXAMPLE,
	secondMessageExampleKey: r.secondMessageExampleKey ?? DEFAULT_SECOND_MESSAGE_EXAMPLE,
});

class NurtureOnboardManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get the nurture onboard object for the campaign
	 * @param {string} campaignId id of the campaign we want to get the nurture onboard object for
	 */
	getNurtureOnboard = (campaignId: string): Promise<NurtureOnboardModel> => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.NurtureOnboard, campaignId);
		return this.RequestManager.get<NurtureOnboardResponse>(url).then((r) => convertToModel(r.data));
	};

	/**
	 * Updates the nurture onboard object for the given id
	 * @param {string} campaignId id of campaign we are updating
	 * @param {string} campaignMemberId the campaign member we are updating for
	 * @param {NurtureOnboardModel} onboard data we want to update the nurture onboard object with
	 * @param {string | null} orgMemberId org member id (needed for impersonation - only for cs admin)
	 */
	updateNurtureOnboard = (
		campaignId: string,
		campaignMemberId: string,
		onboard: NurtureOnboardModel,
		orgMemberId: string | null = null
	): Promise<NurtureOnboardModel> => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.NurtureOnboard,
			campaignId,
			'member',
			campaignMemberId
		);
		return this.RequestManager.post<NurtureOnboardResponse>(url, onboard, { params: { orgMemberId } }).then((r) => convertToModel(r.data));
	};

	/**
	 * Gets the number of old connections
	 * @param {string | null} orgMemberId organization member id of impersonated user
	 */
	getNumOldConnections = (orgMemberId: string | null = null) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.LINKED_IN.NurtureOnboard, 'list', 'count');
		const onboard: Partial<NurtureOnboardModel> = {
			linkedInNurtureListType: NurtureListOptionType.Old,
		};
		return this.RequestManager.post<number>(url, onboard, { params: { orgMemberId } }).then((r) => r.data);
	};

	/**
	 * Gets the number of connections who never replied
	 * @param {string | null} orgMemberId organization member id of impersonated user
	 */
	getNumNeverRepliedConnections = (orgMemberId: string | null = null) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.LINKED_IN.NurtureOnboard, 'list', 'count');
		const onboard: Partial<NurtureOnboardModel> = {
			linkedInNurtureListType: NurtureListOptionType.NeverReplied,
		};
		return this.RequestManager.post<number>(url, onboard, { params: { orgMemberId } }).then((r) => r.data);
	};

	/**
	 * Gets the number of connections with the given tag
	 * @param {string[]} tags tags we want to check for
	 * @param {string | null} orgMemberId organization member id of impersonated user
	 */
	getNumTagConnections = (tags: string[], orgMemberId: string | null = null) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.LINKED_IN.NurtureOnboard, 'list', 'count');
		const onboard: Partial<NurtureOnboardModel> = {
			linkedInNurtureListType: NurtureListOptionType.Tag,
			tags,
		};
		return this.RequestManager.post<number>(url, onboard, { params: { orgMemberId } }).then((r) => r.data);
	};

	/**
	 * Returns true if nurture onboarding is complete for the given campaign member
	 * @param {string} campaignMemberId campaign member id
	 */
	getIsOnboardingComplete = (campaignMemberId: string) => {
		if (!campaignMemberId) return Promise.reject('No campaignMemberId is given');
		const url = this.combineRoute(this.BACKEND_ROUTES.LINKED_IN.NurtureOnboard, 'campaignMember', campaignMemberId);
		return this.RequestManager.get<boolean>(url).then((r) => r.data);
	};

	/**
	 * Set value for nurture onboarding completion status
	 * @param {string} campaignMemberId campaign member id
	 * @param {boolean} isOnboarded whether we want to set onboarding to complete (true) or incomplete (false)
	 */
	setIsOnboardingComplete = (campaignMemberId: string, isOnboarded = true) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.LINKED_IN.NurtureOnboard, 'campaignMember', campaignMemberId, 'onboarded');
		const params = { isOnboarded };
		return this.RequestManager.post<boolean>(url, null, { params }).then((r) => r.data);
	};
}

const instance = new NurtureOnboardManager();
export default instance;
