import React from 'react';
import { Card, Steps, Button } from 'antd';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';

const DEFAULT_STEP_WIDTH = 180;

interface IStyledStepProps {
	stepWidth: number;
}

const StyledStep = styled(Steps.Step).withConfig({
	shouldForwardProp: (prop) => !['stepWidth'].includes(prop),
})<IStyledStepProps>`
	${(props) => {
		const prefix = props.theme['@ant-prefix'];
		return `
			&&& .${prefix}-steps-item-icon {
				margin-left: ${(props.stepWidth - 32) / 2}px;
			}
			&&& .${prefix}-steps-item-content {
				width: ${props.stepWidth}px;
			}
			&&& .${prefix}-steps-item-tail {
				margin-left: ${props.stepWidth / 2}px;
			}
		`;
	}}
`;

interface IInstructionStepsProps {
	title: string;
	steps: { title: string, description?: string }[];
	stepWidth?: number;
	showShadow?: boolean;
	buttonLabel?: string;
	onButtonClick?: () => void;
}

/**
 * Set of steps to be displayed as instructions with a button to get started
 * @param {string} title title for the instructions
 * @param {object[]} steps an array of steps, each with a title and description
 * @param {number} stepWidth width of each step text
 * @param {boolean} showShadow whether we want to show a shadow around the card box
 * @param {string} buttonLabel label for the optional call to action button
 * @param {function} onButtonClick called when the button is clicked
 */
const InstructionSteps: React.FC<IInstructionStepsProps> = (props) => {
	const {
		title, steps, stepWidth = DEFAULT_STEP_WIDTH, showShadow = false, buttonLabel, onButtonClick,
	} = props;

	return (
		<Card style={{ textAlign: 'center', padding: '24px 40px', boxShadow: (showShadow ? '0 0 20px 0 #0000000d' : 'none') }}>
			<h3 style={{ marginBottom: '24px' }}>{title}</h3>
			<Steps labelPlacement="vertical" current={0}>
				{steps.map((step, index) => (
					<StyledStep
						key={`${index} ${step.title}`}
						title={<Title level={4}>{step.title}</Title>}
						description={<p style={{ fontSize: '14px' }}>{step.description}</p>}
						stepWidth={stepWidth}
					/>
				))}
			</Steps>
			{buttonLabel && onButtonClick && (
				<Button type="primary" onClick={onButtonClick} style={{ marginTop: '24px' }}>
					{buttonLabel}
				</Button>
			)}
		</Card>
	);
};

export default InstructionSteps;
