import BaseDataManager, { QueryObject } from './base';
import {
	CampaignResponse,
	PaginatedResponse,
	EmptyResponse,
	ConnectionResponse,
	SequenceNodeResponse,
	ServiceSchedule,
	CampaignOnboardDetails,
	SearchCriteria,
	CampaignType,
	ConnectionMigrationResponse,
	CampaignPauseResponseDTO,
} from '../responses/interface';
import { DailyAllocationType } from '@copilot/common/utils/constant';
import { CampaignsFilterRequestModel, CampaignStatusEnum, FiltersRequestModel } from '../requests/models';
import _ from 'lodash';

class CampaignManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get all campaigns based on the org id
	 * @param {string} orgId
	 */
	public getCampaignsByOrganization = (
		orgId: string,
		page = 0,
		pageSize = 10,
		searchTerm?: string,
		filters?: Partial<CampaignsFilterRequestModel>
	) => {
		const combinedUrl = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			orgId,
			'campaigns'
		);
		const url = this.paginate(combinedUrl, page, pageSize);
		const request: Partial<FiltersRequestModel> = { FilterBy: filters };
		return this.RequestManager.post<PaginatedResponse<CampaignResponse>>(url, request, { params: { searchTerm } }).then(
			(response) => response.data
		);
	};

	/**
	 * Get all Linkedin campaigns based on the org id
	 * @param {string} orgId
	 */
	public getLinkedInCampaignsByOrganization = (
		orgId: string,
		page = 0,
		pageSize = 10
	) => {
		const combinedUrl = this.combineRoute(
			this.BACKEND_ROUTES.LINKED_IN.OrgInfo,
			orgId,
			'campaigns'
		);
		const url = this.paginate(combinedUrl, page, pageSize);
		return this.RequestManager.get<PaginatedResponse<CampaignResponse>>(url).then(
			(response) => response.data
		);
	};

	/**
	 * Get a campaign information based on the campaign id
	 * @param {string} id The id of the campaign we get
	 */
	public getCampaignByCamapignId = (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, id);
		return this.RequestManager.get<CampaignResponse>(url).then((r) => r.data);
	};

	/**
	 * Create a campaign for a teams user
	 * @param {string} name Name of the campaign
	 * @param {CampaignType} type Type of the campaign
	 * @returns {Promise<CampaignResponse>} A promise resolving the created the campaign
	 */
	public createCampaign = (name: string, type: CampaignType) => {
		const campaign = { name, type };
		return this.RequestManager.post<CampaignResponse>(
			this.BACKEND_ROUTES.CAMPAIGN.Create,
			campaign
		).then((response) => response.data);
	};

	/**
	 * Rename a campaign
	 * @param {string} id The id of the campaign we want to rename
	 * @param {string} name The new name of the campaign
	 */
	public renameCampaign = (id: string, name: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, id);
		return this.RequestManager.put<CampaignResponse>(url, { name }).then((r) => r.data);
	};

	/**
	 * Update the status of a campaign
	 * @param {string} id The id of the campaign we want to update
	 * @param {CampaignStatusEnum} status The status of the campaign we want to update to
	 */
	public toggleCampaignStatus = (id: string, status: CampaignStatusEnum) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.UpdateStatus, id, 'enable');
		const data = { enable: status !== CampaignStatusEnum.Disabled };
		return this.RequestManager.post<EmptyResponse>(url, null, { params: data }).then(
			(r) => r.data
		);
	};

	/**
	 * Update a campaign
	 * @param {string} id The id of the campaign
	 * @param {Partial<CampaignResponse>} updates The updates we want to apply
	 */
	public updateCampaign = (id: string, updates: Partial<CampaignResponse>) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, id);
		return this.RequestManager.put<CampaignResponse>(url, updates).then((r) => r.data);
	};

	/**
	 * Convert boolean to CampaignStatus Enum
	 *
	 */
	convertBooltoStatus(status: boolean) {
		if (status) {
			return CampaignStatusEnum.Enabled;
		} else {
			return CampaignStatusEnum.Disabled;
		}
	}

	/**
	 * Get connections for a campaign member
	 * @param {string} id The id of the campaign
	 * @param {string} memberId The id of the campaign member
	 * @param {QueryObject} query The query object we will use for the request
	 */
	public getConnections = (
		id: string,
		memberId: string,
		query: QueryObject = new QueryObject()
	) => {
		const route = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			id,
			'member',
			memberId,
			'connections'
		);
		const url = this.paginate(route, query);
		return this.RequestManager.get<PaginatedResponse<ConnectionResponse>>(url, {
			params: { ...query.toQueryParam() },
		}).then((r) => r.data);
	};

	/**
	 * Get connections for a campaign for admins
	 * @param {string} id The id of the campaign
	 * @param {QueryObject} query The query object we will use for the request
	 */
	public getConnectionsForAdmin = (id: string, query: QueryObject = new QueryObject()) => {
		const route = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.LinkedIn, id, 'connections');
		const url = this.paginate(route, query);
		return this.RequestManager.get<PaginatedResponse<ConnectionResponse>>(url, {
			params: { ...query.toQueryParam() },
		}).then((r) => r.data);
	};

	/**
	 * Get connections for a campaign based on connection id
	 * @param {string} id connection id
	 */
	public getConnectionById = (id: string) => {
		const route = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.LinkedIn, 'connections', id);
		return this.RequestManager.get<ConnectionResponse>(route).then((r) => r.data);
	};

	/**
	 * Delete a campaign
	 * @param {string} id The id of the campaign we want to delete
	 */
	public deleteCampaign = (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, id);
		return this.RequestManager.delete(url).then((r) => r.data);
	};

	/**
	 * Clone campaign
	 * @param {string} id The id of the campaign
	 * @param {string} name name of campaign
	 * @param {string} orgId org Id
	 */
	public cloneCampaign = (id: string, name: string): Promise<string> => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, id, 'clone');
		return this.RequestManager.put(url, null, { params: { name } }).then((r) => r.data);
	};

	/**
	 * retrieve pause status for user
	 * @param orgMemberId org member id
	 * @returns {Promise<string>} date service is paused til, empty if not paused
	 */
	getPauseService(orgMemberId: string) {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
			orgMemberId,
			'pause'
		);

		return this.RequestManager.get<CampaignPauseResponseDTO>(url).then((response) => {
			const dateObj = response.data;
			return dateObj;
		});
	}

	/**
	 * pause service for user
	 * @param orgMemberId org member id
	 * @param date pause till date
	 * @returns {Promise<string>} date service is paused til, empty if not paused
	 */
	pauseService(orgMemberId: string, date: string) {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
			orgMemberId,
			'pause'
		);

		const param = { unpauseDate: date };
		return this.RequestManager.post<CampaignPauseResponseDTO>(url, param)
			.then((response) => {
				const dateObj = response?.data;
				return dateObj;
			})
			.catch((error) => {
				const responseData = error.response.data;
				if (_.isString(responseData) && responseData !== '') {
					throw new Error(responseData);
				} else {
					throw new Error('Please refresh the page and try again');
				}
			});
	}

	/**
	 * unpause service for user
	 * @param orgMemberId org member id
	 * @returns {Promise<string>} date service is paused til, empty if not paused
	 */
	unpauseService(orgMemberId: string) {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
			orgMemberId,
			'unpause'
		);

		return this.RequestManager.post(url).then((response) => {
			const date = response.data;
			return date;
		});
	}

	/**
	 * Get the sequence nodes of a campaign
	 * @param {string} id The id of the campaign
	 */
	public getSequenceNodes = (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, id, 'nodes');
		return this.RequestManager.get<SequenceNodeResponse[]>(url).then((r) => r.data);
	};

	/**
	 *
	 * @param {string} campaignId Id of the campaign we want to update
	 * @param {Partial<SequenceNodeModel>} node The updates we want to make
	 * @param {DataManagerOptions} options
	 * @returns {Promise<CampaignModel>} A promise resolving the updated node
	 */
	updateNode(campaignId: string, node: SequenceNodeResponse) {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, campaignId, 'nodes');
		return this.RequestManager.put<SequenceNodeResponse>(url, node).then((response) => {
			const { data } = response;
			return data;
		});
	}

	createNode(campaignId: string, node: SequenceNodeResponse) {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, campaignId, 'nodes');
		return this.RequestManager.post<SequenceNodeResponse>(url, node).then((response) => {
			const { data } = response;
			return data;
		});
	}

	deleteNode(campaignId: string, nodeId: string) {
		if (!campaignId || campaignId.length === 0 || !nodeId || nodeId.length === 0)
			return Promise.reject(new Error());
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.Default,
			campaignId,
			'nodes',
			nodeId
		);
		return this.RequestManager.delete(url).then((response) => {
			const { data } = response;
			return data;
		});
	}

	/**
	 * Create a service schedule for the campaign
	 * @param {string} id The id of the campaign
	 * @param {ServiceSchedule} schedule the new campaign schedule
	 */
	public createSchedule = async (id: string, schedule: Omit<ServiceSchedule, 'id'>) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, id, 'serviceschedule');

		const response = await this.RequestManager.post<ServiceSchedule>(url, schedule);
		if (response?.data && typeof response?.data === 'object') {
			return response.data;
		} else {
			return Promise.reject();
		}
	};

	/**
	 * Get the schedule for the given campaign
	 * @param {string} id The id of the campaign
	 */
	public getSchedule = async (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, id, 'serviceschedule');
		const response = await this.RequestManager.get<ServiceSchedule>(url);
		if (response?.data && typeof response?.data === 'object') {
			return response.data;
		} else {
			return Promise.reject();
		}
	};

	/**
	 * Delete Campaign Schedule for the given campaign
	 * @param {string} id The id of the campaign
	 */
	public deleteSchedule = async (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, id, 'serviceschedule');
		const response = await this.RequestManager.delete(url);
		return response.data;
	};

	//#region Multi-compaigns end-points
	/**
	 * Create a campaign for an individual user
	 * @param {string} name Name of the campaign
	 * @param {CampaignType} type type of campaign
	 * @param {string} orgMemberId orgMemberId of Impersonated user
	 * @param {boolean} isOnboarded whether we want to have onboarding in the new campaign
	 * @returns {Promise<CampaignResponse>} A promise resolving the created the campaign
	 */
	public createCampaignForIndividual = (
		name: string,
		type: CampaignType,
		orgMemberId = '',
		isOnboarded = true
	) => {
		const campaign = { name, type };
		const params = orgMemberId != '' ? { o: orgMemberId, isOnboarded } : { isOnboarded };
		return this.RequestManager.post<CampaignResponse>(
			this.BACKEND_ROUTES.CAMPAIGN.IndividualUserCreate,
			campaign,
			{ params }
		).then((response) => {
			if (response) {
				return response.data;
			} else {
				return Promise.reject();
			}
		});
	};

	/**
	 * Create a campaign for an individual user
	 * @param {string} name Name of the campaign
	 * @returns {Promise<CampaignOnboardDetails>} A promise resolving campaign's onboard state
	 */
	public getCampaignOnboardStateForIndividual = (campaignId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Onboard, campaignId);
		return this.RequestManager.get<CampaignOnboardDetails>(url).then((response) => ({
			...response.data,
			id: campaignId,
		}));
	};

	/**
	 * Update Campaign's onboard state for with the data
	 * @param {onboardState} onboardState The new campaign's onboard state
	 * @returns {Promise<CampaignOnboardDetails>} A promise resolving the campaign's onboard state
	 *                                            with the update
	 */
	public updateCampaignOnboardStateForIndividual = (
		campaignId: string,
		onboardDetails: Partial<CampaignOnboardDetails>
	) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Onboard, campaignId);
		return this.RequestManager.post<Partial<CampaignOnboardDetails>>(
			url,
			onboardDetails
		).then(() => ({ ...onboardDetails, id: campaignId }));
	};

	/**
	 * Update Campaign's invite/message allocation
	 * @param {string} orgMemberId The org Member Id
	 * @param {object} allocations object with {campaignId: value} pairs
	 * @param {DailyAllocationType} allocationType Type of allocation
	 * @returns {Promise<{[campaignId: string]: number}>} The updated invite/message distribution
	 *                                                    across different campaigns
	 */
	public updateCampaignAllocation = (
		orgMemberId: string,
		allocations: { [k: string]: number },
		allocationType: DailyAllocationType
	) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.LinkedIn, 'allocate');
		return this.RequestManager.put<EmptyResponse>(url, {
			orgMemberId,
			allocations,
			dailyAllocationType: allocationType,
		}).then(
			() => allocations
		);
	};

	//#endregion Multi-campaign end-points
	/**
	 * Retrieve search criteria for a campaign
	 * @param campaignId The campaign we want to retrieve search criteria for
	 * @returns {SearchCriteria[]} The list of search criteria models for this campaign
	 */
	getSearchCriterias(campaignId: string) {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.Default,
			campaignId,
			'licriterias'
		);
		return this.RequestManager.get<SearchCriteria[]>(url).then((r) => r.data);
	}

	/**
	 * Migrate selected connections to the target campaign
	 * @param {string} campaignId target campaignId we are migrating connections to
	 * @param {string} campaignMemberId target campaignMemberId of orgMember running the migration
	 * @param {string[]} connectionIds connectionIds we are migrating to target campaign
	 * @param {Partial<FiltersRequestModel>} connectionsFilter filters to apply to get connections to migrate
	 * @param {boolean} [archive] true when we migrate prospect through inbox cardview and want to archive message from the cardview
	 * @param {string} [omid] orgMemberId of Impersonated user
	 */
	public migrateConnections = (campaignId: string, campaignMemberId: string, connectionIds: string[], connectionsFilter?: Partial<FiltersRequestModel>, archive?: boolean, omid?: string) => {
		if (campaignId === '' || campaignMemberId === '' || (connectionIds.length === 0 && !connectionsFilter)) return Promise.reject(new Error());
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'member',
			campaignMemberId,
			'connections',
			'migrate'
		);

		return this.RequestManager.post<ConnectionMigrationResponse>(url, { connectionIds, connectionsFilter }, { params: { omid, archive } }).then((r) => r.data);
	};
}

const instance = new CampaignManager();
export default instance;
