import { FilterModel } from '@copilot/data/requests/models';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import { AvailableFilters } from '@copilot/common/utils/constant';
import { Button } from 'antd';
import locations, { ALL_LOCATIONS } from '@copilot/common/data/locations';
/**
 * Generate the FilterModel for non boolean filters based on the isExclude property
 */
export const getFilterModel = (filterName: string, filter: FilterDefinition[]) => {
	const useName = filterName === AvailableFilters.TagName || filterName === AvailableFilters.Location;
	const filterRequest: FilterModel = { include: [], exclude: [] };
	filter.forEach((f) => {
		if (f.isVisible && !f.isExclude) {
			filterRequest.include.push(useName ? f.label : f.key);
		} else if (f.isVisible && f.isExclude)
			filterRequest.exclude.push(useName ? f.label : f.key);
	});
	return filterRequest;
};

/**
 * Generate the FilterRequest Model for string filters
 */
export const createStringFilterRequest = (filterStrings: string[]): FilterModel | undefined => ({
	include: filterStrings,
	exclude: [],
});

/**
 * Generate the FilterRequest Model for boolean filters
 */
export const createBooleanFilterRequest = (filterStatus: boolean): FilterModel | undefined => {
	if (!filterStatus) return undefined;

	return createStringFilterRequest([`${filterStatus ? 'True' : 'False'}`]);
};

/**
 * Generate the FilterRequest Model for boolean exclude filters (mainly used for meetingBooked filter button right now)
 */
export const createBooleanExcludeFilterRequest = (selectedFilter: FilterDefinition): FilterModel | undefined => {
	if (!selectedFilter.isVisible) return undefined;

	return !selectedFilter.isExclude ? ({
		include: ['True'],
		exclude: [],
	}) : ({
		include: [],
		exclude: ['True'],
	});
};

/**
 * Generate the FilterRequest Model for number filters
 */
export const createNumberFilterRequest = (filterStatus: number): FilterModel | undefined =>
	createStringFilterRequest([`${filterStatus}`]);

/**
 * Sort tags by filtered tags in table columns
 */
export const sortByFilteredTags = (tags: JSX.Element[], filteredTags: Set<string>) =>
	tags.sort((a, b) => {
		const aPropsChildren: string = a.props.children;
		const bPropsChildren: string = b.props.children;
		if (filteredTags.has(aPropsChildren) && !filteredTags.has(bPropsChildren)) {
			return -1;
		} else if (!filteredTags.has(aPropsChildren) && filteredTags.has(bPropsChildren)) {
			return 1;
		} else return 0;
	});

export const createBooleanFilterButton = (
	onClick: () => void,
	hasToggled: boolean,
	title: string
) => (
	<Button onClick={onClick} type={hasToggled ? 'primary' : 'default'} ghost={hasToggled}>
		{title}
	</Button>
);

/**
 * Return a new FilterDefinition for location with key as `state_city`
 * Does not set isVisible property
 * @param state String for a state for which to create the location
 * @param city city in the state. Can also be 'Whole State' or 'Whole Province' like in data/locations.ts
 * @param dipslayLabel String label used to display the location on UI
 */
export const createFilterDefinitionForLocation = (state: string, city: string, dipslayLabel: string) => (
	new FilterDefinition({
		key: `${state}_${city}`,
		label: dipslayLabel,
	})
);

/**
 * Creates FilterDefinition for the state and ALL of its cities (includes the state as well)
 * @param state String used to add a state
 * @param cities A list used to add cities corresponding to the state
 */
export const addStateAndCities = (state: string, cities: string[]) => {
	let newLocations: FilterDefinition[] = [];

	cities.forEach((city) => {
		if (ALL_LOCATIONS.includes(state) && ALL_LOCATIONS.includes(city)) {
			newLocations = [];
		} else if (ALL_LOCATIONS.includes(city)) {
			newLocations.push(createFilterDefinitionForLocation(state, city, state));
		} else {
			newLocations.push(createFilterDefinitionForLocation(state, city, city));
		}
	});

	return newLocations;
};

/**
 * Returns all the states and cities in data/locations.ts as FilterDefinition
 */
export const initAllLocations = () => (
	Object.keys(locations.us_cities)
		.map((state) => addStateAndCities(state, (locations.us_cities as TypedObject<string[]>)[state]))
		.reduce((accumulator, currentValue) => [...accumulator, ...currentValue])
);

/**
 * If the state is a 'Whole State' or 'Whole Province', then marks each city and state as visible.
 * Otherwise, marks the city as visible
 * @param location List to update the isVisible property of FilterDefintion
 * @param state String used to search a state
 * @param city String used to search a city
 */
export const addVisibilty = (location: FilterDefinition[], state: string, city: string) => {
	let updatedLocation = [...(location ?? [])];

	if (ALL_LOCATIONS.includes(city) && ALL_LOCATIONS.includes(state)) {
		updatedLocation = [];
	} else if (ALL_LOCATIONS.includes(city)) {
		updatedLocation.forEach((locationFilter: FilterDefinition) => {
			const currentState = locationFilter.key.split('_')[0];
			if (currentState === state) {
				locationFilter.isVisible = true;
			}
		});
	} else {
		updatedLocation.forEach((locationFilter: FilterDefinition) => {
			const currentState = locationFilter.key.split('_')[0];
			const currentCity = locationFilter.key.split('_')[1];
			if (currentState === state && currentCity === city) {
				locationFilter.isVisible = true;
			}
		});
	}
	return updatedLocation;
};
