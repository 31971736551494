import AiFeatureIcon from '@copilot/common/components/icon/aiFeatureIcon';
import { isEmptyString } from '@copilot/common/utils/common';
import { Button, Col, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FC, useState } from 'react';
import styled from 'styled-components';

export type PromptGenerateAreaPropsType = Readonly<{
	/**
	 * callback when the user submit a custom prompt to generate
	 * @param prompt
	 */
	onSubmitCustomPrompt: (prompt: string) => void;
	/**
	 * callback when the user discard their prompts
	 */
	onCancel: () => void;
	/** 
     * Placeholder text for textbox
     */
	placeholderText: string;
	/** 
     * Submit button text
     */
	submitButtonText: string;
}>;

/**
 * [Presentational] Submit a prompt to generate message
 * @param props
 */
export const PromptGenerateArea: FC<PromptGenerateAreaPropsType> = (props) => {
	const { onSubmitCustomPrompt, onCancel, placeholderText, submitButtonText } = props;
	const [customPrompt, setCustomPrompt] = useState<string>('');
	return (
		<div style={{ padding: '10px 14px' }}>
			<Row>
				<Col span={24}>
					<StyledTextArea
						placeholder={placeholderText}
						value={customPrompt}
						onChange={({ target }) => { setCustomPrompt(target.value ?? ''); }}
						autoSize={{ minRows: 5, maxRows: 5 }}
						onPressEnter={() => onSubmitCustomPrompt(customPrompt)}
						autoFocus
					/>
				</Col>
			</Row>

			<Row gutter={[24, 0]} style={{ marginTop:24 }}>
				<Col span={12}>
					<Button
						block
						type='default'
						onClick={() => onCancel()}
					>
						Cancel
					</Button>
				</Col>

				<Col span={12}>
					<StyledButton 
						block
						type='primary'
						onClick={() => onSubmitCustomPrompt(customPrompt)}
						disabled={isEmptyString(customPrompt)}
					>
						<Space size={3}>
							<AiFeatureIcon color={isEmptyString(customPrompt) ? 'grey' : 'white'} />
							{submitButtonText}
						</Space>
					</StyledButton>
				</Col>
			</Row>		
		</div>
	);
};

const StyledButton = styled(Button)`
	&.${props => props.theme['@ant-prefix']}-btn[disabled] {
		svg > path {
			fill: ${props => props.theme['@disabled-color']};
			transition: 0.3s ease-in-out;
		}
  }
`;

const StyledTextArea = styled(TextArea)`
	width: 388px;
	height: 100%;
	padding: 8px 12px;
	
	outline: none;
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    background: #FFF;

	color: black;

	::placeholder {
		color: ${props => props.theme['@Gray/7']};
	}
`;