import React, {
	FC, useContext, ReactNode, ReactComponentElement, cloneElement,
} from 'react';
import { Col, Row } from 'antd';
import { WizardContext } from '@copilot/common/components/wizard/context';
import { isReactElement } from '@copilot/common/utils';

interface IWizardStepMainContentProps {
	title?: string;
	description?: ReactNode;
}

/**
 * Main content for a wizard step
 * @param {string} title title of the step content
 * @param {ReactNode} description description of the step content
 */
const WizardStepMainContent: FC<IWizardStepMainContentProps> = (props) => {
	const { title, description, children } = props;
	const { onNodeChange } = useContext(WizardContext);
	return (
		<>
			{!!title && (
				<Row>
					<Col>
						<h2>{title}</h2>
					</Col>
				</Row>
			)}
			{!!description && (
				<Row>
					<Col>
						{description}
					</Col>
				</Row>
			)}
			<Row style={{ marginTop: (title || description) ? '24px' : 0 }}>
				<Col span={24}>
					{isReactElement(children) && cloneElement(children, { setCurrentStep: onNodeChange })}
				</Col>
			</Row>
		</>
	);
};

/**
 * Optional rightmost section for displaying additional information about the step
 */
const WizardStepInfo: FC<{}> = (props) => <>{props.children}</>;

/**
 * Optional footer section for the wizard step
 */
const WizardStepFooter: FC<{}> = (props) => <>{props.children}</>;

interface IWizardStepStaticProps {
	MainContent: typeof WizardStepMainContent;
	Info: typeof WizardStepInfo;
	Footer: typeof WizardStepFooter;
}

interface IWizardStepProps {
	id: number;
	title: string;
	description?: ReactNode;
	isSidebarHidden?: boolean;
	isDisabled?: boolean;
	isAlwaysSaved?: boolean;
}

const getWizardStepSubComponents = (children: React.ReactNode) => {
	let mainContent: React.ReactNode = null;
	let info: React.ReactNode = null;
	let footer: React.ReactNode = null;
	React.Children.map(children, (child) => {
		if (isReactElement(child)) {
			switch (child.type) {
				case WizardStepMainContent:
					mainContent = child;
					break;
				case WizardStepInfo:
					info = child;
					break;
				case WizardStepFooter:
					footer = child;
					break;
			}
		}
	});
	return { mainContent, info, footer };
};

/**
 * Wrapper around a step in the Wizard
 * @param {number} id id of the wizard step
 * @param {string} title (for Wizard Component) title of the step to be displayed in the sidebar
 * @param {string} description (for Wizard Component) description of the step to be displayed in the sidebar
 * @param {boolean} isSidebarHidden (for Wizard Component) true if the sidebar should be hidden for this step
 * @param {boolean} isDisabled (for Wizard Component) true if the step should be disabled
 * @param {boolean} isAlwaysSaved (for Wizard Component) true if we want the save status to always be saved
 */
const WizardStep: React.FC<IWizardStepProps> & IWizardStepStaticProps = (props) => {
	const { id, children } = props;
	const { mainContent, info, footer } = getWizardStepSubComponents(children);
	const { currentNode } = useContext(WizardContext);

	return (
		<>
			{currentNode === id && (
				<Row wrap={false} gutter={24}>
					<Col span={info ? 18 : 24} style={{ paddingRight: info ? '40px' : undefined }}>
						<Row>
							<Col span={24}>
								{mainContent}
							</Col>
						</Row>
						{!!footer && (
							<Row style={{ marginTop: '64px' }}>
								<Col span={24}>
									{footer}
								</Col>
							</Row>
						)}
					</Col>
					{!!info && (
						<Col span={6}>
							<Row>
								<Col span={24} style={{ padding: '0 12px' }}>
									{info}
								</Col>
							</Row>
						</Col>
					)}
				</Row>
			)}
		</>
	);
};

WizardStep.MainContent = WizardStepMainContent;
WizardStep.Info = WizardStepInfo;
WizardStep.Footer = WizardStepFooter;

/**
 * Type Guard for WizardStep
 * @param {ReactNode} element element to check for
 */
export const isWizardStep = (element: ReactNode): element is ReactComponentElement<typeof WizardStep> => isReactElement(element) && element.type === WizardStep;

export default WizardStep;
