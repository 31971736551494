import Peer from 'simple-peer';
import { HubConnection } from '@microsoft/signalr';
import { isUndefined } from 'lodash';

export class PeerConnection {
	private conn: HubConnection;
	public initiatorConn?: Peer.Instance;
	public clientConn?: Peer.Instance;
	private localStream: any;
	private options: Peer.Options;
	private onConnectCb: any;
	private onCloseCb: any;
	private onDataCb: any;
	private onStreamCb: any;

	constructor(conn: HubConnection, options: Peer.Options) {
		this.conn = conn;
		this.options = options;
	}
    
	public setlocalStream(stream: any) {
		this.localStream = stream;
	}
    
	public startInitiator(roomName: string) {
		this.createConnection(true, roomName);
	}

	public startPeer(roomName: string) {
		this.createConnection(false, roomName);
	}
    
	private createConnection(isInitiator: boolean, roomName: string) {
		const options: Peer.Options = this.getOptions(isInitiator);
		const peer: Peer.Instance = new Peer(options);
    
		peer.on('data', (d: BufferSource) => { console.log(d); this.handleData(d);});
		peer.on('signal', (data) => this.sendSignal(data, roomName));
		peer.on('connect', () => { if (this.onConnectCb) this.onConnectCb(); });
		peer.on('error', (err) => { console.log(err); });
		peer.on('stream', (stream) => { if (this.onStreamCb) this.onStreamCb(stream);});
		peer.on('close', () => { if (this.onCloseCb) this.onCloseCb(); });
		if (isInitiator)
			this.initiatorConn = peer;
		else 
			this.clientConn = peer;

	}
    
    
	public setEventCallback(event:string, callback:any) {
		switch (event) {
			case 'connect':
				this.onConnectCb = callback;
				break;
			case 'data':
				this.onDataCb = callback;
				break;
			case 'stream':
				this.onStreamCb = callback;
				break;
			case 'close':
				this.onCloseCb = callback;
				break;
		}
	}
    
	public sendData(data: any) {
		const msg = JSON.stringify({ data: data, userId: this.conn.connectionId });
		this.initiatorConn?.write(msg);
		this.clientConn?.write(msg);
	}
    
	public terminateSession() {
		this.initiatorConn?.destroy();
		this.clientConn?.destroy();
		this.conn.stop();
	}
    
	private getOptions(isInitiator: boolean):Peer.Options {
		const options:Peer.Options = {
			initiator: isInitiator,
		};
    
		if (!isUndefined(this.localStream)) {
			options.stream = this.localStream;
		}
    
		Object.assign(options, this.options);
		
		return options;
	}
    
	private sendSignal(data: any, roomName: string) {
		console.log('Data: ' + JSON.stringify(data) + ' roomName: ' + roomName);
		this.conn.invoke('SendSignal', roomName, JSON.stringify(data));
	}
    
	private handleData(data:BufferSource) {
		const decodedString: string = new TextDecoder('utf-8').decode(data);
		const decodedJSON = JSON.parse(decodedString);
		this.onDataCb(decodedJSON);
	}
    
    

    
}