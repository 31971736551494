import {
	AccountCreationHookType,
	AccountCreationInput,
	AccountCreationResponseModel,
	isAccountCreationResponse,
} from './accountCreationTypes';
import { useState } from 'react';
import { isUndefined } from 'lodash';
import {
	useAddAccountMutation,
	useUpdateAccountManagerMutation,
} from '@copilot/data/graphql/_generated';
import { AccountManager, OrganizationManager } from '../../../../../core/data/index';
import { OrgRoleTitles } from '../../../store/models/const/enum';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/index';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

/**
 * Hook for creating new accounts
 */
export function useAccountCreation(): AccountCreationHookType {
	const [addAccountMutation] = useAddAccountMutation();
	const [updateAccountManager] = useUpdateAccountManagerMutation();
	const [loading, setLoading] = useState(false);

	const parentAccountId = useSelector((state: RootState) => state.app.tenant);

	async function createAccount(
		input: AccountCreationInput
	): Promise<AccountCreationResponseModel> {
		try {
			setLoading(true);

			if (isUndefined(parentAccountId)) {
				throw new Error("Unable to fetch current user's account ID");
			}

			// Create account...

			const response = await addAccountMutation({
				variables: {
					input: {
						accountName: input.accountName,
						parentAccountId,
					},
				},
			});

			const responseData = response.data?.addAccount;
			if (!isAccountCreationResponse(responseData)) {
				throw new Error('Unable to create account');
			}

			// Check if an account Manager was specified
			if (!isNil(input.accountMangerId)) {
				const updateAMResults = await updateAccountManager({
					variables: {
						input: {
							accountId: responseData.id,
							accountManagerUserId: input.accountMangerId,
						},
					},
				});
				if (!isEmpty(updateAMResults.errors)) {
					throw new Error('Encountered an error assigning the account manager');
				}
			}

			// Create primary contact...
			try {
				const membersResponse = await OrganizationManager.addMembers(responseData.orgInfo.id, [
					{
						firstname: input.firstName,
						lastname: input.lastName,
						email: input.email,
						orgRoles: [OrgRoleTitles[input.permission]],
						blockMultipleOrgs: true,
					},
				]);

				if (!isEmpty(membersResponse.errors) || isEmpty(membersResponse.successfulRegistrations)) {
					const errMsg = membersResponse.errors[0]?.message ?? 'Encountered error creating primary contact';
					throw new Error(errMsg);
				} else {
					return {
						accountId: responseData.id,
						accountName: responseData.name,
						orgId: responseData.orgInfo.id,
						userId: membersResponse.successfulRegistrations[0].userId,
					};
				}
			} catch (e) {
				console.error(e);
				//Registration failed, delete the newly created account to prevent having an empty account in dashboard
				//Ideally account creation and first user registration should happen in one request
				await AccountManager.deleteAccount(responseData.id);
				throw e;
			}
		} finally {
			setLoading(false);
		}
	}

	return [createAccount, loading];
}
