import { createReduxSingleEntity } from '@copilot/common/store/common/singleEntity';
import { IUserInterestsModel } from '@copilot/data/responses/models/interests';

export const USER_INTERESTS = 'USER_INTERESTS';

export const {
	reducer: interestsReducer,
	selector: getUserInterests,
	watcher: watchFetchUserInterests,
	action: fetchUserInterests,
} = createReduxSingleEntity<IUserInterestsModel>(
	USER_INTERESTS
);
