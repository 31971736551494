import notificationManager from '@copilot/common/utils/notificationManager';
import type { INotificationConfig } from '@copilot/common/utils/notificationManager';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { useSelector } from 'react-redux';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import React from 'react';
import { AppSelectors } from '@copilot/common/store/selectors';
import { InboxDisplayType } from '@copilot/data/requests/models';

export const withNotification = <T extends Array<any>, U>(
	fn: (...args: T) => Promise<U>,
	successNotification: INotificationConfig = { message: 'Success' },
	errorNotification: INotificationConfig = { message: 'Failed', description: 'Please try again.' }
) => async (...args: T) => {
	try {
		const result = await fn(...args);
		notificationManager.showSuccessNotification(successNotification);
		return result;
	} catch (err) {
		notificationManager.showErrorNotification(errorNotification);
		throw err;
	}
};

type UserInfo = Readonly<{
	organizationId: string;
	activeMember: IOrganizationMember;
}>;

type AppSettings = Readonly<{
	inboxType: InboxDisplayType;
	hideLiSearchVideo: boolean;
	isCampaignAccordionExpanded: boolean;
	isAdvanced: boolean;
}>;

/**
 * Attaches UserInfo to the component.
 * @param Component
 */
export const withUserInfo = <T,>(Component: React.FC<T & UserInfo & { isLoading: boolean }>) => (props: T) => {
	const organizationId = useSelector(OrganizationSelectors.getActiveOrganizationId);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	return (<Component {...props} organizationId={organizationId} activeMember={activeMember!} isLoading={!organizationId || !activeMember} />);
};

const defaultAppSettings: AppSettings = {
	inboxType: InboxDisplayType.Cards,
	hideLiSearchVideo: false,
	isCampaignAccordionExpanded: false,
	isAdvanced: false,
};

/** Attaches AppSetting to the component */
export const withAppSettings = <T extends UserInfo & { isLoading: boolean },>(Component: React.FC<T & { settings: AppSettings }>) => (props: T) => {
	const settings = useSelector(AppSelectors.getSettings);
	return (<Component {...props} settings={{ ...defaultAppSettings, ...settings }} />);
};
