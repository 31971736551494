import Store from '..';
import { createSelector } from 'redux-orm';

export namespace CampaignOnboardSelectors {
	export const getOnboardDetails = (
		state: ReturnType<typeof Store['ReduxStore']['getState']>,
		id: string
	) =>
		createSelector(Store.ORM, (session) => session?.CampaignOnboardDetails.withId(id))(state);
}
