import { StatsResponse } from '@copilot/data/responses/interface';
import { LinkedInStatLabelMap } from '@copilot/common/constant/enum';

export const EXCLUDED_STATS: { [k in keyof Partial<typeof LinkedInStatLabelMap>]: boolean } = {
	AddTag: true,
	SendManualMsg: true,
};

export enum LinkedInCalculatedStatsType {
	ConnectionsOverInvites = 'ConnectionsOverInvites',
	ReplyOverConnections = 'ReplyOverConnections',
	InterestedOverReply = 'InterestedOverReply',
	ReplyOverInvites = 'ReplyOverInvites',
	NurtureReplyOverSent = 'NurtureReplyOverSent',
	NurtureReplyOverNurtureSent = 'NurtureReplyOverNurtureSent'
}

export const LinkedInCalculatedStats = {
	[LinkedInCalculatedStatsType.ConnectionsOverInvites]: 'Connections/Invites',
	[LinkedInCalculatedStatsType.ReplyOverConnections]: 'Reply/New Connections',
	[LinkedInCalculatedStatsType.InterestedOverReply]: 'Interested/Reply',
	[LinkedInCalculatedStatsType.ReplyOverInvites]: 'Reply/Invite',
	[LinkedInCalculatedStatsType.NurtureReplyOverSent]: 'Reply/Messages Sent',
	[LinkedInCalculatedStatsType.NurtureReplyOverNurtureSent]: 'Reply/Messages Sent',
};

export const LinkedInCalculatedStatsLabel = {
	[LinkedInCalculatedStatsType.ConnectionsOverInvites]: 'Connection %',
	[LinkedInCalculatedStatsType.ReplyOverConnections]: 'Prospecting Reply %',
	[LinkedInCalculatedStatsType.InterestedOverReply]: 'Interested %',
	[LinkedInCalculatedStatsType.ReplyOverInvites]: 'Invitation Reply %',
	[LinkedInCalculatedStatsType.NurtureReplyOverSent]: 'Nurture Reply %',
	[LinkedInCalculatedStatsType.NurtureReplyOverNurtureSent]: 'Nurture Reply %',
};

export class StatsModel {
	public types: any[];
	public labels: string[];
	public data: any[][];
	// public times: Date[];
	public rows: any[];
	public totals: number[];

	constructor(stats: PartialBy<StatsModel, 'totals' | 'calculatedRatios'>) {
		this.types = stats.types || [];
		this.labels = stats.labels || [];
		this.data = stats.data || [];
		this.rows = stats.rows || [];
		this.totals = this.getTotals();
	}

	private getTotals = () => {
		const totals: number[] = [];
		this.data.forEach((d) => {
			d.forEach((c, idx) => {
				if (totals.length <= idx) totals.push(0);
				totals[idx] += c;
			});
		});
		return totals;
	};

	static fromResponse = (stats: StatsResponse, includeTagStats = true): StatsModel => {
		const statsMap: { [k: string]: boolean } = {};
		stats.forEach((stat) => {
			Object.keys(stat.actionStats).forEach((s) => {
				statsMap[s] = true;
			});
			if (includeTagStats)
				Object.keys(stat.tagStats).forEach((s) => {
					statsMap[s] = true;
				});
		});

		const labels = Object.keys(statsMap).filter((l) => !(EXCLUDED_STATS as any)[l]);
		const data = stats.map((s) => labels.map((l) => s.actionStats[l] || s.tagStats[l] || 0));
		const rows = stats.map((s) => new Date(s.timestamp.replace('Z', '')));
		return new StatsModel({
			types: labels,
			labels: labels.map((l) => (LinkedInStatLabelMap as any)[l] || l),
			data,
			rows,
		});
	};

	public get calculatedRatios(): { [x: string]: number } {
		const labelTotalsMap = this.labels.reduce<{ [k: string]: number }>((acc, l, idx) => {
			acc[l] = this.totals[idx];
			return acc;
		}, {});
		return {
			[LinkedInCalculatedStats.ConnectionsOverInvites]:
				labelTotalsMap[LinkedInStatLabelMap.LinkedInConnected] /
				labelTotalsMap[LinkedInStatLabelMap.LinkedInInvite],
			[LinkedInCalculatedStats.ReplyOverConnections]:
				labelTotalsMap[LinkedInStatLabelMap.UniqueReplies] /
				labelTotalsMap[LinkedInStatLabelMap.LinkedInConnected],
			[LinkedInCalculatedStats.InterestedOverReply]:
				labelTotalsMap[LinkedInStatLabelMap.ReplyInterested] /
				labelTotalsMap[LinkedInStatLabelMap.UniqueReplies],
			[LinkedInCalculatedStats.ReplyOverInvites]:
				labelTotalsMap[LinkedInStatLabelMap.UniqueReplies] /
				labelTotalsMap[LinkedInStatLabelMap.LinkedInInvite],
		};
	}
}

export class StatFilterDefinition {
	public key: string;
	public label: string;
	public isVisible: boolean;
	public hideable?: boolean;

	constructor(column: any = {}) {
		this.key = column.key || column.Key;
		this.label = column.label || column.Label;
		this.isVisible = column.isVisible || column.IsVisible || false;
		this.hideable = column.hideable === undefined || column.hideable == true;
	}
}
