import React, { useContext } from 'react';
import {
	Col, Row,
} from 'antd';
import BottomPanel from '@copilot/common/components/containers/bottomPanel';
import { CSSProperties, ThemeContext } from 'styled-components';
import { withShadow } from '@copilot/common/hoc/components/withShadow';

interface IBatchActionPanelProps {
	total: number;
	style?: CSSProperties;
}

const BottomPanelWithShadow = withShadow(BottomPanel);

/**
 * [Presentational] Container for Batch Actions
 * @param total number of selected data rows
 * @param style custom styling on batch action panel
 */

const BatchActionPanel: React.FC<IBatchActionPanelProps> = (props) => {
	const themeContext = useContext(ThemeContext);
	const { total, style } = props;

	return (
		<>
			<div style={{ height: '70px', background: themeContext['@layout-body-background'], ...style }} />
			<BottomPanelWithShadow>
				<Row justify="space-between" style={{ padding: '10px', alignItems: 'center' }}>
					<Col span={20}>
						<Row align="middle" gutter={[16, 0]}>
							<Col>
								<span>Batch Actions:</span>
							</Col>
							{props.children}
						</Row>
					</Col>
					<Col span={4}>
						<span>
							Selected:
							{' '}
							{total}
						</span>
					</Col>
				</Row>
			</BottomPanelWithShadow>
		</>
	);
};

export default BatchActionPanel;
