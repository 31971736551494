import React, { useMemo, useState, useCallback } from 'react';
import { DrawerProps } from 'antd/lib/drawer';
import BaseDrawer from '../../base';
import OpenMessageEditor from '@copilot/common/components/editors/message/wizard/open';
import {
	Button, Row, Col,
} from 'antd';
import styled from 'styled-components';
import drawerManager from '@copilot/common/utils/drawerManager';
import IntentMessageEditor from '@copilot/common/components/editors/message/wizard/intent';
import MessageEditorWithTemplates from '@copilot/common/components/editors/message/withTemplates';
import MessageEditor from '@copilot/common/components/editors/message';
import { DEFAULT_TIME_UNITS, REQUEST_MESSAGE_CHAR_LIMIT } from '@copilot/common/pages/settings/message/const';
import { FollowUps } from '@copilot/common/pages/onboard/wizard/messaging/const';

enum WizardMessageEditDrawerType {
	Open,
	Intent,
	FirstFollowUp,
	SecondFollowUp,
	ThirdFollowUp
}
interface WizardMessageEditDrawerProps extends DrawerProps {
	messageType: WizardMessageEditDrawerType;
	currentMessage?: React.ComponentProps<typeof MessageEditorWithTemplates>['message'];
	onSubmit: (
		message: React.ComponentProps<typeof MessageEditorWithTemplates>['message'],
		messageType: WizardMessageEditDrawerType
	) => void;
	closeAlert?: boolean;
}
const StyledBaseDrawer = styled(BaseDrawer)`
	.${(props) => props.theme['@ant-prefix']}-drawer-body {
		padding: 0;
	}
`;
const WizardMessageEditDrawer: React.FC<WizardMessageEditDrawerProps> = (props) => {
	const {
		messageType, currentMessage, closeAlert: _, ...rest
	} = props;
	const [message, setMessage] = useState<
		React.ComponentProps<typeof MessageEditorWithTemplates>['message']
	>(
		currentMessage ?? {
			nodeId: '',
			text: '',
			period: 0,
			time: 0,
		}
	);
	const [messageTemplateType, setMessageTemplateType] = useState<number>(0);
	const component = useMemo(() => {
		switch (messageType) {
			case WizardMessageEditDrawerType.Open:
				return (
					<OpenMessageEditor
						hideToneCopy
						message={message.text}
						onUpdate={(content) => setMessage({ ...message, text: content })}
						templateType={messageTemplateType}
						onTemplateTypeUpdate={setMessageTemplateType}
					/>
				);
			case WizardMessageEditDrawerType.Intent:
				return <IntentMessageEditor message={message} onUpdate={setMessage} />;
			case WizardMessageEditDrawerType.FirstFollowUp:
			case WizardMessageEditDrawerType.SecondFollowUp:
			case WizardMessageEditDrawerType.ThirdFollowUp:
				return (
					<>
						<Row>
							<h1>
								{`${FollowUps[
									messageType - WizardMessageEditDrawerType.FirstFollowUp
								].label
								} Follow-up Message`}
							</h1>
						</Row>
						<Row style={{ display: 'block' }}>
							<MessageEditor
								message={message}
								onUpdate={setMessage}
								recommendedTime={
									FollowUps[
										messageType - WizardMessageEditDrawerType.FirstFollowUp
									].time
								}
								recommendedPeriod={
									FollowUps[
										messageType - WizardMessageEditDrawerType.FirstFollowUp
									].period
								}
								timeUnits={DEFAULT_TIME_UNITS}
							/>
						</Row>
					</>
				);
			default:
				return null;
		}
	}, [messageType, message, setMessage, messageTemplateType, setMessageTemplateType]);

	const onSave = useCallback(() => {
		props.onSubmit(message, messageType);
		drawerManager.closeDrawer();
	}, [message, messageType, props.onSubmit]);

	const isSaveDisabled = useMemo(() => (
		(messageType === WizardMessageEditDrawerType.Open) && (message.text.length > REQUEST_MESSAGE_CHAR_LIMIT)
	), [messageType, message.text]);

	const footer = useMemo(
		() => (
			<Row justify="space-between">
				<Col>
					<Button onClick={() => drawerManager.closeDrawer()}>Discard</Button>
				</Col>
				<Col>
					<Button type="primary" onClick={onSave} disabled={isSaveDisabled}>
						Save
					</Button>
				</Col>
			</Row>
		),
		[onSave]
	);
	return (
		<StyledBaseDrawer {...rest} footer={footer}>
			<div style={{ padding: '24px', minHeight: '100vh' }}>{component}</div>
		</StyledBaseDrawer>
	);
};

export default WizardMessageEditDrawer;
