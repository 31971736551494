import React, { useContext } from 'react';
import { BaseWizardContext } from './context';

interface IWizardContentProps {
	title?: string;
}

/**
 * Wizard Content Container
 */
const WizardStepContent: React.FC<IWizardContentProps> = (props) => {
	const { title, children } = props;

	return (
		<>
			{title && (
			<p style={{ textAlign: 'left' }}>
				{title}
			</p>
			)}
			{children}
		</>
	);
};

interface IBaseWizardStepProps {
	id: number;
}

interface IBaseWizardStepStaticProps {
	Content: typeof WizardStepContent;
}

/**
 * Base Wizard Step Container
 * @param id Id of the wizard step
 */
const BaseWizardStep: React.FC<IBaseWizardStepProps> & IBaseWizardStepStaticProps = (props) => {
	const { id, children } = props;
	const { currentNode } = useContext(BaseWizardContext);
	return (
		<>
			{currentNode === id && children }
		</>
	);
};
BaseWizardStep.Content = WizardStepContent;

export default BaseWizardStep;
