import React from 'react';
import GeneralError from './general';

export interface ErrorBoundaryProps {message?: string}

interface ErrorBoundaryState {
	hasError: boolean;
}

/**
 * Error boundary component. Catches any errors within it's children to prevent propagation to root
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	state: ErrorBoundaryState = { hasError: false };

	constructor(props: ErrorBoundaryProps) {
		super(props);
	}

	static getDerivedStateFromError(_: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(_: Error) {
		//add any additional error logging here
	}

	render() {
		if (this.state.hasError) return <GeneralError message={this.props.message} />;
		else return this.props.children;
	}
}

export default ErrorBoundary;
