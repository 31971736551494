import PasswordResetForm from '@copilot/common/components/forms/user/passwordReset';
import { useFetch } from '@copilot/common/hooks/common';
import { AuthenticationManager } from '@copilot/data';
import {
	Alert, Button, Result,
} from 'antd';
import React, {
	ReactNode, useCallback, useMemo, useState,
} from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { LogInErrorMessagesMapping } from './constant';
import BasicWizardContainer from '@copilot/common/components/wizard/basic/wizardContainer';

enum ResetPasswordWizardSteps {
	Reset,
	Done
}

interface RegistrationPageProps extends RouteComponentProps {}
/**
 * Page for creating a new password
 */
const RegistrationPage: React.FC<RegistrationPageProps> = () => {
	const searchParams = new URLSearchParams(location.search);
	const userId = useMemo(() => searchParams.get('userId'), [searchParams]);
	const code = useMemo(() => searchParams.get('code'), [searchParams]);

	const [wizardStep, setWizardStep] = useState<ResetPasswordWizardSteps>(ResetPasswordWizardSteps.Reset);
	const [resetPassword, fetchResetPassword] = useFetch(AuthenticationManager.resetPassword);
	const [errorMessage, setErrorMessage] = React.useState<ReactNode | undefined>();

	const handleSubmit = useCallback(async (uid: string, ucode: string, password: string) => {
		try {
			await fetchResetPassword(uid, ucode, password, password);
			setWizardStep(ResetPasswordWizardSteps.Done);
		} catch (err: any) {
			const errorCode = err?.data?.errors[0]?.code;
			setErrorMessage(LogInErrorMessagesMapping[errorCode] ?? 'Failed to set up. Please try again later.');
		}
	}, [fetchResetPassword]);

	return ((!userId || !code) ? (
		<Result
			status="404"
			title="This page is no longer available"
			extra={<Button type="link" href="/login">Back to Login</Button>}
		/>
	) : (
		<BasicWizardContainer farthestNode={wizardStep}>
			<BasicWizardContainer.Step id={ResetPasswordWizardSteps.Reset}>
				<BasicWizardContainer.Step.Content title="Please set up a new Password to use.">
					{errorMessage && (
						<Alert
							message={errorMessage}
							type="error"
							showIcon
							style={{ marginBottom: '20px', paddingLeft: '44px', textAlign: 'left' }}
						/>
					)}
					<PasswordResetForm
						userId={userId}
						code={code}
						onSubmit={handleSubmit}
						isSubmitting={resetPassword.isFetching}
					/>
				</BasicWizardContainer.Step.Content>
			</BasicWizardContainer.Step>
			<BasicWizardContainer.Step id={ResetPasswordWizardSteps.Done}>
				<BasicWizardContainer.Step.Content>
					<p>Your password has been set.</p>
					<p>
						Please
						{' '}
						<Link to="/login">login</Link>
						{' '}
						with the new password.
					</p>
				</BasicWizardContainer.Step.Content>
			</BasicWizardContainer.Step>
		</BasicWizardContainer>
	)
	);
};

export default RegistrationPage;
