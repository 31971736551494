import * as React from 'react';
import { Cascader, Tag } from 'antd';
import us_locations from '@copilot/common/data/locations';

export class CascadeOptionWrapper {
	value = '';
	label = '';
	children: CascadeOptionWrapper[] = [];
	constructor(value: string) {
		this.label = value;
		this.value = value;
	}
}

class TagOption {
	key = '';
	name = '';

	constructor(key: string, name: string) {
		this.key = key;
		this.name = name;
	}
}

interface ILocationCascadeSelectionState {
	options: CascadeOptionWrapper[];
	selectedLocations: TagOption[];
}

interface ILocationCascadeSelectionProps {
	title: string;
	selectedLocationStrings: string[];
	additionCallback: (value: string) => void;
	removeCallback: (value: string) => void;
}

function filterLocations(inputValue: any, path: any) {
	return path.some(
		(option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
	);
}

class LocationCascadeSelection extends React.Component<
	ILocationCascadeSelectionProps,
	ILocationCascadeSelectionState
> {
	state: ILocationCascadeSelectionState;

	constructor(props: ILocationCascadeSelectionProps) {
		super(props);
		this.state = {
			selectedLocations: [],
			options: [],
		};
	}

	onChange = (state: any, stateCity: any) => {
		if (stateCity.length >= 2) {
			const locationString = `${stateCity[1].label}, ${stateCity[0].label}`;
			const key = locationString.trim();
			const tagObj = new TagOption(key, locationString);

			this.setState({
				selectedLocations: [...this.state.selectedLocations, tagObj],
			});

			this.props.additionCallback(tagObj.name);
		}
	};

	removeLocation(tag: string) {
		this.props.removeCallback(tag);
	}

	updateLocations() {
		const tagafiedLocations = this.props.selectedLocationStrings.map(
			(city: string) => new TagOption(city, city)
		);
		this.setState({ selectedLocations: tagafiedLocations });
	}

	componentDidUpdate(prevProps: ILocationCascadeSelectionProps) {
		if (this.props.selectedLocationStrings != prevProps.selectedLocationStrings) {
			this.updateLocations();
		}
	}

	componentDidMount() {
		this.initLocationsObject();
		this.updateLocations();
	}

	loadData = (selectedOptions: CascadeOptionWrapper) => {
		const cascadeCityObject = [];
		const cities = us_locations.us_cities[selectedOptions.value as keyof typeof us_locations.us_cities];

		for (const city of cities) {
			cascadeCityObject.push(new CascadeOptionWrapper(city));
		}

		return cascadeCityObject;
	};

	initLocationsObject() {
		const states: CascadeOptionWrapper[] = [];
		for (const state of us_locations.us_states) {
			const stateObj = new CascadeOptionWrapper(state);
			stateObj.children = this.loadData(stateObj);
			states.push(stateObj);
		}
		this.setState({ options: states });
	}

	render() {
		return (
			<>
				<h3>{this.props.title}</h3>
				<div>
					<Cascader
						style={{ width: '50%', minWidth: '400px' }}
						options={this.state.options}
						onChange={this.onChange}
						showSearch={{ filter: filterLocations }}
						placeholder="Select target States/Provinces and Cities"
						changeOnSelect
					/>
				</div>
				<br />
				<div style={{ width: '75%' }}>
					{this.state.selectedLocations.map((city: TagOption) => (
						<Tag
							closable
							key={city.key}
							onClose={() => this.removeLocation(city.name)}
							style={{ marginBottom: '7px' }}
						>
							{city.name}
						</Tag>
					))}
				</div>
			</>
		);
	}
}

export default LocationCascadeSelection;
