import TableModule from '@copilot/common/components/tables/tableModule';
import { Divider, Skeleton, Space, Typography } from 'antd';
import SearchInput from '@copilot/common/components/search/input';
import { ProspectTableColumns } from '@copilot/common/components/tables/tableTypes/prospect/columns';
import { StopAutomationAction } from '@copilot/common/pages/campaignDashboard/searchList/stopAutomationAction';
import { SearchListTableData } from '@copilot/common/pages/campaignDashboard/searchList/table';
import { ReplyPredictionInfoIcon } from '@copilot/common/pages/campaignDashboard/searchList/replyPredictionInfoIcon';
import { isNil } from 'lodash';
import { ReplyPrediction } from '@copilot/common/pages/campaignDashboard/searchList/replyPrediction';
import { LinkedinFilled } from '@ant-design/icons';
import { URL } from '@copilot/common/data/urls';
import Tabs from '@copilot/common/components/tabs/Tabs';
import styled, { useTheme } from 'styled-components';
import { ReactNode } from 'react';
import {
	TableModuleColumn,
	TableModuleHeader,
} from '@copilot/common/components/tables/tableModule/util';
import { TableModuleTable } from '@copilot/common/components/tables/tableModule/table';
import { TableModuleTableProps } from '@copilot/common/components/tables/tableModule/types';
import {
  OPEN_PROFILE_TAB_KEY,
  PROSPECT_TAB_KEY,
  SearchListTableTabKeys,
} from '@copilot/common/pages/campaignDashboard/searchList/const';

/**
 * Component that shows the reply prediction score in percentage
 * @param data
 * @constructor
 */
const ReplyPredictionPercentage = (data: SearchListTableData) => {
	const matchScore = data.matchScore;
	if (isNil(matchScore)) return null;

	const percentage = Math.round(matchScore * 100);
	return <ReplyPrediction percent={percentage} />;
};

type SearchListTableWithOpenProfilesProps = TableModuleTableProps<SearchListTableData> & {
	/**
	 * Header used for the table
	 */
	tableHeader: ReactNode;
	/**
	 * Header used for the table
	 */
	tableOverview: ReactNode;
	/**
	 * Callback for the stop automation button
	 */
	onStopAutomation: (connectionId: string, campaignMemberId: string) => unknown;
	/**
	 * Callback for searching
	 */
	onSearch: (searchTerm: string) => unknown;
	/**
	 * Placeholder for the search input
	 */
	searchPlaceholder: string;
	/**
	 * Counts
	 */
	counts: {
		[PROSPECT_TAB_KEY]?: number;
		[OPEN_PROFILE_TAB_KEY]?: number;
	};
	/**
	 * Active Tab Key
	 */
	activeTabKey: string;
	/**
	 * Callback to update which tab is active
	 * @param value
	 */
	setActiveTabKey: (value: SearchListTableTabKeys) => void;
};

const StyledTabs = styled(Tabs)`
	.${(props) => props.theme['@ant-prefix']}-tabs-content-holder {
		padding: 0;
	}
`;

/**
 * [Presentational] Table for displaying prospects and prospects with open profile
 */
export function SearchListTableWithOpenProfiles(props: SearchListTableWithOpenProfilesProps) {
	const {
		tableOverview,
		tableHeader,
		onStopAutomation,
		counts,
		onSearch,
		searchPlaceholder,
		loading,
		setActiveTabKey,
		activeTabKey,
		...rest
	} = props;

	const theme = useTheme();

	return (
		<TableModule
			header={
				<TableModuleHeader>
					<div style={{ fontWeight: 'normal', paddingTop: theme['@spacer-size-md'] }}>
						{tableHeader}
						<Divider />
						<Space direction={'vertical'} style={{ width: '100%' }}>
							<StyledTabs
								style={{ padding: 0 }}
								items={[
									{
										key: PROSPECT_TAB_KEY,
										label: `Prospects ${counts.prospect ?? ''}`,
									},
									{
										key: OPEN_PROFILE_TAB_KEY,
										label: `Prospects with Open Profiles ${
											counts.openProfile ?? ''
										}`,
									},
								]}
								activeKey={activeTabKey}
								onChange={(key) =>
									setActiveTabKey(key as SearchListTableTabKeys)
								}
							/>
							<SearchInput
								placeholder={searchPlaceholder}
								onSearch={onSearch}
								style={{ width: '463px' }}
							/>
							{loading ? <Skeleton paragraph={false} active /> : tableOverview}
						</Space>
					</div>
				</TableModuleHeader>
			}
			table={
				<TableModuleTable
					style={{ padding: `${theme['@spacer-size-sm']} ${theme['@spacer-size-md']}` }}
					loading={loading}
					{...rest}
				>
					<TableModuleColumn
						title=""
						columnKey={ProspectTableColumns.ContactUrl}
						render={(data: SearchListTableData) => (
							<a href={`${URL.LINKEDIN_URL}${data.inviteUrl}`} target="_blank">
								<LinkedinFilled />
							</a>
						)}
						width="48px"
					/>
					<TableModuleColumn
						title="Name"
						columnKey={ProspectTableColumns.Name}
						dataIndex="name"
					/>
					<TableModuleColumn
						title="Current job title"
						columnKey={ProspectTableColumns.Position}
						dataIndex="position"
					/>
					<TableModuleColumn
						title="Current company"
						columnKey={ProspectTableColumns.Company}
						dataIndex="company"
					/>
					<TableModuleColumn
						title="Location"
						columnKey={ProspectTableColumns.Location}
						dataIndex="location"
						ellipsis
					/>
					<TableModuleColumn
						title={
							<Typography.Text>
								<span style={{ marginRight: '10px' }}>Reply Prediction AI</span>
								<ReplyPredictionInfoIcon />
							</Typography.Text>
						}
						columnKey="replyPrediction"
						render={ReplyPredictionPercentage}
					/>
					<TableModuleColumn
						title="Actions"
						columnKey={ProspectTableColumns.Position}
						render={(data: SearchListTableData) =>
							isNil(data.inMailStatus) ? (
								<StopAutomationAction
									connectionId={data.id}
									campaignMemberId={data.campaignMemberId}
									isRemoveHidden={data.isRemoveHidden ?? false}
									removeProspect={onStopAutomation}
								/>
							) : (
								<Typography.Text type={'secondary'}>InMail sent</Typography.Text>
							)
						}
					/>
				</TableModuleTable>
			}
		/>
	);
}
