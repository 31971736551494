import React, { useEffect } from 'react';
import {
	Button, Col, Form, Input, Row,
} from 'antd';

export interface CalendlyUpdateFormValues {
	url: string;
}

interface CalendlyFormProps {
	onSubmit: (values: CalendlyUpdateFormValues) => Promise<any> | void;
	onResetInfo: () => void;
	calendlyUrl: string;
}

const CalendlyForm: React.FC<CalendlyFormProps> = (props) => {
	const { onSubmit, calendlyUrl, onResetInfo } = props;
	const [form] = Form.useForm();

	const handleFinish = React.useCallback((values: CalendlyUpdateFormValues) => {
		onSubmit?.(values);
	}, [onSubmit]);

	useEffect(() => {
		form.setFieldsValue({
			url: calendlyUrl ?? '',
		});
	}, [calendlyUrl]);

	return (
		<Form
			form={form}
			onFinish={handleFinish}
			scrollToFirstError
		>
			<Form.Item
				name="url"
				label="Calendly URL"
				style={{ fontWeight: 'bold', display: 'block' }}
				rules={[{ required: true }]}
			>
				<Input />
			</Form.Item>
			<Form.Item style={{ fontWeight: 'bold' }}>
				<Row justify="end" gutter={8}>
					<Col>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
					</Col>
					<Col>
						<Button danger type="primary" onClick={onResetInfo}>
							Reset
						</Button>
					</Col>
				</Row>
			</Form.Item>
		</Form>
	);
};

export default CalendlyForm;
