import {
	Button, Col, Row, Typography,
} from 'antd';
import { FC, ReactNode, useContext } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import BaseModal, { BaseModalProps } from '../base';
import { ThemeContext } from 'styled-components';

export interface ICampaignLaunchErrorModalProps extends BaseModalProps {
	/**
	 * Errors to display
	 */
	issues: ReactNode[];
}

/**
 * Modal to display campaign launch errors
 * @param {ICampaignLaunchErrorModalProps} props Props
 */
const CampaignLaunchErrorModal: FC<ICampaignLaunchErrorModalProps> = (props) => {
	const { onCancel, issues, width = 600 } = props;
	const theme = useContext(ThemeContext);
	return (
		<BaseModal {...props} width={width} footer={null} centered>
			<Row wrap={false} gutter={16} style={{ margin: theme['@spacer-num-xs'] }}>
				<Col flex="none">
					<Typography.Title level={4} style={{ color: theme['@error-color'] }}><ExclamationCircleOutlined /></Typography.Title>
				</Col>
				<Col flex="auto">
					<Row gutter={[24, 24]}>
						<Col>
							<Typography.Title level={4}>
								Unable to launch campaign
							</Typography.Title>
							<Typography.Text>You are missing required fields to launch a campaign. Please update your campaign to the following:</Typography.Text>
						</Col>
					</Row>
					<Row gutter={[24, 16]}>
						<Col>
							<ul>
								{issues.map((issue) => <li>{issue}</li>)}
							</ul>
						</Col>
					</Row>
					<Row justify="end">
						<Col>
							<Button type="primary" onClick={onCancel}>Close</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</BaseModal>
	);
};

export default CampaignLaunchErrorModal;
