import {
	ConnectionMigrationResponse,
	InboxMessageTagModel,
	LoadMorePaginatedResponse,
} from '@copilot/data/responses/interface';
import { InboxModel } from '@copilot/data/responses/models/inbox';
import { InboxMessageFilters } from '../ui/types';
import { INBOX_MESSAGES, INBOX_VERSIONS } from './constant';
import { FiltersRequestModel, InboxDisplayType } from '@copilot/data/requests/models';
import { InboxVersion } from '@copilot/common/pages/inbox/data/types';

type CallbacksType<T> = {
	onSuccess?: (result?: T) => void;
	onError?: () => void;
};

//#region update inbox view
export const InboxMessagesUpdateViewActionType = `UPDATE_${INBOX_MESSAGES}_VIEW` as const;

export type InboxMessagesUpdateViewAction = {
	type: typeof InboxMessagesUpdateViewActionType;
	viewType: InboxDisplayType;
	filters?: Partial<InboxMessageFilters>;
	isAdmin: boolean;
};

export const updateDisplayType = (
	viewType: InboxDisplayType,
	isAdmin: boolean,
	filters?: Partial<InboxMessageFilters>
): InboxMessagesUpdateViewAction => ({
	type: InboxMessagesUpdateViewActionType,
	viewType,
	isAdmin,
	filters,
});

export const InboxMessagesUpdateViewSuccessActionType =
	`UPDATE_${INBOX_MESSAGES}_VIEW_SUCCESS` as const;

export type InboxMessagesUpdateViewSuccessAction = {
	type: typeof InboxMessagesUpdateViewSuccessActionType;
	viewType: InboxDisplayType | undefined;
};

export const updateDisplayTypeSuccess = (
	viewType?: InboxDisplayType
): InboxMessagesUpdateViewSuccessAction => ({
	type: InboxMessagesUpdateViewSuccessActionType,
	viewType,
});
//#endregion

//#region Load Page
export const InboxPageLoadActionType = `LOAD_${INBOX_MESSAGES}_PAGE` as const;

/**
 * The action to load inbox page
 */
export type InboxPageLoadAction = {
	type: typeof InboxPageLoadActionType;
	organizationId: string;
	isAdmin: boolean;
	defaultTeamMemberId?: string;
	defaultViewType?: InboxDisplayType;
	defaultFilters?: Partial<InboxMessageFilters>;
	version: InboxVersion;
};

/**
 * load action
 */
export const loadInbox = (
	organizationId: string,
	isAdmin: boolean,
	defaultTeamMemberId?: string,
	defaultViewType?: InboxDisplayType,
	version: InboxVersion = INBOX_VERSIONS.V3,
	defaultFilters?: Partial<InboxMessageFilters>,
): InboxPageLoadAction => ({
	type: InboxPageLoadActionType,
	organizationId,
	isAdmin,
	defaultTeamMemberId,
	defaultViewType,
	defaultFilters,
	version,
});

export const InboxPageLoadSuccessActionType = `LOAD_${INBOX_MESSAGES}_PAGE_SUCCESS` as const;

export type InboxPageLoadSuccessAction<Filters> = {
	type: typeof InboxPageLoadSuccessActionType;
	filters: Filters;
	viewType: InboxDisplayType;
	csOrgId: string | undefined;
};

/**
 * load success action
 */
export const loadInboxSuccess = <Filter>(
	filters: Filter,
	viewType: InboxDisplayType,
	csOrgId: string | undefined
): InboxPageLoadSuccessAction<Filter> => ({
	type: InboxPageLoadSuccessActionType,
	filters,
	viewType,
	csOrgId,
});

export const InboxPageLoadFailedActionType = `LOAD_${INBOX_MESSAGES}_PAGE_FAILED` as const;

export type InboxPageLoadFailedAction = {
	type: typeof InboxPageLoadFailedActionType;
};

/**
 * load failed action
 */
export const loadInboxFailed = (): InboxPageLoadFailedAction => ({
	type: InboxPageLoadFailedActionType,
});

//#endregion

//#region Filter

export const InboxMessagesUpdateFilterActionType = `UPDATE_${INBOX_MESSAGES}_FILTER` as const;

/**
 * The action to update inbox messages filter
 */
export type InboxMessagesUpdateFilterAction = {
	type: typeof InboxMessagesUpdateFilterActionType;
	filter: Partial<InboxMessageFilters>;
};

/**
 * update filter action. Update the filters for inbox messages
 */
export const updateInboxMessagesFilter = (
	filter: Partial<InboxMessageFilters>
): InboxMessagesUpdateFilterAction => ({
	type: InboxMessagesUpdateFilterActionType,
	filter,
});

export const InboxMessagesResetFilterActionType = `RESET_${INBOX_MESSAGES}_FILTER` as const;

/**
 * The action to reset inbox messages filter
 */
export type InboxMessagesResetFilterAction = {
	type: typeof InboxMessagesResetFilterActionType;
};

/**
 * reset filter action
 */
export const resetInboxMessagesFilter = (): InboxMessagesResetFilterAction => ({
	type: InboxMessagesResetFilterActionType,
});

export const InboxMessagesResetFilterInStoreActionType =
	`RESET_STORE_${INBOX_MESSAGES}_FILTER` as const;

/**
 * The action to update the filters for inbox messages back to the initial state
 */
export type InboxMessagesResetFilterInStoreAction = {
	type: typeof InboxMessagesResetFilterInStoreActionType;
};

/**
 * reset filter action. Update the filters for inbox messages back to the initial state
 */
export const resetInboxMessagesFilterInStore = (): InboxMessagesResetFilterInStoreAction => ({
	type: InboxMessagesResetFilterInStoreActionType,
});
//#endregion

//#region Pagination
export const InboxMessagesUpdatePaginationActionType =
	`UPDATE_${INBOX_MESSAGES}_PAGINATION` as const;

/**
 * The action to update inbox messages pagination
 */
export type InboxMessagesUpdatePaginationAction = {
	type: typeof InboxMessagesUpdatePaginationActionType;
	pageSize: number | undefined;
	offset: number | undefined;
};

/**
 * update pagination action. Update the pagination for inbox messages
 */
export const updateInboxMessagesPagination = (
	offset?: number,
	pageSize?: number
): InboxMessagesUpdatePaginationAction => ({
	type: InboxMessagesUpdatePaginationActionType,
	offset,
	pageSize,
});
//#endregion

//#region Load
export const InboxMessagesLoadActionType = `LOAD_${INBOX_MESSAGES}` as const;

/**
 * The action to initiate loading inbox messages
 */
export type InboxMessagesLoadAction = {
	type: typeof InboxMessagesLoadActionType;
};

/**
 * load action. load a batch of inbox messages using the current filters and pagination
 */
export const loadInboxMessages = (): InboxMessagesLoadAction => ({
	type: InboxMessagesLoadActionType,
});

/**
 * Action that fires when we have successfully loaded the inbox messages
 */
export const InboxMessagesLoadSuccessActionType = `LOAD_${INBOX_MESSAGES}_SUCCESS` as const;

/**
 * Action that fires when we have successfully loaded the inbox counts
 */
export const InboxMessagesCountLoadSuccessActionType =
	`LOAD_${INBOX_MESSAGES}_COUNTS_SUCCESS` as const;

/**
 * The action to initiate load inbox messages success
 */
export type InboxMessagesLoadSuccessAction = {
	type: typeof InboxMessagesLoadSuccessActionType;
	payload: LoadMorePaginatedResponse<InboxModel>;
};

export function isLoadMorePaginatedResponse<T>(resp: any): resp is LoadMorePaginatedResponse<T> {
	return resp?.hasMore !== undefined;
}

/**
 * load success action
 */
export const loadInboxMessagesSuccess = (
	payload: LoadMorePaginatedResponse<InboxModel>
): InboxMessagesLoadSuccessAction => ({
	type: InboxMessagesLoadSuccessActionType,
	payload,
});

export type InboxMessagesCountLoadSuccessAction = {
	type: typeof InboxMessagesCountLoadSuccessActionType;
	payload: Record<string, number>;
};

/**
 * load success action
 */
export const loadInboxCountsSuccess = (
	payload: Record<string, number>
): InboxMessagesCountLoadSuccessAction => ({
	type: InboxMessagesCountLoadSuccessActionType,
	payload,
});

export const InboxMessagesLoadFailedActionType = `LOAD_${INBOX_MESSAGES}_FAILED` as const;

/**
 * The action to initiate load inbox messages success
 */
export type InboxMessagesLoadFailedAction = {
	type: typeof InboxMessagesLoadFailedActionType;
};

/**
 * load success action
 */
export const loadInboxMessagesFailed = (): InboxMessagesLoadFailedAction => ({
	type: InboxMessagesLoadFailedActionType,
});
//#endregion

//#region Dismiss
export const InboxMessagesDismissActionType = `DISMISS_${INBOX_MESSAGES}` as const;

/**
 * The action to initiate dismissing inbox messages
 */
export type InboxMessagesDismissAction = {
	type: typeof InboxMessagesDismissActionType;
	threadIds: string[];
};

/**
 * Dismiss action. Dismiss a batch of inbox messages
 */
export const dismissInboxMessages = (threadIds: string[]): InboxMessagesDismissAction => ({
	type: InboxMessagesDismissActionType,
	threadIds,
});

export const InboxMessagesDismissSuccessActionType = `DISMISS_${INBOX_MESSAGES}_SUCCESS` as const;

/**
 * The action for handling a dismiss success
 */
export type InboxMessagesDismissSuccessAction = {
	type: typeof InboxMessagesDismissSuccessActionType;
	threadIds: string[];
	counts: Record<string, number>;
};

/**
 * Dismiss success action
 */
export const dismissInboxMessagesSuccess = (
	threadIds: string[],
	counts: Record<string, number> = {}
): InboxMessagesDismissSuccessAction => ({
	type: InboxMessagesDismissSuccessActionType,
	threadIds,
	counts,
});
//#endregion

//#region Mark As Read
export const InboxMessagesMarkAsReadActionType = `MARK_${INBOX_MESSAGES}_AS_READ` as const;

/**
 * The action to initiate marking inbox messages as read
 */
export type InboxMessagesMarkAsReadAction = {
	type: typeof InboxMessagesMarkAsReadActionType;
	threadIds: string[];
	isRead: boolean;
};

/**
 * The action for handling a mark as read success
 */
export const markInboxMessagesAsRead = (
	threadIds: string[],
	isRead: boolean
): InboxMessagesMarkAsReadAction => ({
	type: InboxMessagesMarkAsReadActionType,
	threadIds,
	isRead,
});

export const InboxMessagesMarkAsReadSuccessActionType =
	`MARK_${INBOX_MESSAGES}_AS_READ_SUCCESS` as const;

/**
 * The action to initiate marking inbox messages as read
 */
export type InboxMessagesMarkAsReadSuccessAction = {
	type: typeof InboxMessagesMarkAsReadSuccessActionType;
	threadIds: string[];
	isRead: boolean;
};

/**
 * Mark as read success action
 */
export const markInboxMessagesAsReadSuccess = (
	threadIds: string[],
	isRead: boolean
): InboxMessagesMarkAsReadSuccessAction => ({
	type: InboxMessagesMarkAsReadSuccessActionType,
	threadIds,
	isRead,
});

export const InboxMessagesLoadUnreadCountActionType =
	`${INBOX_MESSAGES}_LOAD_UNREAD_COUNT` as const;

/**
 * The action to load the count of unread messages
 */
export type InboxMessagesLoadUnreadCountAction = {
	type: typeof InboxMessagesLoadUnreadCountActionType;
};

export const loadUnreadMessagesCount = (): InboxMessagesLoadUnreadCountAction => ({
	type: InboxMessagesLoadUnreadCountActionType,
});

export const InboxMessagesActionsCountActionType =
	`${INBOX_MESSAGES}_LOAD_ACTIONS_COUNT` as const;

export type InboxMessagesActionsCountAction = {
	type: typeof InboxMessagesActionsCountActionType;
};

export const loadActionsMessageCount = (): InboxMessagesActionsCountAction => ({
	type: InboxMessagesActionsCountActionType,
});

export const InboxMessagesMemberActionsCountActionType =
	`${INBOX_MESSAGES}_LOAD_MEMBER_ACTIONS_COUNT` as const;

export type InboxMessagesMemberActionsCountAction = {
	type: typeof InboxMessagesMemberActionsCountActionType;
};

export const loadMemberActionsMessageCount = (): InboxMessagesMemberActionsCountAction => ({
	type: InboxMessagesMemberActionsCountActionType,
});

//#endregion

//#region Apply Tags
export const InboxMessagesApplyTagsActionType = `${INBOX_MESSAGES}_APPLY_TAGS` as const;

/**
 * The action to initiate applying tags to inbox messages
 */
export type InboxMessagesApplyTagsAction = {
	type: typeof InboxMessagesApplyTagsActionType;
	connectionIds: string[];
	tags: string[];
	csOrgId: string | null;
	overrideTags: boolean;
	filterRequest?: Partial<FiltersRequestModel>;
	onFinally?: () => void;
};

/**
 * Apply tags action. Apply tags to a batch of inbox messages
 */
export const applyTagsToInboxMessages = (
	connectionIds: string[],
	tags: string[],
	csOrgId: string | null,
	overrideTags: boolean,
	filterRequest?: Partial<FiltersRequestModel>,
	onFinally?: () => void
): InboxMessagesApplyTagsAction => ({
	type: InboxMessagesApplyTagsActionType,
	connectionIds,
	tags,
	csOrgId,
	overrideTags,
	filterRequest,
	onFinally,
});
//#endregion

//#region Snoozed
export const InboxMessagesSnoozeActionType = `${INBOX_MESSAGES}_SNOOZE` as const;

/**
 * The action to snooze thread
 */
export type InboxMessagesSnoozeAction = {
	type: typeof InboxMessagesSnoozeActionType;
	dateSnoozeUntil: string;
	threadId: string;
};

/**
 * Snooze thread action. Snooze a given thread
 * @param threadId thread id to snooze for
 * @param dateSnoozeUntil date to snooze untill
 */
export const snoozeInboxMessages = (
	threadId: string,
	dateSnoozeUntil: string
): InboxMessagesSnoozeAction => ({
	type: InboxMessagesSnoozeActionType,
	threadId,
	dateSnoozeUntil,
});
//#endregion

//#region Complete Reminder
export const InboxMessagesCompleteReminderActionType =
	`${INBOX_MESSAGES}_COMPLETE_REMINDER` as const;

/**
 * The action to complete reminded thread
 */
export type InboxMessagesCompleteReminderAction = {
	type: typeof InboxMessagesCompleteReminderActionType;
	threadId: string;
};

/**
 * Complete reminded thread action. Complete a given reminded thread
 * @param threadId thread id to complete
 */
export const completeRemindedInboxMessages = (
	threadId: string
): InboxMessagesCompleteReminderAction => ({
	type: InboxMessagesCompleteReminderActionType,
	threadId,
});
//#endregion

//#region Unsnoozed
export const InboxMessagesUnsnoozeActionType = `${INBOX_MESSAGES}_UNSNOOZE` as const;

/**
 * The action to unsnooze thread
 */
export type InboxMessagesUnsnoozeAction = {
	type: typeof InboxMessagesUnsnoozeActionType;
	threadId: string;
};

/**
 * Snooze thread action. Snooze a given thread
 * @param threadId thread id to unsnooze for
 */
export const unsnoozeInboxMessages = (threadId: string): InboxMessagesUnsnoozeAction => ({
	type: InboxMessagesUnsnoozeActionType,
	threadId,
});
//#endregion

//#region Update
export const InboxMessagesUpdateSuccessActionType = `${INBOX_MESSAGES}_UPDATE_SUCCESS` as const;

export type InboxMessagesUpdateSuccessAction = {
	type: typeof InboxMessagesUpdateSuccessActionType;
	threadId: string;
	update: Partial<Exclude<InboxModel, 'id'>>;
	counts: Record<string, number>;
};

export const updateInboxMessagesSuccess = (
	threadId: string,
	update: Partial<InboxModel>,
	counts: Record<string, number> = {}
): InboxMessagesUpdateSuccessAction => ({
	type: InboxMessagesUpdateSuccessActionType,
	threadId,
	update,
	counts,
});
//#endregion

//#region Send Message
export const InboxMessagesSendAutomatedMessageActionType =
	`${INBOX_MESSAGES}_SEND_AUTOMATED_MESSAGE` as const;

/** Action to send automed message */
export type InboxMessagesSendAutomatedMessageAction = {
	type: typeof InboxMessagesSendAutomatedMessageActionType;
	threadId: string;
	message: string;
	nodeId: string;
};

/** Send automated message action */
export const sendAutomatedMessage = (
	threadId: string,
	message: string,
	nodeId: string
): InboxMessagesSendAutomatedMessageAction => ({
	type: InboxMessagesSendAutomatedMessageActionType,
	threadId,
	message,
	nodeId,
});

export const InboxMessagesSendManualMessageActionType =
	`${INBOX_MESSAGES}_SEND_MANUAL_MESSAGE` as const;

/** Action to send manual message */
export type InboxMessagesSendManualMessageAction = {
	type: typeof InboxMessagesSendManualMessageActionType;
	threadId: string;
	message: string;
	templateId?: string;
	campaignId?: string;
	removeReminder: boolean;
};

/** Send manual message action */
export const sendManualMessage = (
	threadId: string,
	message: string,
	removeReminder: boolean,
	templateId?: string,
	campaignId?: string
): InboxMessagesSendManualMessageAction => ({
	type: InboxMessagesSendManualMessageActionType,
	threadId,
	message,
	templateId,
	campaignId,
	removeReminder,
});

export const InboxMessagesSendMessageSuccessActionType =
	`${INBOX_MESSAGES}_SEND_MESSAGE_SUCCESS` as const;

export type InboxMessagesSendMessageSuccessAction = {
	type: typeof InboxMessagesSendMessageSuccessActionType;
	threadId: string;
	counts: Record<string, number>;
};

export const sendMessageSuccess = (
	threadId: string,
	counts: Record<string, number> = {}
): InboxMessagesSendMessageSuccessAction => ({
	type: InboxMessagesSendMessageSuccessActionType,
	threadId,
	counts,
});

export const InboxMessagesSendMessageFailedActionType =
	`${INBOX_MESSAGES}_SEND_MESSAGE_FAILED` as const;

export type InboxMessagesSendMessageFailedAction = {
	type: typeof InboxMessagesSendMessageFailedActionType;
	threadId: string;
};

export const sendMessageFailed = (threadId: string): InboxMessagesSendMessageFailedAction => ({
	type: InboxMessagesSendMessageFailedActionType,
	threadId,
});
//#endregion

//#region Migrate connections
export const InboxMessagesMigrateConnectionActionType =
	`${INBOX_MESSAGES}_MIGRATE_CONNECTION` as const;

export type InboxMessagesMigrateConnectionAction = {
	type: typeof InboxMessagesMigrateConnectionActionType;
	connectionIds: string[];
	targetCampaignId: string;
	callbacks: CallbacksType<ConnectionMigrationResponse>;
};

/**
 * Migrate conversation and connection to a different campaign
 * @param connectionIds
 * @param targetCampaignId
 * @param callbacks
 */
export const migrateConnections = (
	connectionIds: string[],
	targetCampaignId: string,
	callbacks?: CallbacksType<ConnectionMigrationResponse>
): InboxMessagesMigrateConnectionAction => ({
	type: InboxMessagesMigrateConnectionActionType,
	connectionIds,
	targetCampaignId,
	callbacks: {
		...callbacks,
	},
});

export const InboxMessagesMigrateConnectionSuccessActionType =
	`${INBOX_MESSAGES}_MIGRATE_CONNECTION_SUCCESS` as const;

export type InboxMessagesMigrateConnectionSuccessAction = {
	type: typeof InboxMessagesMigrateConnectionSuccessActionType;
	migrationResponse: ConnectionMigrationResponse;
};

export const migrateConnectionSuccess = (migrationResponse: ConnectionMigrationResponse) => ({
	type: InboxMessagesMigrateConnectionSuccessActionType,
	migrationResponse,
});

export const InboxMessagesMigrateConnectionTagsActionType =
	`${INBOX_MESSAGES}_MIGRATE_CONNECTION_TAGS` as const;

export type InboxMessagesMigrateConnectionTagsAction = {
	type: typeof InboxMessagesMigrateConnectionTagsActionType;
	createCampaignTag: boolean;
	selectedTags: string[];
	connectionIds: string[];
	csOrgId: string | null;
	callbacks: CallbacksType<void>;
};

/**
 * Action to apply tags after migrating connections to a different campaign
 * @param createCampaignTag Indicates if a tag should be created based on the connections previous campaign name
 * @param selectedTags additional tags to apply to connections
 * @param connectionIds ids of the connections to apply tags to
 * @param csOrgId the id of the organization, only needed for CS
 * @param callbacks callbacks for the migration
 */
export const migrateConnectionTags = (
	createCampaignTag: boolean,
	selectedTags: string[],
	connectionIds: string[],
	csOrgId: string | null,
	callbacks: CallbacksType<void>
): InboxMessagesMigrateConnectionTagsAction => ({
	type: InboxMessagesMigrateConnectionTagsActionType,
	createCampaignTag,
	selectedTags,
	connectionIds,
	csOrgId,
	callbacks: {
		...callbacks,
	},
});

export const InboxMessagesModifyMessageTagsActionType = `${INBOX_MESSAGES}_MODIFY_TAGS` as const;

export type InboxMessagesModifyMessageTagsAction = {
	type: typeof InboxMessagesModifyMessageTagsActionType;
	threadId: string;
	tags: InboxMessageTagModel[];
};

export const modifyMessageTags = (
	threadId: string,
	tags: InboxMessageTagModel[]
): InboxMessagesModifyMessageTagsAction => ({
	type: InboxMessagesModifyMessageTagsActionType,
	threadId,
	tags,
});

//#endregion

//#region Search Term
export const InboxMessagesUpdateSearchTermActionType =
	`UPDATE_${INBOX_MESSAGES}_SEARCH_TERM` as const;

/**
 * The action to update inbox messages searchTerm
 */
export type InboxMessagesUpdateSearchTermAction = {
	type: typeof InboxMessagesUpdateSearchTermActionType;
	searchTerm: string;
};

/**
 * update searchTerm action. Update the searchTerm for inbox messages
 */
export const updateInboxMessagesSearchTerm = (
	searchTerm: string
): InboxMessagesUpdateSearchTermAction => ({
	type: InboxMessagesUpdateSearchTermActionType,
	searchTerm,
});

//#endregion

//#region Update Version
export const InboxMessagesUpdateVersionActionType = `UPDATE_${INBOX_MESSAGES}_VERSION` as const;

/**
 * The action to update inbox version
 */
export type InboxMessagesUpdateVersionAction = {
	type: typeof InboxMessagesUpdateVersionActionType;
	version: InboxVersion;
};

/**
 * update inbox version
 */
export const updateInboxMessagesVersion = (version: InboxVersion) => ({
	type: InboxMessagesUpdateVersionActionType,
	version,
});

//#endregion

export type InboxMessagesActions<T> =
	| InboxMessagesLoadAction
	| InboxMessagesLoadSuccessAction
	| InboxMessagesCountLoadSuccessAction
	| InboxMessagesLoadFailedAction
	| InboxMessagesApplyTagsAction
	| InboxMessagesDismissAction
	| InboxMessagesMarkAsReadAction
	| InboxMessagesUpdatePaginationAction
	| InboxMessagesUpdateFilterAction
	| InboxPageLoadAction
	| InboxPageLoadSuccessAction<T>
	| InboxPageLoadFailedAction
	| InboxMessagesResetFilterAction
	| InboxMessagesResetFilterInStoreAction
	| InboxMessagesUpdateSuccessAction
	| InboxMessagesUpdateViewSuccessAction
	| InboxMessagesDismissSuccessAction
	| InboxMessagesSendMessageSuccessAction
	| InboxMessagesMigrateConnectionSuccessAction
	| InboxMessagesMarkAsReadSuccessAction
	| InboxMessagesModifyMessageTagsAction
	| InboxMessagesSendAutomatedMessageAction
	| InboxMessagesSendManualMessageAction
	| InboxMessagesSendMessageFailedAction
	| InboxMessagesUpdateSearchTermAction
	| InboxMessagesUpdateVersionAction;
