import { OnboardTemplateStep } from '@copilot/common/pages/onboard/wizard/messaging/const';
import { WizardStepNode } from '@copilot/common/pages/wizard/nurtureOnboard/const';
import { NurtureListOptionType } from '@copilot/common/store/models/const/enum';

type NurtureListOldOption = { optionType: NurtureListOptionType.Old, connectionsLabel: string };
type NurtureListNeverRepliedOption = { optionType: NurtureListOptionType.NeverReplied, connectionsLabel: string };
type NurtureListTagOption = { optionType: NurtureListOptionType.Tag, connectionsLabel: string; tags: string[] };
type NurtureListEmptyOption = { optionType: NurtureListOptionType.Empty };
export type NurtureListOption = NurtureListOldOption | NurtureListNeverRepliedOption | NurtureListTagOption | NurtureListEmptyOption;

export interface NurtureListNumConnections {
	[NurtureListOptionType.Old]: number | undefined;
	[NurtureListOptionType.NeverReplied]: number | undefined;
	[NurtureListOptionType.Tag]: number | undefined;
}

export const numConnectionsLabel = (listType: NurtureListOptionType, numConnections: NurtureListNumConnections) => {
	const numConnectionsForType = (listType === NurtureListOptionType.Old || listType === NurtureListOptionType.NeverReplied || listType === NurtureListOptionType.Tag) ? numConnections[listType] : undefined;
	return typeof numConnectionsForType === 'number' ? `(${numConnectionsForType} Connections)` : '';
};

export const NurtureListOptionText: Record<NurtureListOptionType, { title: string, body: React.ReactNode }> = {
	[NurtureListOptionType.Old]: {
		title: 'Past conversations',
		body: 'Prospects who have replied to you more than two times, but haven’t been contacted by you in more than two-months.',
	},
	[NurtureListOptionType.NeverReplied]: {
		title: 'Prospects who never replied',
		body: 'Connections who did not respond to any of the messages in your other prospecting campaigns.',
	},
	[NurtureListOptionType.Tag]: {
		title: 'Tagged prospects',
		body: (
			<p>
				Prospects you have tagged in your old campaigns. Pick the tags to build your list.
				<br />
				<i>Note: Any connection with one of these tags will be added</i>
			</p>
		),
	},
	[NurtureListOptionType.Empty]: {
		title: 'Start with an Empty List',
		body: 'Add prospects to your nurture campaign manually. Choose the prospects as you go, or add from a URL.',
	},
};

export const NurtureMessageTemplateKeys = ['firstMessageExampleKey', 'secondMessageExampleKey'];

export const NurtureMessagesExamples: Record<string, { [templateStep: string]: { [template: string]: string } }> = {
	Info: {
		[OnboardTemplateStep.Open]: {
			'Information 1':
				'Hey {firstname}, we recently published this article on <INSERT TOPIC> and I thought you might be interested in it. Give it a read, I’d love to hear your thoughts! <INSERT ARTICLE LINK>.',
			'Information 2':
				'Hi {firstname}, our monthly newsletter on <INSERT TOPIC> was just released. What are your thoughts on <INSERT TOPIC> in the <INSERT INDUSTRY>? It\'s a topic I\'ve been looking into lately and would love to get to know your perspective on it',
			'Information 3':
				'Hey {firstname}, based on your expertise in <INSERT INDUSTRY>, I\'d love to chat about your ideas related to an article I wrote on <INSERT TOPIC>: <INSERT ARTICLE LINK>. Let me know!',
		},
		[OnboardTemplateStep.FollowUps]: {
			'Information 1':
				'I\'m so curious to hear what you thought of the article I sent you last week, {firstname}. It\'s kind of a different take, hopefully, it was insightful!',
			'Information 2':
				'What did you think of the article, by the way? Would love to get your insights. I wonder what you thought of the article, {firstname}. My network has been kind of polarized on it.',
			'Information 3':
				'Did you find the article interesting, {firstname}?',
		},
	},
	Event: {
		[OnboardTemplateStep.Open]: {
			'Event Invitation 1':
				'Hey {firstname}, I hope you’re doing well! I wanted to see if you’d be interested in <INSERT EVENT>. Would love to extend an invite if interested!',
			'Event Invitation 2':
				'Hey {firstname}, just wanted to let you know that I will be having a webinar coming up and would love it if you could make it. Let me know!',
			'Event Invitation 3':
				'Hope you\'re doing well, {firstname}. I thought you might be interested in an event I have coming up focusing on <INSERT INDUSTRY/TOPIC> this upcoming <INSERT DATE>. Please let me know if you are available.',
			'Event Invitation 4':
				'Wondering if you\'d be free this <INSERT DATE>, {firstname}. We\'ve got a webinar planned on <INSERT THEME/TOPIC>. I just thought it might be of interest to you since I know you work in the <INSERT INDUSTRY> space.',
		},
		[OnboardTemplateStep.FollowUps]: {
			'Event Invitation 1':
				'Hi {firstname}, following up on my last message. As a reminder, we are hosting an <INSERT EVENT> for <INSERT JOB TITLE>s. We are collaborating with <INSERT ATTENDEES> The event, this <INSERT DATE> will be 100% virtual since we can’t meet in person just yet. Let me know if you’d like me to send the agenda/attendee list and we can set up some time this week to connect and go over details!',
			'Event Invitation 2':
				'Hey {firstname}! Hope your week is going well. Wondering if you have given any more thought to the webinar I was mentioning. Thought it might be an area of focus for you and your company right now.',
			'Event Invitation 3':
				'{firstname}, just wanted to update you on the webinar. We\'ve actually got lots of people signing up already! Any interest in attending?',
			'Event Invitation 4':
				'Hey just wanted to reach out again about the webinar. It\'s right around the corner! Let me know if I should mark you down as attending, {firstname}!',
		},
	},
	CheckIn: {
		[OnboardTemplateStep.Open]: {
			'Check In 1':
				'Hi {firstname}, it\'s been a while since we connected, thought I\'d check-in and see how things were going.',
			'Check In 2':
				'Long time no speak, {firstname}! How\'s business?',
			'Check In 3':
				'Hey, hope you and your family got to enjoy the <INSERT RECENT HOLIDAY> holiday! How have you been doing?',
			'Check In 4':
				'It\'s been a long time since we\'ve chatted, {firstname}! What\'s new with you and your business?',
		},
		[OnboardTemplateStep.FollowUps]: {
			'Check In 1':
				'Hey {firstname}, I\'m sure you\'ve been super busy as of late. Let me know if it would make sense to catch up some time soon, though!',
			'Check In 2':
				'Hey {firstname}, just circling back here, and I was thinking maybe it would be good to set up a chat to catch up soon!',
			'Check In 3':
				'{firstname}, I know you must be pretty busy. Let me know if I can do anything for you or anyone else in your network. I always just like to put that offer out there.',
			'Check In 4':
				'If I can do anything for you, your business, or anyone else in your network, just ping me. Maybe we can catch up at some point, too!',
		},
	},
	Business: {
		[OnboardTemplateStep.Open]: {
			'Business 1':
				'Hey {firstname}, it’s been a while since I first reached out to you. Thought I’d touch base and see if you’d had any more time to think about <INSERT TOPIC>?',
			'Business 2':
				'Hi {firstname}, I hope you\'ve been well. I\'d like to revisit our discussion on <INSERT TOPIC> if you\'re available for a quick 10-15 minute conversation this week. Talk soon!',
			'Business 3':
				'Hey {firstname}, I\'m offering a complimentary 30-minute session on <INSERT SESSION>, along with insights on <INSERT INSIGHTS>. There are still available times, so please let me know if you might be interested and we can get something on the calendar.',
			'Business 4':
				'It\'s been a long time since we\'ve chatted, {firstname}! What\'s new with you and your business?',
		},
		[OnboardTemplateStep.FollowUps]: {
			'Business 1':
				'Hey {firstname}, how are you? Last time we spoke you\'d mentioned reaching back out in <INSERT DATE>. Are you open to a conversation?',
		},
	},
};

export const NURTURE_REVIEW_SUB_DESCRIPTION = 'You can always edit your Nurture campaign in the future in your Campaign - Settings page.';

/**
 * Summary Page description about the nurture list option that is selected
 */
export const NurtureListSummaryText: Record<NurtureListOptionType, string> = {
	[NurtureListOptionType.Old]: 'Create Nurture List from Old Conversations',
	[NurtureListOptionType.NeverReplied]: 'Create Nurture List from Prospects who Never Replied',
	[NurtureListOptionType.Tag]: 'Create Nurture List from the Following Tags:',
	[NurtureListOptionType.Empty]: 'Start with an Empty Nurture List',
};

/**
 * Summary Page description when the nurture list or nurture messaging is incomplete
 */
export const NurtureReviewIncompleteText = {
	[WizardStepNode.NURTURE_LIST]: 'Click "Edit" to return and set up your nurture list.',
	[WizardStepNode.NURTURE_MESSAGING]: 'Click "Edit" to return and set up your messaging.',
};

/**
 * Text to be displayed in the sidebar for the selected nurture list option
 */
export const NurtureListWizardNavText: Record<NurtureListOptionType, string> = {
	[NurtureListOptionType.Old]: 'Create Nurture List from Old Conversations',
	[NurtureListOptionType.NeverReplied]: 'Create Nurture List from Prospects who Never Replied',
	[NurtureListOptionType.Tag]: 'Create Nurture List from Tags',
	[NurtureListOptionType.Empty]: 'Start with an Empty Nurture List',
};
