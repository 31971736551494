import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import {
	CampaignsUpdateFilterAction,
	CampaignsUpdateFilterActionType,
	CampaignsUpdatePaginationAction,
	CampaignsUpdatePaginationActionType,
	FetchCampaignsAction,
	FetchCampaignsActionType,
	load,
	loadError, loadOneError, loadOneSuccess,
	loadSuccess,
	loadSuccessWithFilter,
	ToggleCampaignStatusAction,
	ToggleCampaignStatusActionType,
} from '@copilot/common/pages/campaigns/data/actionCreators';
import { CampaignManager } from '@copilot/data';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { showNotification } from '@copilot/common/store/actionCreators/notification';
import { getCampaignToggleNotification } from '@copilot/common/components/notificationDescription/const/campaignToggle';
import { filterThenMapElements } from '@copilot/common/utils/common/array';
import { CampaignsFilters } from '../types';
import { CampaignsFilterRequestModel } from '@copilot/data/requests/models';
import { getPagedCampaigns } from './selectors';
import { CampaignModelState } from './reducer';
import createSagaMiddleware from 'redux-saga';
import { PaginationObject } from '@copilot/data/managers/base';
import { ConflictStatusCode } from '@copilot/data/responses/statusCodes';
import { CampaignLaunchErrors } from '@copilot/data/responses/interface';

/**
 * Build the CampaignsFilterRequestModel from filters
 * @param filter
 */
const convertFilterToRequestModel = (filter: CampaignsFilters): Partial<CampaignsFilterRequestModel> => ({
	OrgMemberId: {
		include: filterThenMapElements(filter.teamMembers, (f) => f.isVisible, (f) => f.key),
		exclude: [],
	},
});

function* fetchAllAsync(action: FetchCampaignsAction) {
	try {
		yield put(load());
		const payload: PromiseFactoryThenType<typeof CampaignManager.getCampaignsByOrganization> = yield call(CampaignManager.getCampaignsByOrganization, action.organizationId, action.pageNumber, action.pageSize, action.searchTerm);
		yield put(CampaignActions.loadCampaigns(payload.results)); // [TODO] THREE8-97 Hack to fix issues caused by aggressive caching
		yield put(loadSuccess(payload));
	} catch (error) {
		yield put(loadError(error));
	}
}

function* toggleStatusAsync(action: ToggleCampaignStatusAction) {
	try {
		yield call(CampaignManager.toggleCampaignStatus, action.campaignId, action.status);
		const payload: PromiseFactoryThenType<typeof CampaignManager.getCampaignByCamapignId> = yield call(CampaignManager.getCampaignByCamapignId, action.campaignId);
		yield put(loadOneSuccess(payload));
		// below is a hack to sync the two campaign stores!
		yield put(CampaignActions.updateCampaign({ id: action.campaignId, status: action.status }));
		yield put(showNotification(
			getCampaignToggleNotification('SUCCESS', true, action.status, action.campaignType, action.orgType),
			action.onNotificationButtonClick
		));
	} catch (error: any) {
		yield put(loadOneError(error));
		if (error.status === ConflictStatusCode && action.onConflictError) {
			yield call(action.onConflictError, error.data as CampaignLaunchErrors[]);
		} else {
			yield put(showNotification(
				getCampaignToggleNotification('ERROR', false, action.status, action.campaignType, action.orgType),
				action.onNotificationButtonClick
			));
		}
	}
}

/**
 * Fetch campaigns by updated filters
 * @param action
 */
function* updateFilterAsync(action: CampaignsUpdateFilterAction<CampaignsFilters>) {
	try {
		yield put(load());
		const currentState: undefined | CampaignModelState<CampaignsFilters> = yield select(getPagedCampaigns);
		const filter = convertFilterToRequestModel({ ...currentState?.filter, ...action.filter });
		// TODO: Replace hardcoded pagination with currenState
		const payload: PromiseFactoryThenType<typeof CampaignManager.getCampaignsByOrganization> = yield call(CampaignManager.getCampaignsByOrganization, action.orgId, 0, 1000, action.searchTerm, filter);
		yield put(CampaignActions.loadCampaigns(payload.results));
		yield put(loadSuccessWithFilter(payload, filter, action.searchTerm));
	} catch (error) {
		yield put(loadError(error));
	}
}

function* updatePaginationAsync(action: CampaignsUpdatePaginationAction) {
	try {
		yield put(load());
		const currentState: CampaignModelState<CampaignsFilters> = yield select(getPagedCampaigns);
		const pagination = new PaginationObject({
			page: (action.page ?? 1) - 1,
			pageSize: action.pageSize,
		});
		const filters = convertFilterToRequestModel(currentState.filter);
		const payload: PromiseFactoryThenType<typeof CampaignManager.getCampaignsByOrganization> = yield call(CampaignManager.getCampaignsByOrganization, action.orgId, pagination.page, pagination.pageSize, currentState.searchTerm, filters);
		yield put(loadSuccess(payload));
	} catch (error) {
		yield put(loadError(error));
	}
}

/**
 * Watches the fetch campaigns action.
 */
function* watchFetchCampaigns() {
	yield takeLatest(FetchCampaignsActionType, fetchAllAsync);
}

function* watchToggleCampaignStatus() {
	yield takeLatest(ToggleCampaignStatusActionType, toggleStatusAsync);
}

function* watchUpdateFilter() {
	yield takeLatest(CampaignsUpdateFilterActionType, updateFilterAsync);
}

function* watchUpdatePage() {
	yield takeLatest(CampaignsUpdatePaginationActionType, updatePaginationAsync);
}

/**
 * Apply Campaigns sagas to the saga middleware
 * @param sagaMiddleware
 */
export const registerCampaignsSaga = (sagaMiddleware: ReturnType<typeof createSagaMiddleware>): void => {
	sagaMiddleware.run(watchFetchCampaigns);
	sagaMiddleware.run(watchToggleCampaignStatus);
	sagaMiddleware.run(watchUpdateFilter);
	sagaMiddleware.run(watchUpdatePage);
};
