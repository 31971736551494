import React from 'react';
import { ExclamationCircleTwoTone, CheckCircleOutlined } from '@ant-design/icons';
import {
	Row, Col, Button, Modal,
} from 'antd';
import BaseModal from '../base';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';

const StyledCheckCircleIcon = styled(CheckCircleOutlined)`
	color: ${(props) => props.theme['@primary-color']};
	fontsize: '1.5em';
`;
const RowWidth = styled(Row)`
	width: 70%;
	padding-bottom: 0.5em;
`;

interface CampaignCreationAlertModalProps extends React.ComponentProps<typeof Modal> {
	onContinue?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
}

const CampaignCreationAlertModal = React.memo<CampaignCreationAlertModalProps>((props) => {
	const { width = 800, centered = true, ...rest } = props;
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);

	const header = React.useMemo(() => (
		<>
			<Row justify="center">
				<Col>
					<ExclamationCircleTwoTone style={{ fontSize: '9em' }} />
				</Col>
				<br />
			</Row>
			<Row justify="center" gutter={[16, 16]}>
				<Col span={17}>
					<h2>
						Hey
						{activeMember?.firstName && ` ${activeMember.firstName}`}
						,
					</h2>
					<p>
						You have reached the campaign limit on your current plan. If you want to
						run another campaign,
						{' '}
						<strong>
							you can upgrade now and your account will be charged when it’s
							activated.
						</strong>
					</p>
				</Col>
			</Row>
		</>
	), [activeMember?.firstName]);

	const table = React.useMemo(() => (
		<>
			<Row justify="center" gutter={[16, 16]}>
				<Col span={17}> With a new campaign, you can:</Col>
				<RowWidth justify="space-between">
					<Col span={10}>
						<StyledCheckCircleIcon />
						{' '}
						Target different audience
					</Col>
					<Col span={10}>
						<StyledCheckCircleIcon />
						{' '}
						A/B test your campaigns
					</Col>
				</RowWidth>
			</Row>
			<Row justify="center" gutter={[16, 16]}>
				<RowWidth justify="space-between">
					<Col span={10}>
						<StyledCheckCircleIcon />
						{' '}
						Test different template messages
					</Col>
					<Col span={10}>
						<StyledCheckCircleIcon />
						{' '}
						Set invite levels across campaigns
					</Col>
				</RowWidth>
			</Row>
			<br />
		</>
	), []);

	const onCancel = React.useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => props.onCancel?.(e),
		[props.onCancel]
	);

	const onContinue = React.useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			props.onContinue?.(e);
			onCancel(e);
		},
		[props.onContinue, onCancel]
	);

	const footer = React.useMemo(() => (
		<>
			<br />
			<Row justify="center">
				<Col span={8}>
					<Button type="primary" block onClick={onContinue}>
						Continue
					</Button>
				</Col>
			</Row>
			<br />
			<Row justify="center">
				<Col span={8}>
					<Button block type="link" onClick={onCancel} style={{ color: 'grey' }}>
						No thanks
					</Button>
				</Col>
			</Row>
			<br />
		</>
	), [props.onCancel, props.onContinue]);

	return (
		<BaseModal {...rest} width={width} centered={centered}>
			{header}
			{table}
			<Row justify="center" gutter={[16, 16]}>
				<Col span={17}>
					<p>
						If you like, you can set up your campaign now, save your credentials, and
						activate it later.
					</p>
				</Col>
			</Row>

			{footer}
		</BaseModal>
	);
});

export default CampaignCreationAlertModal;
