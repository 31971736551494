import React, { CSSProperties } from 'react';
import { Layout, Tabs } from 'antd';
import { TabsProps, TabPaneProps } from 'antd/lib/tabs';
import styled from 'styled-components';

// Convert to use theme context?
const headerBackgroundColor = 'white';
const verticalPadding = '12px';
const sidePadding = '24px';

export interface HeaderContentLayoutProps {
	style?: CSSProperties;
}

interface LayoutHeaderProps extends React.ComponentProps<typeof Layout.Header> {}

const Header = styled(Layout.Header)`
	&& {
		background-color: ${headerBackgroundColor};
		height: auto;
		padding: ${verticalPadding} ${sidePadding};
	}
`;

const LayoutHeader: React.SFC<LayoutHeaderProps> = (props: LayoutHeaderProps) => {
	const { children, ...rest } = props;
	return <Header {...rest}>{children}</Header>;
};

interface LayoutContentProps extends React.ComponentProps<typeof Layout.Content> {}

const LayoutContent: React.SFC<LayoutContentProps> = (props: LayoutContentProps) => {
	const { children, ...rest } = props;
	return <Layout.Content {...rest}>{children}</Layout.Content>;
};

interface LayoutTabsProps extends TabsProps {
	children?: React.ReactNode;
}

const TabBarStyle = {
	backgroundColor: headerBackgroundColor,
	padding: `0px ${sidePadding}`,
};

const StyledDiv = styled.div`
	${(props) => {
		const prefix = props.theme['@ant-prefix'];
		return `
			.${prefix}-tabs.${prefix}-tabs-card > .${prefix}-tabs-nav .${prefix}-tabs-tab-active {
				border: 1px solid ${props.theme['@primary-color']};
				border-bottom: 1px solid #e8e8e8;
			}
			
			.${prefix}-tabs-content-holder {
				padding: 0 16px
			}
		`;
	}}
`;

const LayoutTabs: React.SFC<LayoutTabsProps> = (props: LayoutTabsProps) => {
	const { children, ...rest } = props;
	return (
		<StyledDiv>
			<Tabs tabBarStyle={TabBarStyle} type="card" {...rest}>
				{children}
			</Tabs>
		</StyledDiv>
	);
};

interface LayoutTabPaneProps extends TabPaneProps {
	children?: React.ReactNode;
}

const LayoutTabPanes: React.SFC<LayoutTabPaneProps> = (props: LayoutTabPaneProps) => (
	<Tabs.TabPane {...props}><div style={{ padding: 16 }}>{props.children}</div></Tabs.TabPane>
);

class HeaderContentLayout extends React.Component<HeaderContentLayoutProps> {
	static Header: typeof LayoutHeader = LayoutHeader;
	static Content: typeof LayoutContent = LayoutContent;
	static Tabs: typeof LayoutTabs = LayoutTabs;
	static TabPanes: typeof LayoutTabPanes = LayoutTabPanes;
	constructor(props: HeaderContentLayoutProps) {
		super(props);
	}

	render() {
		return <Layout style={this.props.style}>{this.props.children}</Layout>;
	}
}

export default HeaderContentLayout;
