import ConnectionSelectors from './selector';
import ConnectionsViewManager from '@copilot/data/managers/connectionsViewManager';
import { PaginatedResponse } from '@copilot/data/responses/interface';
import createSagaMiddleware from '@redux-saga/core';
import {
	call, put, select, takeLatest,
} from '@redux-saga/core/effects';
import {
	ConnectionsLoadAction,
	ConnectionsLoadActionType,
	loadConnectionsFailed,
	loadConnectionsSuccess,
} from './actionCreators';
import { ConnectionModelState } from './reducers';
import { ConnectionViewModel } from './types';
import { IPaginatedQuery } from '@copilot/data/requests/models';

/**
 * load connections
 * @param action
 */
function* loadAsync(action: ConnectionsLoadAction) {
	try {
		const currentState: ConnectionModelState = yield select(ConnectionSelectors);
		const query: IPaginatedQuery = {
			orgId: action.organizationId,
			page: currentState.page,
			pageSize: currentState.pageSize,
			queryModel: {
				SortBy: 'lastThreadActivity',
				OrderDescending: false,
				SearchTerm: null,
				FilterBy: [],
			},

		};
		const result: PaginatedResponse<ConnectionViewModel> = yield call(
			ConnectionsViewManager.getConnections,
			query
		);
		yield put(loadConnectionsSuccess(result));
	} catch {
		yield put(loadConnectionsFailed());
	}
}

function* watchLoad() {
	yield takeLatest(ConnectionsLoadActionType, loadAsync);
}

export const registerConnectionsSaga = (
	sagaMiddleware: ReturnType<typeof createSagaMiddleware>
): void => {
	sagaMiddleware.run(watchLoad);
};
