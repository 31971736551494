/**
 * Generate the inverse of a given colour hex
 */
export const invertColorHex = (hex: string) => {
	const inverse = (figure: number) => (figure & 0x000000) | (~figure & 0xffffff);
	return (
		`#${
			inverse(parseInt(hex.substr(1), 16))
				.toString(16)
				.padStart(6, '0')
				.toUpperCase()}`
	);
};

/**
 * Returns the rgba form of the given hex colour with the given alpha value set
 * @throws if the hex string does not have exactly 7 characters
 */
export const hexToRgba = (hex: string, alpha = 1, useAlpha = true) => {
	if (hex.length != 7)
		throw Error(`Expected a hex string of the form '#rrggbb'; got '${hex}'`);
	// Source: https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
	const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
	return `rgb${useAlpha ? 'a' : ''}(${r},${g},${b}${useAlpha ? `,${alpha}` : ''})`;
};
