import React from 'react';
import { PlusOutlined, StopOutlined } from '@ant-design/icons';
import { Tag, Row, theme } from 'antd';
import { FilterDefinition } from '../componentModels/filterDefinition';
import styled from 'styled-components';

const { useToken } = theme;

const iconStyle = { marginLeft: '3px', right: '16px' };

export interface IFilterItemProps {
	selectedFilter: FilterDefinition;
	isExcludable: boolean;
	updateFilterItem: (value: FilterDefinition, isExclude?: boolean, isVisible?: boolean) => void;
	isVisible: boolean;
}


/**
 * The "props" for TagStyle.
 */
interface TagStyleProps {
	/**
	 * Whether or not the tag is excludable.
	 */
	isExcludable: boolean;
}

const TagStyle = styled(Tag).attrs<TagStyleProps>((props) => ({
	isExcludable: props.isExcludable || false,
}))<TagStyleProps>`
	&& {
		font-size: 0.85em;
		display: block;
		margin-right: 0;
		margin-top: 1px;
		position: relative;
		padding-right: ${(props) => (props.isExcludable ? 18 * 2 : 18)}px;
		white-space: nowrap;
		overflow: hidden;
		box-sizing:border-box;
		text-overflow: ellipsis;
		
		& > span {
		    position: absolute;
		    right: 2px;
			top: 4px;
			font-size: 12px;
		}
	}
`;

const FilterItem: React.FC<IFilterItemProps> = (props) => {
	const {
		isExcludable, selectedFilter, updateFilterItem, isVisible,
	} = props;
	const handleExclude = () => {
		updateFilterItem(selectedFilter, !selectedFilter.isExclude);
	};

	const handleRemove = () => {
		updateFilterItem(selectedFilter, selectedFilter.isExclude, false);
	};
	
	const { token } = useToken();

	return (
		<Row style={{ display: 'block' }}>
			<TagStyle
				isExcludable={isExcludable}
				key={selectedFilter.key}
				color={selectedFilter.isExclude ? token.colorError : token.colorPrimary}
				closable
				onClose={handleRemove}
				visible={isVisible}
			>
				{' '}
				{selectedFilter.displayName ?? selectedFilter.label}
				{isExcludable && (selectedFilter.isExclude ? (
					<PlusOutlined
						style={iconStyle}
						onClick={handleExclude}
					/>
				)
					: (
						<StopOutlined
							style={iconStyle}
							onClick={handleExclude}
						/>
					)
				)}
			</TagStyle>
		</Row>
	);
};

export default FilterItem;
