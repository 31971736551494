import React from 'react';
import { Config } from '@copilot/common/config';
import { AgencyCode } from '@copilot/common/config/constant';

type ErrorMessagesType = Readonly<Record<string, React.ReactNode>>;

const ErrorMessages: ErrorMessagesType = {
	UnknownUser: (
		<span>
			Hmmm, it seems that email doesn’t exist on our platform. Try logging in with a different
			email
			{Config.supportEmail && Config.agencyCode !== AgencyCode.prosocial ? (
				<span>
					, or contact
					{' '}
					<a href={`mailto:${Config.supportEmail}`}>{Config.supportEmail}</a>
					{' '}
					to recover your username
				</span>
			) : (
				''
			)}
			.
		</span>
	),

	Expired: (
		<span>
			Oops! Looks like the link you used has expired. Please request a new password reset link
			from the login page using the ‘Forgot Password’ button.
			{Config.supportEmail && Config.agencyCode !== AgencyCode.prosocial ? (
				<span>
					If the problem persists, please contact
					{' '}
					<a href={`mailto:${Config.supportEmail}`}>{Config.supportEmail}</a>
				</span>
			) : (
				''
			)}
		</span>
	),

	Password: `
		Something doesn’t look right. All passwords must be a minimum of 8 characters in length and
		require 1 upper case letter, 1 lower case letter, 1 number, and 1 special character: !, @, #, $, %, ^, &, *, ().
	`,

	Empty: 'Woops! Please complete the fields below before submitting.',
};

export const LogInErrorMessagesMapping: ErrorMessagesType = {
	'Invalid User': ErrorMessages.UnknownUser,
	UserIdNotFound: ErrorMessages.UnknownUser,
	UserNameNotFound: ErrorMessages.UnknownUser,
	InvalidToken: ErrorMessages.Expired,
	PasswordMismatch: ErrorMessages.Password,
	PasswordRequireDigit: ErrorMessages.Password,
	PasswordRequireLower: ErrorMessages.Password,
	PasswordRequireNonLetterOrDigit: ErrorMessages.Password,
	PasswordTooShort: ErrorMessages.Password,
	PasswordRequireUpper: ErrorMessages.Password,
	'Empty Fields': ErrorMessages.Empty,
};
