import React from 'react';
import { Col, Row, Button } from 'antd';

export interface IToken {
	label: string;
	value: string;
}

interface MessageEditorTokensProps {
	tokens: IToken[];
	onTokenClick: (token: IToken) => void;
}

const MessageEditorTokens: React.FC<MessageEditorTokensProps> = (props) => {
	const { tokens = [] } = props;

	const handleClick = React.useCallback(
		(t: IToken) => () => {
			props.onTokenClick?.(t);
		},
		[props.onTokenClick]
	);

	return (
		<Row gutter={16}>
			{tokens.map((t) => (
				<Col key={t.label}>
					<Button onClick={handleClick(t)} block>{t.label}</Button>
				</Col>
			))}
		</Row>
	);
};

export default MessageEditorTokens;
