import React, { useEffect, useState } from 'react';
import {
	Input, Button, Form, Popover, Typography,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import LinkedInSideInfo from '@copilot/common/components/linkedin/login/sideInfo';

interface LinkedInPinFormProps {
	isSubmitting?: boolean;
	onSubmit: (pin: string) => Promise<void>;
	colLayout?: Partial<{
		labelCol: FormProps['labelCol'];
		wrapperCol: FormProps['wrapperCol'];
	}>;
	onClickSupport: () => void;
}

const validatePin = (rule: any, value: any, callback: any) => {
	if (!value) callback('Please enter the 6 character PIN.');
	else if (value.length < 6) callback('PIN must be at least 6 characters.');
	else callback();
};

const LinkedInPinForm: React.FC<LinkedInPinFormProps> = (props) => {
	const {
		isSubmitting, onSubmit, colLayout, onClickSupport,
	} = props;

	const [form] = Form.useForm();

	const handleFinish = (values: { pin: string }) => {
		onSubmit(values.pin).catch(() => form.resetFields(['pin']));
	};

	const [IsPinHelpClicked, setIsPinHelpClicked] = useState<boolean>(false);

	useEffect(() => {
		if (IsPinHelpClicked) onClickSupport();
	}, [IsPinHelpClicked]);

	return (
		<Form
			form={form}
			style={{ textAlign: 'center' }}
			onFinish={handleFinish}
			layout="vertical"
			scrollToFirstError
			{...colLayout}
		>

			<Form.Item
				name="pin"
				label={<Typography.Text strong style={{ fontSize: 'small' }}>Enter 6-digit verification pin</Typography.Text>}
				rules={[{ required: false, validator: validatePin }]}
				hasFeedback
			>
				<Input type="pin" data-private placeholder="- - - - - -" disabled={isSubmitting} />
			</Form.Item>
			<Form.Item>
				<Popover placement="right" trigger="click" content={<LinkedInSideInfo />}>
					<Button onClick={() => setIsPinHelpClicked(true)} type="link">Having trouble with your pin?</Button>
				</Popover>
			</Form.Item>

			<Form.Item>
				<Button type="primary" block htmlType="submit" loading={isSubmitting}>
					Submit
				</Button>
			</Form.Item>
		</Form>
	);
};

export default LinkedInPinForm;
