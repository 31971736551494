import { ButtonGroupProps } from 'antd/lib/button';
import ButtonGroup from 'antd/lib/button/button-group';
import React from 'react';
import styled from 'styled-components';

interface IStyledButtonGroupProps {
	radius?: number;
}
const StyledButtonGroup = styled(ButtonGroup) <IStyledButtonGroupProps>`
	${(props) => {
		const prefix = props.theme['@ant-prefix'];
		const borderRadius = props.radius ?? 15;
		return `
			&&& {
				.${prefix}-btn:first-child {
					border-top-left-radius: ${borderRadius}px;
				    border-bottom-left-radius: ${borderRadius}px;
				}

				.${prefix}-btn:last-child {
					border-top-right-radius: ${borderRadius}px;
				    border-bottom-right-radius: ${borderRadius}px;
				}
			}
		`;
	}}
`;

interface IPillButtonProps extends ButtonGroupProps {
	/**
	 * Border radius of the pill.
	 * @default 15
	 */
	radius?: number;
}

/**
 * Create a buttonGroup that is styled to be a pill
 */
const PillButtonGroup: React.FC<IPillButtonProps> = (props) => <StyledButtonGroup {...props} />;
export default PillButtonGroup;
