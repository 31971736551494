import { BaseModel } from './base';
import { attr } from 'redux-orm';
import { CampaignApprovalStatus, CampaignType } from '@copilot/data/responses/interface';
import { CampaignStatusEnum } from '@copilot/data/requests/models';

interface IBaseClient {
	id: string;
	name: string;
	organizationId: string;
	billingEndDate: string;
	customerSuccessName: string;
	totalActiveCampaigns: number;
	monthlyInvite: number;
	monthlyMessage: number;
	status: string;
}

export interface ITeamClientMember {
	name: string;
	orgMemberId: string;
	email: string;
	isLoggedOut: boolean;
	vpnPort: number;
	isEnabled: boolean;
	isActive: boolean;
	orgRole: string;
	linkedInError: number;
	weeksBeforeWithdrawal: number;
	withdrawExternalInvites: boolean;
}

export interface ClientCampaign {
	campaignMemberId: string;
	id: string;
	isCampaignRunning: boolean;
	isPaused: boolean;
	linkedInError: number;
	unpauseDate: Date;
	campaignType: CampaignType;
	approvalStatus: CampaignApprovalStatus;
	status: CampaignStatusEnum;
	name: string;
}

export interface ITeamClient extends IBaseClient {
	/** MaxSeats === null when EnhancedBillingFeature turned on. */
	maxSeats?: number;
	/** Max smart reply credits */
	maxSmartReplyCredits: number;
	members: ITeamClientMember[];
	ownerId: string;
	instanceId: string;
	maxInvitesCount: number;
	maxMessagesCount: number;
	customerSuccessUserId: string;
	seatsAllocated: number;
	activeMemberCount: number;
	tenantId: string;
}

export interface IClient extends IBaseClient {
	firstName: string;
	lastName: string;
	userName: string;
	userId: string;
	organizationMemberId: string;
	customerSuccessId: string;
	campaigns: { [key:string]:ClientCampaign };
	isCampaignRunning: boolean;
	daysLastSeen: number;
	monthlyInvite: number;
	monthlyMessage: number;
	isSubscriptionActive: boolean;
	inviteProgress: number;
	serviceStartDate: string;
	serviceStopDate: string;
	stats: any;
	linkedInError: number;
	isEnabled: boolean;
	isPaused: boolean;
	unpauseDate: string;
	hasMessageTemplateAccess: boolean;
	activationsRequired: number;
	approvedCampaigns: number;
	isAdvanced: boolean;
}

export class Client extends BaseModel<typeof Client, IClient> {
	static modelName = 'Client';
	static fields = {
		id: attr(),
		name: attr(),
		firstName: attr(),
		lastName: attr(),
		userName: attr(),
		userId: attr(),
		organizationId: attr(),
		organizationMemberId: attr(),
		campaignId: attr(),
		campaignMemberId: attr(),
		isCampaignRunning: attr(),
		daysLastSeen: attr(),
		monthlyInvite: attr(),
		monthlyMessage: attr(),
		billingEndDate: attr(),
		isSubscriptionActive: attr(),
		inviteProgress: attr(),
		customerSuccessName: attr(),
		customerSuccessId: attr(),
		status: attr(),
		serviceStartDate: attr(),
		serviceStopDate: attr(),
		stats: attr(),
		linkedInError: attr(),
		isEnabled: attr(),
		isPaused: attr(),
		unpauseDate: attr(),
		hasMessageTemplateAccess: attr(),
		activationsRequired: attr(),
		approvedCampaigns: attr(),
		totalActiveCampaigns: attr(),
		isAdvanced: attr(),
	};
}

export class TeamClient extends BaseModel<typeof TeamClient, ITeamClient> {
	static modelName = 'TeamClient';
	static fields = {
		id: attr(),
		name: attr(),
		ownerId: attr(),
		instanceId: attr(),
		maxInvitesCount: attr(),
		maxMessagesCount: attr(),
		organizationId: attr(),
		maxSeats: attr(),
		billingEndDate: attr(),
		customerSuccessName: attr(),
		customerSuccessId: attr(),
		totalActiveCampaigns: attr(),
		seatsAllocated: attr(),
		activeMemberCount: attr(),
		maxSmartReplyCredits: attr(),
		tenantId: attr(),
	};
}
