import React, { useCallback } from 'react';
import {
	Row, Col, Button, Divider,
} from 'antd';
import BaseModal from '../base';
import WistiaPlayer from '@copilot/common/components/embed/wistia';
import { VideoModalDetails } from '@copilot/common/components/modals/wrappers/video/const';
import { ISalesNavTutorialModalProps } from './const';

/**
 * Sales Navigator Tutorial Video Modal for New User Onboarding Flow
 * @param {function} onCancel called to close the modal, passed in automatically
 */
const SalesNavTutorialModal = React.memo<ISalesNavTutorialModalProps>((props) => {
	const { onCancel } = props;

	const closeModal = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCancel?.(event), [onCancel]);

	return (
		<BaseModal {...props}>
			<Row>
				<Col>
					<h3 style={{ fontWeight: 'bold', marginBottom: 0 }}>Sales Navigator Tutorial</h3>
				</Col>
			</Row>
			<Divider style={{ margin: '16px 0' }} />
			<Row>
				<Col>
					<p>
						Learn how to build your search URL and input it into CoPilot AI.
					</p>
				</Col>
			</Row>
			<Row style={{ marginBottom: '24px' }}>
				<Col span={24}>
					<WistiaPlayer videoHash={VideoModalDetails.SearchVideo.url} />
				</Col>
			</Row>
			<Row justify="end">
				<Col>
					<Button type="primary" onClick={closeModal}>
						Got it
					</Button>
				</Col>
			</Row>
		</BaseModal>
	);
});

export default SalesNavTutorialModal;
