import BasicContainer from '@copilot/common/components/containers/basic';
import BubbleCheckboxGroup from '@copilot/common/components/forms/common/generics/checkboxes/bubbleGroup';
import OnboardWizardOccupationDetails from '@copilot/common/pages/onboard/wizard/occupationDetails';
import { FC } from 'react';

interface IWithExtraOccupations {
	/**
	 * elected years of work experience
	 */
	experience: string[];
	/**
	 * true if selected criteria to target those with recent job change
	 */
	recentJobChange: boolean;
	/**
	 * selected company headcount
	 */
	headCount: string[];
	/**
	 * selected selected exclude keywords
	 */
	exclusions: string[];
	/**
	 * options to display for experience
	 */
	experienceOptions: React.ComponentProps<typeof BubbleCheckboxGroup>['options'];
	/**
	 * options to display for headCount
	 */
	headCountOptions: React.ComponentProps<typeof BubbleCheckboxGroup>['options'];
	/**
	 * callback on updating experience
	 */
	handleExperienceChange: (values: string[]) => void;
	/**
	 * callback on updating recent job change
	 */
	handleRecentJobChange: (values: boolean) => void;
	/**
	 * callback on updating headcount
	 */
	handleHeadCountChange: (values: string[]) => void;
	/**
	 * callback on updating exclusions
	 */
	handleExclusionsChange: (values: string[]) => void;
}

/**
 * [HOC] Add extra occupation details to component
 */

const withExtraOccupations = <T,>(WrappedComponent: FC<T>) => (props: T & IWithExtraOccupations): JSX.Element => {
	const {
		experience, recentJobChange, headCount, exclusions, handleExperienceChange, handleRecentJobChange, handleHeadCountChange, handleExclusionsChange,
		experienceOptions, headCountOptions,
	} = props;

	return (
		<>
			<WrappedComponent
				{...props}
			/>
			<BasicContainer>
				<BasicContainer.Content>
					<OnboardWizardOccupationDetails
						recentJobChange={recentJobChange}
						experience={experience}
						headCount={headCount}
						exclusions={exclusions}
						handleExperienceChange={handleExperienceChange}
						handleExclusionsChange={handleExclusionsChange}
						handleHeadCountChange={handleHeadCountChange}
						handleRecentJobChangeUpdate={handleRecentJobChange}
						experienceOptions={experienceOptions}
						headCountOptions={headCountOptions}
					/>
				</BasicContainer.Content>
			</BasicContainer>
		</>
	);
};
export default withExtraOccupations;
