import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { addScriptToDocument } from '@copilot/common/utils/scripts';
import { Config } from '@copilot/common/config';
import _ from 'lodash';

/**
 * HubSpot component to inject the tracking code needed to display popup surveys in the entire app
 */
const HubSpot: React.FC<{}> = () => {
	// get the current user
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);

	useEffect(() => {
		// do not load script for reseller envs
		if (!Config.isAgency) {
			// load hubspot tracking script so hubspot is aware of page activity
			addScriptToDocument('https://js.hs-scripts.com/22312186.js', 'hubspot_script');
	
			const email = activeMember?.email;
			if (
				!_.isNil(email)
			) {  
				// use the tracking api to identify the user by making them view a page
				const _hsq = window._hsq ?? [];
				_hsq.push(['identify', {
					email: email,
				}]);
				_hsq.push(['trackPageView']);			
			}
		}
	}, [activeMember?.email]);
	
	return null;
};

export default HubSpot;