import Store from '..';
import { createSelector } from 'redux-orm';
import { IClientSelectors } from '../interface/selectors';

class TeamClientSelectors implements IClientSelectors {
	public getClients = createSelector(
		Store.ORM,
		(session) => session.TeamClient.all().toRefArray()
	);
}

const instance = new TeamClientSelectors();
export default instance;
