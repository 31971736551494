import React from 'react';
import {
	Affix, Row, Col, Button,
} from 'antd';
import BasicContainer from '@copilot/common/components/containers/basic';

interface CreateCampaignWizardFooterProps {
	isHidden?: boolean;
	isLoading?: boolean;
	isBackDisable?: boolean;
	isNextDisable?: boolean;
	backText?: string;
	nextText?: string;
	onBack?: React.ComponentProps<typeof Button>['onClick'];
	onNext?: React.ComponentProps<typeof Button>['onClick'];
}
const CampaignOnboardWizardFooter = React.memo<CreateCampaignWizardFooterProps>((props) => {
	const {
		isHidden = false,
		isLoading = false,
		isBackDisable = false,
		isNextDisable = false,
		backText = 'Back',
		nextText = 'Next',
		onBack,
		onNext,
	} = props;

	return isHidden ? null : (
		<Affix offsetBottom={0}>
			<Row justify="center">
				<Col offset={3} span={20}>
					<BasicContainer>
						<BasicContainer.Content
							style={{
								boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.5)',
								zIndex: 1,
								position: 'relative',
							}}
						>
							<Row justify="space-between">
								<Col>
									{backText ? (
										<Button
											disabled={isBackDisable || isLoading}
											onClick={onBack}
										>
											{backText}
										</Button>
									) : null}
								</Col>
								<Col>
									{nextText ? (
										<Button
											type="primary"
											disabled={isNextDisable || isLoading}
											onClick={onNext}
										>
											{nextText}
										</Button>
									) : null}
								</Col>
							</Row>
						</BasicContainer.Content>
					</BasicContainer>
				</Col>
			</Row>
		</Affix>
	);
});
export default CampaignOnboardWizardFooter;
