import React from 'react';
import {
	AutoComplete, Button, Col, Row,
} from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { SelectValue } from 'antd/lib/select';
import IdOption from './option';

interface IdFilterProps extends AutoCompleteProps {
	models: any[];
	columnKey: string;
	confirm?: () => void;
	resetFields?: () => void;
	setModel?: (selectedKeys: string[]) => void;
}

const preventDefault = (e: React.MouseEvent<HTMLSpanElement>) => e.preventDefault();
// TODO: specify type for option
const filterOption = (inputValue: string, option: any) => {
	const modelValue = option.value;
	if (modelValue === undefined) return true;
	if (typeof modelValue === 'string')
		return modelValue.toUpperCase().includes(inputValue.toUpperCase());
	else return ((modelValue as unknown) as string) == inputValue;
};

const IdFilter: React.FC<IdFilterProps> = (props) => {
	const {
		models, confirm, setModel, resetFields, ...rest
	} = props;
	const [selectedModels, setSelectedModels] = React.useState<{ [k: string]: boolean }>({});
	const handleDone = React.useCallback(() => {
		confirm?.();
	}, [confirm]);
	const handleReset = React.useCallback(() => {
		setSelectedModels({});
		resetFields?.();
	}, [resetFields]);
	const handleSelect = React.useCallback(
		(value: SelectValue, option: Record<string, any>) => {
			const modelId = option.key;
			setSelectedModels((existingModels) => {
				let newModels;
				if (existingModels[modelId]) {
					const { [modelId]: _removed, ...others } = existingModels;
					newModels = others;
				} else {
					newModels = { ...existingModels, [modelId]: true };
				}
				setModel?.(Object.keys(newModels));
				return newModels;
			});
		},
		[setModel]
	);
	const dataSource = React.useMemo(() => {
		const tagOptions = Array.isArray(models)
			? models.map((t) => (
				<AutoComplete.Option key={t.id} value={t.id}>
					<span onClick={preventDefault}>
						<IdOption
							model={t}
							columnKey={props.columnKey}
							selected={selectedModels[t.id] === true}
						/>
					</span>
				</AutoComplete.Option>
			))
			: [];
		return [
			// <AutoComplete.OptGroup key="campaign-id" label="All Tags">
			...tagOptions,
			// </AutoComplete.OptGroup>,
			<AutoComplete.Option
				style={{ bottom: '0px', height: '42px', position: 'fixed' }}
				key="footer"
				value="footer"
				disabled
			>
				<Row gutter={16}>
					<Col>
						<Button type="primary" onClick={handleDone}>
							Done
						</Button>
					</Col>
					<Col>
						<Button type="default" onClick={handleReset}>
							Reset
						</Button>
					</Col>
				</Row>
			</AutoComplete.Option>,
		];
	}, [models, selectedModels, handleReset, handleDone]);
	return (
		<Row style={{ padding: '10px' }}>
			<Col>
				<AutoComplete
					{...rest}
					// TODO: [AntdUpgrade] handle dropdownMenuStyle (not available in v4) - add as CSS
					// dropdownMenuStyle={{
					// 	maxHeight: '250px',
					// 	marginBottom: '42px',
					// }}
					backfill
					defaultActiveFirstOption={false}
					filterOption={filterOption}
					onSelect={handleSelect}
					// TODO: [AntdUpgrade] handle optionLabelProp (not available in v4) - set Option value directly
					// optionLabelProp="label"
					placeholder="Search for a team member..."
					getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
				>
					{dataSource}
				</AutoComplete>
			</Col>
		</Row>
	);
};

export default IdFilter;
