import {
	FC, useContext,
} from 'react';
import { Row, Col } from 'antd';
import Text from 'antd/lib/typography/Text';
import BasicContainer from '@copilot/common/components/containers/basic';
import styled, { ThemeContext } from 'styled-components';
import { ReactComponent as QuickReplyOutlined } from '@copilot/common/assets/icon/onboardingCategorizationOptionIcon.svg';
import { MessageName, MessageTypeEnum } from '@copilot/common/pages/onboard/wizard/messaging/const';

interface ICategoryContainerProps {
	hoverhighlightcolor: string;
}

const selectedClassName = 'selected';
const iconClassName = 'icon';

const CategoryContainer = styled(BasicContainer)<ICategoryContainerProps>`
	cursor: pointer;
	outline: 1px solid rgba(0, 0, 0, 0.07);
	height: 100%;

	:hover,
	.${selectedClassName} {
		color: ${(props: ICategoryContainerProps) => props.hoverhighlightcolor};
		fill: ${(props: ICategoryContainerProps) => props.hoverhighlightcolor};
		outline: 1px solid ${(props: ICategoryContainerProps) => props.hoverhighlightcolor};
	}

	> * {
		height: 100%;
	}

	.${iconClassName} {
		transform: scale(5/6);
		margin-right: 5px;

		@media only screen and (max-width: 900px) {
			display: none;
		}
	}

	.content {
		display: flex;
		align-items: center;
		padding-top: 17.5px;
	}
`;

export interface IMessagingCategoryOptionsProps {
	onClickCategory: (selectedCategory: MessageTypeEnum) => void;
	categories: MessageTypeEnum[];
	selection?: MessageTypeEnum;
}

/**
 * Allows user to select messaging template categories
 * @param onClickSelected called when the user clicks a category
 * @param categories the list of categories to be displayed
 * @param selection the title of the currently selected category (optional)
 */
export const MessagingCategoryOptions: FC<IMessagingCategoryOptionsProps> = (props) => {
	const { onClickCategory, categories, selection } = props;
	const themeContext = useContext(ThemeContext);

	return (
		<>
			<Row style={{ marginBottom: '20px' }}>
				<Col>
					<Text strong>Choose a template to get started</Text>
				</Col>
			</Row>
			<Row style={{ marginBottom: '20px' }} gutter={[16, 16]}>
				{categories.map((category) => {
					const isSelected = category === selection;
					return (
						<Col span={8} key={category}>
							<CategoryContainer hoverhighlightcolor={themeContext['@primary-color']}>
								<BasicContainer.Content
									className={isSelected ? selectedClassName : ''}
									onClick={() => onClickCategory(category)}
								>
									<QuickReplyOutlined className={iconClassName} />
									<Text strong>{MessageName[category]}</Text>
								</BasicContainer.Content>
							</CategoryContainer>
						</Col>
					);
				})}
			</Row>
		</>
	);
};
