import React from 'react';
import { isReactElement } from '@copilot/common/utils';
import { Button } from 'antd';

interface ICardTitle {}

/**
 * Info Card Title Container
 */
const CardTitle: React.FC<ICardTitle> = ({ children }) => (
	<h2 style={{ marginTop: 0 }}>
		{children}
	</h2>
);

interface ICardContent {}

/**
 * Info Card Content Container
 */
const CardContent: React.FC<ICardContent> = ({ children }) => <>{children}</>;

interface ICardCTA {
	cancelType?: 'link' | 'text' | 'default' | 'primary' | 'dashed',
	confirmType?: 'link' | 'text' | 'default' | 'primary' | 'dashed',
	onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
	onConfirm?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
	showCancel?: boolean;
	showConfirm?: boolean;
	cancelText?: string;
	confirmText?: string;
}

/**
 * Info Card Call To Action Buttons Container
 */
const CardCTA: React.FC<ICardCTA> = (props) => {
	const {
		cancelType, confirmType, onCancel, onConfirm, showCancel = false, showConfirm = false, cancelText = 'Cancel', confirmText = 'Confirm',
	} = props;
	return (
		<div style={{ textAlign: 'center', margin: '0.2em 0' }}>
			{showCancel && (
			<div style={{ padding: '0.5em', display: 'inline' }}>
				<Button type={cancelType} onClick={onCancel}>
					{' '}
					{cancelText}
					{' '}
				</Button>
			</div>
			)}
			{showConfirm && (
			<div style={{ padding: '0.5em', display: 'inline' }}>
				<Button type={confirmType} onClick={onConfirm}>
					{' '}
					{confirmText}
					{' '}
				</Button>
			</div>
			)}
		</div>
	);
};

const getHeaderAndContent = (children: React.ReactNode) => {
	let title: React.ReactNode = null;
	let content: React.ReactNode = null;
	let cta: React.ReactNode = null;
	React.Children.map(children, (child) => {
		if (isReactElement(child)) {
			switch (child.type) {
				case CardTitle:
					title = child;
					break;
				case CardContent:
					content = child;
					break;
				case CardCTA:
					cta = child;
					break;
			}
		}
	});
	return {
		title, content, cta,
	};
};

interface IInfoCardStaticProps {
	Title: typeof CardTitle;
	Content: typeof CardContent;
	CTA: typeof CardCTA;
}

interface IInfoCardProps { }

/**
 * [Presentational] Information Card with title, body, content, and CTA buttons
 */
const InfoCard: React.FC<IInfoCardProps> & IInfoCardStaticProps = (props) => {
	const { children } = props;

	const {
		title, content, cta,

	} = getHeaderAndContent(children);
	return (
		<div style={{ padding: '0 2em' }}>
			<div style={{ marginBottom: '16px' }}>
				{title}
			</div>
			{content}
			{cta}
		</div>
	);
};

InfoCard.Title = CardTitle;
InfoCard.Content = CardContent;
InfoCard.CTA = CardCTA;

export default InfoCard;
