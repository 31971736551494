import Store from '..';
import { createSelector, OrmState } from 'redux-orm';

export namespace MessageTemplateSelectors {
	export const getMessageTemplate = (
		state: { entities: OrmState<any> },
		id: string
	) => Store.ORM.session(state.entities).MessageTemplate.withId(id);

	export const getMessageTemplates = createSelector(
		Store.ORM,
		(session) => session.MessageTemplate.all().toModelArray()
	);

	export const getTemplatesByCampaignId = (
		state: ReturnType<typeof Store['ReduxStore']['getState']>,
		id: string
	) => getMessageTemplates(state).filter(
		(message) =>
			message.campaignIds.includes(id)
	);

	export const getTemplatesByCampaignIds = (
		state: ReturnType<typeof Store['ReduxStore']['getState']>,
		ids: string[]
	) => getMessageTemplates(state).filter(
		(message) =>
			ids.reduce<boolean>((isMatch, id) => isMatch || message.campaignIds.includes(id), false)
	);
}
