import { useCallback, useState, useEffect } from 'react';
import { ServiceSchedule } from '@copilot/data/responses/interface';
import {
	ScheduleHierarchyType,
	SYSTEM_DEFAULT_SCHEDULE,
} from '@copilot/common/pages/settings/schedule/constant';
import { CampaignManager, OrganizationManager, ScheduleManager } from '@copilot/data';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceScheduleAction } from '@copilot/common/store/actions/serviceSchedule';
import { ServiceScheduleSelectors } from '@copilot/common/store/selectors/serviceSchedule';

export function useSchedule(
	organizationId: string,
	ownerId: string,
	ownerType: ScheduleHierarchyType,
	showNotification = true
): [
		{ schedule: PartialBy<ServiceSchedule, 'id'>; isFetching: boolean },
		(newSchedule: PartialBy<ServiceSchedule, 'id'>) => void
	] {
	const orgSchedule = useSelector((state) =>
		ServiceScheduleSelectors.getServiceScheduleByOwnerId(state, organizationId)
	);
	const ownerSchedule = useSelector((state) =>
		ServiceScheduleSelectors.getServiceScheduleByOwnerId(state, ownerId)
	);
	const dispatch = useDispatch();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const showSuccessNotification = useCallback(() => {
		if (showNotification) {
			notificationManager.showSuccessNotification({
				message: 'Schedule Updated',
				description: 'Your update has been saved.',
			});
		}
		setIsFetching(false);
	}, []);
	const showErrorNotification = useCallback(() => {
		if (showNotification) {
			notificationManager.showErrorNotification({
				message: 'Update Failed',
				description: 'Please refresh the page and try again',
			});
		}
		setIsFetching(false);
		throw TypeError('Schedule Updated Failed');
	}, []);

	useEffect(() => {
		(async () => {
			setIsFetching(true);
			if (ownerType === ScheduleHierarchyType.Campaign) {
				try {
					const result = await CampaignManager.getSchedule(ownerId);
					dispatch(ServiceScheduleAction.loadSerivceSchedule(result));
					setIsFetching(false);
					return;
				} catch {}
			}

			if (
				ownerType === ScheduleHierarchyType.Organization ||
				ownerType === ScheduleHierarchyType.Campaign
			)
				try {
					const result = await OrganizationManager.getSchedule(
						ownerType === ScheduleHierarchyType.Campaign ? organizationId : ownerId
					);
					dispatch(ServiceScheduleAction.loadSerivceSchedule(result));
					setIsFetching(false);
					return;
				} catch {}
			setIsFetching(false);
		})();
	}, [organizationId, ownerId, ownerType]);

	const createSchedule = useCallback(
		(schedule: Omit<ServiceSchedule, 'id'>) => {
			const newSchedule = {
				...schedule,
				ownerId,
				hierarchyType: ownerType,
			};
			switch (ownerType) {
				case ScheduleHierarchyType.Campaign:
					CampaignManager.createSchedule(ownerId, newSchedule)
						.then((result) => {
							dispatch(ServiceScheduleAction.loadSerivceSchedule(result));
							showSuccessNotification();
						})
						.catch(showErrorNotification);
					break;
				case ScheduleHierarchyType.Organization:
					OrganizationManager.createSchedule(ownerId, newSchedule)
						.then((result) => {
							dispatch(ServiceScheduleAction.loadSerivceSchedule(result));
							showSuccessNotification();
						})
						.catch(showErrorNotification);
					break;
				default:
					throw new TypeError('Owner Type not handled.');
			}
		},
		[ownerId, ownerType]
	);

	const updateSchedule = useCallback(
		(newSchedule: PartialBy<ServiceSchedule, 'id'>) => {
			setIsFetching(true);
			if (newSchedule.id !== undefined && newSchedule.id !== '') {
				const scheduleToUpdate = {
					...newSchedule,
					hierarchyType: ownerType,
					id: newSchedule.id,
				};
				ScheduleManager.setSchedule(scheduleToUpdate).then((result) => {
					dispatch(ServiceScheduleAction.loadSerivceSchedule(result));
					showSuccessNotification();
				}).catch(showErrorNotification);
			} else {
				createSchedule(newSchedule);
			}
		},
		[createSchedule, ownerType]
	);
	return [
		{
			schedule: ownerSchedule ?? orgSchedule ?? { ...SYSTEM_DEFAULT_SCHEDULE, hierarchyType: ownerType, ownerId },
			isFetching,
		},
		updateSchedule,
	];
}
