import { CAMPAIGN_SEARCH_LIST_TAB } from './const';
import { selectCampaignMembers } from '@copilot/common/utils/campaignMember/selector';
import { createSelector } from 'reselect';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';
import { ModelState } from '@copilot/common/store/models/fetch';

/**
 * Returns the selected campaign member id for the campaign search list tab
 * Note: state is typed with "any" to avoid type issues when using createSelector below
 * @param {Record<string, CampaignSearchListTabType>} state current state of the store
 */
const campaignSearchListTabMemberIdSelector = (campaignId: string) => (state: Record<string, any>): string | undefined => state?.[CAMPAIGN_SEARCH_LIST_TAB]?.[campaignId]?.selectedCampaignMemberId;

/**
 * Returns the selected campaign member object based on the selectedCampaignMemberId stored in the campaign search list tab store object
 * @param {string} campaignId the id of the campaign we are getting the selected member for
 */
export const campaignSearchListTabMemberSelector = (campaignId: string) => createSelector(
	selectCampaignMembers(campaignId),
	campaignSearchListTabMemberIdSelector(campaignId),
	(state: ModelState<CampaignMemberModel>, memberId: string | undefined) => (memberId ? state.data.find((member) => member.id === memberId) : undefined)
);

/**
 * Returns the selected campaign member object based on the selectedCampaignMemberId stored in the campaign search list tab store object or first one as default
 * @param {string} campaignId the id of the campaign we are getting the selected member for
 */
export const campaignSummaryMemberSelector = (campaignId: string) => createSelector(
	selectCampaignMembers(campaignId),
	campaignSearchListTabMemberIdSelector(campaignId),
	(state: ModelState<CampaignMemberModel>, memberId: string | undefined) => (memberId ? state.data.find((member) => member.id === memberId) : state.data[0])
);
