import Store from '..';
import { Contact } from '../models/redux';
import { SessionBoundModel, createSelector, OrmState } from 'redux-orm';

export namespace ContactSelectors {
	export const getContact = (
		state: { entities: OrmState<any> },
		id: string
	) =>
		createSelector(Store.ORM, (session) => session.Contact.withId(id))(state);

	export const getContacts = (ids: string[]) => (
		state: { entities: OrmState<any> }
	) => {
		const contacts = Store.ORM.session(state.entities).Contact;
		return ids
			.map((id) => contacts.withId(id) as NonNullable<SessionBoundModel<Contact, {}>>)
			.filter((c) => c !== null);
	};
	// export const getContacts = createSelector<
	// 	ReturnType<typeof Store.ReduxStore.getState>,
	// 	ReturnType<typeof Store.ORM.getEmptyState>
	// >(Store.ORM, (state) => state.entities, (session) => session.Contact.all());
}
