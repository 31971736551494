const AttentionRequiredLevels = {
	warning: 'warning',
	blocker: 'blocker',
} as const;

const AttentionRequiredLevelLabels = {
	[AttentionRequiredLevels.blocker]: 'BLOCKER',
	[AttentionRequiredLevels.warning]: 'WARNING',
} as const;


export { AttentionRequiredLevels, AttentionRequiredLevelLabels };
