import React from 'react';
import {
	Col, Row, Button,
} from 'antd';
import { OnboardMessage } from '@copilot/data/requests/models';
import DeleteButton from '@copilot/common/components/button/delete';
import Title from '@copilot/common/typography/title';

/**
 * Diplay message with option of edit and delete
 *
 * @param messageTitle message title
 * @param message message
 * @callback onEditClick called on edit
 * @param isEditing edit status of a message - true when editing a message, false when viewing a message
 * @param isEditable whether a message is editable
 * @param isDeletable whether a message is deletable
 * @callback handleDelete called on delete
 */
interface MessageHeaderProps {
	messageTitle: string;
	message: OnboardMessage;
	onEditClick: () => void;
	isEditable: boolean;
	isDeletable?: boolean;
	onDeleteClick: (message: OnboardMessage) => void;
}

const MessageHeader: React.FC<MessageHeaderProps> = (props) => {
	const {
		messageTitle, message, onEditClick, isDeletable = false, onDeleteClick, isEditable,
	} = props;

	return (
		<>
			<Row gutter={16}>
				<Col>
					<Title level={5}>{messageTitle}</Title>
				</Col>
				<Col>
					<Button
						onClick={onEditClick}
						disabled={!isEditable}
						size="small"
					>
						Edit
					</Button>
				</Col>
				<Col>
					{isDeletable && (
						<DeleteButton
							label="Delete"
							confirmText="Are you sure you want to delete this message?"
							deleteAction={() => onDeleteClick(message)}
							size="small"
							disabled={!isEditable}
						/>
					)}
				</Col>
			</Row>
		</>
	);
};

export default MessageHeader;
