import { createFetchOneSelector, createFetchSelector, createQualifiedFetchOneWithLoadingSelector } from '@copilot/common/store/selectors/fetch';
import { IOrganizationTeamMember } from './models';
import { ORGANIZATION_MEMBERS } from './constant';

export const getAllOrganizationTeamMembers = createFetchSelector<IOrganizationTeamMember>(
	ORGANIZATION_MEMBERS
);

export const getOneOrganizationTeamMember = createFetchOneSelector<IOrganizationTeamMember>(
	ORGANIZATION_MEMBERS
);

export const getOneOrganizationTeamMemberState = createQualifiedFetchOneWithLoadingSelector<IOrganizationTeamMember>(
	ORGANIZATION_MEMBERS
);
