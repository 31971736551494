import Store from '..';
import { createSelector, OrmState } from 'redux-orm';

export namespace LinkedinSelectors {
	export const getLinkedinMemberProfile = (
		state: { entities: OrmState<any> },
		id: string
	) => Store.ORM.session(state.entities).MemberLinkedInProfile.withId(id);

	export const getOrgMemberLinkedInMeta = createSelector(
		Store.ORM,
		(_, orgMemberId: string) => orgMemberId,
		(session, orgMemberId) => session.LinkedInMeta.get({ orgMemberId })
	);
}
