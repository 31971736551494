import React, { useCallback } from 'react';
import styled from 'styled-components';

interface BubbleCheckboxProps {
	value: string;
	checked: boolean;
	onChange?: (value: string, checked: boolean) => unknown;
	disabled?: boolean;
}

const CheckboxButton = styled.button`
	background: none;
	border: 1px solid #333;
	border-radius: 15px;
	color: #333;
	cursor: pointer;
	font: inherit;
	margin-bottom: 10px;
	margin-right: 10px;
	outline: inherit;
	padding: 5px 16px;

	&.checked {
		background-color: #2695f0;
		border-color: #2695f0;
		color: #fff;
	}

	&.unchecked {
		background: white;
		border-color: #333;
		color: #333;
	}
`;

const BubbleCheckbox: React.FC<BubbleCheckboxProps> = (props) => {
	const {
		value, checked, onChange, children, disabled,
	} = props;
	const handleClick = useCallback<
		NonNullable<React.ComponentProps<typeof CheckboxButton>['onClick']>
	>(() => {
		onChange?.(value, !checked);
	}, [checked]);
	return (
		<CheckboxButton
			disabled={disabled}
			onClick={handleClick}
			className={checked ? 'checked' : 'unchecked'}
		>
			{children ?? value}
		</CheckboxButton>
	);
};

export default BubbleCheckbox;
