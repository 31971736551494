import {
	ComponentType,
	useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SentMessagesPage from './sentMessagesPage';
import { LoadSentMessagesAction } from '../data/saga';
import SentMessagesSelector from '../data/selector';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { withUserInfo } from '@copilot/common/hoc/utils';
import { withLoading } from '@copilot/common/components/errorAndLoading/errorAndLoading';
import SentMessagesTable, { SentMessagesTableColumnKeys } from './sentMessagesTable';
import { TableProps } from '@copilot/common/components/tables/basicTable';
import { ThreadStatus } from '@copilot/common/store/models/const/enum';
import { OutboxManager } from '@copilot/data';

/**
 * Expected type of the sent messages
 */
type SentMessageType = {
	name: string;
	message: string;
	contactId: string;
	lastSent: string;
	orgMemberId: string;
	isContactDeleted: boolean;
	status: ThreadStatus;
};

type SentMessageViewComponetProps = Omit<TableProps<SentMessageType>, 'pagination'> & { total: number };
type SentMessagesLoaderType = typeof OutboxManager.getCustomerSentMessages | typeof OutboxManager.getSentMessages;
type SentMessagesContainerConfig = {
	/**
	 *  The organization member id of the sent message
	 */
	organizationMemberId: string;
	/**
	 * Sent message loader
	 */
	loader: SentMessagesLoaderType;
};

type ContainerProps = Readonly<{
	/** Container's config */
	config: SentMessagesContainerConfig;
}>;

type UserInfo = {
	organizationId: string;
	activeMember: IOrganizationMember;
};

/**
 * [Container] A container component for the Sent Messages Page
 */
const Container = (ViewComponent: ComponentType<SentMessageViewComponetProps>) => (props: ContainerProps) => {
	const { config } = props;
	const { loader: fetcher, organizationMemberId } = config;
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			LoadSentMessagesAction(
				fetcher,
				{ sorter: { columnKey: SentMessagesTableColumnKeys.lastUpdate, order: 'descend' } },
				organizationMemberId
			)
		);
	}, []);

	const messages = useSelector(SentMessagesSelector);

	const handleTableChange = useCallback(
		(pagination, filters, sorter) => {
			dispatch(
				LoadSentMessagesAction(
					fetcher,
					{
						pagination,
						filters,
						sorter,
					},
					organizationMemberId
				)
			);
		},
		[]
	);

	return (
		<ViewComponent
			dataSource={messages?.data}
			loading={messages.loading}
			total={messages.totalCount}
			onChange={handleTableChange}
		/>
	);
};

/**
 * Create a sent messages container component with CS Impersonation applied
 * @param Component
 */
const withCsImpersonationCapability = (Component: ComponentType<UserInfo & { config: SentMessagesContainerConfig }>) => (props: UserInfo) => {
	const { activeMember } = props;
	const config: Readonly<SentMessagesContainerConfig> = {
		organizationMemberId: activeMember.id,
		loader: OutboxManager.getCustomerSentMessages,
	};
	return <Component {...props} config={config} />;
};

/**
 * Create a sent messages container component with CS Impersonation applied
 * @param Component
 */
const withBasicCapability = (Component: ComponentType<UserInfo & { config: SentMessagesContainerConfig }>) => (props: UserInfo) => {
	const { activeMember } = props;
	const config: SentMessagesContainerConfig = {
		organizationMemberId: activeMember.id,
		loader: OutboxManager.getSentMessages,
	};
	return <Component config={config} {...props} />;
};

export const SentMessageContainer = withUserInfo<{}>(withLoading(withBasicCapability(Container(SentMessagesPage))));
export const ImpersonatedSentMessageContainer = withUserInfo<{}>(withLoading(withCsImpersonationCapability(Container(SentMessagesTable))));
