import {
	Model, ForeignKey, ManyToMany,
} from 'redux-orm';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/no-unresolved
import { ModelFieldMap, AnyModel } from 'redux-orm/Model';

export class BaseModel<M extends typeof AnyModel, Fields extends ModelFieldMap> extends Model<
	M,
	Fields
> {
	static parse(data: any) {
		const clonedData = { ...data };
		Object.keys(this.fields).forEach((k: string) => {
			if (
				this.fields[k] instanceof ForeignKey &&
				Object.prototype.toString.call(clonedData[k]) === '[object Object]'
			) {
				const field = this.fields[k] as any;
				const { session } = this as any;
				clonedData[k] = session[field.toModelName].parse(clonedData[k]);
			} else if (
				this.fields[k] instanceof ManyToMany &&
				Object.prototype.toString.call(clonedData[k]) === '[object Array]'
			) {
				clonedData[k] = clonedData[k].map((d: any) => {
					if (Object.prototype.toString.call(d) === '[object Object]') {
						return (this as any).session[(this.fields[k] as any).toModelName].parse(d);
					} else {
						return d;
					}
				});
			}
		});
		return this.upsert(clonedData);
	}
}
