import React from 'react';
import BatchMigrateConnections from '@copilot/common/components/componentModels/activities/batchActions/actions/migrateConnections';
import BatchActionPanel from '@copilot/common/components/containers/batchAction';
import {
	CSVColumnDefinition,
} from '@copilot/common/components/componentModels/filterTypeDefinition';
import ExportSelector from '@copilot/common/components/tables/tableTypes/exportSelector';
import { Button, Col, Popconfirm } from 'antd';
import { ConnectionResponse } from '@copilot/data/responses/interface';
import { MigrateApplyTags, MigrateConnections } from '@copilot/common/components/componentModels/activities/batchActions/type';
import { useExportCsvColumns } from '@copilot/common/pages/connections/hook';

interface IConnectionPageBatchActionProps {
	connections: ConnectionResponse[];
	total: number;
	hideRemove?: boolean;
	hideMigrate?: boolean;
	onApplyTag: () => void;
	onExportCSV: (columns: CSVColumnDefinition[], connectionId?: string[]) => unknown;
	onRemove: () => void;
	onMigrate: MigrateConnections;
	onMigrateTag: MigrateApplyTags;
}

/**
 * [Presentational] Batch Actions for Connections page
 * @param connectionIds a list of selected connectionId
 * @param total total number of connections
 * @param hideRemove whether to hide remove action
 * @param hideMigrate whether to hide migrate action
 * @callback onApplyTag called on apply tag on connections
 * @callback onExportCSV called on export connections as CSV
 * @callback onRemove called on remove connections
 * @callback onMigrate called on migrating connections to another campaign
 * @callback onMigrateTag called on tagging migrated connections
 */
const ConnectionPageBatchAction: React.FC<IConnectionPageBatchActionProps> = (props) => {
	const {
		connections,
		total,
		hideRemove = false,
		hideMigrate = false,
		onApplyTag,
		onExportCSV,
		onRemove,
		onMigrate,
		onMigrateTag,
	} = props;
	
	const columns = useExportCsvColumns();
	return (
		<>
			<BatchActionPanel total={total}>
				<Col>
					<Button onClick={onApplyTag} type="primary" ghost>
						Apply Tags
					</Button>
				</Col>
				<Col>
					<ExportSelector
						columns={columns}
						connectionIds={connections.map((c) => c.id)}
						exportCSV={onExportCSV}
						label="Export selected as CSV"
						placement="topRight"
						type="primary"
						ghost
					/>
				</Col>
				{!hideMigrate && (
					<Col>
						<BatchMigrateConnections
							connections={connections}
							onMigrate={onMigrate}
							onMigrateTag={onMigrateTag}
						/>
					</Col>
				)}
				{!hideRemove && (
				<Col>
					<Popconfirm
						title="Are you sure you wish to remove the selected connections?"
						onConfirm={onRemove}
						okText="Yes"
						cancelText="No"
					>
						<Button type="primary" ghost>
							Remove
						</Button>
					</Popconfirm>
				</Col>
				)}
			</BatchActionPanel>
		</>
	);
};
export default ConnectionPageBatchAction;
