import { Col, Row } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import LinkedInPinForm from '../../forms/linkedin/login/pin';

interface IInitialLinkedInVerificationStepProps {
	onSubmit: (pin: string) => Promise<void>;
	onClickSupport: () => void;
	isFetching?: boolean;
}

const StyledRow = styled(Row)`
	text-align: center
`;

const InitialLinkedInVerificationStep: FC<IInitialLinkedInVerificationStepProps> = (props) => {
	const { onSubmit, onClickSupport, isFetching = false } = props;
	return (
		<StyledRow>
			<Col span={18} offset={3}>
				<LinkedInPinForm onSubmit={onSubmit} onClickSupport={onClickSupport} isSubmitting={isFetching} />
			</Col>
		</StyledRow>
	);
};

export default InitialLinkedInVerificationStep;
