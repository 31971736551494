/**
 * Produces a sort function (usable by Array.sort) that does a case insensitive string comparison.
 * @param valueExtractor The function to extract the value to compare from an object.
 */
export const createCaseInsensitiveSorter = <T>(valueExtractor: (item: T) => string) => (a: T, b: T) => valueExtractor(a).localeCompare(valueExtractor(b));

/**
 * The case insensitive sorter by name.
 */
export const sorterByName = createCaseInsensitiveSorter<{ name: string }>((item) => item.name);
