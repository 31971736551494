import React, { useCallback } from 'react';
import {
	Button, Form, Input, Row,
} from 'antd';
import { TemplateManager } from '@copilot/data';
import notificationManager from '@copilot/common/utils/notificationManager';
import { FilterTemplateActions } from '@copilot/common/store/actions/filterTemplate';
import { useDispatch } from 'react-redux';
import { IFilterTemplate } from '@copilot/common/store/models/redux';
import { ConnectionsPageButtonClicks } from '@copilot/common/pages/connections/tracking';

interface FilterTemplateFormProps {
	ownerId: string;
	filterRequest: IFilterTemplate['filters'];
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	onTrackingUpdate: (buttonName: ConnectionsPageButtonClicks) => void;
}

const TEMPLATE_NAME_MAX_LENGTH = 20;

const FilterTemplateForm: React.FC<FilterTemplateFormProps> = (props) => {
	const {
		filterRequest, setIsVisible, ownerId, onTrackingUpdate,
	} = props;
	const [form] = Form.useForm();
	const storeDispatch = useDispatch();

	const handleFinish = useCallback((values) => {
		const { templateName } = values;
		const templateFilter: Partial<IFilterTemplate> = {
			name: templateName,
			ownerId,
			filters: filterRequest,
		};
		TemplateManager.createTemplateFilter(templateFilter)
			.then((result) => {
				storeDispatch(FilterTemplateActions.loadTemplates(result));
				notificationManager.showSuccessNotification({
					message: 'Template Create',
					description: 'Filter Template has been created',
				});
				setIsVisible(false);
			})
			.catch(() => {
				notificationManager.showErrorNotification({
					message: 'Failed to create template',
					description: 'Please try again',
				});
			});
		onTrackingUpdate('Save Filter Template');
	}, [ownerId, filterRequest]);

	const onCancel = () => {
		setIsVisible(false);
		form.resetFields();
	};

	return (
		<Form
			form={form}
			onFinish={handleFinish}
		>
			<Form.Item
				name="templateName"
				label="Please name your template"
				rules={[
					{
						required: true,
						message: 'Pleae name your template!',
					},
					{
						max: TEMPLATE_NAME_MAX_LENGTH,
						message: 'Template Name is Too Long',
					},
					{
						pattern: new RegExp('^[a-zA-Z0-9 ]*$'),
						message: 'Please use alphanumerics only',
					},
				]}
			>
				<Input />
			</Form.Item>
			<Form.Item>
				<Row style={{ float: 'right' }}>
					<Button onClick={onCancel} style={{ margin: '5px' }}>Cancel</Button>
					<Button type="primary" htmlType="submit" style={{ margin: '5px' }}>
						Save
					</Button>
				</Row>
			</Form.Item>
		</Form>
	);
};

export default FilterTemplateForm;
