import { OnboardMessage } from '@copilot/data/requests/models';
import { FC, ReactNode, useContext } from 'react';
import { EditTwoTone, DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import Link from 'antd/lib/typography/Link';
import BasicContainer from '@copilot/common/components/containers/basic';
import { ThemeContext } from 'styled-components';
import { Config } from '@copilot/common/config';
import NewUserOnboardTitle from '@copilot/common/components/wizard/steps/newUser/newUserTitle';
import { DEFAULT_MESSAGE_DESCRIPTIONS, MESSAGE_EDIT_DESCRIPTION, MAX_MESSAGES_NUMBER } from '@copilot/common/pages/campaignDashboard/newUserOnboard/const';
import { hexToRgba } from '@copilot/common/utils/colorHelper';
import { IMessagingCategoryOptionsProps, MessagingCategoryOptions } from '../messaging/messagingCategoryOptions';

type MessageDescription = [description: string, editDescription: ReactNode];

const getMessageDescriptions: (index: number) => MessageDescription = (index: number) => [
	DEFAULT_MESSAGE_DESCRIPTIONS[index] ??
		DEFAULT_MESSAGE_DESCRIPTIONS[DEFAULT_MESSAGE_DESCRIPTIONS.length - 1],
	MESSAGE_EDIT_DESCRIPTION[index] ??
		MESSAGE_EDIT_DESCRIPTION[MESSAGE_EDIT_DESCRIPTION.length - 1],
];

interface IRequiredTagProps {
	color: string;
}

/**
 * Tag that says Required
 * @param color color of the text in hex; background color is set to same color with opacity of 0.1
 */
const RequiredTag: FC<IRequiredTagProps> = (props) => {
	const { color } = props;
	return (
		<>
			<Col style={{ paddingLeft: '4px' }}>
				<Text
					style={{
						color,
						backgroundColor: hexToRgba(color, 0.1),
						padding: '6px 8px',
						fontSize: '12px',
					}}
					strong
				>
					Required
				</Text>
			</Col>
			<Col flex={1} />
		</>
	);
};

interface IMessageContainerProps {
	title: string;
	message: OnboardMessage;
	onEdit: () => void;
	onDelete: () => void;
	isDeletable?: boolean;
	description: string;
	isRequired?: boolean;
}

/**
 * Message Container with edit and delete actions
 * @param title message title
 * @param message message text
 * @param onEdit called on edit
 * @param onDelete called on delete
 * @param isDeletable whether the message is deletable or not
 * @param description message description to display when there is no saved text
 */
const MessageContainer: FC<IMessageContainerProps> = (props) => {
	const {
		title, message, description, onEdit, onDelete, isDeletable = false, isRequired = false,
	} = props;

	const themeContext = useContext(ThemeContext);
	return (
		<BasicContainer style={{ textAlign: 'left', marginBottom: '15px' }}>
			<BasicContainer.Content>
				<Row gutter={[16, 16]}>
					<Col flex={isRequired ? 0 : 1}>
						<Text strong>{title}</Text>
					</Col>
					{isRequired && <RequiredTag color={themeContext['@error-color']} />}
					<Col>
						<EditTwoTone onClick={onEdit} />
					</Col>
					{isDeletable && (
						<Col>
							<DeleteOutlined
								style={{ color: themeContext['@error-color'] }}
								onClick={onDelete}
							/>
						</Col>
					)}
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						{message.text ? (
							<Text>{message.text}</Text>
						) : (
							<Text style={{ color: themeContext['@text-color-secondary'] }} type="secondary">{description}</Text>
						)}
					</Col>
				</Row>
			</BasicContainer.Content>
		</BasicContainer>
	);
};

interface INewUserMessagingProps {
	messages: OnboardMessage[];
	messageTitles: string[];
	onEdit: (title: string, description: ReactNode, message: OnboardMessage, index: number) => void;
	onDelete: (index: number) => void;
	onCreate: () => void;
	showCategoryOptions: boolean;
}

/**
 * [Presentational] Messaging Step of New User Onboard Wizard
 * @param messages messages to display
 * @param messageTitles list of message titles
 * @param onEdit called on message edit
 * @param onDelete called on message delete
 * @param onCreate called on message create
 * @param showCategoryOptions should the category options be displayed?
 */
const NewUserMessaging: FC<INewUserMessagingProps & IMessagingCategoryOptionsProps> = (props) => {
	const {
		messages, messageTitles, onEdit, onDelete, onCreate, showCategoryOptions,
	} = props;
	const themeContext = useContext(ThemeContext);

	return (
		<Row justify="center">
			<Col xs={24} xxl={20}>
				<NewUserOnboardTitle title="Craft your campaign messages">
					<Text>
						Compelling messages win the best leads.
						{' '}
						<Link href={Config.messagingPurposeURL} target="_blank" underline>
							Learn more
						</Link>
						{' '}
						about outreaching with impact.
					</Text>
				</NewUserOnboardTitle>
				{showCategoryOptions && <MessagingCategoryOptions {...props} />}
				{messages.map((message, index) => {
					const title = messageTitles[index];
					const [description, editDescription] = getMessageDescriptions(index);
					return (
						<MessageContainer
							key={title}
							title={title}
							message={message}
							onEdit={() => onEdit(title, editDescription, message, index)}
							onDelete={() => onDelete(index)}
							isDeletable={index > 0}
							description={description}
							isRequired={index == 0}
						/>
					);
				})}
				{messages.length < MAX_MESSAGES_NUMBER && (
					<Button onClick={onCreate}>Add another message</Button>
				)}
			</Col>
			<Row style={{ marginTop: '4rem' }}>
				<Col>
					<Text strong style={{ color: themeContext['@text-color-secondary'] }}>
						You'll be able to customize your messaging after launch in the automated messaging tab.
					</Text>
				</Col>
			</Row>
		</Row>
	);
};

export default NewUserMessaging;
