import React from 'react';
import styled from 'styled-components';
import BasicContainer from '@copilot/common/components/containers/basic';
import {
	Button, Col, Dropdown, Row, Table, Input,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { GetRowKey } from 'rc-table/lib/interface';
import { CampaignModel } from '@copilot/common/pages/campaigns/data/models';
import { CampaignType } from '@copilot/data/responses/interface';
import { displayCampaignOptions } from '@copilot/common/utils/campaign';
import modalManager from '@copilot/common/utils/modalManager';
import { NameValidator } from '@copilot/common/components/modals/wrappers/campaignCreation/nameEditor';
import { withErrorHandling, withLoading } from '@copilot/common/components/errorAndLoading/errorAndLoading';
import CampaignsPageFilters from './filter';
import { CampaignsFilters, CampaignsFiltersCallbacksType } from '../types';
import { CampaignModelState } from '../data/reducer';
import { TablePaginationConfig } from 'antd/lib/table';

type OnSearchType = (searchTerm: string) => void;

type OnRowClickType = (obj: CampaignModel, rowIndex?: number) => void;

type OnCreateCampaignType = (name: string, type: CampaignType) => Promise<void>;

type OnTableChangeType = (pagination: TablePaginationConfig) => void;

export type CampaignsConfig = {
	/**
	 * Has the edit right or not.
	 */
	editRight: boolean;

	/**
	 * Determines if the alert message should be shown when creating a campaign.
	 */
	showAlertOnCampaignCreation: boolean;

	/**
	 * Determines if members are shown.
	 */
	showMembers: boolean;

	/**
	* Determine if the user can create a nurture campaign
	*/
	canCreateNurture: boolean;
};

export type CampaignsConfigProps = Readonly<{
	/**
	 * The capability map for this campaigns page.
	 */
	config: CampaignsConfig;
}>;

/**
 * The props for the Campaigns component.
 */
export type ICampaignsProps = {
	/**
	 * The campaigns to display.
	 */
	readonly data: CampaignModelState<CampaignsFilters>;

	/**
	 * The search term that appears in the search box.
	 */
	searchTerm?: string;

	/**
	 * The validator for the campaign name to create.
	 */
	campaignNameValidator: NameValidator;

	/**
	 * The event handler called when the search term in the search box changes.
	 * This is simply the text in the box changed - not necessarily the user requested the actual search.
	 * For the actual search, use onSearch.
	 */
	onSearchTermChange: OnSearchType;

	/**
	 * The event handler called when the user requests to search.
	 */
	onSearch: OnSearchType;

	/**
	 * the event handler for row click.
	 */
	onRowClick?: OnRowClickType;

	onCreateCampaign: OnCreateCampaignType;

	/**
	 * event handler for table change
	 */
	onChangeTable: OnTableChangeType;

	/** Filters campaigns are filter by */
	filters: CampaignsFilters;

	/** Callbacks to handle filter update */
	onUpdateFilter: CampaignsFiltersCallbacksType;
} & CampaignsConfigProps;

const StyledBasicContainer = styled(BasicContainer)`
	.flag {
		background: #ffd9d9;
	}

	.messageRead {
		background: #f7f7f7;
	}

	.messageUnread {
		font-weight: bold;
	}
`;

const CampaignsTable = withErrorHandling(withLoading(Table));

const rowKeyFunc: GetRowKey<{}> = (record) => (record as CampaignModel).id;

/**
 * [Presentational] The presentational component for displaying a list of campaigns.
 * @param props The props.
 */
const Campaigns: React.FC<ICampaignsProps> = (props) => {
	const {
		config, data, campaignNameValidator, onRowClick, onCreateCampaign,
		searchTerm, onSearchTermChange, onSearch, filters, onUpdateFilter,
	} = props;

	const handleOnClick = (record: {}, rowIndex?: number) => ({
		onClick: () => {
			onRowClick?.call(undefined, record as CampaignModel, rowIndex);
		},
	});

	const showCampaignNameEditor = (campaignType: CampaignType) => modalManager.openCampaignCreationModal({
		nameEditorValidator: campaignNameValidator,
		onCreate: async (name: string) => {
			await onCreateCampaign(name, campaignType);
		},
	});

	const handleCampaignTypeSelected = (campaignType: CampaignType) => {
		if (config.showAlertOnCampaignCreation) {
			modalManager.openCampaignCreationAlert({
				onContinue: () => showCampaignNameEditor(campaignType),
			});
		} else {
			showCampaignNameEditor(campaignType);
		}
	};

	const handleCreateCampaignButtonClick = () => {
		handleCampaignTypeSelected(CampaignType.Prospecting);
	};

	return (
		<StyledBasicContainer>
			<BasicContainer.Header>
				<Row justify="space-between" align="middle">
					<Col>
						<h2>Campaigns</h2>
					</Col>
				</Row>
			</BasicContainer.Header>
			<BasicContainer.Content style={{ padding: '0px' }}>
				<Row justify="space-between" style={{ margin: '10px' }}>
					<Col span={5}>
						<Input.Search
							placeholder="Search Campaign Name"
							value={searchTerm}
							onChange={(ev) => onSearchTermChange(ev.target.value)}
							onSearch={onSearch}
						/>
					</Col>
					{ config.editRight && (
						<Col>
							{/* TODO: Remove canCreateNurture after Nurture roll-out. Added to disable Nurture creation on individual user */}
							{config.canCreateNurture ? (
								<Dropdown overlay={displayCampaignOptions(handleCampaignTypeSelected)} trigger={['click']}>
									<Button>
										Create Campaign
										{' '}
										<DownOutlined style={{ float: 'right', marginTop: '6px', color:'#bfbfbf', fontSize: '75%' }}/>
									</Button>
								</Dropdown>
							) :
								<Button onClick={handleCreateCampaignButtonClick}>Create Campaign</Button>}
						</Col>
					)}
				</Row>
				{config.showMembers && (
					<Row style={{ marginLeft: '10px' }}>
						<Col>
							<CampaignsPageFilters filters={filters} filterUpdateCallbacks={onUpdateFilter} />
						</Col>
					</Row>
				)}
				<Row style={{ paddingTop: '10px' }}>
					<Col span={24}>
						<CampaignsTable
							rowKey={rowKeyFunc}
							dataSource={data?.data}
							pagination={{
								showSizeChanger: true,
							}}
							//pagination={{ total: data?.totalCount, current: data?.page + 1, pageSize: data.pageSize, showSizeChanger: true }}
							rowClassName="clickable"
							onRow={handleOnClick}
							isLoading={data?.loading}
							error={data?.error}
							//onChange={onChangeTable}
						>
							{props.children}
						</CampaignsTable>
					</Col>
				</Row>
			</BasicContainer.Content>
		</StyledBasicContainer>
	);
};

export default Campaigns;
