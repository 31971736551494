import { DiscountItemDurationType } from '@copilot/data/responses/models/billing';
import { capitalizeFirstLetter } from '@copilot/common/utils';
import { pluralString } from '@copilot/common/utils/stringFormat';

/**
 * Returns the formatted discount tag given a discount. (ex. 3 Months, Forever, etc.)
 * @param discount
 * @returns formatted discount tag
 */
export const getFormattedDiscountTag = (duration: DiscountItemDurationType, durationInMonths?: number) => {
	const discountTag = durationInMonths != null ?
		`${durationInMonths} ${pluralString('Month', durationInMonths)}` :
		capitalizeFirstLetter(duration.toString());
	return discountTag;
};
