import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';

interface WarningProps {
	onNext: () => void;
}

const WarningWrapper: React.FC<WarningProps> = (props) => (
	<>
		<Row>
			<Col style={{ textAlign: 'center', margin: '15px' }}>
				<ExclamationCircleOutlined style={{ color: '#0075e2', fontSize: '8em' }} />
				{' '}
				<h2>You are about to create a new customer</h2>
			</Col>
		</Row>
		<Row>
			<Col offset="1">
				They will count toward your total seat count until they become disconnected.
			</Col>
		</Row>
		<Row>
			<Col span={24} style={{ display: 'flex', justifyContent: 'center', margin: '2em 0 0 0' }}>
				<Button type="primary" onClick={props.onNext}>
					Next
				</Button>
			</Col>
		</Row>
	</>
);

export default WarningWrapper;
