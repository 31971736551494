import { INotificationConfig } from '../utils/notificationManager';

export type NotificationContentType = { Success: INotificationConfig; Fail: INotificationConfig };

export const NotificationContent: { [k: string]: NotificationContentType } = {
	// 'Delete Message' is the terminology used in inbox v2 instead of 'Dismiss Message'
	DeleteMessage: {
		Success: { message: 'Messages Deleted' },
		Fail: {
			message: 'Delete failed',
			description: 'Please try again.',
		},
	},
	DismissMessage: {
		Success: { message: 'Messages Dismissed' },
		Fail: {
			message: 'Dismiss failed',
			description: 'Please try again.',
		},
	},
	RetryMessage: {
		Success: { message: 'Messages Retry Successful' },
		Fail: {
			message: 'Retry Failed',
			description: 'Please try again.',
		},
	},
	RemoveConnectionAutomation: {
		Success: {
			message: 'Connection Removed',
			description: 'Connection removed from the sequence.',
		},
		Fail: {
			message: 'Connection Removal Failed',
			description: 'Unable to remove this connection from the sequence. Please try again later.',

		},
	},
	RemoveBatchAutomation: {
		Success: {
			message: 'Automation Stopped',
			description: 'The selected connections will not continue with their automation.',
		},
		Fail: {
			message: 'Automation Stopping Failed.',
			description: 'Failed to stop automation. Please try again later.',
		},
	},
	RemoveProspectingAutomation: {
		Success: {
			message: 'Prospect Removed',
			description: 'Prospect removed from the sequence.',
		},
		Fail: {
			message: 'Prospect Removal Failed',
			description: 'Unable to remove this prospect from the sequence. Please try again later.',

		},
	},
	MarkAsRead: {
		Success: {
			message: 'Messages Marked',
		},
		Fail: {
			message: 'Marking has failed',
			description: 'Please try again.',
		},
	},
	SnoozeThread: {
		Success: {
			message: 'Reminder set',
		},
		Fail: {
			message: 'Unable to set reminder',
			description: 'Please try again.',
		},
	},
	CompleteRemindedThread: {
		Success: {
			message: 'Task accomplished!',
			description: 'You\'ve successfully completed your reminder task, great job! 🎉',
		},
		Fail: {
			message: 'Task couldn\'t be completed',
			description: 'Sorry, we\'ve encountered an issue while trying to mark this task as complete. Please try again!',
		},
	},
	UnsnoozeThread: {
		Success: {
			message: 'Reminder cancelled',
		},
		Fail: {
			message: 'Unable to cancel reminder',
			description: 'Please try again.',
		},
	},
	SendMessage: {
		Success: {
			message: 'Message has been sent to Outbox',
			description: 'Sent to Outbox',
		},
		Fail: {
			description: 'Failed',
			message: 'Failed to send message.',
		},
	},
	MigrateConnection: {
		Success: {
			message: 'Prospect has been migrated successfully.',
		},
		Fail: {
	    	message: 'Failed to migrate',
	    	description: 'Please try again.',
		},
	},
};
