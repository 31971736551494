import {
	ColumnDefinition,
	ColumnTypes,
} from '@copilot/common/components/componentModels/columnDefinition';
import { ICampaignMember } from '@copilot/common/store/models/redux';

interface CampaignMember extends ICampaignMember {
	invites: number;
	messages: number;
	connections: number;
	movedConnections: number;
	replies: number;
	connectionRate: number;
	replyRate: number;
	nurtureReplyRate: number;
}
export enum CampaignMemberColumns {
	Name = 'name',
	Email = 'email',
	Status = 'status',
	DailyInvites = 'dailyInvites',
	DailyMessages = 'dailyMessages',
	InviteSent = 'invites',
	MessageSent = 'messages',
	Connections = 'connections',
	MovedConnections = 'movedConnections',
	Replies = 'replies',
	ConnectionRate = 'connectionRate',
	ReplyRate = 'replyRate',
	NurtureReplyRate = 'nurtureReplyRate'
}

const CampaignMemberProspectingColumns = [
	CampaignMemberColumns.Name,
	CampaignMemberColumns.Email,
	CampaignMemberColumns.Status,
	CampaignMemberColumns.DailyInvites,
	CampaignMemberColumns.InviteSent,
	CampaignMemberColumns.Connections,
	CampaignMemberColumns.Replies,
	CampaignMemberColumns.ConnectionRate,
	CampaignMemberColumns.ReplyRate,
];

const CampaignMemberNurtureColumns = [
	CampaignMemberColumns.Name,
	CampaignMemberColumns.Email,
	CampaignMemberColumns.Status,
	CampaignMemberColumns.DailyMessages,
	CampaignMemberColumns.MessageSent,
	CampaignMemberColumns.MovedConnections,
	CampaignMemberColumns.Replies,
	CampaignMemberColumns.NurtureReplyRate,
];

export const CampaignMemberColumnMap = {
	[CampaignMemberColumns.Name]: new ColumnDefinition({
		key: CampaignMemberColumns.Name,
		properties: ['firstName', 'lastName'],
		label: 'Name',
		type: ColumnTypes.String,
		isVisible: true,
		filter: true,
	}),
	[CampaignMemberColumns.Email]: new ColumnDefinition({
		key: CampaignMemberColumns.Email,
		properties: ['email'],
		label: 'Email',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	[CampaignMemberColumns.Status]: new ColumnDefinition({
		key: CampaignMemberColumns.Status,
		properties: ['status'],
		label: 'Status',
		type: ColumnTypes.EnabledStatus,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.status - b.status,
	}),
	[CampaignMemberColumns.DailyInvites]: new ColumnDefinition({
		key: CampaignMemberColumns.DailyInvites,
		properties: ['dailyInvites'],
		label: 'Daily Invites',
		type: ColumnTypes.String,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.dailyInvites - b.dailyInvites,
	}),
	[CampaignMemberColumns.DailyMessages]: new ColumnDefinition({
		key: CampaignMemberColumns.DailyMessages,
		properties: ['dailyMessages'],
		label: 'Daily Messages',
		type: ColumnTypes.String,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.dailyMessages - b.dailyMessages,
	}),
	[CampaignMemberColumns.InviteSent]: new ColumnDefinition({
		key: CampaignMemberColumns.InviteSent,
		properties: ['invites'],
		label: 'Invites Sent',
		type: ColumnTypes.String,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.invites - b.invites,
	}),
	[CampaignMemberColumns.MessageSent]: new ColumnDefinition({
		key: CampaignMemberColumns.MessageSent,
		properties: ['messages'],
		label: 'Messages Sent',
		type: ColumnTypes.String,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.messages - b.messages,
	}),
	[CampaignMemberColumns.Connections]: new ColumnDefinition({
		key: CampaignMemberColumns.Connections,
		properties: ['connections'],
		label: 'New Connections',
		type: ColumnTypes.String,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.connections - b.connections,
	}),
	[CampaignMemberColumns.MovedConnections]: new ColumnDefinition({
		key: CampaignMemberColumns.MovedConnections,
		properties: ['movedConnections'],
		label: 'Connections Added',
		type: ColumnTypes.String,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.movedConnections - b.movedConnections,
	}),
	[CampaignMemberColumns.Replies]: new ColumnDefinition({
		key: CampaignMemberColumns.Replies,
		properties: ['replies'],
		label: 'Replies',
		type: ColumnTypes.String,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.replies - b.replies,
	}),
	[CampaignMemberColumns.ConnectionRate]: new ColumnDefinition({
		key: CampaignMemberColumns.ConnectionRate,
		properties: ['connectionRate'],
		label: 'Connection Rate',
		type: ColumnTypes.Percent,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.connectionRate - b.connectionRate,
	}),
	[CampaignMemberColumns.ReplyRate]: new ColumnDefinition({
		key: CampaignMemberColumns.ReplyRate,
		properties: ['replyRate'],
		label: 'Reply Rate',
		type: ColumnTypes.Percent,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.replyRate - b.replyRate,
	}),
	[CampaignMemberColumns.NurtureReplyRate]: new ColumnDefinition({
		key: CampaignMemberColumns.NurtureReplyRate,
		properties: ['nurtureReplyRate'],
		label: 'Reply Rate',
		type: ColumnTypes.Percent,
		isVisible: true,
		sort: (a: CampaignMember, b: CampaignMember) => a.nurtureReplyRate - b.nurtureReplyRate,
	}),
};

export const CampaignMemberProspectingColumnsArray = Object.values(CampaignMemberProspectingColumns).map(
	(c) => CampaignMemberColumnMap[c]
);

export const CampaignMemberNurtureColumnsArray = Object.values(CampaignMemberNurtureColumns).map(
	(c) => CampaignMemberColumnMap[c]
);
