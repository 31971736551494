import { Modal, ModalStatus, ModalType } from '@copilot/common/store/models/redux/modal';
import { ModalSelectors } from '@copilot/common/store/selectors/modal';
import modalManager from '@copilot/common/utils/modalManager';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Ref } from 'redux-orm';

/**
 * Hook to handle closing of modal
 * @param modalType type of modal we want to close
 * @returns function closeModal
 */
export const useCloseModal = (modalType: ModalType): () => void => {
	const modals = useSelector(ModalSelectors.getModals);

	return useCallback(() => {
		const currentModal = modals.filter((modal) => (modal.type === modalType && modal.status === ModalStatus.Open));
		if (currentModal && currentModal.length > 0) {
			modalManager.closeModal(currentModal[0].id);
		}
	}, [modals]);
};

/**
 * Hook to find an active modal of a type
 * @param modalType type of modal we want to get
 * @returns {Ref<Modal>} The modal we want to find
 */
export const useActiveModal = (modalType: ModalType): Ref<Modal> => {
	const modals = useSelector(ModalSelectors.getModals);
	return useMemo(() => {
		const targetModal = modals.filter((modal) => modal.type === modalType);
		return targetModal[0];
	}, [modals]);
};
