import { Config } from '@copilot/common/config';
import BaseDataManager from '@copilot/data/managers/base';

/**
 * utility function which returns authorization token
 * @returns <string> JWT bearer token
 */
const getAuthToken: () => Promise<string> = async () => {
	const token = Config.isB2CEnabled
		? await BaseDataManager.RequestManager.getToken(Object.keys(Config.b2cScopes)[0])
		: document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
	return token;
};

export default getAuthToken;