import React, { useMemo } from 'react';
import {
	Row, Col, Input, Button, Select, Form,
} from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ProxySelectors } from '@copilot/common/store/selectors/proxy';
import { Config } from '@copilot/common/config';
import { Validators } from '@copilot/common/components/forms/common/validators';

export interface UserInfoWrapperValues {
	customerFirstName: string;
	customerLastName: string;
	customerStripeId: string;
	customerEmail: string;
	newPassword: string;
	customerConfirmPassword: string;
	proxy: string;
}
interface UserInfoFormProps {
	onNext: (values: UserInfoWrapperValues) => Promise<any> | void;
	err: boolean;
	submitting: boolean;
}

const UserInfoForm: React.FC<UserInfoFormProps> = (props) => {
	const { onNext, err, submitting } = props;
	const [form] = Form.useForm();
	const proxyMapping = useSelector(ProxySelectors.getProxies);

	const compareConfirmationPassword = React.useCallback((rule: any, value: any, callback: any) => {
		if (value !== form.getFieldValue('newPassword'))
			callback('Your passwords must match.');
		else callback();
	}, []);

	const handleFinish = React.useCallback((values: UserInfoWrapperValues) => {
		onNext?.(values);
	}, [onNext]);

	const vpnOptions = useMemo(
		() =>
			proxyMapping.map((proxy: any) => (
				<Select.Option key={proxy.value} value={proxy.value}>
					{proxy.name}
				</Select.Option>
			)),
		[proxyMapping]
	);

	return (
		<Form
			form={form}
			onFinish={handleFinish}
			scrollToFirstError
			initialValues={{
				customerFirstName: '',
				customerLastName: '',
				customerEmail: '',
				customerStripeId: '',
			}}
		>
			<Row style={{ display: 'block ' }}>
				<Col style={{ textAlign: 'center' }}>
					<h2>Set E-mail and Password</h2>
				</Col>
			</Row>
			<Row style={{ display: 'block ' }}>
				<Col style={{ padding: '10px 0' }}>
					This will be used to access the customers dashboard in the future. Password can
					be changed at any time in the future.
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<BlockFormItem
						name="customerFirstName"
						rules={[
							{
								required: true,
								message: 'Name required',
							},
						]}
						label={(
							<span>
								<strong>Customer First Name</strong>
							</span>
						)}
					>
						<Input />
					</BlockFormItem>
					<BlockFormItem
						name="customerLastName"
						rules={[
							{
								required: true,
								message: 'Name required',
							},
						]}
						label={(
							<span>
								<strong>Customer Last Name</strong>
							</span>
						)}
					>
						<Input />
					</BlockFormItem>
					<BlockFormItem
						name="customerEmail"
						rules={[
							{
								required: true,
								type: 'email',
								message: 'Please enter a valid E-mail.',
							},
						]}
						label={(
							<span>
								<strong>Login Email</strong>
							</span>
						)}
					>
						<Input type="email" />
					</BlockFormItem>
					<BlockFormItem
						label="New Password"
						style={{ fontWeight: 'bold' }}
						name="newPassword"
						rules={[
							{ required: true, validator: Validators.validateSecurePassword },
						]}
					>
						<Input type="password" />
					</BlockFormItem>
					<BlockFormItem
						label="Confirm Password"
						style={{ fontWeight: 'bold' }}
						name="confirmPassword"
						rules={[{ required: true, validator: compareConfirmationPassword }]}
					>
						<Input type="password" />
					</BlockFormItem>
					<BlockFormItem
						label="Proxy Location"
						style={{ fontWeight: 'bold' }}
						name="proxy"
						rules={[{ required: true }]}
					>
						<Select style={{ minWidth: '150px' }}>{vpnOptions}</Select>
					</BlockFormItem>
					{!Config.isAgency && (
						<BlockFormItem
							label={(
								<span>
									<strong>Stripe Id</strong>
								</span>
							)}
							name="customerStripeId"
						>
							<Input />
						</BlockFormItem>
					)}
				</Col>
			</Row>
			<Col style={{ display: 'flex', justifyContent: 'center' }}>
				<Button
					type="primary"
					htmlType="submit"
					disabled={submitting}
				>
					Create
				</Button>
			</Col>
			<br />
			{err && (
				<div style={{ color: 'red' }}>
					Customer creation failed. Customer exists or you have used up all your seats
					allocated.
				</div>
			)}
		</Form>
	);
};

const BlockFormItem = styled(Form.Item)`
	display: block;
`;

export default UserInfoForm;
