import React from 'react';
import BasicContainer from '@copilot/common/components/containers/basic';
import { Divider, Button, notification } from 'antd';
import { OnboardMessage } from '@copilot/data/requests/models';
import AutomatedMessage from '@copilot/common/components/lists/automatedMessages/message';
import drawerManager from '@copilot/common/utils/drawerManager';
import { useSelector } from 'react-redux';
import { CampaignOnboardSelectors } from '@copilot/common/store/selectors/campaignOnboard';
import { ERROR_NOTIFICATION_KEY } from '../constant';
import { FollowUps } from '@copilot/common/pages/onboard/wizard/messaging/const';

const MessageDescriptions: { title: string; description: string }[] = [
	{
		title: 'Connection Request Message',
		description: 'This is the invitation connection messages that sends out',
	},
	{
		title: '2nd Message',
		description: 'The aim of this message is to follow-up with new conversation',
	},
];
interface CampaignOnboardWizardMessagingProps {
	campaignId: string;
	portOverCampaignName?: string;
	automatedMessages: OnboardMessage[];
	setAutomatedMessages: (messages: OnboardMessage[]) => void;
	followUpMessages: OnboardMessage[];
	setFollowUpMessages: (messages: OnboardMessage[]) => void;
}
const CampaignOnboardWizardMessaging: React.FC<CampaignOnboardWizardMessagingProps> = (props) => {
	const onboardDetails = useSelector((state) =>
		CampaignOnboardSelectors.getOnboardDetails(state, props.campaignId)
	);
	const header = React.useMemo(
		() => (
			<>
				<h1>Confirm Messaging</h1>
				<h3>
					{onboardDetails?.messages ? null : props.portOverCampaignName ? (
						<>
							We brought over your messaging from
							{' '}
							<strong>
								{' '}
								{props.portOverCampaignName}
								{' '}
							</strong>
							{' '}
							Campaign
						</>
					) : (
						"We weren't able to bring over your message from other campaigns."
					)}
				</h3>
				<p>
					If you want to edit your messaging, feel free to click edit. Otherwise, click
					Save & Next. Please note that
					{' '}
					<strong>Connection request message</strong>
					{' '}
					and
					{' '}
					<strong>Second Message</strong>
					{' '}
					are mandatory.
				</p>
			</>
		),
		[props.portOverCampaignName, onboardDetails?.messages]
	);

	const followUpHeader = React.useMemo(
		() => (
			<>
				<h2>Follow-up messages</h2>
				<p>
					If a prospect doesn't reply to you after you have sent your second message, you have the option to send another follow-up!
				</p>
			</>
		),
		[]
	);

	const handleMessageEdit = React.useCallback(
		(message: OnboardMessage, messageType: number) => {
			props.setAutomatedMessages([
				...props.automatedMessages.slice(0, messageType),
				message,
				...props.automatedMessages.slice(messageType + 1),
			]);
		},
		[props.automatedMessages, props.setAutomatedMessages]
	);

	const handleFollowUpMessageEdit = React.useCallback(
		(message: OnboardMessage, messageType: number) => {
			props.setFollowUpMessages([
				...props.followUpMessages.slice(0, messageType - 2),
				message,
				...props.followUpMessages.slice(messageType - 1),
			]);
		},
		[props.followUpMessages, props.setFollowUpMessages]
	);

	const onEdit = React.useCallback((messageType, onSubmit, message?) => {
		notification.destroy(ERROR_NOTIFICATION_KEY);
		drawerManager.openWizardMessageEditDrawer({
			messageType,
			currentMessage: message,
			onSubmit,
			closeAlert: true,
		});
	}, []);

	const onDelete = React.useCallback(
		(index: number) => {
			notification.destroy(ERROR_NOTIFICATION_KEY);
			props.setFollowUpMessages([
				...props.followUpMessages.slice(0, index),
				...props.followUpMessages.slice(index + 1),
			]);
		},
		[props.followUpMessages, props.setFollowUpMessages]
	);

	const mandatoryMessages = React.useMemo(
		() =>
			MessageDescriptions.map((messageInfo, index) => (
				<React.Fragment key={messageInfo.title}>
					<AutomatedMessage
						title={messageInfo.title}
						description={messageInfo.description}
						message={props.automatedMessages[index]?.text}
						onEdit={() => {
							onEdit(index, handleMessageEdit, props.automatedMessages[index]);
						}}
					/>
					<br />
				</React.Fragment>
			)),
		[MessageDescriptions, props.automatedMessages, handleMessageEdit]
	);

	const followupMessages = React.useMemo(
		() =>
			props.followUpMessages.map((followUpMessage, index) => (
				<React.Fragment key={`${FollowUps[index].label} Message`}>
					<AutomatedMessage
						title={`${FollowUps[index].label} Message`}
						message={followUpMessage.text}
						onEdit={() => {
							onEdit(
								index + props.automatedMessages.length,
								handleFollowUpMessageEdit,
								followUpMessage
							);
						}}
						onDelete={() => {
							onDelete(index);
						}}
					/>
					<br />
				</React.Fragment>
			)),
		[
			props.followUpMessages,
			props.automatedMessages,
			handleFollowUpMessageEdit,
			FollowUps,
			onDelete,
		]
	);

	return (
		<BasicContainer>
			<BasicContainer.Content>
				{header}
				<Divider />
				{mandatoryMessages}
				<Divider />
				{followUpHeader}
				{followupMessages}
				{props.followUpMessages.length < 3 && (
					<Button
						onClick={() => {
							onEdit(
								props.followUpMessages.length + props.automatedMessages.length,
								handleFollowUpMessageEdit,
								{
									period: FollowUps[props.followUpMessages.length].period,
									time: FollowUps[props.followUpMessages.length].time,
									text: '',
								}
							);
						}}
					>
						Add Follow-up message
					</Button>
				)}
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default CampaignOnboardWizardMessaging;
