import React, {
	FC, ReactNode, useState, useEffect, useCallback, useMemo, CSSProperties,
} from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import Navigation from '@copilot/common/components/wizard/modules/navigation';
import { IWizardNavigationNode } from './interface';
import WizardStep, { isWizardStep } from '@copilot/common/components/wizard/steps';
import { WizardContext } from '@copilot/common/components/wizard/context';
import { Colors } from '@copilot/common/utils/constant';

const StyledLayout = styled(Layout)`
	&&& {
		background-color: ${Colors.WizardBackground};
	}
`;

const LayoutSiderLeft = styled(Layout.Sider)`
	&&& {
		left: 0;
		overflow: auto;
		background-color: transparent;
	}
	::-webkit-scrollbar {
		width: 10px;
		background-color: transparent;
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(255, 255, 255, 0.3);
	}
`;

const InnerLayout = styled(Layout.Content)`	
	::-webkit-scrollbar {
		width: 10px;
		background-color: transparent;
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.3);
	}
`;

const InnerPanel = styled.div`
	padding: 24px 32px;
`;

const DescriptionDiv = styled.div`
	margin-bottom: 12px;
	line-height: 1.5;
	font-size: 14px;
`;

/**
 * Gets the node status for the given step
 * @param {number} node the node to determine the status for
 * @param {number} currentNode current node / step (only needed when we want to limit future steps)
 * @param {number} farthestNode farthest node / step the user reached (only needed when we want to limit future steps)
 */
const getStepNodeStatus = (node: number, currentNode?: number, farthestNode?: number) => {
	if (currentNode === undefined || farthestNode === undefined) return undefined;
	if (node === currentNode) return 'process';
	return (farthestNode > node) ? 'finish' : 'wait';
};

/**
 * Gets the navigation nodes for the sidebar as well as the alwaysSaved array
 * @param {ReactNode} children child nodes composed of WizardStep components
 * @param {number} currentNode current node (only needed when we want to limit future steps)
 * @param {number} farthestNode farthest node (only needed when we want to limit future steps)
 */
const getSidebarWizardSteps = (children: ReactNode, currentNode?: number, farthestNode?: number) => {
	const wizardSteps: IWizardNavigationNode[] = [];
	const alwaysSaved: number[] = [];
	React.Children.map(children, (child) => {
		if (isWizardStep(child)) {
			wizardSteps.push({
				description: child.props.title,
				element: <DescriptionDiv>{child.props.description}</DescriptionDiv>,
				order: child.props.id,
				disabled: !!child.props.isDisabled || (farthestNode !== undefined && (farthestNode < child.props.id)),
				status: getStepNodeStatus(child.props.id, currentNode, farthestNode),
				sidebarHidden: !!child.props.isSidebarHidden,
			});
			if (child.props.isAlwaysSaved) alwaysSaved.push(child.props.id);
		}
	});
	return { wizardSteps, alwaysSaved };
};

export interface IWizardStaticProps {
	Step: typeof WizardStep;
}

export interface IWizardProps {
	farthestNode: number;
	onSave: (saveStatus: boolean, modifications?: { [k: string]: any }) => void;
	afterNodeChange?: (node: number) => void;
	disableUnreachedSteps?: boolean;
	style?: CSSProperties;
	sidebarWidth?: number;
	sidebarStyle?: CSSProperties;
	innerLayoutStyle?: CSSProperties;
	sidebarBreakpoint?: React.ComponentProps<typeof Layout.Sider>['breakpoint'],
}

/**
 * Wizard component layout
 * @param {number} farthestNode the step to load the wizard from
 * @param {function} onSave called when we want to save the current progress, with modifications that happen while saving
 * @param {function} afterNodeChange called after the target node has been changed
 * @param {boolean} disableUnreachedSteps whether the user is able to click to an unreached step
 * @param {CSSProperties} style styling for the layout
 * @param {number} sidebarWidth width for the sidebar
 * @param {CSSProperties} sidebarStyle styling for the sidebar
 * @param {CSSProperties} innerLayoutStyle styling for the inner layout
 * @param {'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'} sidebarBreakpoint the breakpoint for the sidebar to collapse
 */
const Wizard: FC<IWizardProps> & IWizardStaticProps = (props) => {
	const {
		farthestNode,
		onSave,
		afterNodeChange,
		disableUnreachedSteps = false,
		style,
		sidebarWidth = 350,
		sidebarStyle,
		innerLayoutStyle,
		sidebarBreakpoint,
		children,
	} = props;
	const [currentNode, setCurrentNode] = useState<number>(farthestNode);
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const { wizardSteps, alwaysSaved } = getSidebarWizardSteps(children, currentNode, (disableUnreachedSteps ? farthestNode : undefined));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [currentNode]);

	// updates the current node and saves using the updated farthest step and given modifications (if applicable)
	const onNodeChange = useCallback((targetNode: number, modifications?: { [k: string]: any }) => {
		setCurrentNode(targetNode);
		if (targetNode > farthestNode) {
			onSave(alwaysSaved.includes(targetNode), { step: targetNode, ...modifications });
		} else {
			onSave(true, modifications);
		}
		if (afterNodeChange) afterNodeChange(targetNode);
	}, [onSave, afterNodeChange, farthestNode, alwaysSaved]);

	const isSidebarHidden = useMemo(() => (
		!!wizardSteps[currentNode]?.sidebarHidden
	), [wizardSteps, currentNode]);

	return (
		<WizardContext.Provider value={{ currentNode, onNodeChange }}>
			{
				!!sidebarBreakpoint && collapsed && (
				<Navigation
					type="navigation"
					direction="horizontal"
					currentNode={currentNode}
					onNodeClick={onNodeChange}
					nodes={wizardSteps}
				/>
				)
			}
			<StyledLayout style={style}>
				{!isSidebarHidden && (
					<LayoutSiderLeft
						width={sidebarWidth}
						style={sidebarStyle}
						collapsedWidth={0}
						breakpoint={sidebarBreakpoint}
						onCollapse={(isCollapsed) => {
							setCollapsed(isCollapsed);
						}}
					>
						<InnerPanel>
							<Navigation currentNode={currentNode} onNodeClick={onNodeChange} nodes={wizardSteps} />
						</InnerPanel>
					</LayoutSiderLeft>
				)}
				<InnerLayout style={{ marginLeft: isSidebarHidden ? `${sidebarWidth}px` : '', ...innerLayoutStyle }}>
					<InnerPanel>
						{children}
					</InnerPanel>
				</InnerLayout>
			</StyledLayout>
		</WizardContext.Provider>
	);
};

Wizard.Step = WizardStep;

export default Wizard;
