import { useEffect, useState } from 'react';
import { Button, Form, Popover, Tooltip } from 'antd';
import { throwError } from '@copilot/common/utils';
import { isEmpty, isNil, isUndefined } from 'lodash';
import ModalManager from '@copilot/common/utils/modalManager';
import { SaveOutlined, InfoCircleFilled, VideoCameraAddOutlined } from '@ant-design/icons';
import { TextEditor } from '../textEditor';
import { TextEditorSmartReply } from '../textEditor/smartReply';
import { TextEditorAutomatedStepsAndReminderFooter } from '@copilot/common/components/forms/textEditor/footer/automatedStepsAndReminder';
import { TextEditorScheduledMessageFooter } from '../textEditor/footer/scheduledMessage';
import { MessageFormProps, handleSmartReplyOutOfCredit } from './types';
import styles from './messageForm.module.less';
import ResumeAutomationButton from './resumeAutomationButton';
import { THREAD_RESTRICTED_TEXT, VIDEO_IN_APP_VALIDATION_ENABLED_BUTTON_TEXT } from './constants';

/**
 * [Smart] Message Form
 * Form component to selectively handle message template selection, automation, and submitting/scheduling messages
 * using a template radio selector or template dropdown
 */
function MessageForm({
	messageTemplates,
	automatedSteps = [],
	isDisabled = false,
	isScheduled = false,
	isUsingTemplateDropdown = false,
	placeholder,
	onSetupTemplate,
	onSubmit,
	onScheduledSubmit,
	onSetReminder,
	onCardTracking,
	chatGPTWriteRetryCallback,
	chatGPTWriteCallback,
	chatGPTEditCallback,
	chatGPTQueryResult,
	isSubmitting,
	isThreadRestricted,
	smartReplyPromptCategory,
	videoInAppValidationEnabled,
	hasAcceptedAITermsOfUse = false,
}: MessageFormProps) {
	const [form] = Form.useForm();
	
	const [onTextAreaFocus, setOnTextAreaFocus] = useState<boolean>(false);
	const [isAssistantActive, setAssistantActive] = useState<boolean>(false);
	// #region ai message generation
	const [hasGenerated, setHasGenerated] = useState<boolean>(false);
	useEffect(() => {
		if (!isNil(chatGPTQueryResult?.data)) {
			setHasGenerated(true);
			form.setFieldsValue({ message: chatGPTQueryResult.data });
		}
	}, [chatGPTQueryResult.data]);

	function calcPlaceHolder() {
		if (isThreadRestricted) return undefined;
		return placeholder ?? 'Type your message or select from the option above';
	}

	function onSignUpToVideo() {
		window.open(
			'https://docs.google.com/forms/d/e/1FAIpQLScOA4_8wL0J2-2nkSW3KGS2JDaaq1PkU4n4c4yGJ9zvktnfEw/viewform',
			'_blank'
		);
	}

	useEffect(() => {
		if (onTextAreaFocus) setAssistantActive(false);
	}, [onTextAreaFocus]);
	// #endregion

	const isAbleToResumeAutomation = automatedSteps.length > 0;

	return (
		<TextEditor value={''} disabled={isDisabled}>
			<TextEditor.Header>
				{!isEmpty(messageTemplates) ? (
					<TextEditor.Dropdown
						items={messageTemplates.map((template) => ({
							key: template.id,
							label: template.name,
							value: template.message,
							title: template.name,
						}))}
					>
						Quick Responses
					</TextEditor.Dropdown>
				) : null}
				<Tooltip placement="bottom" title="Save as quick response" trigger="hover">
					<TextEditor.Button
						icon={<SaveOutlined />}
						onClick={onSetupTemplate}
						style={{ border: 'none', boxShadow: 'none' }}
					/>
				</Tooltip>
				{isAbleToResumeAutomation && (
					<ResumeAutomationButton
						automatedSteps={automatedSteps}
						// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
						disabled={isThreadRestricted || isDisabled}
						onCardTracking={onCardTracking}
					/>
				)}
			</TextEditor.Header>
			<TextEditor.Body
				placeholder={calcPlaceHolder()}
				autoSize={{ minRows: 3, maxRows: 8 }}
				bordered={false}
				onFocus={() => setOnTextAreaFocus(true)}
				onBlur={() => setOnTextAreaFocus(false)}
			>
				{isThreadRestricted && (
					<div className={styles.restrictedMessageBanner}>
						<InfoCircleFilled className={styles.restrictedIcon} />
						{THREAD_RESTRICTED_TEXT}
					</div>
				)}
				<div className={styles.bodyButtonWrapper}>
					<TextEditorSmartReply
						isActive={isAssistantActive}
						canRegenerate={hasGenerated}
						onRegenerate={chatGPTWriteRetryCallback}
						setIsActive={setAssistantActive}
						onWrite={chatGPTWriteCallback}
						data={chatGPTQueryResult}
						onEdit={chatGPTEditCallback}
						onOutOfCredit={handleSmartReplyOutOfCredit}
						isAvailable={hasAcceptedAITermsOfUse}
						onNotAvailable={() =>
							ModalManager.openAIFeatureTermsOfUseModal({
								onSubmit: () => setAssistantActive(true),
							})
						}
						smartReplyPromptCategory={smartReplyPromptCategory}
					/>
					{videoInAppValidationEnabled && (
						<Popover
							content={
								<div className={styles.signUpWrapper}>
									<div className={styles.recordVideoPopover}>
										Do you want 10 personalized business videos? Sign up for our
										video BETA test
									</div>
									<Button size="small" type="primary" onClick={onSignUpToVideo}>
										Sign up here
									</Button>
								</div>
							}
							trigger="click"
							placement="topLeft"
						>
							<Button disabled={isDisabled} icon={<VideoCameraAddOutlined />}>
								{VIDEO_IN_APP_VALIDATION_ENABLED_BUTTON_TEXT}
							</Button>
						</Popover>
					)}
				</div>
			</TextEditor.Body>
			<TextEditor.Footer>
				{isScheduled && !isUndefined(onScheduledSubmit) ? (
					<TextEditorScheduledMessageFooter
						isSubmitting={isSubmitting}
						onScheduledSubmit={onScheduledSubmit}
					/>
				) : (
					<TextEditorAutomatedStepsAndReminderFooter
						onSubmit={(text, nodeId, quickResponseId, reminderDate) => {
							const removeReminder = isNil(reminderDate);
							isUndefined(onSubmit)
								? throwError('Missing callback for sending messages')
								: onSubmit(text, removeReminder, nodeId, quickResponseId);
							if (isNil(reminderDate)) return;
							isUndefined(onSetReminder)
								? throwError('Missing callback for setting reminder')
								: onSetReminder(reminderDate);
						}}
						showAutomationSteps={!isUsingTemplateDropdown}
					/>
				)}
			</TextEditor.Footer>
		</TextEditor>
	);
}

export default MessageForm;
