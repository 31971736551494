import { Calendar, DatePicker } from 'antd';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import type { PickerTimeProps } from 'antd/lib/date-picker/generatePicker';
import { ComponentProps, forwardRef } from 'react';

export type CopilotDateType = Moment;

export const CopilotDatePicker = DatePicker.generatePicker<CopilotDateType>(momentGenerateConfig);
export type CopilotRangePickerProps = ComponentProps<typeof CopilotDatePicker.RangePicker>;

export interface CopilotTimePickerProps extends Omit<PickerTimeProps<CopilotDateType>, 'picker'> {}

export const CopilotTimePicker = forwardRef<any, CopilotTimePickerProps>((props, ref) => (
	<CopilotDatePicker {...props} picker="time" ref={ref} />
));

CopilotTimePicker.displayName = 'CopilotTimePicker';

export const CopilotCalendar = Calendar.generateCalendar<CopilotDateType>(momentGenerateConfig);