import { FC } from 'react';
import BaseModal from '../base';
import { WarningFilled } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import { useCloseModal } from '@copilot/common/hooks/modal';
import { ModalType } from '@copilot/common/store/models/redux/modal';
import { IInstanceDataUpdateModalProps } from './props';

/**
 * A modal that is shown when CS wants to update the instanceId of a TeamsCustomer
 * @param onContinue callback that is called when CS wants to continue with the update
 * @param currentDomain name of the current domain of the TeamsCustomer
 * @param newDomain name of the domain that CS wants to update to
 */
const TeamCustomerInstanceDataUpdateModal: FC<IInstanceDataUpdateModalProps> = (props) => {
	const {
		onContinue, currentDomain, newDomain, ...rest
	} = props;
	const closeModal = useCloseModal(ModalType.TeamCustomerInstanceDataModal);
	return (
		<BaseModal
			width={590}
			centered
			{...rest}
		>
			<Row justify="center">
				<Col>
					<WarningFilled style={{ fontSize: '300%', color: 'red' }} />
				</Col>
			</Row>
			<br />
			<Row justify="center" style={{ textAlign: 'center' }}>
				<Col>
					<h3>
						<strong>
							{`Are you sure you want to change the domain of this organization from ${currentDomain} to ${newDomain}?`}
						</strong>
					</h3>
					<p>
						{`The domain of the org will be switched from ${currentDomain} to ${newDomain}.`}
					</p>
				</Col>
			</Row>
			<Row justify="center">
				<Col>
					<Button
						type="primary"
						onClick={() => {
							closeModal();
							onContinue();
						}}
					>
						Change Domain
					</Button>
				</Col>
			</Row>
			<br />
			<Row justify="center">
				<Col>
					<Button type="text" onClick={closeModal}>
						Do not change domain
					</Button>
				</Col>
			</Row>
		</BaseModal>
	);
};

export default TeamCustomerInstanceDataUpdateModal;
