import React from 'react';
import {
	Col, Row, Form, Input,
} from 'antd';
import BasicContainer from '@copilot/common/components/containers/basic';
import { Config } from '@copilot/common/config';

interface CampaignOnboardWizardSalesNavSearchProps {
	skipHelp: boolean;
	searchUrl: string;
	updateSearchUrl: (url: string) => void;
	searchUrlValid?: boolean;
	openModal: () => void;
}

const CampaignOnboardWizardSalesNavSearch = React.memo<CampaignOnboardWizardSalesNavSearchProps>(
	(props) => {
		const isCopilotUser = !Config.isAgency;
		React.useEffect(() => {
			if (!props.skipHelp && isCopilotUser) props.openModal();
		}, []);

		return (
			<BasicContainer>
				<BasicContainer.Content style={{ minHeight: '20em', paddingTop: 30 }}>
					<>
						<Row justify="start" gutter={[16, 16]}>
							<Col>
								<h3>Please enter your LinkedIn Sales Navigator search URL here:</h3>
							</Col>
						</Row>
						<Row justify="start">
							<Col span={20}>
								<Form>
									<Form.Item
										hasFeedback
										validateStatus={
											props.searchUrlValid
												? 'success'
												: props.searchUrlValid === undefined
													? undefined
													: 'error'
										}
										help={
											props.searchUrlValid ||
											props.searchUrlValid === undefined
												? undefined
												: 'Please enter a valid LinkedIn Sales Navigator search URL'
										}
									>
										<Input
											placeholder="Input Sales Navigator URL here"
											onChange={(e) => props.updateSearchUrl(e.target.value)}
											defaultValue={props.searchUrl}
										/>
									</Form.Item>
								</Form>
							</Col>
						</Row>
					</>
					{isCopilotUser && (
						<Row justify="start">
							<Col span={24}>
								<h4>
									Need a refresher?
									{' '}
									<a onClick={props.openModal}>
										Click here to rewatch the best practices video
									</a>
								</h4>
							</Col>
						</Row>
					)}
					<Row>
						<p>
							Your Account Strategist will look over the search URL before starting
							campaign
						</p>
					</Row>
				</BasicContainer.Content>
			</BasicContainer>
		);
	}
);

export default CampaignOnboardWizardSalesNavSearch;
