import {
	ColumnTypes,
	ColumnActionTypes,
	ColumnDefinition,
	ColumnDefinitionAdvanced,
	TextWithTagsColumnDefinition,
	ColumnActionFormat,
	StatusWithPopoverColumnDefinition,
	LinkActionColumnDefinition,
	IconColumnDefinition,
} from './columnDefinition';

import { DrawerTypes } from '../../utils/drawerManager';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { getCampaignIcon } from '@copilot/common/utils/campaign';

export enum CustomFieldActions {
	Calendar = 'Calendar',
	Dismiss = 'Dismiss',
	Remove = 'Remove',
	Retry = 'Retry'
}

export const SentColumns = [
	new StatusWithPopoverColumnDefinition({
		Key: 'status',
		Properties: ['status', 'error', 'isContactDeleted'],
		Label: '',
		hideable: false,
		Filter: false,
		Type: ColumnTypes.StatusWithPopover,
		determineStatusIcon: UtilityFunctions.getThreadStatusIcon,
		width: '80px',
		IsVisible: true,
	}),
	new ColumnDefinition({
		Key: 'name',
		Properties: ['name'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		Filter: true,
		width: '15%',
	}),
	new ColumnDefinition({
		Key: 'message',
		Properties: ['message'],
		Label: 'Message',
		Text: ['messsage'],
		Type: ColumnTypes.String,
		IsVisible: true,
		ellipsis: true,
		width: '60%',
	}),
	new ColumnDefinition({
		Key: 'LastSentDate',
		Properties: ['lastSent'],
		Label: 'Last Updated',
		Type: ColumnTypes.Date,
		IsVisible: true,
		Sort: true,
		width: '15%',
	}),
];
export const EmailColumns = [
	new ColumnDefinition({
		Key: 'link',
		Properties: ['alias'],
		Filter: false,
		hideable: false,
		Label: '',
		Type: ColumnTypes.Link,
		IsVisible: true,
		action: {
			type: ColumnActionTypes.Custom,
			format: ColumnActionFormat.ImageLink,
			metaData: {
				values: 'route-to-image.jpg',
			},
		},
	}),
	new ColumnDefinition({
		Key: 'contactName',
		Properties: ['contactName'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		Filter: true,
	}),
	new TextWithTagsColumnDefinition({
		Key: 'textWithTags',
		Properties: ['lastReceived.message', 'tags', 'isRead', 'filteredTags'],
		Text: ['message'],
		Tags: ['tags'],
		Label: 'Message and Tags',
		Type: ColumnTypes.TextWithTags,
		IsVisible: true,
	}),
	new IconColumnDefinition({
		Key: 'campaignName',
		Properties: ['campaignName', 'campaignType'],
		Label: 'Campaign',
		Type: ColumnTypes.TextWithIcon,
		determineIconFunction: getCampaignIcon,
		IsVisible: true,
	}),
	new ColumnDefinition({
		Key: 'polarity',
		Properties: ['polarity'],
		Label: 'Initial Sentiment',
		Type: ColumnTypes.String,
		IsVisible: true,
	}),
	new ColumnDefinition({
		Key: 'timestamp',
		Properties: ['lastReceived.timestamp'],
		Label: 'Message Received',
		Type: ColumnTypes.Date,
		IsVisible: true,
	}),
	new LinkActionColumnDefinition({
		Key: 'calendar',
		Properties: ['threadId', 'isSnoozed'],
		Label: '',
		Type: ColumnTypes.LinkAction,
		Filter: false,
		hideable: false,
		IsVisible: true,
		width: 150,
		action: {
			type: ColumnActionTypes.Custom,
			metaData: {
				type: CustomFieldActions.Calendar,
				fieldValues: { threadId: 'threadId' },
			},
		},
	}),
	new LinkActionColumnDefinition({
		Key: 'dismiss',
		Properties: ['threadId'],
		Filter: false,
		hideable: false,
		Label: '',
		Type: ColumnTypes.LinkAction,
		IsVisible: true,
		width: 150,
		action: {
			type: ColumnActionTypes.Custom,
			metaData: {
				type: CustomFieldActions.Dismiss,
				fieldValues: { threadId: 'threadId' },
			},
		},
	}),
];

export const OutboxColumns = [
	new StatusWithPopoverColumnDefinition({
		Key: 'status',
		Properties: ['status', 'error'],
		Label: '',
		hideable: false,
		Filter: false,
		Type: ColumnTypes.StatusWithPopover,
		DetermineIconFunction: null,
		IsVisible: true,
		width: 50,
	}),
	new ColumnDefinition({
		Key: 'name',
		Properties: ['name'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		width: '15%',
		// Filter: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: {
					id: 'contactId',
					memberId: 'orgMemberId',
				},
			},
		},
	}),
	new ColumnDefinition({
		Key: 'message',
		Properties: ['message'],
		Label: 'Message',
		Type: ColumnTypes.String,
		IsVisible: true,
		width: '50%',
		// Filter: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: {
					id: 'contactId',
					memberId: 'orgMemberId',
				},
			},
		},
	}),
	new ColumnDefinition({
		Key: 'campaign',
		Properties: ['campaignName'],
		Label: 'Campaign',
		Type: ColumnTypes.String,
		IsVisible: true,
		width: '15%',
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: { id: 'contactId', memberId: 'orgMemberId' },
			},
		},
	}),
	new ColumnDefinition({
		Key: 'timestamp',
		Properties: ['timestamp'],
		Label: 'Last Updated',
		Type: ColumnTypes.Date,
		IsVisible: true,
		// Sort: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: {
					id: 'contactId',
					memberId: 'orgMemberId',
				},
			},
		},
	}),
];

export const FailedOutboxColumns = [
	new StatusWithPopoverColumnDefinition({
		Key: 'status',
		Properties: ['status', 'error'],
		Label: 'Error',
		hideable: false,
		Filter: false,
		Type: ColumnTypes.StatusWithPopover,
		DetermineIconFunction: null,
		IsVisible: true,
		width: '10%',
	}),
	new ColumnDefinition({
		Key: 'name',
		Properties: ['name'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		width: '15%',
		// Filter: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: {
					id: 'contactId',
					memberId: 'orgMemberId',
				},
			},
		},
	}),
	new ColumnDefinition({
		Key: 'message',
		Properties: ['message'],
		Label: 'Message',
		Type: ColumnTypes.String,
		IsVisible: true,
		width: '50%',
		// Filter: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: {
					id: 'contactId',
					memberId: 'orgMemberId',
				},
			},
		},
	}),
	new ColumnDefinition({
		Key: 'campaign',
		Properties: ['campaignName'],
		Label: 'Campaign',
		Type: ColumnTypes.String,
		IsVisible: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: { id: 'contactId', memberId: 'orgMemberId' },
			},
		},
	}),
	new ColumnDefinition({
		Key: 'timestamp',
		Properties: ['timestamp'],
		Label: 'Last Updated',
		Type: ColumnTypes.Date,
		IsVisible: true,
		Sort: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: {
					id: 'contactId',
					memberId: 'orgMemberId',
				},
			},
		},
	}),
	new LinkActionColumnDefinition({
		Key: 'retry',
		Properties: ['id', 'status'],
		Label: '',
		width: 150,
		Type: ColumnTypes.LinkAction,
		IsVisible: true,
		hideable: false,
		Filter: false,
		DetermineLinkDisplay: null,
		action: {
			type: ColumnActionTypes.Custom,
			metaData: {
				type: CustomFieldActions.Retry,
				fieldValues: { id: 'id' },
			},
		},
	}),
	new LinkActionColumnDefinition({
		Key: 'dismiss',
		Properties: ['id', 'status'],
		Label: '',
		width: 150,
		Type: ColumnTypes.LinkAction,
		IsVisible: true,
		hideable: false,
		Filter: false,
		DetermineLinkDisplay: null,
		action: {
			type: ColumnActionTypes.Custom,
			metaData: {
				type: CustomFieldActions.Dismiss,
				fieldValues: { id: 'id' },
			},
		},
	}),
];

export const BaseTeamMemberColumns = [
	new ColumnDefinition({
		Key: 'name',
		Properties: ['firstName', 'lastName'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		Filter: true,
	}),
	new ColumnDefinition({
		Key: 'email',
		Properties: ['email'],
		Label: 'Email',
		Type: ColumnTypes.String,
		IsVisible: true,
	}),
];

export const OrganizationTeamMemberStatsColumns = [
	new ColumnDefinition({
		Key: 'name',
		Properties: ['firstName', 'lastName'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		Filter: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.AdminMemberDrawer],
				fieldValues: { id: 'id' },
			},
		},
	}),
	new ColumnDefinition({
		Key: 'invites',
		Properties: ['invites'],
		Label: 'Invites Sent',
		Type: ColumnTypes.String,
		IsVisible: true,
		Filter: true,
	}),
	new ColumnDefinition({
		Key: 'connections',
		Properties: ['connections'],
		Label: 'New Connections',
		Type: ColumnTypes.String,
		IsVisible: true,
		Filter: true,
	}),
	new ColumnDefinition({
		Key: 'replies',
		Properties: ['replies'],
		Label: 'Total Replies',
		Type: ColumnTypes.String,
		IsVisible: true,
		Filter: true,
	}),
];

export const OrganizationTeamMemberColumns = [
	new ColumnDefinition({
		Key: 'name',
		Properties: ['firstName', 'lastName'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		Filter: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.AdminMemberDrawer],
				fieldValues: { id: 'id' },
			},
		},
	}),
	new ColumnDefinition({
		Key: 'email',
		Properties: ['email'],
		Label: 'Email',
		Type: ColumnTypes.String,
		IsVisible: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.AdminMemberDrawer],
				fieldValues: { id: 'id' },
			},
		},
	}),
];

export const CampaignTeamMemberColumns = [
	...BaseTeamMemberColumns,
	new ColumnDefinition({
		Key: 'searchCriteria',
		Properties: ['searchCriteria.name'],
		Label: 'Search Criteria',
		Type: ColumnTypes.String,
		IsVisible: true,
	}),
	new ColumnDefinition({
		key: 'status',
		label: 'Status',
		properties: ['status'],
		Type: ColumnTypes.EnabledStatus,
		isVisible: true,
	}),
	new ColumnDefinition({
		key: 'dailyInvites',
		label: 'Daily Invites',
		properties: ['dailyInvites'],
		type: ColumnTypes.String,
		isVisible: true,
	}),
];

export const CampaignConnectionColumns = [
	new ColumnDefinition({
		Key: 'lastname',
		Properties: ['contact.lastname'],
		Label: 'Last Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		Sort: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: {
					id: 'contactId',
					memberId: 'orgMemberId',
				},
			},
		},
	}),
	new ColumnDefinition({
		Key: 'firstname',
		Properties: ['contact.firstname'],
		Label: 'First Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		Sort: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: {
				type: [DrawerTypes.ContactDrawer],
				fieldValues: {
					id: 'contactId',
					memberId: 'orgMemberId',
				},
			},
		},
	}),
	new ColumnDefinition({
		Key: 'tags',
		Properties: ['contact.tags'],
		Label: 'Tags',
		Type: ColumnTypes.Tags,
		IsVisible: true,
		Filter: true,
	}),
	new ColumnDefinition({
		Key: 'lastThreadActivity',
		Properties: ['contact.lastThreadActivity'],
		Label: 'Last Updated',
		Type: ColumnTypes.Date,
		IsVisible: true,
		Sort: true,
	}),
];

export const SearchCriteriaColumns = [
	new ColumnDefinition({
		Key: 'name',
		Properties: ['name'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		action: {
			type: ColumnActionTypes.DetailsDrawer,
			metaData: { type: [DrawerTypes.SearchCriteriaDrawer], fieldValues: { id: 'id' } },
		},
	}),
	new ColumnDefinition({
		Key: 'location',
		Properties: ['locations'],
		Label: 'Cities',
		Type: ColumnTypes.Tags,
		IsVisible: true,
	}),
	new ColumnDefinition({
		Key: 'titles',
		Properties: ['titles'],
		Label: 'Job Titles',
		Type: ColumnTypes.Tags,
		IsVisible: true,
	}),
	new ColumnDefinition({
		Key: 'experience',
		Properties: ['experience'],
		Label: 'Years of Exp',
		Type: ColumnTypes.Tags,
		IsVisible: true,
	}),
];

export const StatsColumns = [
	new ColumnDefinition({
		key: 'invites',
		properties: ['invites'],
		label: 'Invites',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	new ColumnDefinition({
		key: 'connections',
		properties: ['connections'],
		label: 'Connections',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	new ColumnDefinition({
		key: 'total_replies',
		properties: ['total_replies'],
		label: 'Total Replies',
		type: ColumnTypes.String,
		isVisible: true,
	}),
	new ColumnDefinition({
		key: 'connection_response_rate',
		properties: ['connection_response_rate'],
		label: 'Connections Responded',
		type: ColumnTypes.Percent,
		isVisible: true,
		Sort: true,
	}),
];

export const SearchListColumns = [
	new ColumnDefinitionAdvanced({
		Key: 'name',
		Properties: ['firstname', 'lastname'],
		SubProperties: ['company'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
	}),
	new ColumnDefinition({
		key: 'inviteUrl',
		properties: ['inviteUrl'],
		label: 'View Profile',
		type: ColumnTypes.Link,
		isVisible: true,
	}),
];
