/**
 * Load Single Entity Actions' Types
 */
export type LoadSingleActionTypes = {
	loadSingleAction: string;
	loadSingleSuccessAction: string;
	loadSingleErrorAction: string;
};

/**
 * Get the Load Single Entity Actions' Types
 * @param name
 */
export const getLoadSingleActionTypes = (name: string): LoadSingleActionTypes => ({
	loadSingleAction: `LOAD_${name}`,
	loadSingleSuccessAction: `${name}_LOADED`,
	loadSingleErrorAction: `${name}_LOAD_FAILED`,
});

/**
 * Generic Type for Load Single Entity Actions
 */
export type LoadSingleActions<T> = {
	loadSingle: () => { type: string };
	loadSingleSuccess: (payload: T) => { type: string; payload: T };
	loadSingleError: (error: any) => { type: string; error: any };
};

/**
 * Create actions for Load Single Entity
 */
export const createLoadSingleActions = <T,>(name: string): LoadSingleActions<T> => {
	const types = getLoadSingleActionTypes(name);
	return ({
		loadSingle: () => ({ type: types.loadSingleAction }),
		loadSingleSuccess: (payload) => ({ type: types.loadSingleSuccessAction, payload }),
		loadSingleError: (error) => ({ type: types.loadSingleErrorAction, error }),
	});
};
