import { useState } from 'react';
import { Button, Col, Row, Tabs } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import { getStartOfDay } from '@copilot/common/pages/campaignDashboard/summaryV2/dataFilterUtils';
import styles from './dateRange.module.less';
import DateRangeSelector, { DateRangeSelectorProps } from '../forms/common/generics/daterangeselector';
import { disableFutureDates } from '@copilot/common/utils';

const DATE_PICKER_KEY = 'date-picker';

/**
 * Possible date range filters
 */
export enum DateRangeFilterEnum {
    AllTime = 0,
    Last90 = 1,
    Last60 = 2,
    Last30 = 3,
    Last14 = 4,
    Last7 = 5,
    Today = 6
}
type DefaultDateRange = DateRangeFilterEnum | { startDate: Date; endDate: Date };

type DateFilter = Readonly<{
    label: string;
    endDate: number | null;
    startDate: number | null;
    enumValue: DateRangeFilterEnum;
}>;

const filters: DateFilter[] = [
    {
        label: 'All-Time',
        endDate: null,
        startDate: null,
        enumValue: DateRangeFilterEnum.AllTime,
    },
    {
        label: 'Last 14 Days',
        endDate: null,
        startDate: -14,
        enumValue: DateRangeFilterEnum.Last14,
    },
    {
        label: 'Last 7 Days',
        endDate: null,
        startDate: -7,
        enumValue: DateRangeFilterEnum.Last7,
    },
    {
        label: 'Today',
        endDate: null,
        startDate: 0,
        enumValue: DateRangeFilterEnum.Today,
    },
];

export type DateRangeFilterProps = Readonly<{
    datePickerProps?: DateRangeSelectorProps;
    defaultDateRange?: DefaultDateRange;
    dateFilters?: DateFilter[];
    hideCustomDates?: boolean;
    onChange?: (startDate?: Date, endDate?: Date, enumValue?: DateRangeFilterEnum) => void;
    /**
     * Should the tab overflow be hidden
     */
    hideTabOverflow?: boolean;
}>;

/**
 * Get the default tab based on date filters and default date range
 * @param dateFilters 
 * @param defaultDateRange 
 * @returns 
 */
function getDefaultTab(dateFilters: DateFilter[], defaultDateRange?: DefaultDateRange): string {
    if (isNil(defaultDateRange)) return `${DateRangeFilterEnum.Last7}`;
    if (isNumber(defaultDateRange)) return `${defaultDateRange}`;
    if (isNil(dateFilters)) return `${filters.length}`;
    return `${dateFilters.length}`;
}

/**
 * Date range filters in tab form
 * @returns 
 */
export default function DateRangeFilter({
    hideTabOverflow,
    hideCustomDates,
    defaultDateRange,
    dateFilters = filters,
    datePickerProps,
    onChange,
}: DateRangeFilterProps): JSX.Element {
    const [{ activeKey, isPickerShown }, setTabState] = useState({
        activeKey: getDefaultTab(dateFilters, defaultDateRange),
        isPickerShown: false,
    });

    function handleTabChange(tabKey: string) {
        setTabState({ activeKey: tabKey, isPickerShown: tabKey === DATE_PICKER_KEY });
        const filter = dateFilters.find(f => f.enumValue === parseInt(tabKey));
        if (filter) {
            const dateEnum = filter.enumValue ?? DateRangeFilterEnum.AllTime;
            const startDate = isNil(filter.startDate) ? 0 : getStartOfDay(Math.abs(filter.startDate));
            const endDate = isNil(filter.endDate) || filter.endDate === 0
                ? moment().endOf('d')
                : moment(startDate).add(filter.endDate, 'd').endOf('d');
            onChange?.(new Date(startDate), endDate.toDate(), dateEnum);
        }
    }

    return (
        <Row align='middle' justify='space-between' gutter={32}>
            <Col className={hideTabOverflow ? styles.tabsWithHiddenDropdown : undefined}>
                <Tabs activeKey={activeKey} onChange={handleTabChange} hideAdd>
                    {dateFilters.map(filter => (
                        <Tabs.TabPane key={`${filter.enumValue}`} tab={filter.label} />
                    ))}
                    {!hideCustomDates && (
                        <Tabs.TabPane
                            key={DATE_PICKER_KEY}
                            tab={
                                isPickerShown
                                    ? (
                                        <DateRangeSelector
                                            {...datePickerProps}
                                            onChange={onChange}
                                            disabledDate={disableFutureDates}
                                        />
                                    ) : (
                                        <Button type='primary'>
                                            <CalendarOutlined style={{ marginRight: '0px' }} />
                                        </Button>
                                    )
                            }
                        />
                    )}
                </Tabs>
            </Col>
        </Row>
    );
}