import React, {
	useState, useCallback, useMemo, useEffect,
} from 'react';
import {
	Col, Row, Radio, Divider, Alert,
} from 'antd';
import MessageEditorWithTemplates from '@copilot/common/components/editors/message/withTemplates';
import {
	MessageTypeEnum, MessageName, OpenMessagesExamples, MessageSentence,
} from '@copilot/common/pages/onboard/wizard/messaging/const';
import BasicWizardMessageEditor, { BasicWizardMessageEditorProps } from './wizard';
import { REQUEST_MESSAGE_CHAR_LIMIT } from '@copilot/common/pages/settings/message/const';
import styled from 'styled-components';

const StyledRadioGroup = styled(Radio.Group)`
	&&& {
		display: flex;
		& .${(props) => props.theme['@ant-prefix']}-radio-button-wrapper {
			flex: 1;
			text-align: center;
		}
	}
`;

interface OpenMessageEditorProps {
	hideToneCopy?: boolean;
	message?: string;
	onUpdate: (message: string) => unknown;
	templateType?: MessageTypeEnum;
	onTemplateTypeUpdate?: (type: MessageTypeEnum) => unknown;
}

const MessageList = [MessageTypeEnum.Casual, MessageTypeEnum.Moderate, MessageTypeEnum.Direct];
const MessageOptions = MessageList.map((type) => (
	<Radio.Button value={type} key={type} style={{ padding: '0 4em' }}>
		{MessageName[type]}
	</Radio.Button>
));

const OpenMessageEditor = (Editor: React.FC<BasicWizardMessageEditorProps>) => (
	props: OpenMessageEditorProps
) => {
	const {
		hideToneCopy = false,
		message = '',
		onUpdate,
		templateType = MessageTypeEnum.Casual,
		onTemplateTypeUpdate,
	} = props;
	const [messageType, setMessageType] = useState(templateType);
	const [selectedTemplate, setSelectedTemplate] = useState<string>('');

	useEffect(() => {
		if (onTemplateTypeUpdate) onTemplateTypeUpdate(messageType);
	}, [messageType, onTemplateTypeUpdate]);
	const handleMessageTypeChange = useCallback<
		NonNullable<React.ComponentProps<typeof Radio.Group>['onChange']>
	>((event) => {
		setMessageType(event.target.value as MessageTypeEnum);
	}, []);
	const messageTemplates = useMemo(() => {
		const messageGroup = OpenMessagesExamples[MessageTypeEnum[messageType] as keyof typeof OpenMessagesExamples];
		return Object.keys(messageGroup).map((k) => ({
			key: `${messageType}-${k}`,
			label: k,
			value: messageGroup[k as keyof typeof messageGroup],
		}));
	}, [messageType]);
	const messageObject = useMemo<
		React.ComponentProps<typeof MessageEditorWithTemplates>['message']
	>(() => ({ period: 0, text: message, time: 20 }), [message]);
	const handleUpdate = useCallback<
		NonNullable<React.ComponentProps<typeof MessageEditorWithTemplates>['onUpdate']>
	>(
		({ text }) => {
			onUpdate?.(text);
		},
		[onUpdate]
	);

	const header = (
		<>
			<Row>
				<Col>
					<h1>Connection Request Message</h1>
				</Col>
			</Row>
			<Row>
				<Col>
					<p style={{ marginBottom: 0 }}>
						This message will be sent to every prospect you attempt to connect with.
						It serves as a simple introduction and request for a connection.
					</p>
				</Col>
			</Row>
			<Divider />
			<Row>
				<h3>Choose Tone of Examples</h3>
				<Col style={{ width: '100%' }}>
					<StyledRadioGroup
						value={messageType}
						onChange={handleMessageTypeChange}
						buttonStyle="solid"
					>
						{MessageOptions}
					</StyledRadioGroup>
				</Col>
			</Row>
			{!hideToneCopy && (
				<>
					<br />
					<Row>
						<Col>{MessageSentence[messageType]}</Col>
					</Row>
				</>
			)}
			<Alert
				message={(
					<p style={{ margin: 0 }}>
						Please replace all
						{' '}
						<strong>TEXT IN CAPS</strong>
						{' '}
						with your own personal information as they are only filler text.
					</p>
				)}
				type="warning"
				showIcon
				style={{ marginTop: '16px' }}
			/>
		</>
	);

	return Editor({
		...props,
		templates: messageTemplates,
		message: messageObject,
		onUpdate: handleUpdate,
		hideTiming: true,
		header,
		charLimit: REQUEST_MESSAGE_CHAR_LIMIT,
		selectedTemplate,
		updateSelectedTemplate: setSelectedTemplate,
	});
};

export default OpenMessageEditor(BasicWizardMessageEditor);
