import { ActionType, createFetchReducer } from '@copilot/common/store/reducers/fetch';
import { CAMPAIGN_MEMBERS } from '@copilot/common/utils/campaignMember/constant';
import { ModelStateMap } from '@copilot/common/store/models/fetch';
import { Reducer } from 'redux';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';

type ReducerType<T extends { id: string },> = { [p: string]: Reducer<ModelStateMap<T>, ActionType<T>> };
const baseFetchReducer: ReducerType<CampaignMemberModel> = createFetchReducer<CampaignMemberModel>(CAMPAIGN_MEMBERS);

export default baseFetchReducer;
