import { ComponentProps } from 'react';
import { Typography, theme } from 'antd';

const { Title: antdTitle, Text } = Typography;
const { useToken } = theme;

/**
 * Custom Title Component that does not include margins
 * @param props
 * @constructor
 */
function Title(props: ComponentProps<typeof antdTitle>) {
	const { level = 1, style, ...rest } = props;
	const { token } = useToken();
	return <Text strong {...rest} style={{ fontSize: token[`fontSizeHeading${level}`], ...style }}/>;
}

export default Title;
