import * as React from 'react';
import {
	Button, Form, Row, Col, Input, Alert,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import BubbleCheckboxGroup, { CheckboxObj } from '@copilot/common/components/forms/common/generics/bubblecheckboxgroup';
import MessageEditorTokens, { IToken } from '@copilot/common/components/editors/message/tokens';
import { MessageEditorNameTokens } from '@copilot/common/utils/constant';
import TextareaAutosize from 'react-textarea-autosize';
import { MessageTemplate } from '@copilot/common/store/models/redux';
import { SessionBoundModel } from 'redux-orm';
import { inputAndFocusAtCaret } from '@copilot/common/utils/textArea/textAreaInsert';
import { complianceMessage } from '../../../../constant/strings';

export interface MessageTemplateFormProps {
	threadUrl?: string;
	submitText: string;
	onSubmit?: (templateName: string, message: string, campaigns?: string[]) => void;
	disabled?: boolean;
	disableText?: boolean;
	template: SessionBoundModel<MessageTemplate> | null;
	initialCampaigns: CheckboxObj[];
	showCampaigns: boolean;
}
const TextBox = styled(TextareaAutosize)`
	height: auto;
	width: 75%;
	min-height: 75px;
	color: rgba(0, 0, 0, 0.65);
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	padding: 4px 11px;
`;
const FormSpacing = styled(Form.Item)`
	.${(props) => props.theme['@ant-prefix']}-form-item-control {
		line-height: inherit;
	}
`;

interface MessageTemplateFormState {
	campaignOptions: React.ComponentProps<typeof BubbleCheckboxGroup>['values'];
}

class MessageTemplateForm extends React.Component<
	MessageTemplateFormProps,
	MessageTemplateFormState
> {
	static defaultProps = {
		submitText: 'Save Template',
	};
	messageAreaRef: React.RefObject<HTMLTextAreaElement>;
	formRef: React.RefObject<FormInstance> = React.createRef();

	constructor(props: MessageTemplateFormProps) {
		super(props);

		this.state = {
			campaignOptions: [],
		};
		this.messageAreaRef = React.createRef<HTMLTextAreaElement>();
	}

	handleFinish = (values: Record<string, string>) => {
		if (this.props.onSubmit) {
			let campaigns: string[] | undefined;
			if (this.props.initialCampaigns.length > 0) {
				campaigns = this.state.campaignOptions
					.filter((c: CheckboxObj) => c.isChecked)
					.map((c: CheckboxObj) => c.value);
			}
			const { message } = values;
			const templateName: string = values.name;
			this.props.onSubmit(templateName, message, campaigns);
			this.formRef.current?.resetFields();
		}
	};

	validateMessageName = (rule: any, value: string, callback: any) => {
		if (!value) callback('Cannot leave field blank');
		else if (value.length > 50) callback('Name must be less than 50 characters');
		else if (/[^a-zA-Z\d\s]/.test(value)) callback('Please use only alphanumericals');
		else callback();
	};

	updateName = () => {
		//todo add regex matching
	};

	handleTokenClick = (token: IToken) => {
		if (this.messageAreaRef.current) inputAndFocusAtCaret(token.value, this.messageAreaRef.current);
	};

	componentDidMount() {
		const campaignSelected = this.props.template?.campaignIds.reduce((
			map: { [x: string]: string },
			c: string
		) => {
			map[c] = c;
			return map;
		},
		{}
		) ?? {};

		const campaignOptions = this.props.initialCampaigns.map((c) => {
			if (campaignSelected[c.value]) {
				c.isChecked = true;
			}
			return c;
		});
		this.setState({ campaignOptions });
	}

	render() {
		return (
			<Form
				ref={this.formRef}
				onFinish={this.handleFinish}
				initialValues={{
					name: this.props.template?.name
						? this.props.template.name
						: '',
					message: this.props.template?.message
						? this.props.template.message
						: '',
				}}
			>
				<Row>
					<h3>Message Template Name</h3>
				</Row>
				<Row>
					<FormSpacing
						name="name"
						rules={[
							{
								required: true,
								validator: this.validateMessageName,
							},
						]}
						style={{ marginBottom: '4px', width: '75%' }}
					>
						<Input onChange={this.updateName} />
					</FormSpacing>
				</Row>
				<Row>
					<h3>Write the message you would like to send</h3>
				</Row>
				<Row>
					<FormSpacing
						name="message"
						rules={[
							{
								required: true,
							},
						]}
						style={{ marginBottom: '4px', width: '100%' }}
					>
						<TextBox
							disabled={this.props.disableText}
							rows={3}
							style={{ resize: 'none' }}
							inputRef={this.messageAreaRef}
							placeholder={this.props.disableText ? 'Sending Message...' : ''}
						/>
					</FormSpacing>
				</Row>
				<Row>
					<FormSpacing style={{ marginBottom: '8px' }}>
						<MessageEditorTokens
							tokens={MessageEditorNameTokens}
							onTokenClick={this.handleTokenClick}
						/>
					</FormSpacing>
				</Row>
				{this.props.showCampaigns && this.props.initialCampaigns.length > 0 && (
					<Row>
						<Col>
							<div>
								<strong>
									Choose the campaigns that you would like to use this template
									for.
								</strong>
							</div>
							<div>
								This message template will only show up in the campaigns that you
								have chosen.
							</div>
							<div style={{ marginTop: '5px' }}>
								<FormSpacing
									name="campaigns"
									style={{ marginBottom: '4px' }}
								>
									<BubbleCheckboxGroup
										onChange={console.log}
										values={this.state.campaignOptions}
										showCheckAll
									/>
								</FormSpacing>
							</div>
						</Col>
					</Row>
				)}
				<br />
				<Row>
					<Col span={19}>
						<p>
							Whenever you use a saved template, a tag is automatically added to that
							prospect with the name of the template so you can track how many times
							you use it!
						</p>
						<Alert
							message={complianceMessage}
							type="warning"
						/>
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<Form.Item>
							<Button type="primary" htmlType="submit" disabled={this.props.disabled}>
								{this.props.submitText}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default MessageTemplateForm;
