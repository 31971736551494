import { OrmState } from 'redux-orm';
import Store from '..';
import { IProxy } from '../models/redux';

export namespace ProxySelectors {
	export const getProxies = (state: { entities: OrmState<any> }) =>
		Store.ORM.session(state.entities)
			.Proxy.all()
			.toRefArray() as IProxy[];
}
