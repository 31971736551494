export enum EntitiesActionTypes {
	Create = 'CREATE',
	Update = 'UPDATE',
	Delete = 'DELETE',
	Reset = 'RESET'
}

export enum ActionTypes {
	LoadSettings = 'LOAD_SETTINGS',
	UpdateSettings = 'UPDATE_SETTINGS',
	SetActiveUser = 'SET_ACTIVE_USER',
	SetActiveTenant = 'SET_ACTIVE_TENANT',
	SetActiveImpersonator = 'SET_ACTIVE_IMPERSONATOR',
	SetActiveUserHash = 'SET_ACTIVE_USER_HASH',
	SetAdminUser = 'SET_ADMIN_USER',
	RemoveActiveUser = 'REMOVE_ACTIVE_USER',
	OpenDrawer = 'OPEN_DRAWER',
	CloseDrawer = 'CLOSE_DRAWER',
	SetActiveOrganization = 'SET_ACTIVE_ORGANIZATION',
	RemoveActiveOrganization = 'REMOVE_ACTIVE_ORGANIZATION',
	IsFetchingData = 'IS_FETCHING_DATA'
}

export enum PermissionActionTypes {
	SetPermissions = 'SET_PERMISSIONS',
	ResetPermissions = 'RESET_PERMISSIONS'
}
