import { UtilityFunctions } from '@copilot/common/utils/common';
import { Card, Col, Row, Space, Typography } from 'antd';
import { TooltipProps } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useTheme } from 'styled-components';


function PerformanceChartTooltipInternal(props: TooltipProps<number, string>) {
	const theme = useTheme();
	const { active, payload: payloads } = props;
	if (!active || !payloads?.length) return null;

	return (
		<Card style={{ width: 225 }}>
			{payloads.map((payload: Payload<number, string>) => (
				<Row key={payload.dataKey} justify='space-between'>
					<Col>
						<Space>
							<div style={{ borderRadius: '50%', width: theme['@font-size-base'], height: theme['@font-size-base'], backgroundColor: payload.color }} />
							<Typography.Text>{payload.name}:</Typography.Text>
						</Space>
					</Col>
					<Col>
						<Typography.Text>{UtilityFunctions.convertDecimalToPercentage(payload.value, 0)}</Typography.Text>
					</Col>
				</Row>
			))
			}
		</Card >
	);
}

export default PerformanceChartTooltipInternal;
