import React from 'react';
import BasicContainer from '@copilot/common/components/containers/basic';
import LinkedInSearchLocation, {
	ILocation,
} from '@copilot/common/components/linkedin/searchCriteria/location';
import LinkedInSearchOccupation from '@copilot/common/components/linkedin/searchCriteria/occupation';
import { ConstantManager } from '@copilot/data';
import { useFetch } from '@copilot/common/hooks/common';

interface CampaignOnboardWizardCopilotSearchProps {
	location?: ILocation;
	updateLocation: (location: ILocation) => unknown;
	occupation: string[];
	updateOccupation: (occupation: string[]) => unknown;
	extraOccupations: string[];
	updateExtraOccupations: (extraOccupation: string[]) => unknown;
}

const CampaignOnboardWizardCopilotSearch: React.FC<CampaignOnboardWizardCopilotSearchProps> = (
	props
) => {
	//#region Location
	const locationComponent = React.useMemo(
		() => <LinkedInSearchLocation location={props.location} onUpdate={props.updateLocation} />,
		[props.location, props.updateLocation]
	);
	//#endregion Location

	//#region Occupation
	const [occupationTitlesFetch, fetchOccupationTitles] = useFetch(
		ConstantManager.getTargetCriteriaTitles
	);
	React.useEffect(() => {
		fetchOccupationTitles();
	}, []);

	const occupationComponent = React.useMemo(() => (
		occupationTitlesFetch.data && (
		<LinkedInSearchOccupation
			options={occupationTitlesFetch.data}
			onUpdate={props.updateOccupation}
			value={props.occupation}
			otherOccupations={props.extraOccupations}
			onOtherOccupationsUpdate={props.updateExtraOccupations}
		/>
		)
	), [
		occupationTitlesFetch.data,
		props.updateOccupation,
		props.occupation,
		props.extraOccupations,
		props.updateExtraOccupations,
	]);
	//#endregion Occupation

	return (
		<>
			<BasicContainer>
				<BasicContainer.Content>
					{locationComponent}
					<br />
					{occupationComponent}
				</BasicContainer.Content>
			</BasicContainer>
		</>
	);
};

export default CampaignOnboardWizardCopilotSearch;
