import { attr, fk } from 'redux-orm';
import { BaseModel } from './base';
import { CampaignMember } from './campaignMember';
import { Contact } from './contact';
import { Campaign } from './campaign';

interface Fields {
	id: string;
	campaignMemberId: string;
	contactId: string;
	campaignId: string;
	inviteUrl: string;
	connectionStatus: string;
	firstName: string;
	lastName: string;
	email: string;
	lastThreadActivity: string;
	campaignMemberName: string;
	dateCreated: string;
	tags: {
		id: string;
		name: string;
		tagId: string;
		contactId: string;
		connectionId: string;
	}[];
}

interface InstanceFields {
	campaignMember: CampaignMember;
	contact: Contact;
	campaign: Campaign;
}

export type IConnection = Fields & InstanceFields;

export class Connection extends BaseModel<typeof Connection, IConnection> {
	static modelName = 'Connection';
	static fields = {
		id: attr(),
		campaignMemberId: fk({ to: CampaignMember.modelName, as: 'campaignMember' }),
		contactId: fk({ to: Contact.modelName, as: 'contact' }),
		campaignId: fk({ to: Campaign.modelName, as: 'campaign' }),
		inviteUrl: attr(),
		connectionStatus: attr(),
		firstName: attr(),
		lastName: attr(),
		email: attr(),
		lastThreadActivity: attr(),
		dateCreated: attr(),
		tags: attr(),
		campaignMemberName: attr(),
	};

	// Custom Connection Parse Helpers
	static parse(data: Fields) {
		const {
			contactId, firstName, lastName, email, lastThreadActivity, tags,
		} = data;
		const lastActivityDate = new Date(lastThreadActivity);
		const wrappedData = {
			...data,
			contactId: {
				id: contactId,
				firstName,
				lastName,
				email,
				lastThreadActivity: lastActivityDate,
				tags,
			},
			lastThreadActivity,
		};
		super.parse(wrappedData);
	}
}
