import BaseDataManager from './base';
import { LoginResponse, EmptyResponse, LoginResponseToken } from '../responses/interface';
import JwtDecode from 'jwt-decode';

class AuthenticationManager extends BaseDataManager {
	constructor() {
		super();
	}

	//#region PUBLIC INTERFACE
	/**
	 * Log a user in
	 * @param {string} email User email
	 * @param {string} password User password
	 */
	public login(email: string, password: string) {
		const formData = new FormData();
		formData.append('username', email);
		formData.append('password', password);
		return this.RequestManager.post<LoginResponse>(
			this.BACKEND_ROUTES.AUTHENTICATION.Login,
			formData
		).then((response) => {
			const data: LoginResponse = response.data;
			const payload: LoginResponseToken = JwtDecode(data.access_token);
			return { ...data, payload };
		});
	}

	/**
	 * Reset a user password
	 * @param {string} userId User id
	 * @param {string} code User reset password code
	 * @param {string} password Password to set to
	 * @param {string} confirmationPassword Password confirmation field
	 * @returns {Promise<EmptyResponse>}
	 */
	public resetPassword = (
		userId: string,
		code: string,
		password: string,
		confirmationPassword: string
	) => {
		const data = {
			userId, code, password, confirmPassword: confirmationPassword,
		};
		return this.RequestManager.post<EmptyResponse>(
			this.BACKEND_ROUTES.AUTHENTICATION.ResetPassword,
			data
		).then((response) => response.data);
	};

	/**
	 * Update the currently logged in user's password
	 * @param oldPw The old password
	 * @param newPw The new password
	 * @param confirmPw Confirmation password
	 */
	public setPassword(oldPw: string, newPw: string, confirmPw: string) {
		const data = { OldPassword: oldPw, Password: newPw, ConfirmPassword: confirmPw };
		return this.RequestManager.post<EmptyResponse>(
			this.BACKEND_ROUTES.AUTHENTICATION.SetPassword,
			data
		).then((response) => response.data);
	}

	/**
	 * Fires a call to copilot ai for identity tracking (ie. last active time)
	 */
	public callIdentify = () => {
		const url = this.BACKEND_ROUTES.AUTHENTICATION.Identify;
		return this.RequestManager.post<EmptyResponse>(url).then(() => true);
	};

	/**
	 * change organization cookie
	 * @param {string} orgId Org id
	 * @param options Options
	 */
	changeOrganization = (orgId: string) => {
		return this.RequestManager.post<LoginResponse>(this.BACKEND_ROUTES.AUTHENTICATION.ChangeOrg, null, {
			params: { orgId },
		}).then((response) => {
			const { data } = response;
			const payload: LoginResponseToken = JwtDecode(data.access_token);
			return { ...data, payload };
		});
	};
	//#endregion PUBLIC INTERFACE
}
const instance = new AuthenticationManager();
export default instance;
