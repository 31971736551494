import {
	NewUserMessageEditorNameTokens,
} from '@copilot/common/utils/constant';
import { OnboardMessage } from '@copilot/data/requests/models';
import {
	Alert, Col, Input, Row,
} from 'antd';
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea';
import React, {
	ComponentProps, FC, useCallback, useRef,
} from 'react';
import MessageEditorTokens from '../message/tokens';
import Text from 'antd/lib/typography/Text';
import MessageEditorTiming from '../message/timing';
import { NodeTimerPeriodStringEnum } from '@copilot/common/store/models/const/enum';
import { DEFAULT_TIME_UNITS, Template } from '@copilot/common/pages/settings/message/const';
import BubbleCheckbox from '../../forms/common/generics/bubblecheckbox';
import { EmptyProps } from 'antd/lib/empty';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';

interface ITimingEditorProps {
	timeDescription?: string;
	period: number;
	time: number;
	onPeriodUpdate: (value: number) => void;
	onTimeUpdate: (value: number) => void;
	timeUnits: NodeTimerPeriodStringEnum[];
}

const ReplaceFillerTextAlert: FC<EmptyProps> = () => (
	<Row gutter={[16, 24]}>
		<Col>
			<Alert
				message="If applicable, replace any filler text in the message templates highlighted in CAPS."
				type="warning"
				showIcon={false}
			/>
		</Col>
	</Row>
);

/**
 * Time Editor for Message Editor
 * @param timeDescription description for message timing
 * @param period message period
 * @param time message time
 * @param onPeriodUpdate callback on period update
 * @param onTimeUpdate callback on time update
 * @param timeUnits time unit options for message timing
 */
const TimingEditor: FC<ITimingEditorProps> = (props) => {
	const {
		timeDescription, period, time, onPeriodUpdate, onTimeUpdate, timeUnits,
	} = props;
	return (
		<>
			<Row gutter={[16, 16]}>
				<Col>
					<Text strong>Follow-up timing</Text>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col>
					<Text>
						{timeDescription}
					</Text>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col>
					<MessageEditorTiming
						period={period}
						time={time}
						onPeriodUpdate={onPeriodUpdate}
						onTimeUpdate={onTimeUpdate}
						timeUnits={timeUnits}
					/>
				</Col>
			</Row>
		</>
	);
};

interface INewUserMessageEditorProps {
	message: OnboardMessage;
	onMessageUpdate: (message: OnboardMessage) => void;
	maxLength?: number;
	timeUnits?: NodeTimerPeriodStringEnum[];
	timeDescription?: string;
	placeholder?: string;
	hideTiming?: boolean;
	selectedTemplate?: string;
	templates?: Template[];
	onTemplateUpdate?: (key: string) => void;
}

/**
 * Message Editor for new user onboard
 * @param message message to edit
 * @param onMessageUpdate callback on message update
 * @param maxLength max length of message text
 * @param timeUnits time units for message timing
 * @param timeDescription description for message timing
 * @param placeholder placeholder for text area
 * @param hideTiming if we want to hide timing selector
 * @param selectedTemplate selectedTemplate
 * @param templates list of templates
 * @param onTemplateUpdate callback on message update
 */

const NewUserMessageEditor: FC<INewUserMessageEditorProps> = (props) => {
	const {
		message, maxLength, timeUnits = DEFAULT_TIME_UNITS, timeDescription, placeholder, hideTiming = false,
		onMessageUpdate, templates, onTemplateUpdate, selectedTemplate,
	} = props;

	const textAreaRef = useRef<TextAreaRef>(null);
	const isOnboardMessageImprovementFeatureEnabled = useFeatureToggle(Features.OnboardMessageImprovementFeature);

	const handleMessageChange = useCallback<
		NonNullable<ComponentProps<typeof TextArea>['onChange']>
	>((event) => {
		const { value } = event.currentTarget;
		onMessageUpdate({
			...message,
			text: value,
		});
	}, [onMessageUpdate, message]);

	const handleTokenClick = useCallback<
		React.ComponentProps<typeof MessageEditorTokens>['onTokenClick']
	>(
		(token) => {
			const startIndex: number = textAreaRef.current?.resizableTextArea?.textArea?.selectionStart ?? message.text.length;
			onMessageUpdate({
				...message,
				text: `${message.text.slice(0, startIndex)}${token.value}${message.text.slice(
					startIndex
				)}`,
			});
		},
		[onMessageUpdate, message]
	);

	const handleTemplateSelect = useCallback(
		(templateLabel: string) => {
			if (!onTemplateUpdate) return;
			const template = templates?.find((t) => t.label === templateLabel);
			if (!template) return;
			onTemplateUpdate(template.key);
			onMessageUpdate({ ...message, text: template.value });
		},
		[templates, message, onTemplateUpdate, onMessageUpdate]
	);

	const handlePeriodChange = useCallback((value: number) => {
		onMessageUpdate({ ...message, period: value });
	}, [onMessageUpdate, message]);

	const handleTimeChange = useCallback((value: number) => {
		onMessageUpdate({ ...message, time: value });
	}, [onMessageUpdate, message]);

	return (
		<>
			{!isOnboardMessageImprovementFeatureEnabled && (
			<Row>
				{templates?.map((t) => (
					<Col key={t.key}>
						<BubbleCheckbox
							handleCheckChildElement={handleTemplateSelect}
							isChecked={selectedTemplate === t.key}
							id={t.key}
							value={t.label}
							label={t.label}
						/>
					</Col>
				))}
			</Row>
			)}
			{isOnboardMessageImprovementFeatureEnabled && <ReplaceFillerTextAlert />}
			<Row gutter={[16, 16]}>
				<Col span={24} style={{ backgroundColor: 'white', marginTop: isOnboardMessageImprovementFeatureEnabled ? '0' : '10px' }}>
					<Input.TextArea
						value={message.text}
						onChange={handleMessageChange}
						ref={textAreaRef}
						rows={5}
						maxLength={maxLength}
						showCount={!!maxLength}
						placeholder={placeholder}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col>
					<Text>
						Personalize your messages with information from your prospect's profile
					</Text>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col>
					<MessageEditorTokens
						tokens={NewUserMessageEditorNameTokens}
						onTokenClick={handleTokenClick}
					/>
				</Col>
			</Row>
			{!isOnboardMessageImprovementFeatureEnabled && <ReplaceFillerTextAlert />}
			{!hideTiming && (
				<TimingEditor
					timeDescription={timeDescription}
					period={message.period}
					time={message.time}
					onPeriodUpdate={handlePeriodChange}
					onTimeUpdate={handleTimeChange}
					timeUnits={timeUnits}
				/>
			)}
		</>
	);
};

export default NewUserMessageEditor;
