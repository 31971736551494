import { useCampaignSummaryAttentionItemsQuery } from '@copilot/data/graphql/_generated';
import { isNil } from 'lodash';

const useCampaignAttentionItems = (campaignId: string, campaignMemberId: string, organizationId: string, orgMemberId: string ) => {
	const { data, ...rest } = useCampaignSummaryAttentionItemsQuery(
		{
			variables: {
				campaignId,
				organizationIds: [organizationId],
			},
		}
	);
	
	return { 
		...rest, 
		data: isNil(data) ? undefined : {
			campaignSummary: {
				attentionItems: data.campaignSummary.attentionItems.filter(item => item.memberId === campaignMemberId),
				name: data.campaignSummary.name,
			},
			orgMembers: { 
				attentionItems: data.orgMembers.filter(item => item.id === orgMemberId).map(member => member.attentionItems).flat(),
				name: data.campaignSummary.name,
			},
		}, 
	};
};

export default useCampaignAttentionItems;
