import { useCallback } from 'react';
import { CampaignManager } from '@copilot/data';
import { useFetch } from '../../../hooks/common';
import { DailyAllocationType } from '../../../utils/constant';
import { Allocation } from './interface';
import { useDispatch } from 'react-redux';
import { CampaignMemberActions } from '../../../store/actions/campaignMember';
import notificationManager from '../../../utils/notificationManager';

type UsageLimitHelper = (allocations: Allocation[], type: DailyAllocationType) => Promise<void>;
/**
 * Generate the save request for updating allocations
 * @param orgMemberId The organization member id the allocations are for
 * @returns
 */
export const useUsageLimitsHelper = (orgMemberId?: string): UsageLimitHelper => {
	const [, updateCampaignAllocation] = useFetch(CampaignManager.updateCampaignAllocation);
	const dispatch = useDispatch();
	return useCallback<UsageLimitHelper>(async (allocations: Allocation[], type: DailyAllocationType) => {
		try {
			if (!orgMemberId) throw new Error('No organization member provided');
			const newAllocations = allocations.reduce<Record<string, number>>((acc, alloc) => {
				acc[alloc.id] = alloc.allocation;
				return acc;
			}, {});
			await updateCampaignAllocation(orgMemberId, newAllocations, type);
			const allocationProperty = type === DailyAllocationType.Messages ? 'dailyMessages' : 'dailyInvites';
			const memberUpdates = allocations.map((alloc) => ({
				id: alloc.campaignMemberId,
				[allocationProperty]: alloc.allocation,
			}));
			dispatch(CampaignMemberActions.updateCampaignMember(memberUpdates));
			notificationManager.showSuccessNotification({ message: 'Allocations Saved' });
		} catch (err) {
			notificationManager.showErrorNotification({
				message: ' Failed',
				description: 'Please refresh the page and try again',
			});
			throw err;
		}
	}, [orgMemberId]);
};
