import React, { useMemo, useCallback, useState } from 'react';
import WarningPage from './pages/warning';
import SuccessPage from './pages/success';
import FailPage from './pages/fail';
import UserInfoWrapper from './pages/userInfoWrapper';
import modalManager from '@copilot/common/utils/modalManager';
import { useSelector } from 'react-redux';
import { ModalSelectors } from '@copilot/common/store/selectors/modal';
import { ModalType } from '@copilot/common/store/models/redux/modal';
import BaseModal, { BaseModalProps } from '../base';
import { ResponseType } from './constant';

interface CustomerWizardProps extends BaseModalProps {}

enum WizardPage {
	Warning,
	UserInfo,
	Success,
	Fail
}

const WizardPageLookupTable: {
	[key in WizardPage]: React.FC<any>;
} = {
	[WizardPage.Warning]: WarningPage,
	[WizardPage.UserInfo]: UserInfoWrapper,
	[WizardPage.Success]: SuccessPage,
	[WizardPage.Fail]: FailPage,
};

const CustomerWizard: React.FC<CustomerWizardProps> = (props) => {
	const { ...modalProps } = props;
	const [page, setPage] = useState<WizardPage>(WizardPage.Warning);
	const modals = useSelector(ModalSelectors.getModals);

	const onClose = useCallback(() => {
		//TODO update modal closing to use hooks or passed in props with context to close it
		const currentModal = modals.filter((x) => x.type == ModalType.CustomerAddition);
		if (currentModal && currentModal.length > 0) {
			modalManager.closeModal(currentModal[0].id);
		}
	}, modals);

	const onNext = (response?: ResponseType) => {
		if (response && response == ResponseType.Fail) {
			setPage(WizardPage.Fail);
		}

		if (page == WizardPage.Success) {
			onClose();
		} else {
			setPage(page + 1);
		}
	};

	const modalElements = useMemo(() => {
		const PageContent = WizardPageLookupTable[page] ?? React.Fragment;
		return <PageContent onNext={onNext} />;
	}, [page]);

	return <BaseModal maskClosable={false} {...modalProps}>{modalElements}</BaseModal>;
};

export default CustomerWizard;
