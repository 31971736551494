import * as React from 'react';
import { DrawerProps } from 'antd/lib/drawer';
import BaseDrawer from '../../base';

import TriggerEditFrom from './triggerEditForm';
import {
	SequenceNodeTriggerCondition,
	SequenceNodeTrigger,
} from '@copilot/data/responses/interface';

export interface TriggerEditDrawerProps extends DrawerProps {
	saveTrigger: (
		triggerIndex: number,
		conditions: SequenceNodeTriggerCondition[],
		name: string
	) => void;
	triggerIndex: number;
	trigger?: SequenceNodeTrigger;
	closeAlert?: boolean;
}

const TriggerEditDrawer: React.FC<TriggerEditDrawerProps> = (props) => {
	const {
		saveTrigger: _saveTrigger, triggerIndex: _triggerIndex, closeAlert: _closeAlert, ...rest
	} = props;
	return (
		<BaseDrawer {...rest}>
			<TriggerEditFrom {...props} />
		</BaseDrawer>
	);
};

export default TriggerEditDrawer;
