import { BellOutlined, DisconnectOutlined } from '@ant-design/icons';
import {
	Badge, Dropdown, Typography,
} from 'antd';
import { INotification } from '@copilot/common/store/models/redux';
import { NotificationsSelectors } from '@copilot/common/store/selectors/notifications';
import { useSelector } from 'react-redux';
import { NotificationType } from '@copilot/common/store/models/const/enum';
import { useHistory } from 'react-router';
import { JSX } from 'react';
import styles from './header.module.less';
import { isString } from 'lodash';

const { Text } = Typography;

type NotificationProps = Readonly<{
	/**
	 * Whether to render this button for a dark background
	 */
	isDark?: boolean;
}>;

/**
 * Component displaying the current user's notifications.
 */
export function Notifications({ isDark }: NotificationProps): JSX.Element {
	
	const history = useHistory();
	const notificationStored = useSelector(NotificationsSelectors.getNotifications);
	const notifications = notificationStored.filter(
		(n: INotification) => n.id === NotificationType.LoggedOut
	);
	
	const items = (notifications.length === 0)
		? [{ key: 'NO_NOTIFICATIONS', label: "You're all caught up!", disabled: true }]
		: notifications.map((notification) => ({
			key: notification.id,
			icon: <DisconnectOutlined />,
			label: NotificationItem(notification.title, notification.body),
			onClick: () => {
				if (isString(notification.actions[0].meta))
					history.push(notification.actions[0].meta);
			},
		}));

	return (
		<Dropdown
			menu={{  items }}
			trigger={['click']}
			overlayStyle={{ userSelect: 'none', width: '260px' }}
		>
			<div className={isDark ? styles.notificationsDark : styles.notificationsLight} tabIndex={4}>
				<Badge count={notifications.length} dot style={{ display: 'flex' }}>
					<BellOutlined className={styles.headerIcon} />
				</Badge>
			</div>
		</Dropdown>
	);
}

function NotificationItem(title: string, body: string): JSX.Element {
	
	return (
		<div>
			<Text strong>{title}</Text>
			<br/>
			<Text>{body}</Text>
		</div>
	);
}
