import {
	attr, many, fk, MutableQuerySet, SessionBoundModel,
} from 'redux-orm';
import { BaseModel } from './base';
import { CampaignMember } from './campaignMember';
import {
	SequenceNodeResponse,
	CampaignApprovalStatus,
	CampaignType,
} from '@copilot/data/responses/interface';
import { Organization } from './organization';
import { CampaignStatusEnum } from '@copilot/data/requests/models';

export interface CampaignFields {
	id: string;
	organizationId: string;
	name: string;
	contacts: string[];
	dateFrom: Date;
	dateTo: Date;
	sequenceNodes: SequenceNodeResponse[];
	metaData: Record<string, unknown>;
	primaryColour: string;
	status: CampaignStatusEnum;
	tagStats: string[];
	actionStats: string[];
	approvalStatus: CampaignApprovalStatus;
	type: CampaignType;
	isNew: boolean;
}

interface InstanceFields {
	organization: Organization;
	members: MutableQuerySet<CampaignMember>;
}

export type ICampaign = Omit<CampaignFields, 'members'> & InstanceFields;

export class Campaign extends BaseModel<typeof Campaign, ICampaign> {
	static modelName = 'Campaign';
	static fields = {
		id: attr(),
		organizationId: fk({ to: 'Organization', as: 'Organization' }),
		name: attr(),
		contacts: attr(),
		members: many({ to: 'CampaignMember', relatedName: 'campaign' }),
		dateFrom: attr(),
		dateTo: attr(),
		sequenceNodes: attr(),
		metaData: attr(),
		primaryColour: attr(),
		status: attr(),
		tagStats: attr(),
		actionStats: attr(),
		approvalStatus: attr(),
		isNew: attr(),
		type: attr(),
	};

	/**
	 * Check if Campaign is enabled
	 */
	// @ts-expect-error disabling for 'get' and 'set' accessors cannot declare 'this' parameters
	public get isEnabled(this: SessionBoundModel<Campaign>) {
		return !!+this.status;
	}
}
