import type { CustomFieldActions } from './columnTypeDefinition';

export enum ColumnTypes {
	Date = 'Date',
	Dropdown = 'Dropdown',
	EnabledStatus = 'EnabledStatus',
	Percent = 'Percent',
	String = 'String',
	Tags = 'Tags',
	Link = 'Link',
	SubString = 'SubString',
	Icon = 'Icon',
	TextWithTags = 'TextWithTags',
	LinkAction = 'LinkAction',
	StatusWithPopover = 'StatusWithPopover',
	StringWithId = 'StringWithId',
	TextWithIcon = 'TextWithIcon'
}

export enum ColumnActionTypes {
	DetailsDrawer = 'DetailsDrawer',
	Custom = 'Custom'
}

export enum ColumnActionFormat {
	IconLink = 'IconLink',
	ImageLink = 'ImageLink'
}

// define icon for type in tables/columns/link.tsx
export enum ColumnIconType {
	LinkedinFilled
}

export class ColumnActionMetaData {
	public type: string;
	public format: ColumnActionFormat;
	public fieldValues: { [x: string]: string };
	public values: { [x: string]: any };

	constructor(metaData: { [x: string]: any } = {}) {
		this.type = metaData.type;
		this.format = metaData.format;
		this.fieldValues = metaData.fieldValues ? { ...metaData.fieldValues } : {};
		this.values = metaData.values ? { ...metaData.values } : {};
	}
}

export class ColumnAction {
	public type: ColumnActionTypes;
	public metaData?: ColumnActionMetaData;

	constructor(action: any = {}) {
		this.type = action.type;
		this.metaData = new ColumnActionMetaData(action.metaData as Record<string, any>);
	}
}

export class ColumnDefinition {
	public key: string;
	public label: string;
	public width?: string;
	public className?: string;
	public properties: string[];
	public type: ColumnTypes;
	public isVisible: boolean;
	public action?: ColumnAction;
	public filter?: boolean;
	public sort?: boolean;
	public hideable?: boolean;
	public ellipsis?: boolean;
	public hideForRecord: (record: any) => boolean;

	constructor(column: any = {}) {
		this.key = column.key || column.Key;
		this.label = column.label || column.Label;
		this.width = column.width;
		this.ellipsis = column.ellipsis;
		this.className = column.className;
		this.properties = column.properties || column.Properties || [];
		this.type = column.type || column.Type || ColumnTypes.String;
		this.isVisible = column.isVisible || column.IsVisible || false;
		this.action = column.action ? new ColumnAction(column.action) : undefined;
		this.filter = column.filter || column.Filter || false;
		this.sort = column.sort || column.Sort || false;
		this.hideable = column.hideable === undefined || column.hideable == true;
		this.hideForRecord = column.hideForRecord
			? column.hideForRecord
			: function () {
				return false;
			  };
	}
}

export class IconColumnDefinition extends ColumnDefinition {
	public determineIconFunction: (x: any) => string;

	constructor(column: any = {}) {
		super(column);
		this.determineIconFunction =
			column.determineIconFunction ||
			column.DetermineIconFunction ||
			function () {
				return '';
			};
	}
}

export class ActionDropdownColumnLink {
	public label: string;
	public action: (record: any) => void;

	constructor(link: any = {}) {
		this.label = link.label;
		this.action = link.action;
	}
}

/** Action Dropdown Column Definition class. These should only be created from the from end. */
export class ActionDropdownColumnDefinition extends ColumnDefinition {
	public links: ActionDropdownColumnLink[];
	public hideForRecord: (record: any) => boolean;
	constructor(column: any = {}) {
		super(column);
		this.type = ColumnTypes.Dropdown;
		this.hideForRecord = column.hideForRecord;
		this.links = column.links
			? column.links.map((link: any) => new ActionDropdownColumnLink(link))
			: [];
	}
}

export class ColumnDefinitionAdvanced extends ColumnDefinition {
	public subproperties: string[];
	constructor(column: any = {}) {
		super(column);
		this.subproperties = column.SubProperties || column.subproperties;
	}
}

export class TagColumnDefinition extends ColumnDefinition {
	public tagFilters: any[];
	constructor(column: any = {}) {
		super(column);
		this.tagFilters = [];
	}
}

export class TextWithTagsColumnDefinition extends ColumnDefinition {
	public text: string;
	public tags: string[];
	constructor(column: any = {}) {
		super(column);
		this.text = column.text || column.Text;
		this.tags = column.tags || column.Tags;
	}
}

export class LinkActionColumnDefinition extends ColumnDefinition {
	public determineLinkDisplay: (actionType: CustomFieldActions, x: any) => any;

	constructor(column: any = {}) {
		super(column);
		this.determineLinkDisplay =
			column.determineLinkDisplay ||
			column.determineLinkDisplay ||
			function () {
				return '';
			};
	}
}

export class StatusWithPopoverColumnDefinition extends ColumnDefinition {
	public determineStatusIcon: (x: any) => any;

	constructor(column: any = {}) {
		super(column);
		this.determineStatusIcon =
			column.determineStatusIcon ||
			column.determineStatusIcon ||
			function () {
				return '';
			};
	}
}

export class StringWithIdColumnDefinition extends ColumnDefinition {
	public idFilters: any[];
	constructor(column: any = {}) {
		super(column);
		this.idFilters = [];
	}
}
