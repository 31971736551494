import useTracking from '@copilot/common/hooks/tracking';
import { useCallback } from 'react';

const EVENT_NAME = 'organization_team_members';

/**
 * Possible button clicks on Team Members' tab in Organization Hub
 */
export type OrganizationMemberButtonClickTypes =
    'Organization Member Deactivation Modal' |
    'Organization Member Deactivation Confirm' |
    'Organization Member Activation Modal' |
    'Organization Member Activation Confirm' |
    'Organization Member Restriction Modal' |
    'Organization Member Add Team Member';

interface OrganizationTeamMemberTrackingParameter {
	/** Parameter for button clicks on organization_team_members event */
	buttonClicked: OrganizationMemberButtonClickTypes,
}

/**
 * Specific hook for tracking on Team Members' tab in Organization Hub
 * @param componentName name of the component this hook it attached to
 * @param params parameters to track
 */
export const useOrganizationTeamMemberTracking = (componentName: string, params: OrganizationTeamMemberTrackingParameter | null = null): (buttonClicked: OrganizationMemberButtonClickTypes) => void => {
	const trackEvent = useTracking(componentName, EVENT_NAME, params);
	const trackButtonClicked = useCallback((buttonClicked: OrganizationMemberButtonClickTypes) => {
		trackEvent({ buttonClicked });
	}, []
	);

	return trackButtonClicked;
};
