import { CampaignFields, ICampaign } from '@copilot/common/store/models/redux';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { CampaignType, ConnectionResponse } from '@copilot/data/responses/interface';
import { UpOutlined } from '@ant-design/icons';
import {
	Button, Dropdown,
} from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, {
	useCallback, useContext, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import modalManager from '@copilot/common/utils/modalManager';
import { MigrateApplyTags, MigrateConnections } from '../type';
import MenuOptions from '@copilot/common/components/dropdown/dropdownOptions';
import { getCampaignIcon } from '@copilot/common/utils/campaign';
import { ConnectionsFilterRequestModel } from '@copilot/data/requests/models';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { getUniqueArray } from '@copilot/common/utils';

type Connection = Pick<ConnectionResponse, 'id' | 'orgMemberId' | 'campaignId'>;

interface IBatchMigrateConnectionsProps {
	connections: Connection[];
	onMigrate: MigrateConnections;
	onMigrateTag: MigrateApplyTags;
	connectionsFilter?: ConnectionsFilterRequestModel;
}

const noNurtureError = 'You have no Nurture Campaigns to add connections to';

const getCampaignDisplayIcon = (campaign: CampaignFields) => getCampaignIcon(campaign.type);

/**
 * Selector for campaign to migrate connections over to.
 * Any row can be selected from the table - we need to gate certain migrations through this selector
 * @param connections a list of connections to migrate to new campaign
 * @param onMigrate called on migrate
 * @param onMigrateTag called on tagging after migration
 */

const BatchMigrateConnections = (props: IBatchMigrateConnectionsProps): JSX.Element => {
	const {
		connections, onMigrate, onMigrateTag, connectionsFilter,
	} = props;
	const migratableCampaigns = useSelector(CampaignSelectors.getCampaignsByType([CampaignType.Nurture]));
	const selectedOrgMemberIds = getUniqueArray<string>(connections.map((connection) => connection.orgMemberId));
	const orgMembers = useSelector(OrganizationMemberSelectors.getOrgMembers(selectedOrgMemberIds));
	const themeContext = useContext(ThemeContext);

	const orgMemberNameMap = useMemo(() => {
		const nameMap: { [k: string]: string } = {};
		orgMembers.map((m) => nameMap[m.id] = `${m.firstName} ${m.lastName}`);
		return nameMap;
	}, [orgMembers]);

	const isSelectedMultiOrgMember = useMemo(() => (orgMembers.length > 1), [orgMembers]);

	const getCampaignWarning = useCallback((campaign: ICampaign) => {
		const orgMembersInCampaignSet = new Set(campaign.members.toModelArray().map((m) => m.orgMemberId));
		const missingOrgMembers: string[] = [];
		orgMembers.forEach((orgMember) => {
			if (!orgMembersInCampaignSet.has(orgMember.id)) {
				missingOrgMembers.push(orgMember.id);
			}
		});
		if (isSelectedMultiOrgMember) {
			return 'Please select connections from the same team member to proceed';
		} else if (missingOrgMembers.length > 0) {
			const missingOrgMemberName = `${missingOrgMembers.map((omid) => orgMemberNameMap[omid]).join(', ')}`;
			//TODO: update copy or error display when the flow is confirmed
			return `Selected connections are connected to ${missingOrgMemberName} but he/she is missing from the campaign. Please add ${missingOrgMemberName} to the campaign.`;
		} else return '';
	}, [orgMembers, isSelectedMultiOrgMember]);

	const getTargetCampaignMemberId = React.useCallback((campaignId: string, orgMemberId: string) => {
		const selectedCampaignToMigrate = migratableCampaigns.find((c) => c.id === campaignId);
		const targetCampaignMember = selectedCampaignToMigrate?.members.toModelArray().find((c) => c.orgMemberId === orgMemberId);
		return targetCampaignMember?.id ?? '';
	}, [migratableCampaigns]);

	const handleClick = (param: MenuInfo) => {
		const targetCampaignId = (param.key).toString();
		//orgMemberToConnectionsMap is used to determine whether to enable migration to specific campaign
		//target campaign can only be set when there is one orgMember selected which is why we grab
		//first key of orgMemberToConnectionsMap to pass into the modal as props
		const selectedOrgMember = orgMembers[0];
		const newConnectionIds = connections.filter((c) => c.campaignId != targetCampaignId).map((newConnection) => newConnection.id);

		if (!targetCampaignId || !selectedOrgMember) throw new Error('Campaign id or org member not found ');
		if (!onMigrate || !onMigrateTag) throw new Error('Migration functions are not found');

		modalManager.openProspectsMigrationModal(
			{
				campaignId: targetCampaignId,
				campaignMemberId: getTargetCampaignMemberId(targetCampaignId, selectedOrgMember.id),
				connectionIds: newConnectionIds,
				onMigrate,
				onMigrateTag,
				connectionsFilter,
			});
	};

	const campaignOptions = (
		<MenuOptions<ICampaign>
			onClick={handleClick}
			data={migratableCampaigns}
			getValidationStatus={getCampaignWarning}
			getDisplayIcon={getCampaignDisplayIcon}
			errorColor={themeContext['@error-color']}
			errorText={noNurtureError}
		/>
	);

	return (
		<Dropdown
			overlay={campaignOptions}
			placement="top"
			getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
			trigger={['click']}
		>
			<Button type="primary" ghost>
				Add to Campaign
				{' '}
				<UpOutlined />
			</Button>
		</Dropdown>
	);
};

export default BatchMigrateConnections;
