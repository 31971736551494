import {
	FC, ReactNode, useCallback, useEffect, useMemo, useReducer, useState,
} from 'react';
import Wizard from '@copilot/common/components/wizard';
import {
	DEFAULT_MESSAGE_TITLES, NewUserOnboardSteps, NewUserOnboardStepTitles, STRATEGY_OPTIONS, TIME_DESCRIPTION, WelcomeCarouselContents,
} from '@copilot/common/pages/campaignDashboard/newUserOnboard/const';
import {
	Col, Row,
} from 'antd';
import { useEffectAsync, useFetch } from '@copilot/common/hooks/common';
import { LoadingPane } from '@copilot/common/components/loadingPane/loadingPane';
import { OnboardMessage } from '@copilot/data/requests/models';
import { SaveOutlined } from '@ant-design/icons';
import { Config } from '@copilot/common/config';
import { useIntercom } from 'react-use-intercom';
import NewUserGetStarted from '@copilot/common/components/wizard/steps/newUser/newUserGetStarted';
import modalManager from '@copilot/common/utils/modalManager';
import NewUserSearchCriteria from '@copilot/common/components/wizard/steps/newUser/newUserSearchCriteria';
import NewUserMessaging from '@copilot/common/components/wizard/steps/newUser/newUserMessaging';
import NewUserSchedule from '@copilot/common/components/wizard/steps/newUser/newUserSchedule';
import NewUserComplete from '@copilot/common/components/wizard/steps/newUser/newUserComplete';
import { DaySchedule, Features, LinkedInSearchType } from '@copilot/data/responses/interface';
import { ILocation, LocationType } from '@copilot/common/components/linkedin/searchCriteria/location';
import { SYSTEM_DEFAULT_SCHEDULE } from '../../settings/schedule/constant';
import { getMessageTitles } from '@copilot/common/utils/message';
import { useDispatch, useSelector } from 'react-redux';
import {
	CampaignManager, NewUserOnboardManager, OrganizationManager, ConstantManager,
} from '@copilot/data';
import { NewUserOnboardModel } from '@copilot/data/responses/models/newUserOnboard';
import notificationManager, { INotificationConfig } from '@copilot/common/utils/notificationManager';
import { ScheduleUtility } from '@copilot/common/utils/schedule';
import drawerManager from '@copilot/common/utils/drawerManager';
import {
	MessageIntentExamples, MessageTypeEnum, OpenMessagesExamples,
} from '../../onboard/wizard/messaging/const';
import { REQUEST_MESSAGE_CHAR_LIMIT } from '../../settings/message/const';
import { NodeTimerPeriodNumber } from '@copilot/common/store/models/const';
import styled from 'styled-components';
import { WizardStepNavButtons, withWizardStepButtonProperties, IWizardStepButtonProperties } from '@copilot/common/components/wizard/steps/navButton';
import { fetchNewUserOnboard, getNewUserOnboard } from './data';
import {
	LOGIN_SKIP_EVENT, SALES_NAV_URL_SELECTED_EVENT, SEARCH_CRITERIA_SELECTED_EVENT, WELCOME_SKIP_EVENT, withOnboardTracking,
} from './tracking';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { DEFAULT_NTH_MESSAGE_TIMING, DEFAULT_THIRD_MESSAGE_TIMING } from '../../wizard/const';
import DefaultTemplatesManager, { DefaultMessageTemplatesMap } from '@copilot/data/managers/defaultTemplates';

const INITIAL_MESSAGE_INDEX = 0;
const SECOND_MESSAGE_INDEX = 1;

const onSaveError = () => {
	notificationManager.showErrorNotification({
		message: 'Save Failed',
		description: 'Please try again',
	});
};

const StyledSaveIcon = styled(SaveOutlined)`
	font-size: 24px;
	margin: 0 8px;
	color: ${(props) => props.theme['@text-color-secondary']};
`;

const FooterRow = styled(Row)`
	width: 100%;
	min-height: 64px;
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 1;
	align-items: center;
	padding-left: 200px;
	background-color: ${(props) => props.theme['@layout-body-background']};
	box-shadow: 0px 4px 11px 0px;

	& > .${(props) => props.theme['@ant-prefix']}-col {
		padding: 16px;
	}
`;

const NewUserOnboardFooterComponent: FC<IWizardStepButtonProperties> = (props) => (
	<FooterRow justify="space-between">
		<Col style={{ display: 'flex' }}>
			<StyledSaveIcon />
			<span style={{ placeSelf: 'center' }}>Autosaved</span>
		</Col>
		<Col>
			<Row>
				<WizardStepNavButtons {...props} />
			</Row>
		</Col>
	</FooterRow>
);
const NewUserOnboardFooter = withWizardStepButtonProperties(NewUserOnboardFooterComponent);

interface NewUserOnboardWizardProps {
	campaignId: string;
	onboard: NewUserOnboardModel;
	updateOnboard: (updates: any) => void;
	activeMember: IOrganizationMember;
	isLoading: boolean;
	trackEvent?: <T>(params: T) => void;
	hideCriteria?: boolean;
	defaultTemplates?: DefaultMessageTemplatesMap;
}

const reducer = (state: NewUserOnboardModel, action: any) => {
	switch (action.type) {
		case 'OVERWRITE_ONBOARD':
			return { ...action.value };
		case 'UPDATE_ONBOARD':
			return { ...state, ...action.value };
		case 'UPDATE_SERVICE_SCHEDULE':
			return { ...state, serviceSchedule: { ...state.serviceSchedule, ...action.value } };
		case 'UPDATE_SEARCH_CRITERIA':
			return { ...state, searchCriteria: { ...state.searchCriteria, ...action.value } };
		default:
			return { ...state };
	}
};

/**
 * [Smart] In-app onboard wizard for new users
 * @param {string} campaignId id of the campaign
 * @param {NewUserOnboardModel} onboard details about the current onboarding
 * @param {function} updateOnboard updates the current onboarding object
 * @param {IOrganizationMember} activeMember current active member
 * @param {boolean} isLoading whether the onboard is loading
 */
export const NewUserOnboardWizardBase: FC<NewUserOnboardWizardProps> = (props) => {
	const {
		campaignId, onboard, updateOnboard, activeMember, isLoading, trackEvent, hideCriteria, defaultTemplates,
	} = props;
	const dispatch = useDispatch();
	const { startTour } = useIntercom();
	const [, loadCampaign] = useFetch(CampaignManager.getCampaignByCamapignId, CampaignActions.loadCampaign);
	const [, loadOrgMembers] = useFetch(OrganizationManager.getMembers, OrganizationMemberActions.loadOrganizationMember);
	const isAdmin = !!useSelector(OrganizationMemberSelectors.getAdminMember);
	const isCompleteStep = useMemo(() => onboard.step === NewUserOnboardSteps.COMPLETE, [onboard.step]);
	const [isRedirectButtonLoading, setIsRedirectButtonLoading] = useState<boolean>(false);
	const [needCampaignReload, setNeedCampaignReload] = useState<boolean>(false);

	const [locationTabState, setLocationTab] = useState(LocationType.City);

	const updateLocations = useCallback((updates: ILocation) => {
		const { locationTab, ...rest } = updates;
		setLocationTab(locationTab);
		updateOnboard({ type: 'UPDATE_SEARCH_CRITERIA', value: rest });
	}, [updateOnboard]);

	const updateOccupation = useCallback((updates: string[]) => {
		updateOnboard({ type: 'UPDATE_SEARCH_CRITERIA', value: { titles: updates } });
	}, [updateOnboard]);

	const updateExtraOccupations = useCallback((updates: string[]) => {
		updateOnboard({ type: 'UPDATE_SEARCH_CRITERIA', value: { extraTitles: updates } });
	}, [updateOnboard]);

	const updateExperience = useCallback((updates: string[]) => {
		updateOnboard({ type: 'UPDATE_SEARCH_CRITERIA', value: { experience: updates } });
	}, [updateOnboard]);

	const updateExclusions = useCallback((updates: string[]) => {
		updateOnboard({ type: 'UPDATE_SEARCH_CRITERIA', value: { exclusions: updates } });
	}, [updateOnboard]);

	const updateHeadCount = useCallback((updates: string[]) => {
		updateOnboard({ type: 'UPDATE_SEARCH_CRITERIA', value: { headCount: updates } });
	}, [updateOnboard]);

	const updateRecentJob = useCallback((updates: boolean) => {
		updateOnboard({ type: 'UPDATE_SEARCH_CRITERIA', value: { recentJobChange: updates } });
	}, [updateOnboard]);

	const updateTimezone = useCallback((updates: string) => {
		updateOnboard({ type: 'UPDATE_SERVICE_SCHEDULE', value: { timezoneCode: updates } });
	}, [updateOnboard]);

	const updateSyncSchedule = useCallback((updates: boolean) => {
		updateOnboard({ type: 'UPDATE_SERVICE_SCHEDULE', value: { synchronization: updates } });
	}, [updateOnboard]);

	const updateWeeklySchedule = useCallback((updates: DaySchedule[]) => {
		updateOnboard({ type: 'UPDATE_SERVICE_SCHEDULE', value: { weeklySchedule: updates } });
	}, [updateOnboard]);

	const updateMessages = useCallback((updates: OnboardMessage[]) => {
		updateOnboard({ type: 'UPDATE_ONBOARD', value: { messages: updates } });
	}, [updateOnboard]);

	// Sends updated onboard object to BE
	const handleSave = useCallback((_?, modifications?: { [k: string]: any }) => {
		const isSubmit = modifications?.step === NewUserOnboardSteps.COMPLETE;
		dispatch(fetchNewUserOnboard(
			NewUserOnboardManager.updateNewUserOnboard,
			{ onError: () => onSaveError },
			campaignId,
			{ ...onboard, ...modifications },
			isSubmit,
			isAdmin ? activeMember.id : null
		));
	}, [campaignId, onboard, isAdmin, activeMember.id]);

	const startProductTour = useCallback(() => {
		const tourId = Config.OnboardTargetAudienceProductTourId;
		if (!Config.isAgency && !!tourId && onboard.step === NewUserOnboardSteps.START) {
			startTour(tourId);
		}
	}, [onboard.step, startTour]);

	const handleLinkedInSyncSkip = useCallback(() => {
		startProductTour();
		trackEvent?.({ buttonClicked: LOGIN_SKIP_EVENT });
	}, [startProductTour, trackEvent]);

	const handleOpenLinkedInLogin = useCallback(() => {
		if (activeMember.id) {
			modalManager.openInitialLinkedInModal({
				orgMemberId: activeMember.id,
				onSkip: handleLinkedInSyncSkip,
				afterClose: () => handleSave(null, { isFirstLogin: false }),
			});
		}
	}, [activeMember.id, handleLinkedInSyncSkip, handleSave]);

	const trackWelcomeFlow = useCallback((step: number) => {
		const contentItem = WelcomeCarouselContents[step];
		if (contentItem && trackEvent) trackEvent({ wizardStep: `Onboard Welcome - ${contentItem.titleText}` });
	}, [trackEvent]);

	useEffect(() => {
		if (onboard.isFirstLogin) {
			trackWelcomeFlow(0);
			modalManager.openCarouselModal({
				carouselItems: WelcomeCarouselContents,
				finalButtonText: 'Get Started',
				skipButtonText: 'Skip Intro',
				afterClose: handleOpenLinkedInLogin,
				onSkip: () => trackEvent?.({ buttonClicked: WELCOME_SKIP_EVENT }),
				onCarouselSlideChange: trackWelcomeFlow,
			});
		}
	}, [onboard.isFirstLogin, trackEvent, trackWelcomeFlow]);
	//#region - SearchCriteria
	const isSearchCriteriaRemovalFeatureEnabled = useFeatureToggle(Features.OnboardSearchCriteriaRemovalFeature);

	const [yearsOfExperienceFetch, fetchYearsOfExperience] = useFetch(
		ConstantManager.loadCriteriaYears
	);
	const [companySizesFetch, fetchCompanySizes] = useFetch(ConstantManager.loadCompanySize);

	useEffect(() => {
		fetchYearsOfExperience();
		fetchCompanySizes();
	}, []);

	//#endregion

	//#region Messaging Step
	const isOnboardMessageImprovementFeatureEnabled = useFeatureToggle(Features.OnboardMessageImprovementFeature);
	const [selectedStrategy, setSelectedStrategy] = useState(onboard.messagingStrategy);

	// If the messaging page improvement feature is disabled, default to the existing data flow
	useEffect(() => {
		if (!isOnboardMessageImprovementFeatureEnabled)
			setSelectedStrategy(onboard.messagingStrategy);
	}, [onboard.messagingStrategy]);

	// Loads the messaging templates for the selected messaging strategy
	const handleMessagingTemplateSelection = (strategy: MessageTypeEnum) => {
		setSelectedStrategy(strategy);

		const templateData = defaultTemplates![strategy];
		const templateKey = templateData.groupId;
		const templates: string[] = templateData.messageTemplates;
		const updatedMessages: OnboardMessage[] = onboard.messages.map((msg, i) => {
			const copy = { ...msg };
			copy.text = templates[i];
			return copy;
		});
		handleSave(null, {
			messages: updatedMessages,
			...{
				messagingStrategy: strategy,
				firstMessageExampleKey: templateKey,
				secondMessageExampleKey: templateKey,
				messagingStrategyVersion: templateData.version,
				messagingStrategyGroupId: templateData.groupId,
			},
		});
	};

	const messageTitles = useMemo(() => getMessageTitles(DEFAULT_MESSAGE_TITLES.length, onboard.messages.length, DEFAULT_MESSAGE_TITLES, ' Message'), [onboard.messages.length]);

	const handleMessageSave = useCallback((messageIndex: number) => (message: OnboardMessage, strategy?: MessageTypeEnum, templateKey?: string) => {
		const updatedMessages = [...onboard.messages.slice(0, messageIndex), message, ...onboard.messages.slice(messageIndex + 1)];
		const templateObj: { [k: string]: string | MessageTypeEnum } = {};
		if (strategy !== undefined && templateKey) {
			templateObj.messagingStrategy = strategy;
			if (messageIndex === INITIAL_MESSAGE_INDEX) {
				templateObj.firstMessageExampleKey = templateKey;
			} else if (messageIndex === SECOND_MESSAGE_INDEX) {
				templateObj.secondMessageExampleKey = templateKey;
			}
		}
		handleSave(null, {
			messages: updatedMessages,
			...templateObj,
		});
		drawerManager.closeDrawer();
	}, [onboard.messages, handleSave]);

	const handleFirstTemplateSave = useCallback((strategy: MessageTypeEnum, templateKey: string) => {
		const obj = { messagingStrategy: strategy, firstMessageExampleKey: templateKey };
		updateOnboard({ type: 'UPDATE_ONBOARD', value: obj });
	}, []);

	const handleSecondTemplateSave = useCallback((strategy: MessageTypeEnum, templateKey: string) => {
		const obj = { messagingStrategy: strategy, secondMessageExampleKey: templateKey };
		updateOnboard({ type: 'UPDATE_ONBOARD', value: obj });
	}, []);

	const handleInitialMessageEdit = useCallback((title: string, description: ReactNode, message: OnboardMessage) => {
		drawerManager.openNewUserMessageEditWithTemplateDrawer(
			{
				title,
				description,
				message,
				onSaveMessage: handleMessageSave(INITIAL_MESSAGE_INDEX),
				closeAlert: true,
				selectedStrategy,
				selectedTemplateKey: onboard.firstMessageExampleKey,
				strategies: STRATEGY_OPTIONS,
				isStrategySelectable: true,
				maxLength: REQUEST_MESSAGE_CHAR_LIMIT,
				templates: OpenMessagesExamples,
				hideTimingEditor: true,
			});
	}, [selectedStrategy, onboard.firstMessageExampleKey, handleMessageSave, handleFirstTemplateSave]);

	const handleFollowUpMessageEdit = useCallback((title: string, description: ReactNode, message: OnboardMessage, messageIndex: number) => {
		messageIndex === SECOND_MESSAGE_INDEX ?
			drawerManager.openNewUserMessageEditWithTemplateDrawer(
				{
					title,
					description,
					message,
					onSaveMessage: handleMessageSave(messageIndex),
					closeAlert: true,
					selectedStrategy,
					selectedTemplateKey: onboard.secondMessageExampleKey,
					strategies: STRATEGY_OPTIONS,
					templates: MessageIntentExamples,
					timeDescription: TIME_DESCRIPTION.SecondMessage,
				}) :
			drawerManager.openNewUserMessageEditDrawer(
				{
					title,
					description,
					message,
					onSaveMessage: handleMessageSave(messageIndex),
					closeAlert: true,
					timeDescription: messageIndex == 2 ? TIME_DESCRIPTION.ThirdMessage : TIME_DESCRIPTION.FollowUps,
				});
	}, [selectedStrategy, onboard.secondMessageExampleKey, handleSecondTemplateSave, handleMessageSave]);

	const handleMessageEdit = useCallback((title: string, description: ReactNode, message: OnboardMessage, messageIndex: number) => {
		 messageIndex === INITIAL_MESSAGE_INDEX ? handleInitialMessageEdit(title, description, message) : handleFollowUpMessageEdit(title, description, message, messageIndex);
	 }, [handleInitialMessageEdit, handleFollowUpMessageEdit]);

	const handleMessageDelete = useCallback((messageIndex: number) => {
		updateMessages([
			...onboard.messages.slice(0, messageIndex),
			...onboard.messages.slice(messageIndex + 1),
		]);
	 }, [onboard.messages]);

	const handleMessageCreate = useCallback(() => {
		// 3rd message has a timing of 3-5 days and 4th onwards has a timing of 2-4 weeks
		const newMessage: OnboardMessage = {
			nodeId: '',
			text: '',
			period: onboard.messages.length == 2 ? NodeTimerPeriodNumber.Days : NodeTimerPeriodNumber.Weeks,
			time: onboard.messages.length == 2 ? DEFAULT_THIRD_MESSAGE_TIMING : DEFAULT_NTH_MESSAGE_TIMING,
		};
		if (isOnboardMessageImprovementFeatureEnabled && selectedStrategy) {
			// If our messaging strategy has more templates available, populate the new message's text
			const templates: string[] = defaultTemplates![selectedStrategy].messageTemplates;
			const numExistingMessages: number = onboard.messages.length;
			const moreTemplatesAvailable: boolean = numExistingMessages < templates.length;
			if (moreTemplatesAvailable)
				newMessage.text = templates[numExistingMessages];
		}
		updateMessages([...onboard.messages, newMessage]);
	}, [onboard.messages]);

	const connectionRequestMessageEmptyErrorNotification: INotificationConfig = {
		message: 'Add a connection message',
		description: 'A connection message is required to continue to the next step',
		style: { // pass styles so toast does not overlap the next button and correctly lines up with the nav
			marginBottom: '50px',
			marginRight: '-10px',
		},
	};

	const handleMessagingPageNextClick = useCallback(() => {
		if (!isOnboardMessageImprovementFeatureEnabled)
			return true; // if the feature flag is not enabled, the next button will always work
		const isConnectionRequestMessageNonEmpty = onboard.messages[0].text.trim().length > 0;
		if (!isConnectionRequestMessageNonEmpty)
			notificationManager.showErrorNotification(connectionRequestMessageEmptyErrorNotification);
		return isConnectionRequestMessageNonEmpty;
	}, [onboard.messages[0]]);

	//#endregion Messaging Step

	//#region Timezone Step
	const hasInvalidTimeRange = useMemo(() => (
		ScheduleUtility.getScheduleError(onboard.serviceSchedule.weeklySchedule)
	), [onboard.serviceSchedule.weeklySchedule]);
	//#endregion

	//#region exiting new user onboard
	const fetchCampaignAndOrgMembers = useCallback(async () => {
		await loadCampaign(campaignId);
		await loadOrgMembers(activeMember.organizationId);
	}, [campaignId, activeMember.organizationId]);

	const onCompleteRedirect = useCallback(() => {
		setIsRedirectButtonLoading(true);
		setNeedCampaignReload(true);
	}, []);

	useEffectAsync(async () => {
		if (!isLoading && needCampaignReload) {
			setNeedCampaignReload(false);
			await fetchCampaignAndOrgMembers();
		}
	}, [isLoading, needCampaignReload, fetchCampaignAndOrgMembers]);

	const unloadListener = useCallback((event) => {
		if (onboard.step === NewUserOnboardSteps.COMPLETE) return null;
		handleSave();
		// NOTE: not having a prompt / alert message will cause the save to occasionally fail.
		// Most modern browsers also don't allow for custom messages in beforeunload, so users may see the default message.
		const message = 'Your campaign is still incomplete. Are you sure you want to navigate away?';
		(event || window.event).returnValue = message;
		return message;
	}, [handleSave, onboard.step]);

	useEffect(() => {
		window.removeEventListener('beforeunload', unloadListener);
		window.addEventListener('beforeunload', unloadListener);
		return () => window.removeEventListener('beforeunload', unloadListener);
	}, [unloadListener]);

	// called on unmount - loads non-onboarding state if onboarding is completed
	useEffect(() => () => {
		if (onboard.step === NewUserOnboardSteps.COMPLETE) {
			fetchCampaignAndOrgMembers();
		}
	}, [fetchCampaignAndOrgMembers, onboard.step]);
	//#endregion

	return (
		<Wizard style={{ backgroundColor: 'transparent' }} farthestNode={onboard.step} onSave={handleSave} disableUnreachedSteps sidebarWidth={250}>
			<Wizard.Step
				id={NewUserOnboardSteps.START}
				title={NewUserOnboardStepTitles[NewUserOnboardSteps.START]}
				isDisabled={isCompleteStep}
			>
				<Wizard.Step.MainContent>
					<NewUserGetStarted
						salesNavUrl={onboard.searchUrl}
						onSalesNavUrlSelected={() => trackEvent?.({ buttonClicked: SALES_NAV_URL_SELECTED_EVENT })}
						onSearchCriteriaSelected={() => trackEvent?.({ buttonClicked: SEARCH_CRITERIA_SELECTED_EVENT })}
						showCriteriaPanel={!isSearchCriteriaRemovalFeatureEnabled && !hideCriteria} // feature flag takes precedence over props
					/>
				</Wizard.Step.MainContent>
			</Wizard.Step>
			{!isSearchCriteriaRemovalFeatureEnabled && (
				<Wizard.Step
					id={NewUserOnboardSteps.SEARCH}
					title={NewUserOnboardStepTitles[NewUserOnboardSteps.SEARCH]}
					isDisabled={isCompleteStep || onboard.searchType === LinkedInSearchType.Url}
				>
					<Wizard.Step.MainContent>
						<NewUserSearchCriteria
							location={{
								locations: onboard.searchCriteria?.locations ?? [],
								zip: onboard.searchCriteria?.zip ?? [],
								radius: onboard.searchCriteria?.radius ?? '25',
								locationTab: locationTabState,
							}}
							updateLocation={updateLocations}
							occupation={onboard.searchCriteria?.titles ?? []}
							updateOccupation={updateOccupation}
							extraOccupations={onboard.searchCriteria?.extraTitles ?? []}
							setExtraOccupations={updateExtraOccupations}
							recentJobChange={onboard.searchCriteria?.recentJobChange ?? false}
							experience={onboard.searchCriteria?.experience ?? []}
							headCount={onboard.searchCriteria?.headCount ?? []}
							exclusions={onboard.searchCriteria?.exclusions ?? []}
							headCountOptions={companySizesFetch.data ?? []}
							experienceOptions={yearsOfExperienceFetch.data ?? []}
							updateExperience={updateExperience}
							updateExclusions={updateExclusions}
							updateHeadCount={updateHeadCount}
							updateRecentJob={updateRecentJob}
						/>
					</Wizard.Step.MainContent>
					<Wizard.Step.Footer>
						<NewUserOnboardFooter />
					</Wizard.Step.Footer>
				</Wizard.Step>
			)}
			<Wizard.Step
				id={NewUserOnboardSteps.MESSAGING}
				title={NewUserOnboardStepTitles[NewUserOnboardSteps.MESSAGING]}
				isDisabled={isCompleteStep}
			>
				<Wizard.Step.MainContent>
					<NewUserMessaging
						messages={onboard.messages}
						messageTitles={messageTitles}
						onEdit={handleMessageEdit}
						onDelete={handleMessageDelete}
						onCreate={handleMessageCreate}
						onClickCategory={handleMessagingTemplateSelection}
						categories={[MessageTypeEnum.Sales, MessageTypeEnum.Recruitment, MessageTypeEnum.Networking]}
						selection={selectedStrategy}
						showCategoryOptions={isOnboardMessageImprovementFeatureEnabled}
					/>
				</Wizard.Step.MainContent>
				<Wizard.Step.Footer>
					<NewUserOnboardFooter
						backButtonTargetStep={onboard.searchType === LinkedInSearchType.Criteria ? NewUserOnboardSteps.SEARCH : NewUserOnboardSteps.START}
						isAllowedToContinue={handleMessagingPageNextClick}
					/>
				</Wizard.Step.Footer>
			</Wizard.Step>
			<Wizard.Step
				id={NewUserOnboardSteps.TIMEZONE}
				title={NewUserOnboardStepTitles[NewUserOnboardSteps.TIMEZONE]}
				isDisabled={isCompleteStep}
			>
				<Wizard.Step.MainContent>
					<NewUserSchedule
						timezone={onboard.serviceSchedule.timezoneCode ?? ScheduleUtility.getLocaleTimezone()}
						onTimezoneUpdate={updateTimezone}
						sync={onboard.serviceSchedule.synchronization ?? SYSTEM_DEFAULT_SCHEDULE.synchronization}
						onSyncUpdate={updateSyncSchedule}
						weeklySchedule={onboard.serviceSchedule.weeklySchedule ?? SYSTEM_DEFAULT_SCHEDULE.weeklySchedule}
						onScheduleUpdate={updateWeeklySchedule}
					/>
				</Wizard.Step.MainContent>
				<Wizard.Step.Footer>
					<NewUserOnboardFooter
						nextLabel="Submit"
						isNextDisabled={hasInvalidTimeRange.insufficientRuntime || hasInvalidTimeRange.excessiveRunDay}
					/>
				</Wizard.Step.Footer>
			</Wizard.Step>
			<Wizard.Step
				id={NewUserOnboardSteps.COMPLETE}
				title={NewUserOnboardStepTitles[NewUserOnboardSteps.COMPLETE]}
			>
				<Wizard.Step.MainContent>
					<NewUserComplete
						onRedirect={onCompleteRedirect}
						isButtonLoading={isRedirectButtonLoading}
					/>
				</Wizard.Step.MainContent>
			</Wizard.Step>
		</Wizard>
	);
};

interface WithOnboardProps {
	campaignId: string;
	activeMember: IOrganizationMember;
	onboard: NewUserOnboardModel;
	updateOnboard: (updates: Partial<NewUserOnboardModel>) => void;
	isLoading: boolean;
}

type NewOnboardObject<T> = Omit <T, 'onboard' | 'updateOnboard' | 'isLoading'>;

/**
 * Retreives onboard object and message templates from the backend and passes them as props to WrappedComponent
 */
const withOnboardObjectAndMessageTemplates = <T extends WithOnboardProps>(WrappedComponent: FC<T>) => (props: NewOnboardObject<T>): JSX.Element => {
	const { campaignId } = props;
	const dispatch = useDispatch();
	const { data: backendOnboard, loading: isLoadingOnboardObj, error: backendLoadingError } = useSelector(getNewUserOnboard);
	const [onboard, updateOnboard] = useReducer(reducer, undefined);
	const [{ data: defaultTemplates, isFetching: isLoadingTemplates, error: templateLoadError }, fetchDefaultTemplates] = useFetch(DefaultTemplatesManager.getDefaultMessageTemplates);

	// Fetch messaging templates from the backend
	useEffect(() => {
		fetchDefaultTemplates();
	}, []);

	// Update onboard object w/ data from the backend
	useEffect(() => {
		// update onboard object only when loading is completed to prevent loading outdated onboarding data
		if (!isLoadingOnboardObj && backendOnboard) {
			updateOnboard({ type: 'OVERWRITE_ONBOARD', value: backendOnboard });
		}
	}, [isLoadingOnboardObj, backendOnboard]);

	// Fetch onboard object from the backend if we haven't fetched the onboard object yet
	useEffect(() => {
		// NOTE: we need the condition below to avoid fetching and loading previously-saved onboarding data if a save is currently in progress
		if (!isLoadingOnboardObj && !backendOnboard && !backendLoadingError) {
			dispatch(fetchNewUserOnboard(NewUserOnboardManager.getNewUserOnboard, {}, campaignId));
		}
	}, [campaignId, isLoadingOnboardObj, backendOnboard, backendLoadingError]);

	// If onboard hasn't been populated by the backend, show a loading pane
	return (
		<LoadingPane isLoading={!onboard || (isLoadingTemplates || templateLoadError !== undefined)}>
			<WrappedComponent
				{...props as T}
				defaultTemplates={defaultTemplates}
				campaignId={campaignId}
				onboard={onboard}
				updateOnboard={updateOnboard}
				isLoading={!!isLoadingOnboardObj}
			/>
		</LoadingPane>
	);
};

const withSelfServe = <T,>(WrappedComponent: FC<T>) => (props: T) => {
	const [{ data, isFetching: isLoading, error }, fetchSelfServe] = useFetch(NewUserOnboardManager.getSelfServe);

	useEffect(() => {
		try {
			fetchSelfServe();
		} catch (err) {
			// confirm with Desmond that wishes to log the error in the console.
			console.log(err);
		}
	}, []);

	// if we fail to get selfServe status, the user's onboard will continue via the conventional (i.e. default) stream
	// else, we go by whatever the selfServe status directs.
	const selfServe = (error === undefined) ? data : false;

	return (
		<LoadingPane isLoading={isLoading}>
			<WrappedComponent
				hideCriteria={selfServe}
				{...props}
			/>
		</LoadingPane>
	);
};

// TODO combine data retrieval to one HOC
const NewUserWithSelfServeOnboardWizard = withSelfServe(withOnboardObjectAndMessageTemplates(withOnboardTracking(NewUserOnboardWizardBase, NewUserOnboardStepTitles)));
export default NewUserWithSelfServeOnboardWizard;
