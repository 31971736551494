import { FC } from 'react';
import { Row, Col, Button } from 'antd';

export interface ISetOnboardingProps {
	onOnboardingOptionSelected: (showOnboarding: boolean) => void;
	showOnboarding: boolean | undefined;
	isLoading: boolean;
}

/**
 * [Presentational] Modal Content for the Set Onboarding Step of Campaign Creation
 * @param {function} onOnboardingOptionSelected callback when the onboarding option is selected
 * @param {boolean} isLoading whether we want to show loading state
 */
const SetOnboarding: FC<ISetOnboardingProps> = (props) => {
	const { onOnboardingOptionSelected, showOnboarding, isLoading } = props;

	return (
		<>
			<Row justify="center" style={{ marginBottom: '24px' }}>
				<Col>
					<strong>Nurture Onboarding</strong>
				</Col>
			</Row>
			<Row justify="center" style={{ marginBottom: '24px' }}>
				<Col span={18}>
					Would you like this customer to get the Nurture Onboarding flow when first accessing this campaign?
				</Col>
			</Row>
			<Row justify="center" gutter={12} style={{ marginBottom: '16px' }}>
				<Col>
					<Button
						disabled={isLoading && showOnboarding !== false}
						loading={isLoading && showOnboarding === false}
						onClick={() => onOnboardingOptionSelected(false)}
					>
						No
					</Button>
				</Col>
				<Col>
					<Button
						type="primary"
						disabled={isLoading && showOnboarding !== true}
						loading={isLoading && showOnboarding === true}
						onClick={() => onOnboardingOptionSelected(true)}
					>
						Yes
					</Button>
				</Col>
			</Row>
		</>
	);
};

export default SetOnboarding;
