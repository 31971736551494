import {
	createContext, FC, useContext, useEffect, useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { launchpadContainerId } from './launchpadContainer';
import { head } from 'lodash';

interface AppcuesProps {
	/**
	 * AppId for Appcues
	 */
	appId: string;
	/**
	 * Flag to indicate whether to use Appcues or not
	 */
	isEnabled: boolean;
}

type UseScriptOptions = Partial<{
	async: boolean;
	defer: boolean;
	onload: Command;
	type: string;
}>;

/**
 * Load an external script
 * @param url Script url we want to load
 * @param options Options for loading the script
 */
const loadScript = (url: string, options: UseScriptOptions): [HTMLScriptElement, () => void] => {
	const {
		async, defer, onload, type,
	} = options;
	const script = document.createElement('script');
	if (async !== undefined) script.async = async;
	if (defer !== undefined) script.defer = defer;
	if (type !== undefined) script.type = type;
	if (onload !== undefined) script.onload = onload;
	script.src = url;
	const headElement = document.getElementsByTagName('head')[0];
	headElement.appendChild(script);
	const removeFunction = () => headElement.removeChild(script);
	return [script, removeFunction];
};

export interface IAppcuesContext {
	/**
	 * Used to identify the user to app cues
	 */
	identify: (userId: string, metaData?: Record<string, unknown>) => void;
	/**
	 * Signifies a page change
	 */
	page: () => void;
	/**
	 * Track custom events
	 */
	track: () => void;
	/**
	 * Load the Launchpad widget
	 */
	loadLaunchpad: (containerId: string, options?: Record<string, unknown>, onLoad?: () => void) => void;
}

const AppcuesContext = createContext<IAppcuesContext>({
	page: () => {},
	identify: () => {},
	track: () => {},
	loadLaunchpad: () => {},
});

type Command = (appcues: any) => void;

/**
 * Provider to add Appcues script and functions as context for hooks
 * @param props App cues props
 */
const AppcuesProvider: FC<AppcuesProps> = ({ appId, isEnabled, children }) => {
	const {
 		userId, email, organizationId, id: orgMemberId, orgType, userCreatedDate, orgRoles,
	} = useSelector(OrganizationMemberSelectors.getActiveMember) ?? {};

	const orgRole: string = head(orgRoles) ?? '';

	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const isFreeTrial = activeOrganization?.isFreeTrial;
	const location = useLocation();

	const queuedCommands = useRef<Command[]>([]);

	const queueOrExecute = (command: Command) => {
		if (window.Appcues) command(window.Appcues);
		else queuedCommands.current.push(command);
	};

	const context: IAppcuesContext = {
		page: () => queueOrExecute((appcues) => appcues.page()),
		identify: (usrId, metaData) => queueOrExecute((appcues) => appcues.identify(usrId, metaData)),
		track: () => queueOrExecute((appcues) => appcues.track()),
		loadLaunchpad: (containerId, options, onLoad) => {
			queueOrExecute((appcues) => {
				appcues.loadLaunchpad(containerId, options);
				onLoad?.();
			});
		},
	};

	useEffect(() => {
		if (userId) {
			context.identify(userId, {
				email,
				organizationId,
				orgMemberId,
				orgType,
				orgRole,
				isFreeTrial,
				userCreatedDate,
			});
		}
	}, [userId, email, organizationId, orgMemberId, orgType, orgRole, isFreeTrial, userCreatedDate]);

	useEffect(() => {
		context.page();
	}, [location]);

	const flushCommands = () => {
		if (queuedCommands.current.length <= 0) return;
		while (queuedCommands.current.length) {
			const cb = queuedCommands.current.shift();
			if (typeof cb === 'function') cb(window.Appcues);
		}
	};

	// Load Appcues library
	useEffect(() => {
		if (isEnabled) {
			const [, removeFunction] = loadScript(`//appcues.copilotai.com/${appId}.js`, {
				async: true,
				defer: true,
				type: 'text/javascript',
				onload: flushCommands,
			});
			return removeFunction;
		} else {
			return undefined;
		}
	}, [isEnabled, appId]);

	return (
		<AppcuesContext.Provider value={context}>
			{children}
		</AppcuesContext.Provider>
	);
};

export default AppcuesProvider;

/**
 * Hook to use Appcues commands
 * @returns Appcues context
 */
export const useAppcues = (): IAppcuesContext => {
	const context = useContext(AppcuesContext);
	return context;
};

/**
 * Adds Appcues Launchpad widget to the app; an HTML element with id equal to
 * launchpadContainerId must be present on the DOM before this function is called
 */
export const addAppcuesLaunchpad = (context: IAppcuesContext): void =>
	context.loadLaunchpad(`#${launchpadContainerId}`, {
		position: 'top',
		header: '<h1 class="copilot-appcues-header">Guided Tours</h1>',
		footer: `
			<h1 
				class="copilot-appcues-footer"
				onclick="document.getElementsByClassName('appcues-widget-backdrop')[0]?.click()"
			>
				Close
			</h1>`,
	}, () => {
		// After launchpad has loaded, move the main launchpad container from the menu to the body
		const containers = document.getElementsByClassName('appcues-container-inline');
		if (containers.length) {
			const appcuesContainer = containers[0];
			document.body.appendChild(appcuesContainer);
		}
	});