import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { Space } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { InProgressIcon } from '@copilot/common/components/statusIndicator/InProgressIcon';

export const OperationStatuses = {
	inProgress: 'inProgress',
	complete: 'complete',
	error: 'error',
} as const;

export type OperationStatus = typeof OperationStatuses[keyof typeof OperationStatuses];

export type StatusIndicatorProps = {
	/**
	 * The current status
	 */
	status: OperationStatus,
	/**
	 * Classes to apply
	 */
	className?: string;
};

/**
 * A UI element describing the current status of an operation.
 * @param props
 */
export const StatusIndicator: FC<StatusIndicatorProps> = (props) => {
	const { status, children, className } = props;
	const theme = useTheme();
	let icon;
	if (status === OperationStatuses.inProgress)
		icon = <InProgressIcon />;
	else if (status === OperationStatuses.complete)
		icon = <CheckCircleFilled style={{ color: theme['@Success/6'] }} />;
	else if (status === OperationStatuses.error)
		icon = <CloseCircleFilled style={{ color: theme['@Error/6'] }} />;
	else
		throw new Error(`unexpected status: ${status}`);
	return (
		<StyledDiv className={className}>
			<Space direction='horizontal' align='center'>
				{icon}
				{children}
			</Space>
		</StyledDiv>
	);
};

const StyledDiv = styled.div`
    /* Layout */
    display: inline-flex;
    padding: ${(props) => `${props.theme['@spacer-size-xxs']} ${props.theme['@spacer-size-xs']}`};
    font-size: ${(props) => props.theme['@font-size-base']};

    /* Styling */
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
`;
