import {
	Button, Col, Row, Tooltip, Alert,
} from 'antd';
import styled from 'styled-components';
import BasicWizardContainer from '../../wizard/basic/wizardContainer';
import { LinkedInLoginWizardSteps } from '../const';
import LinkedinLoginResult, { LinkedInResultStatus } from '../login/result';
import InitialLinkedInLoginStep from './login';
import {
	QuestionCircleTwoTone,
} from '@ant-design/icons';
import InitialLinkedInVerificationStep from './pin';
import { useMemo } from 'react';
import LinkedInProgress from '../login/loading';
import { Config } from '@copilot/common/config';
import Text from 'antd/lib/typography/Text';

const StyledHeader = styled.h2`
	font-weight: bold;
`;

const StyledRow = styled(Row)`
	text-align: center;
`;

const StyledTitle = styled.h2`
	margin-bottom: 8px;
	font-weight: bold;
`;

const StyledDescription = styled.p`
	margin-bottom: 24px;
`;
interface IInitialLinkedInLoginWizardProps {
	currentStep: LinkedInLoginWizardSteps;
	orgMemberId: string;
	onLogin: (username: string, password: string) => Promise<void>;
	onPinSubmit: (pin: string) => Promise<void>;
	onPinSupport: () => void;
	onSkip: () => void;
	isFetching?: boolean;
	errorMessage?: string;
	errorButtonLabel?: string;
	successButtonLabel?: string;
}

const InitialLinkedInLoginWizard: React.FC<IInitialLinkedInLoginWizardProps> = (props) => {
	const {
		currentStep, onLogin, onPinSubmit, onPinSupport, onSkip, isFetching = false, errorButtonLabel, successButtonLabel, errorMessage,
	} = props;

	const hideSkip = useMemo(() => currentStep === LinkedInLoginWizardSteps.Done || currentStep === LinkedInLoginWizardSteps.Error, [currentStep]);

	return (
		<BasicWizardContainer farthestNode={currentStep}>
			<BasicWizardContainer.Step id={LinkedInLoginWizardSteps.Login}>
				<Row justify="end">
					<Col>
						<Tooltip
							title={`We require this information in order to operate the ${Config.agencyName} service on your LinkedIn profile. This information is not collected or shared and is not visible to our team.`}
						>
							<QuestionCircleTwoTone />
						</Tooltip>
					</Col>
				</Row>
				{errorMessage && (
				<Row justify="center" style={{ marginBottom: '8px' }}>
					<Col span={19}>
						<Alert message={<Text style={{ fontSize: '0.9em' }}>{errorMessage}</Text>} type="error" showIcon />
					</Col>
				</Row>
				)}
				<StyledRow>
					<Col span={24}>
						<StyledTitle>
							Network with millions of professionals
						</StyledTitle>
						<StyledDescription>
							Connect your
							{' '}
							<strong>Linkedin account</strong>
							{' '}
							to get started.
						</StyledDescription>
					</Col>
				</StyledRow>
				<InitialLinkedInLoginStep onSubmit={onLogin} isFetching={isFetching} />
			</BasicWizardContainer.Step>
			<BasicWizardContainer.Step id={LinkedInLoginWizardSteps.Pin}>
				{errorMessage && (
				<Row justify="center" style={{ marginBottom: '8px' }}>
					<Col span={19}>
						<Alert message={<Text style={{ fontSize: '0.9em' }}>{errorMessage}</Text>} type="error" showIcon />
					</Col>
				</Row>
				)}
				<StyledRow>
					<Col span={24}>
						<StyledTitle>
							LinkedIn Verification Pin
						</StyledTitle>
						<StyledDescription>
							Check for your verification pin in your email or SMS
						</StyledDescription>
					</Col>
				</StyledRow>
				<InitialLinkedInVerificationStep onSubmit={onPinSubmit} onClickSupport={onPinSupport} isFetching={isFetching} />
			</BasicWizardContainer.Step>
			<BasicWizardContainer.Step id={LinkedInLoginWizardSteps.Error}>
				<LinkedinLoginResult
					header="Looks like we are unable to connect your LinkedIn account at this time."
					callToAction="Please reach out to your Account Strategist."
					status={LinkedInResultStatus.Error}
					buttonText={errorButtonLabel}
					onClickCallback={onSkip}
				/>
			</BasicWizardContainer.Step>
			<BasicWizardContainer.Step id={LinkedInLoginWizardSteps.Done}>
				<LinkedinLoginResult
					header={(
						<>
							<StyledHeader>You've successfully connected your</StyledHeader>
							<StyledHeader>LinkedIn account!</StyledHeader>
						</>
					)}
					status={LinkedInResultStatus.Success}
					buttonText={successButtonLabel}
					onClickCallback={onSkip}
				/>
			</BasicWizardContainer.Step>
			<BasicWizardContainer.Step id={LinkedInLoginWizardSteps.Loading}>
				<LinkedInProgress />
			</BasicWizardContainer.Step>
			{!hideSkip && currentStep != LinkedInLoginWizardSteps.Loading && (
			<BasicWizardContainer.Footer>
				<Button type="text" onClick={onSkip} style={{ float: 'right', marginTop: '10px' }}>
					Skip for now
				</Button>
			</BasicWizardContainer.Footer>
			)}

		</BasicWizardContainer>
	);
};

export default InitialLinkedInLoginWizard;
