import React from 'react';
import { LocationDescriptor } from 'history';
import { RouteProps, Route, Redirect } from 'react-router';
import { useIsPermitted } from '../hooks/user';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '../store/selectors/organizationMember';

type RoleRouteDescriptor = { [key: string]: LocationDescriptor };

interface RouteWithPermissionsProps extends RouteProps {
	allowedRoles?: (string | undefined)[];
	roleRedirectTo?: LocationDescriptor | RoleRouteDescriptor;
	roleBasedComponents?: { [key: string]: RouteProps['component'] };
}

const permissionDeniedPanel = (
	<div>
		<h1>Sorry, you do not have access to this part of the application.</h1>
	</div>
);

const RouteWithPermissions: React.FC<RouteWithPermissionsProps> = (props) => {
	const {
		component,
		roleBasedComponents,
		allowedRoles = [undefined],
		roleRedirectTo,
		...rest
	} = props;
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const adminMember = useSelector(OrganizationMemberSelectors.getAdminMember);

	const orgRoles = adminMember
		? adminMember.orgRoles
		: activeMember
			? activeMember.orgRoles
			: undefined;
	const userRoles = adminMember
		? adminMember.userRoles
		: activeMember
			? activeMember.userRoles
			: undefined;
	const isAllowedForOrg = useIsPermitted(allowedRoles);

	if (isAllowedForOrg) {
		let displayedComponent = component;
		if (orgRoles && roleBasedComponents)
			displayedComponent = roleBasedComponents[orgRoles[0]] ?? component;

		return <Route {...rest} component={displayedComponent} />;
	} else {
		const role = !Array.isArray(orgRoles)
			? undefined
			: orgRoles.length > 0
				? orgRoles[0]
				: undefined;

		const userRole = !Array.isArray(userRoles)
			? undefined
			: userRoles.length > 0
				? userRoles[0]
				: undefined;
		if (role === undefined || userRole === undefined) return <Redirect to="/login" />;
		if (roleRedirectTo === undefined) return <>{permissionDeniedPanel}</>;
		if (typeof roleRedirectTo === 'string') return <Redirect to={roleRedirectTo} />;
		else return <Redirect to={(roleRedirectTo as RoleRouteDescriptor)[role]} />;
	}
};

export default RouteWithPermissions;
