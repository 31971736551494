import React from 'react';
import styled from 'styled-components';

export interface BubbleCheckboxProps {
	handleCheckChildElement: (event: any) => void;
	id: string;
	isChecked: boolean;
	value: string;
	label: string | null;
}

const CheckboxButton = styled.button`
	background: none;
	border: 1px solid #333;
	border-radius: 15px;
	color: #333;
	cursor: pointer;
	font: inherit;
	margin-bottom: 10px;
	margin-right: 10px;
	outline: inherit;
	padding: 5px 16px;

	&.checked {
		background-color: ${(props) => props.theme['@primary-color']};
		border-color: ${(props) => props.theme['@primary-color']};
		color: #fff;
	}

	&.unchecked {
		background: white;
		border-color: #333;
		color: #333;
	}
`;

const BubbleCheckbox: React.FC<BubbleCheckboxProps> = (props) => {
	const {
		isChecked, value, label, handleCheckChildElement,
	} = props;
	return (
		<span className="bubble-checkbox">
			<CheckboxButton
				className={`${isChecked ? 'checked' : 'unchecked'}`}
				onClick={(e) => {
					e.preventDefault();
					handleCheckChildElement(value);
				}}
			>
				{label ?? value}
			</CheckboxButton>
		</span>
	);
};

export default BubbleCheckbox;
