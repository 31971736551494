import { aiIcon } from '@copilot/common/constant/icons';
import { Icon } from '@iconify/react';
import { FC } from 'react';
import { useTheme } from 'styled-components';


export type AiFeatureIconPropsType = Readonly<{
	/**
     * Color we want the icon to be
     */
	color?: string;
}>;

/**
 * [Presentational] Icon indicate it is AI related feature
 * @constructor
 */
const AiFeatureIcon: FC<AiFeatureIconPropsType> = ({ color }) => {
	const theme = useTheme();
	return <Icon
		icon = {aiIcon}
		style={{
			verticalAlign: 'middle',
			fontSize: '20px',
			marginRight: '5px',
			marginBottom: '2px',
		}}
		color={color ?? theme['@primary-color']}
	/>;
};

export default AiFeatureIcon;
