import React, { useCallback, useEffect, useState } from 'react';
import BaseModal from '../base';
import InitialLinkedInLogin from '@copilot/common/components/linkedin/initialLogin';
import { IInitialLinkedinSyncModalProps } from './const';
import { useLinkedinLogin } from '@copilot/common/hooks/linkedin';
import { LinkedInLoginErrorMessages, LinkedInLoginWizardSteps } from '@copilot/common/components/linkedin/const';
import { useLinkedInLoginTracking } from '@copilot/common/components/linkedin/login/tracking';
import { LinkedInTaskManager } from '@copilot/data/managers/tasks';
import { ModalType } from '@copilot/common/store/models/redux/modal';
import { useCloseModal } from '@copilot/common/hooks/modal';

const InitialLinkedinSyncModal: React.FC<IInitialLinkedinSyncModalProps> = (props) => {
	const { orgMemberId, onSkip, ...modalProps } = props;

	const [trackWizardStepChange, trackButtonClicked] = useLinkedInLoginTracking('LinkedIn Login', { wizardStep: 'LinkedIn Login Form' });
	const [wizardStep, setWizardStep] = useState<LinkedInLoginWizardSteps>(LinkedInLoginWizardSteps.Login);
	const closeModal = useCloseModal(ModalType.InitialSyncLinkedIn);
	const cancelLogin = () => {
		setWizardStep(LinkedInLoginWizardSteps.Error);
		trackWizardStepChange('LinkedIn System Error');
	};

	const [
		fetchLinkedInLogin,
		submitVerificationPin,
		resetTimeoutTimer,
		loginTask,
	] = useLinkedinLogin(LinkedInLoginErrorMessages, cancelLogin);

	useEffect(() => {
		if (loginTask.status !== undefined) {
			if (loginTask.status) {
				trackWizardStepChange('LinkedIn Login Success');
				setWizardStep(LinkedInLoginWizardSteps.Done);
			} else {
				setWizardStep(LinkedInLoginWizardSteps.Login);
			}
		} else if (loginTask.issueCode) {
			if (loginTask.issueCode === LinkedInTaskManager.UPDATE_CODES.VERIFY_CODE_NEEDED) {
				setWizardStep(LinkedInLoginWizardSteps.Pin);
				trackWizardStepChange('LinkedIn Pin Form');
				resetTimeoutTimer();
			} else if (loginTask.issueCode === LinkedInTaskManager.UPDATE_CODES.VERIFY_CODE_INVALID) {
				setWizardStep(LinkedInLoginWizardSteps.Pin);
				trackWizardStepChange('LinkedIn Incorrect Pin Submitted');
				resetTimeoutTimer();
			}
		}
	}, [loginTask]);

	const handleLoginSubmission = async (username: string, password: string) => {
		try {
			setWizardStep(LinkedInLoginWizardSteps.Loading);
			await fetchLinkedInLogin(orgMemberId, username, password);
			trackWizardStepChange('LinkedIn Credentials Submitted');
		} catch (err) {
			setWizardStep(LinkedInLoginWizardSteps.Error);
		}
	};

	const handlePinSubmission = async (pin: string) => {
		if (!submitVerificationPin) return;
		try {
			setWizardStep(LinkedInLoginWizardSteps.Loading);
			await submitVerificationPin(pin);
			trackWizardStepChange('LinkedIn Pin Submitted');
		} catch (err) {
			console.error(err);
			setWizardStep(LinkedInLoginWizardSteps.Error);
		}
	};

	const handlePinSupport = () => {
		trackButtonClicked('LinkedIn Get Support');
	};

	const handleSkip = useCallback(() => {
		onSkip?.();
		closeModal();
	}, [closeModal, onSkip]);

	return (
		<BaseModal width={600} closable={false} maskClosable={false} keyboard={false} {...modalProps}>
			<InitialLinkedInLogin
				currentStep={wizardStep}
				orgMemberId={orgMemberId}
				onLogin={handleLoginSubmission}
				onPinSubmit={handlePinSubmission}
				onPinSupport={handlePinSupport}
				onSkip={handleSkip}
				errorButtonLabel="Continue to next step"
				successButtonLabel="Build your first campaign"
				errorMessage={loginTask.error}
			/>
		</BaseModal>
	);
};

export default InitialLinkedinSyncModal;
