import React, { useCallback } from 'react';
import { Button, Form, Input } from 'antd';
import styled from 'styled-components';

const FormItem = Form.Item;

const DEFAULT_SUBMIT_LABEL = 'Email me a recovery link';

const StyledInput = styled(Input)`
	padding: 8px 12px;
`;

const StyledFormItem = styled(FormItem)`
	&&& {
		height: 68px;
		margin-bottom: 0;
		text-align: left;

		& .${(props) => props.theme['@ant-prefix']}-form-item-explain {
			font-size: 14px;
		}
	}
`;

interface PasswordRecoveryFormProps {
	onSubmit: (email: string) => Promise<void>;
	isSubmitting: boolean;
	buttonLabel?: string;
}

/**
 * [Presentational] Password recovery form
 * @param onSubmit callback when user submit the form
 * @param isSubmitting whether submission is in progress
 * @param buttonLabel label for submit button
 */
const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = (props) => {
	const { buttonLabel = DEFAULT_SUBMIT_LABEL, onSubmit, isSubmitting } = props;
	const [form] = Form.useForm();

	const handleSubmit = useCallback(
		(values: { email: string }) => {
			onSubmit(values.email);
		},
		[onSubmit]
	);

	return (
		<Form form={form} onFinish={handleSubmit} scrollToFirstError layout="horizontal">
			<StyledFormItem
				name="email"
				rules={[
					{
						type: 'email',
						message: 'The input is not a valid E-mail',
					},
					{
						required: true,
						message: 'Please input your E-mail',
					},
				]}
			>
				<StyledInput type="email" placeholder="Email" style={{ padding: '8px 12px' }} />
			</StyledFormItem>
			<StyledFormItem>
				<Button type="primary" htmlType="submit" style={{ width: '100%', padding: '8px 12px', height: 'auto' }} loading={isSubmitting}>
					{buttonLabel}
				</Button>
			</StyledFormItem>
		</Form>
	);
};

export default PasswordRecoveryForm;
