import { INotification, Notification } from '../models/redux';
import { EntitiesActionTypes } from './types';
import { NotificationType } from '@copilot/common/store/models/const/enum';

const itemType = Notification.modelName;
export namespace NotificationActions {
	export const loadNotifications = (notifications: Partial<INotification>[]) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: notifications,
	});

	export const removeNotifications = (notifications: Partial<INotification>[]) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: notifications,
	});

	export const updateNotification = (notification: Partial<INotification>) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: notification,
	});

	export const resetNotifications = () => ({
		type: EntitiesActionTypes.Reset,
		itemType,
	});

	/**
	 * Action for updating a notification count
	 * @param countUpdate number to be added to the current count
	 */
	export const updateNotificationCount = (notificationType: NotificationType, countUpdate: number) => ({
		type: 'UPDATE_NOTIFICATION_COUNT',
		payload: { id: notificationType, update: countUpdate },
	});
}
